<div
  class="flex flex-row items-start justify-start gap-[8px] pr-3 rounded p-2 m-1"
  [ngClass]="{ 'bg-[#e5f3ff]': IsSelected }"
  style="height: 80px; width: 96%">
  <!-- | date:'MMM d' -->
  <!-- | date:'EEEE, h:mm a' -->
  <div class="flex flex-col items-center pb-0 px-[4px]">
    <mat-icon class="w-4 h-4" [style.color]="LateColor" svgIcon="point"></mat-icon>
    <span class="pt-1  font-semibold">{{ Index + 1 }}</span>
  </div>
  <div fxFlex="75px" fxLayout="column" fxLayoutAlign="center start">
    <div class="mat-body-s text-[10px] ">{{ DigDate | date: 'EE h:mm a' }}</div>
    <div class="mat-overline text-sm ">{{ DigDate | date: 'MMM d' }}</div>
  </div>
  <div class="flex flex-col w-full">
    <div class="flex flex-row w-full justify-between items-start h-[49px]">
      <div>
        <div class=" text-primary capitalize m-0 font-[400] text-[14px] leading-[14px] tracking-[0.0071em]">
          {{ Address }}
        </div>
        <div class="mat-body-s font-medium ">{{ TypeOfWork.toUpperCase() }}</div>
      </div>
      <div class="flex flex-col flex-grow">
        <div class="flex flex-col items-end justify-end">
          <div
            class="mat-body-xs font-semibold text-right pb-1 "
            [style.color]="CallTypeDesc[0] ? CallTypeDesc[0].color : 'black'">
            {{ CallTypeDesc[0] ? CallTypeDesc[0].value.toUpperCase() : '' }}
          </div>
          <div class="mat-body-xs font-bold ">{{ RequestNumber }}</div>
        </div>
        <div *ngIf="prox.length > 0" class="flex flex-row items-center pt-4 justify-end">
          <div class="mat-body-xs"><mat-icon class="h-4" svgIcon="pin_black"></mat-icon></div>
          <div class="mat-body-xs font-semibold  text-nowrap overflow-hidden mb-[2px]">{{ prox }}</div>
        </div>
      </div>
    </div>

    <div class="h-[26px]" style="width: 100%; translate: -82px">
      <app-ticket-list-tags
        [showDamageThreatScore]="true"
        [AssignmentID]="AssignmentID"
        [LocateStatusID]="LocateStatusID"
        [CallTypeID]="CallTypeID"
        [showAll]="false"></app-ticket-list-tags>
    </div>
  </div>
</div>
<div class="flex justify-start">
  <div style="width: calc(100% - 12px); border-bottom: 1px solid rgba(0, 0, 0, 0.2)"></div>
</div>

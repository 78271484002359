<div class="w-[700px] bg-white rounded-lg inline-flex flex-col">
  <div class="px-6 pt-6 pb-2 flex items-center gap-6">
    <div class="flex-1">
      <div class="text-lg font-semibold text-slate-900">Impact - Insights</div>
      <div class="text-[13px] font-light text-slate-900">{{address}}</div>
    </div>
    <div>
      <button mat-icon-button (click)="onConfirm()">
        <mat-icon class="text-[26px]">close</mat-icon>
      </button>    </div>
  </div>
  <div class="flex flex-col items-start gap-4 h-[332px] px-6 py-4 w-[652px]">
 <div class="h-64 flex flex-col gap-2 flex-wrap">
    @for (impact of impactInsights; track $index) {
    <div class="flex flex-col gap-2 w-80 h-18">
        <div class="flex-1 px-3 py-2 bg-sky-50 rounded shadow-[0_1px_3px_0_rgba(0,0,0,0.18)] outline outline-1 outline-blue-500">
          <div class="flex items-center gap-6">
            <div class="w-16 h-16 rounded flex justify-center items-center">
              <mat-icon class="w-7 h-7" svgIcon={{impact.icon}}></mat-icon>
            </div>
            <div class="inline-flex flex-col justify-start items-start gap-2">
              <div class="justify-start text-black text-sm font-semibold font-['Inter'] leading-tight">{{impact.title}}</div>
              <div class="justify-start text-[#3E70D4] text-sm font-semibold font-['Inter'] leading-tight">{{impact.description}}</div>
              </div>
          </div>
        </div>
    </div>
  }
  </div>
  <div>
    <span class="text-[13px] italic font-light">*Our Impact Insights is based on the recent GIS data updated in the application. Please always refer to your GIS system for the latest asset information</span>
  </div>
  </div>
</div>
import {
  Component,
  Inject,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CompetersDeprecatedInputComponent } from '../../inputs/competers-deprecated-input/competers-deprecated-input.component';
import { SearchableDropdownComponent } from '../../inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersCheckboxComponent } from '../../inputs/competers-checkbox/competers-checkbox.component';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CompetersDatePickerComponent } from '../../inputs/competers-date-picker/competers-date-picker.component';
import { JsonForm, JsonFormControl } from '~lib/types/jsonForm';
import { CompetersRadioSelectComponent } from '../../inputs/competers-radio-select/competers-radio-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-json-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CompetersDeprecatedInputComponent,
    SearchableDropdownComponent,
    CompetersCheckboxComponent,
    MatIcon,
    MatDialogClose,
    CompetersDatePickerComponent,
    CommonModule,
    CompetersRadioSelectComponent,
    MatTooltipModule
  ],
  template: `
    <div class="relative size-fit">
      <div class="text-headline-6  font-semibold capitalize p-6">
        {{ jsonFormData.name }}
      </div>
      <div class="max-h-[500px]  px-6 py-1">
        <div
          [formGroup]="myForm"
          class="relative flex flex-col gap-6 justify-center items-center">
          @for (control of jsonFormData?.controls; track $index) {
            <div class="flex flex-row gap-6 w-96 justify-start items-center">
              @if (
                [
                  'text',
                  'number',
                  'password',
                  'email',
                  'search',
                  'tel',
                  'url'
                ].includes(control.type)
              ) {
                <div class="flex flex-col w-full gap-[6px] justify-between">
                  <label
                    for="{{ control.name }}"
                    class="flex flex-row items-center">
                    <div class="text-lg  font-semibold capitalize">
                      {{ control.label }}
                    </div>
                    @if (control.tooltip) {
                      <mat-icon
                        matTooltip="{{ control.tooltip }}"
                        class="pl-2 scale-75"
                        matTooltipPosition="right"
                        svgIcon="info" />
                    }
                  </label>
                  <div class="w-full h-8">
                    <app-competers-depricated-input
                      [fitParentContainer]="true"
                      [formControl]="getControl(control.name)"
                      [type]="control.type"
                      [placeholder]="
                        control.placeholder
                      "></app-competers-depricated-input>
                  </div>
                </div>
              } @else if (control.type === 'textarea') {
                <div class="flex flex-col w-full gap-[6px] justify-between">
                  <label
                    for="{{ control.name }}"
                    class="flex flex-row items-center">
                    <div class="text-lg  font-semibold capitalize">
                      {{ control.label }}
                    </div>
                    @if (control.tooltip) {
                      <mat-icon
                        matTooltip="{{ control.tooltip }}"
                        class="pl-2 scale-75"
                        matTooltipPosition="right"
                        svgIcon="info" />
                    }
                  </label>
                  <div class="w-full h-8">
                    <textarea
                      [formControl]="getControl(control.name)"
                      [placeholder]="control.placeholder"></textarea>
                  </div>
                </div>
              } @else if (control.type === 'checkbox') {
                <div class="flex flex-col w-full gap-[6px] justify-between">
                  <label
                    for="{{ control.name }}"
                    class="flex flex-row items-center">
                    <div class="text-lg  font-semibold capitalize">
                      {{ control.label }}
                    </div>
                    @if (control.tooltip) {
                      <mat-icon
                        matTooltip="{{ control.tooltip }}"
                        class="pl-2 scale-75"
                        matTooltipPosition="right"
                        svgIcon="info" />
                    }
                  </label>
                  <div class="w-full h-8">
                    <app-competers-checkbox
                      [checkStyle]="'checkmark'"
                      [formControl]="getControl(control.name)" />
                  </div>
                </div>
              } @else if (control.type === 'select') {
                <div
                  class="relative flex flex-col w-full gap-3 justify-between">
                  <label
                    for="{{ control.name }}"
                    class="flex flex-row items-center">
                    <div class="text-lg  font-semibold capitalize">
                      {{ control.label }}
                    </div>
                    @if (control.tooltip) {
                      <mat-icon
                        matTooltip="{{ control.tooltip }}"
                        class="pl-2 scale-75"
                        matTooltipPosition="right"
                        svgIcon="info" />
                    }
                  </label>
                  <div class="relative w-full h-8">
                    <app-searchable-dropdown
                      class="w-full"
                      [fitParentContainer]="true"
                      [multiple]="!!control.options['multiple']"
                      [formControl]="getControl(control.name)"
                      [options]="
                        control.dataSource ?? []
                      "></app-searchable-dropdown>
                  </div>
                </div>
              } @else if (['date', 'dateTime'].includes(control.type)) {
                <div class="flex flex-col w-full gap-3 justify-between">
                  <label
                    for="{{ control.name }}"
                    class="flex flex-row items-center">
                    <div class="text-lg  font-semibold capitalize">
                      {{ control.label }}
                    </div>
                    @if (control.tooltip) {
                      <mat-icon
                        matTooltip="{{ control.tooltip }}"
                        class="pl-2 scale-75"
                        matTooltipPosition="right"
                        svgIcon="info" />
                    }
                  </label>
                  <div class="w-full h-8">
                    <app-competers-date-picker
                      [title]="control.label"
                      [showTime]="control.type === 'dateTime'"
                      [dateFloor]="control.validations['minDate']"
                      [dateCeiling]="control.validations['maxDate']"
                      [formControl]="getControl(control.name)">
                    </app-competers-date-picker>
                  </div>
                </div>
              } @else if (control.type === 'radio') {
                <div class="flex flex-col w-full gap-3 justify-between">
                  <label
                    for="{{ control.name }}"
                    class="flex flex-row items-center">
                    <div class="text-lg  font-semibold capitalize">
                      {{ control.label }}
                    </div>
                    @if (control.tooltip) {
                      <mat-icon
                        matTooltip="{{ control.tooltip }}"
                        class="pl-2 scale-75"
                        matTooltipPosition="right"
                        svgIcon="info" />
                    }
                  </label>
                  <div class="w-full -ml-2.5">
                    <app-competers-radio-select
                      [title]="control.label"
                      [config]="control"
                      [formControl]="
                        getControl(control.name)
                      "></app-competers-radio-select>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
      @for (errorMessage of getErrorMessages(); track $index) {
        <span
          class=" text-warn self-start"
          *ngIf="!myForm.valid && !myForm.pristine">
          <p class="m-0 px-6">{{ errorMessage }}</p>
        </span>
      }
      <div
        class="flex justify-end items-center space-x-3 sticky bottom-0 bg-white p-2 pt-4">
        <button
          [mat-dialog-close]="false"
          class="flex justify-center items-center w-32 h-9 appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize pr-0">
          cancel
        </button>
        <button
          [disabled]="getErrorMessages().length > 0"
          (click)="submitForm()"
          class="flex justify-center items-center w-32 h-9 appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize">
          confirm
        </button>
      </div>
    </div>
  `,
})
export class JsonFormComponent implements OnInit, OnChanges {
  // IO
  @Input() jsonFormData: JsonForm;

  // Services
  private formBuilder = inject(FormBuilder);

  //members
  public myForm: FormGroup = this.formBuilder.group({});

  constructor(
    public dialogRef: MatDialogRef<JsonFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: JsonForm
  ) { }

  ngOnInit() {
    if (!this.jsonFormData && this.dialogData) {
      this.jsonFormData = this.dialogData;
      this.createForm(this.dialogData.controls);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.jsonFormData.firstChange) {
      this.createForm(this.jsonFormData.controls);
    }
  }

  createForm(controls: Array<JsonFormControl>) {
    controls.forEach((control) => {
      const validatorsToAdd = [];

      for (const [key, value] of Object.entries(control.validations)) {
        switch (key) {
          case 'min':
          case 'max':
            if (typeof value === 'number') {
              validatorsToAdd.push(
                key === 'min' ? Validators.min(value) : Validators.max(value)
              );
            }
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
          case 'maxLength':
            if (typeof value === 'number') {
              validatorsToAdd.push(
                key === 'minLength'
                  ? Validators.minLength(value)
                  : Validators.maxLength(value)
              );
            }
            break;
          case 'pattern':
            if (typeof value === 'string') {
              validatorsToAdd.push(Validators.pattern(value));
            }
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }

      // default the form value to be the minDate or today 
      // if you set the default date to today and have a floor value, the user 
      // can choose today as a date. We don't want that - we want the user to choose 
      // a date that is the current excavation date or later 
      let initialValue = null;
      if (['date', 'dateTime'].includes(control.type)) {
        initialValue = control.validations['minDate'] ?? new Date();
      }

      this.myForm.addControl(
        control.name,
        this.formBuilder.control(initialValue, validatorsToAdd)
      );
    });
  }

  getErrorMessages(): string[] {
    const errorMessages: string[] = [];

    this.jsonFormData.controls.forEach((control) => {
      const controlName = control.name;
      const controlLabel = control.label;
      const formControl = this.myForm.get(controlName);

      if (formControl && formControl.errors) {
        const controlErrors = formControl.errors;

        if (controlErrors['required']) {
          errorMessages.push(`${controlLabel} is required.`);
        }
        if (controlErrors['minlength']) {
          errorMessages.push(
            `${controlLabel} must be at least ${controlErrors['minlength'].requiredLength} characters long.`
          );
        }
        if (controlErrors['maxlength']) {
          errorMessages.push(
            `${controlLabel} must less than ${controlErrors['maxlength'].requiredLength} characters long.`
          );
        }
        if (controlErrors['email']) {
          errorMessages.push(`${controlLabel} must be a valid email address.`);
        }
        if (controlErrors['min']) {
          errorMessages.push(
            `${controlLabel} must be at least ${controlErrors['min'].min}.`
          );
        }
        if (controlErrors['max']) {
          errorMessages.push(
            `${controlLabel} must be no more than ${controlErrors['max'].max}.`
          );
        }
        if (controlErrors['pattern']) {
          errorMessages.push(`${controlLabel} format is invalid.`);
        }
        if (controlErrors['requiredTrue']) {
          errorMessages.push(`${controlLabel} must be checked.`);
        }
      }
    });

    return errorMessages;
  }

  getControl(name: string): FormControl {
    return this.myForm['controls'][name] as FormControl;
  }

  submitForm() {
    if (this.myForm.valid) {
      if (this.dialogRef) {
        this.dialogRef.close(this.myForm.value);
      }
    }
  }
}

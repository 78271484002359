import { inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { fromLonLat, Projection } from 'ol/proj';
import { Attribution } from 'ol/control';
import randomCity from '../utilities/cities';
import Overlay from 'ol/Overlay';
import { DrawingService, DrawingTypes } from '../drawing.service';
import { Layer } from 'ol/layer';
import { getCenter } from 'ol/extent';
import { MapData } from './drawing-manager.service';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

export type LonLat = [longitude: number, latitude: number];
export type LatLon = [latitude: number, longitude: number];

@Injectable({
  providedIn: 'root',
})
export class OpenLayersService implements OnDestroy {
  // services
  private ngZone = inject(NgZone);
  private drawingService = inject(DrawingService);

  // observables
  private destroy$: Subject<void> = new Subject();
  private _map$: BehaviorSubject<Map | null> = new BehaviorSubject(null);

  // members
  private _map: Map | null = null;
  private mapView: View | null = null;
  private canvasView: View | null = null;
  private _extent = [0, 0, 1024, 968];
  private _projection = new Projection({
    code: 'PIXELS',
    units: 'pixels',
    extent: this._extent,
  });

  constructor() {
    // using the default projection
    this.mapView = new View({
      center: fromLonLat(randomCity()),
      zoom: 18,
    });
    // using a pixel based projection
    this.canvasView = new View({
      zoom: 2,
      maxZoom: 8,
      center: getCenter(this._extent),
      projection: this._projection,

    });
  }

  public initMap() {
    if (this._map) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this._map = new Map({
        controls: [new Attribution({ collapsible: false })],
        pixelRatio: 1,
      });
      this._map$.next(this._map);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this._map$.complete();
  }

  flyToCoordinate(newCoords: LonLat, zoom: number = undefined) {
    this.mapView.setCenter(fromLonLat(newCoords));
    this.mapView.setZoom(zoom ?? 18);
  }

  attachOverlay(ref: Overlay) {
    this._map?.addOverlay(ref);
  }

  removeOverlay(ref: Overlay) {
    this._map?.removeOverlay(ref);
  }

  useCanvasView() {
    this.ngZone.runOutsideAngular(() => {
      this._map.setView(this.canvasView);
      this.canvasView.setCenter(getCenter(this._extent));
      this.canvasView.setZoom(2);
    });
  }

  setupCaptureParameters(mapData: MapData) {
    if (this.mapView) {
      this.mapView.setCenter(mapData.centerCoordinates);
      this.mapView.setZoom(mapData.zoomLevel);
      this.mapView.setResolution(mapData.resolution);
      this.mapView.setRotation(mapData.rotation);
    }
  }

  renderMap() {
    this.map.render();
  }

  refreshLayers() {
    this.map.getAllLayers().forEach((layer) => {
      if (layer instanceof VectorLayer && layer.getSource() instanceof VectorSource) {
        (layer.getSource() as VectorSource).refresh()
      }
    })
  }

  // getters
  get map$(): Observable<Map | null> {
    return this._map$.pipe();
  }

  get map(): Map {
    return this._map$.value;
  }

  set layers(layers: Layer[]) {
    this.ngZone.runOutsideAngular(() => {
      this._map?.setLayers(layers);
    });
  }

  get layers() {
    return this.map.getAllLayers();
  }

  set mapTarget(ref: HTMLElement) {
    this.ngZone.runOutsideAngular(() => {
      this._map?.setTarget(ref);
    });
  }

  set canvasExtent(extent: [number, number, number, number]) {
    this.ngZone.runOutsideAngular(() => {
      this._extent = extent;
    });
  }

  get resolution() {
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      return this.canvasView.getResolution();
    } else {
      return this.mapView.getResolution();
    }
  }

  get rotation() {
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      return this.canvasView.getRotation();
    } else {
      return this.mapView.getRotation();
    }
  }

  get projection() {
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      return this.canvasView.getProjection();
    } else {
      return this.mapView.getProjection();
    }
  }

  get extent() {
    let extent: number[];
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      extent = this._extent;
    } else {
      extent = this.mapView.calculateExtent();
    }
    return extent as [number, number, number, number];
  }

  get center(): [number, number] {
    let center: number[];
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      center = this.canvasView.getCenter();
    } else {
      center = this.mapView.getCenter();
    }
    if (center.length !== 2) {
      throw new Error("'center' contains > 2 coordinates");
    }
    return center as [number, number];
  }

  get zoom() {
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      return this.canvasView.getZoom();
    } else {
      return this.mapView.getZoom();
    }
  }

  useMapView() {
    this.ngZone.runOutsideAngular(() => {
      this._map.setView(this.mapView);
    });
  }
}

<div class="dense-6">
  <span class="pr-2 text-md  font-semibold capitalize" *ngIf="leadingText.length > 0">{{leadingText}}:
  </span>

  <mat-form-field appearance="outline" [ngClass]="{ 'mat-form-field-error': state.warning}">
    <mat-label *ngIf="label?.length > 0">{{ label }}</mat-label>
    <input matInput [placeholder]="placeholder" [formControl]="textFormControl" (input)="handleInputChange()" class="pt-[3px] text-md text-black-lighter
      readonly:text-ellipsis 
      readonly:whitespace-nowrap readonly:overflow-hidden 
      readonly:opacity-100 readonly:pointer-events-auto readonly:cursor-pointer" (keyup.enter)="saveEdit()">

    <mat-button class="pr-2 hover: cursor-pointer" matSuffix mat-icon-button
      *ngIf="state.readOnly && !readOnly; else readonly" (click)="toggleEdit()">
      <mat-icon svgIcon="edit" class="scale-90" />
    </mat-button>

    <mat-button class="pr-2 hover: cursor-pointer" matSuffix mat-icon-button *ngIf="state.warning" (click)="saveEdit()">
      <mat-icon svgIcon="warning" class="text-warn scale-90" />
    </mat-button>

    <mat-button class="pr-2 hover: cursor-pointer" matSuffix mat-icon-button *ngIf="state.edit" (click)="saveEdit()">
      <mat-icon svgIcon="yes-filled" class="text-success scale-90" />
    </mat-button>

    <mat-hint class="text-sm" *ngIf="validationError" align="end">{{ validationError }}</mat-hint>
    <ng-template #readonly>
      <mat-button class="pr-2 hover: cursor-not-allowed" matSuffix mat-icon-button *ngIf="state.readOnly">
        <mat-icon svgIcon="edit" class="scale-90 text-gray-300" />
      </mat-button>
    </ng-template>
  </mat-form-field>



</div>
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { Circle as CircleGeom, GeometryCollection, LineString, MultiLineString, Point, Polygon } from 'ol/geom';
import { Feature, Map } from 'ol';
import { StyleFunction } from 'ol/style/Style';
import { OpenLayersUtilities } from '../../classes/open-layers-utilities';
import { ShapeType, SymbolShape } from '../types';
import { StyleToolbox } from '../../classes/style-toolbox';
import { createEmpty, extend, getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

import measureLine from './measureLine';
import { Coordinate } from 'ol/coordinate';

export type StyleGenerator = (...args: any) => StyleFunction | Style | Style[];

const olUtilities = new OpenLayersUtilities();
const styleToolbox = new StyleToolbox();

function calculatePolygonRotation(poly: Polygon): number {
  if (poly) {
    try {
      const coords = poly.getCoordinates()[0];
      return Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]) - Math.PI;
    } catch (error) {
      throw new Error('rotation failed');
    }
  }
  return undefined;
}

function calculateImageScale(poly: Polygon, img: HTMLImageElement, olMap: Map): number {
  let scale = 1;
  if (poly) {
    try {
      const { width: geomWidth } = olUtilities.measureRectangle(poly, olMap);
      scale = geomWidth / img.naturalWidth;
    } catch (error) {
      console.log(error);
      throw new Error('scale failed');
    }
  }
  return scale;
}

const invisible = () => {
  const fill = new Fill({
    color: [255, 255, 255, 0],
  });
  const stroke = new Stroke({
    color: [255, 255, 255, 0],
    width: 3,
  });
  return new Style({
    image: new Circle({
      fill: fill,
      stroke: stroke,
      radius: 5,
    }),
    fill: fill,
    stroke: stroke,
  });
}

const baseStyle = () => {
  const fill = new Fill({
    color: [255, 255, 255, 1],
  });
  const stroke = new Stroke({
    color: [255, 255, 255, 1],
    width: 3,
  });
  return new Style({
    image: new Circle({
      fill: fill,
      stroke: stroke,
      radius: 5,
    }),
    fill: fill,
    stroke: stroke,
  });
}

function setupStroke(
  strokeColour: string,
  strokeWidth: number,
  strokeDasharray: string,
) {
  const stroke = new Stroke({
    color: [51, 153, 204, 0.8],
    width: 3,
  })
  if (typeof strokeColour === 'string' && strokeColour.length === 7) {
    stroke.setColor(styleToolbox.hexToRGBA(strokeColour, 100))
  }
  if (![null, undefined].includes(strokeWidth)) {
    stroke.setWidth(Number(strokeWidth))
  }
  if (![null, undefined].includes(strokeDasharray)) {
    stroke.setLineDash(strokeDasharray.split(',').map(x => Number(x)))
  }
  return stroke;
}

function setupFill(
  fillColour: string,
  fillOpacity: number,
) {
  const fill = new Fill({
    color: [51, 153, 204, 0.8],
  })
  let opacity = 100;

  if (![null, undefined].includes(fillOpacity)) {
    opacity = Number(fillOpacity)
  }

  if (typeof fillColour === 'string' && fillColour.length === 7) {
    fill.setColor(styleToolbox.hexToRGBA(fillColour, opacity))
  }
  return fill;
}

function addLineText(feature: Feature) {
  const text = feature.get('text_label');
  const textStyle = new Text({
    font: '12px Calibri,sans-serif',
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
    text: text,
    placement: 'line',
    repeat: 150,
  });
  return textStyle;
}

const defaultStyle: StyleFunction = (feature: Feature, resolution) => {
  const style = new Style();
  const {
    stroke_colour,
    stroke_width,
    stroke_dasharray,
    fill_colour,
    fill_opacity,
    text_label
  } = feature.getProperties();

  const stroke: Stroke = setupStroke(
    stroke_colour,
    stroke_width,
    stroke_dasharray,
  );

  style.setStroke(stroke);

  const fill: Fill = setupFill(
    fill_colour,
    fill_opacity,
  );
  style.setFill(fill);

  if (![null, undefined].includes(text_label)) {
    const text = addLineText(feature);
    style.setText(text);
  }

  return style;
}

const arrowLine = (width: number, color: number[]) =>
  (feature: Feature<LineString>) => {
    const geom = feature.getGeometry();
    const isLegacy = feature.get("shape_type_id") === ShapeType.ArrowLineLegacy;
    let start: Coordinate, end: Coordinate;
    if (isLegacy) {
      start = geom.getFirstCoordinate();
      end = geom.getLastCoordinate();
    } else {
      end = geom.getFirstCoordinate();
      start = geom.getLastCoordinate();
    }
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx) + 90 * (Math.PI / 180);
    const length = Math.sqrt(dx * dx + dy * dy);
    const arrowLength = length / 10;
    const mid = end;
    const arrowHead = new MultiLineString([
      [end, [end[0] - arrowLength / 2, end[1] + arrowLength]],
      [end, [end[0] + arrowLength / 2, end[1] + arrowLength]],
      [end, end],
    ]);

    arrowHead.rotate(rotation, mid);

    const stroke = new Stroke({ width, color });

    // linestring
    return [
      new Style({ stroke }),
      new Style({
        geometry: arrowHead,
        stroke,
      }),
    ];
  }

const text = (map: Map, styleToolbox: StyleToolbox) => {
  const textStroke = new Stroke({
    color: [0, 0, 0, 1],
    width: 0.5,
  });

  const textFill = new Fill({
    color: [0, 0, 0, 1]
  })

  const textBackgroundStroke = new Stroke({
    color: [0, 0, 0, 1],
    width: 0.5,
  });

  const textBackgroundFill = new Fill({
    color: [0, 0, 0, 0]
  })

  const textStyle = new Text({
    font: '14px Calibri,sans-serif',
    justify: 'left',
    text: 'error',
    stroke: textStroke,
    fill: textFill,
    backgroundStroke: textBackgroundStroke,
    backgroundFill: textBackgroundFill,
    overflow: true,
  })

  const style = new Style({
    fill: new Fill({
      color: [0, 0, 0, 0],
    }),
    stroke: new Stroke({
      color: [0, 0, 0, 0],
      width: 1,
    }),
    text: textStyle
  })

  return (feature: Feature, resolution): Style => {
    try {
      const strokeColour: string = feature.get('text_colour');
      const fillColour = feature.get('fill_colour');
      const opacity = feature.get('fill_opacity');
      const textContent = feature.get('text_label');

      if (strokeColour) {
        textStroke.setColor(styleToolbox.hexToRGBA(strokeColour, opacity * 100 || 100));
        textFill.setColor(styleToolbox.hexToRGBA(strokeColour, opacity * 100 || 100));
      } else {
        textStroke.setColor(null);
        textFill.setColor(null);
      }

      if (fillColour) {
        textBackgroundStroke.setColor(styleToolbox.hexToRGBA(fillColour, opacity * 100 || 100));
        textBackgroundFill.setColor(styleToolbox.hexToRGBA(fillColour, opacity * 100 || 100));
      } else {
        textBackgroundStroke.setColor([0, 0, 0, 0]);
        textBackgroundFill.setColor([0, 0, 0, 0]);
      }
      const padding = [0, 0, 0, 0];

      textStyle.setPadding(padding);

      textStyle.setText(textContent);

      textStyle.setRotation(((): number => {
        const geometry = feature.getGeometry() as Polygon;
        let rot = 0;
        if (geometry) {
          try {
            const coords = geometry?.getCoordinates()[0];
            rot = Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]) - Math.PI;
          } catch (error) {
            throw new Error('rotation failed');
          }
        }
        return rot;
      })());

      textStyle.setScale((() => {
        const geometry = feature.getGeometry() as Polygon;
        if (geometry) {
          let scale = 1;
          try {
            // measure the text width in pixels
            const { width: textWidth } = olUtilities.measureText(
              feature.get('text_label'),
              '14px Calibri,sans-serif',
              padding
            );
            const { width: geomWidth } = olUtilities.measureRectangle(geometry, map);
            scale = geomWidth / textWidth;
          } catch (error) {
            throw new Error('scale failed');
          }
          return scale;
        } else {
          return 1;
        }
      })());

      return style;
    } catch (error) {
      console.log(error);
      return new Style();
    }
  };
}

const sticker =
  (img: HTMLImageElement, map: Map): ((feat: Feature, num: number) => Array<Style>) =>
    (feature: Feature): Array<Style> => {
      try {
        let geoms;
        const isCollection = feature.getGeometry() instanceof GeometryCollection;
        if (isCollection) {
          geoms = (feature.getGeometry() as GeometryCollection).getGeometries();
        } else {
          geoms = [feature.getGeometry()];
        }
        const poly = geoms.filter((geom) => geom instanceof Polygon)[0] as Polygon;
        const pointStyle = new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
          image: new Icon({
            crossOrigin: 'anonymous',
            src: img.src,
            rotation: calculatePolygonRotation(poly),
            scale: calculateImageScale(poly, img, map),
          }),
        });
        const polyStyle = new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
        });
        return [pointStyle, polyStyle];
      } catch (error) {
        console.log(error);
        return [];
      }
    }

const symbol = (symbol: Array<SymbolShape>, olMap: Map) => {
  // return the style function, which will be called for each feature / render
  return (feature: Feature, resolution: number): Array<Style> => {
    // pull this stuff out of the style function, so it only gets called once
    const styles: Style[] = [];
    try {
      const fullExtent = createEmpty();
      symbol.forEach((shape) => {
        if (shape.geometry instanceof Point) {
          if (shape.radius && shape.radius !== 0) {
            const circle = new CircleGeom((shape.geometry as Point).getCoordinates(), shape.radius);
            extend(fullExtent, circle.getExtent());
          } else if (shape.text && shape.text !== '') {
            const { width, height } = olUtilities.measureText(shape.text, `${shape.width}px Arial`);
            const rect = olUtilities.createRectangleGeometry(olMap, shape.geometry.getCoordinates(), width, height);
            rect.scale(1 / resolution);
            extend(fullExtent, rect.getExtent());
          }
        } else {
          extend(fullExtent, shape.geometry.getExtent());
        }
      });
      const centroid = getCenter(fullExtent);
      const { width: extentWidth } = olUtilities.measureRectangle(fromExtent(fullExtent), olMap);
      const geometry = feature.getGeometry() as Polygon;
      const polyCenter = getCenter(geometry.getExtent());
      const diff: [number, number] = [polyCenter[0] - centroid[0], polyCenter[1] - centroid[1]];
      const scale = ((): number => {
        let scale = 1;
        if (geometry) {
          const { width: geomWidth } = olUtilities.measureRectangle(geometry, olMap);
          scale = geomWidth === 0 || extentWidth === 0 ? 1 : geomWidth / extentWidth;
        } else {
          // do nothing
        }
        return scale;
      })();

      const rotation = ((): number => {
        let rot = 0;
        if (geometry) {
          try {
            const coords = geometry?.getCoordinates()[0];
            rot = Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]);
          } catch (error) {
            throw new Error('rotation failed');
          }
        }
        return -rot;
      })();
      styles.push(
        new Style({
          fill: new Fill({
            color: [153, 51, 255, 0],
          }),
          stroke: new Stroke({
            color: [153, 51, 255, 0],
          }),
        })
      );
      symbol.forEach((symbolShape: SymbolShape) => {
        switch (symbolShape.geojson.type) {
          case 'Point':
            if (symbolShape.text && symbolShape.text !== '') {
              styles.push(
                new Style({
                  geometry: () => {
                    const poly = symbolShape.geometry.clone();
                    poly.translate(diff[0], diff[1]);
                    poly.rotate(rotation, polyCenter);
                    poly.scale(scale, scale, polyCenter);
                    return poly;
                  },
                  text: new Text({
                    text: symbolShape.text,
                    font: `${symbolShape.width}px Arial`,
                    fill: new Fill({
                      color: [0, 0, 0, 1],
                    }),
                    scale: scale / resolution,
                    rotation: -rotation,
                  }),
                })
              );
            } else if (symbolShape.radius && symbolShape.radius !== 0) {
              styles.push(
                new Style({
                  geometry: () => {
                    const poly = new CircleGeom((symbolShape.geometry as Point).getCoordinates(), symbolShape.radius);
                    poly.translate(diff[0], diff[1]);
                    poly.scale(scale, scale, polyCenter);
                    poly.rotate(rotation, polyCenter);
                    return poly;
                  },
                  fill: new Fill({
                    color: [125, 125, 125, 0.01],
                  }),
                  stroke: new Stroke({
                    color: [0, 0, 0, 1],
                    width: 1,
                  }),
                })
              );
            }
            break;
          default:
            styles.push(
              new Style({
                geometry: () => {
                  const poly = symbolShape.geometry.clone();
                  poly.translate(diff[0], diff[1]);
                  poly.rotate(rotation, polyCenter);
                  poly.scale(scale, scale, polyCenter);
                  return poly;
                },
                stroke: new Stroke({
                  color: [0, 0, 0, 1],
                  width: symbolShape.width,
                }),
                fill: new Fill({
                  color: [0, 0, 0, 0],
                }),
              })
            );
            break;
        }
      });
    } catch (e) {
      console.warn('Caught error in symbol style function');
    }
    return styles;
  };
}

const svgSymbol = (img: HTMLImageElement, olMap: Map) => {
  // return the style function, which will be called for each feature / render
  return (feature: Feature, resolution: number): Array<Style> => {
    try {
      let geoms;
      const isCollection = feature.getGeometry() instanceof GeometryCollection;
      if (isCollection) {
        geoms = (feature.getGeometry() as GeometryCollection).getGeometries();
      } else {
        geoms = [feature.getGeometry()];
      }
      const poly = geoms.filter((geom) => geom instanceof Polygon)[0] as Polygon;
      const pointStyle = new Style({
        fill: new Fill({
          color: [0, 0, 0, 0],
        }),
        stroke: new Stroke({
          color: [0, 0, 0, 0],
          width: 1,
        }),
        image: new Icon({
          crossOrigin: 'anonymous',
          src: img.src,
          rotation: calculatePolygonRotation(poly),
          scale: calculateImageScale(poly, img, olMap),
        }),
      });
      const polyStyle = new Style({
        fill: new Fill({
          color: [0, 0, 0, 0],
        }),
        stroke: new Stroke({
          color: [0, 0, 0, 0],
          width: 1,
        }),
      });
      return [pointStyle, polyStyle];
    } catch (error) {
      console.log(error);
      return [];
    }
  };
}

const selectedStyle = (): Style =>
  new Style({
    fill: new Fill({
      color: [153, 224, 255, 0],
    }),
    stroke: new Stroke({
      color: [13, 101, 176],
      width: 1.5,
      lineDash: [30, 10],
    }),
  })

const digSiteShape = () => (): Style =>
  new Style({
    fill: new Fill({
      color: [255, 255, 255, 0.4],
    }),
    stroke: new Stroke({
      color: [65, 64, 66],
      width: 3,
    }),
  })

const locateArea = () =>
  new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: 2,
    }),
  })


export default {
  invisible,
  defaultStyle,
  baseStyle,
  arrowLine,
  measureLine,
  text,
  sticker,
  symbol,
  svgSymbol,
  selectedStyle,
  digSiteShape,
  locateArea
};

import { ImpactAndRiskColors } from "../utils/UrbintColors";

export const sections = [
  {
    title: 'combined',
    description: [
      'Mix of Locate Status and Call Types.',
      'Ticket Markers are colored based on the status of each ticket.',
    ],
    pins: [
      { label: 'standard', color: '#858585' },
      { label: 'emergency', color: '#FFFF00' },
      { label: 'private', color: '#323232' },
      { label: 'completed', color: '#0000FF' },
      { label: 'cancelled', color: '#FF1493' },
      { label: 'assistance needed', color: '#8B008B' },
      { label: 'meeting required', color: '#008000' },
    ],
  },
  {
    title: 'Damage Threat',
    description: [`Ticket markers are colored based on how severe the ticket is, according to the following:`],
    pins: [
      { label: 'Very High', color: ImpactAndRiskColors["Very High"] },
      { label: 'High', color: ImpactAndRiskColors["High"] },
      { label: 'Medium', color: ImpactAndRiskColors["Medium"] },
      { label: 'Low', color: ImpactAndRiskColors["Low"] },
      { label: 'Missing Data', color: ImpactAndRiskColors["Missing Data"] }
    ],
  },
  {
    title: 'Impact',
    description: [`Ticket markers are colored based on how severe the ticket is, according to the following:`],
    pins: [
      { label: 'Very High', color: ImpactAndRiskColors["Very High"] },
      { label: 'High', color: ImpactAndRiskColors["High"] },
      { label: 'Medium', color: ImpactAndRiskColors["Medium"] },
      { label: 'Low', color: ImpactAndRiskColors["Low"] },
      { label: 'Missing Data', color: ImpactAndRiskColors["Missing Data"] }
    ],
  },
  {
    title: 'compliance',
    description: [`Ticket markers are colored based on how near due the ticket is, according to the following:`],
    pins: [
      { label: 'completed', color: '#0000FF' },
      { label: 'late', color: '#FF0000' },
      { label: '< 1', color: '#FF6E00' },
      { label: '< 2', color: '#FFC800' },
      { label: '< 3', color: '#FFFF00' },
      { label: '< 4', color: '#C8FF00' },
      { label: '5+', color: '#00FF00' },
    ],
  },
  {
    title: 'locators',
    description: [`Ticket markers are colored based on which locator they are assigned to.`],
    pins: [],
  },
  {
    title: 'locate status',
    description: [`Ticket markers are colored based on the specific locate status of the ticket.`],
    pins: [
      { label: 'system analyzing', color: '#323232' },
      { label: 'ready for dispatching', color: '#858585' },
      { label: 'pre-sketched', color: '#D3D3D3' },
      { label: 'dispatched to locator', color: '#20B2AA' },
      { label: 'in the field', color: '#00BFFF' },
      { label: 'completed', color: '#0000FF' },
      { label: 'assistance needed', color: '#8B008B' },
      { label: 'cancelled', color: '#FF1493' },
    ],
  },
  {
    title: 'call type',
    description: [`Ticket markers are colored based on the specific call type of the ticket.`],
    pins: [
      { label: 'emergency', color: '#FFFF00' },
      { label: 'priority', color: '#FFA500' },
      { label: 'standard', color: '#858585' },
      { label: 'project', color: '#D3D3D3' },
      { label: 'private', color: '#323232' },
      { label: 'planning', color: '#EE82EE' },
      { label: 'sewer lateral', color: '#00BFFF' },
    ],
  },
];



export type TicketPinLegendSection = (typeof sections)[number];
export type TicketPinLegendPin = TicketPinLegendSection['pins'][number];

import { Component, computed, Inject, inject, Injectable, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { api, apiKeys } from '../../../ENDPOINTS';
import { ApiService, UtilocateApiRequest } from '../../../modules/core/api/baseapi.service';
import {
  BehaviorSubject,
  combineLatestWith,
  concatMap,
  distinctUntilChanged,
  firstValueFrom,
  from,
  iif,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { first, map, tap, toArray } from 'rxjs/operators';
import { SnackbarService } from '../../../modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../modules/shared/snackbar/snackbar/snackbar';
import { isEqual } from 'lodash-es';
import { ProgressBarService } from '../../../modules/shared/progress-bar/progress-bar.service';
import { TicketMapService } from '../../components/maps/open-layers/ticket-map/ticket-map.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ButtonDropdownSelectComponent } from '../../components/inputs/button-dropdown-select/button-dropdown-select-select.component';
import { AsyncPipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export type PreviousSearch = {
  criteria: SearchCriteria[];
  found: number;
  date: Date;
};

export type TicketSearchSettings = {
  ticketLimit: number;
};

const globalTicketLimitMax = 20000;
const globalTicketLimitMin = 500;

@Injectable({
  providedIn: 'root',
})
export class TicketSearchService {
  // services
  private dialogService = inject(MatDialog);
  private snackBarService: SnackbarService = inject(SnackbarService);
  private progressBarService = inject(ProgressBarService);
  private ticketMapService = inject(TicketMapService);

  // members
  private _defaultSearchFilterValue: { filters: Record<number, TicketFilter>; categories: Record<any, any> } = {
    filters: null,
    categories: null,
  };
  private _searchFilters$: BehaviorSubject<typeof this._defaultSearchFilterValue> = new BehaviorSubject(
    this.defaultSearchFilterValue
  );
  private updatedTemplateData: TemplateColumn[] = [];
  private _lastSearch$$: WritableSignal<PreviousSearch> = signal(null);

  // Signals and Observables
  private destroy$ = new Subject<void>();
  private _lastCaller: unknown = null;
  private _lastCaller$ = new BehaviorSubject<unknown>(this._lastCaller);

  private _searchResults: SearchResult = {
    fields: [],
    result: [],
  };
  private _searchResults$ = new BehaviorSubject<SearchResult>(this._searchResults);

  private _savedSearches: [] = [];
  private _savedSearches$ = new BehaviorSubject<any[]>(this._savedSearches);

  private _formData$ = new BehaviorSubject<object>(null);

  private templateData: TemplateColumn[] = [];
  private templateDataObservable = new BehaviorSubject<TemplateColumn[]>(this.templateData);

  private _advancedSearchIsOpen = false;
  private _advancedSearchIsOpen$ = new BehaviorSubject<boolean>(this._advancedSearchIsOpen);

  private _editingSearch = false;
  private _editingSearch$ = new BehaviorSubject<boolean>(this._editingSearch);

  private _prefillSearch: SavedSearch = null;
  private _prefillSearch$ = new BehaviorSubject<SavedSearch>(this._prefillSearch);

  private _ticketSearchSettings$ = new BehaviorSubject<TicketSearchSettings>({
    ticketLimit: 5000,
  });

  constructor(private utilocateApiService: ApiService) {
    this.makeAPIRequest({ query: { ticketSearchFilters: { all: true } } })
      .pipe(first())
      .subscribe(({ body }) => {
        if (body) {
          this._defaultSearchFilterValue = body.ticketSearchFilters;
        }
        this._searchFilters$.next(this._defaultSearchFilterValue);
      });
  }

  clearLastSearch() {
    this._lastSearch$$.set(null);
    this._searchResults = {
      fields: [],
      result: [],
    };
    this._searchResults$.next(this._searchResults);
  }

  private makeAPIRequest(body: QueryBody): Observable<any> {
    if (body) {
      const url = apiKeys.u2.savedSearchController;
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.savedSearchController,
        API_TYPE: type,
        API_BODY: body,
      };

      return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
    } else {
      return of({ ok: false });
    }
  }

  refreshSavedSearches(): Observable<Record<string, any>> {
    const requestBody: QueryBody = {
      query: {
        savedSearches: {
          all: true,
        },
      },
    };
    return this.makeAPIRequest(requestBody).pipe(
      first(),
      map((event) => {
        if (event.ok && event.body && event.body.savedSearches) {
          this._savedSearches = event.body.savedSearches;
          this._savedSearches$.next(this._savedSearches);
          return 'success';
        } else {
          return null;
        }
      }),
      switchMap((result) =>
        iif(
          () => result === 'success',
          from(this._savedSearches).pipe(
            concatMap((search: SavedSearch) =>
              this.makeAPIRequest({
                query: {
                  searchResults: {
                    count: true,
                    searchCriteria: search.SavedSearchCriteria,
                  },
                },
              }).pipe(
                tap((result) => {
                  if (
                    result &&
                    result.ok &&
                    result.body &&
                    result.body.searchResults &&
                    result.body.searchResults.count
                  ) {
                    search.Count = result.body.searchResults.count;
                  }
                })
              )
            ),
            toArray(),
            tap(() => this._savedSearches$.next(this._savedSearches)),
            map(() => ({ search: 'success', count: 'success' }))
          ),
          of({ search: null, count: null })
        )
      )
    );
  }

  async fetchNewSearchResults(lastCaller: unknown, searchFields: SearchCriteria[], limit?: number): Promise<unknown> {
    this.lastCaller = lastCaller;
    const globalLimit = this._ticketSearchSettings$.value.ticketLimit;
    if (!limit) {
      limit = globalLimit;
    }
    const resultsArr = [];
    const getResults = async (offset: number, queryLimit: number) => {
      if (offset + queryLimit > globalLimit) {
        queryLimit = globalLimit - offset;
      }
      const event = await firstValueFrom(
        this.makeAPIRequest({
          query: {
            searchResults: {
              searchCriteria: searchFields,
              limit: queryLimit,
              offset,
            },
          },
        })
      );
      if (event && event.body && event.status === 200) {
        const results = event.body.searchResults?.results;
        if (!results) {
          throw new Error(`Warning: Some search results may be missing`);
        }

        //if we don't have fields, set them
        if (this._searchResults.fields.length == 0) {
          this._searchResults.fields = results.fields;
        }
        //append the result data
        this._searchResults.result = this._searchResults.result.concat(results.result); // Append the results to _searchResults
      } else {
        throw new Error(`Warning: Failed to fetch results`);
      }

      //update the search history as we get more data back
      this._lastSearch$$.update(() => ({
        criteria: searchFields,
        found: this._searchResults.result.length,
        date: new Date(),
      }));
    };

    //calculate the number of entries we need to get
    const result = await firstValueFrom(
      this.makeAPIRequest({
        query: {
          searchResults: {
            count: true,
            searchCriteria: searchFields,
          },
        },
      })
    );
    //find the number of 'pages' we need (groups of "limit" tickets)
    let searchTotal = result?.body?.searchResults?.count;
    if (result.status !== 200 || searchTotal === undefined) {
      throw new Error(`Warning: Some search results may be missing`);
    }
    if (searchTotal === 0) {
      //update the search history as we get more data back
      this._searchResults$.next({
        fields: [],
        result: [],
      });
      this._lastSearch$$.update(() => ({
        criteria: searchFields,
        found: 0,
        date: new Date(),
      }));
      return 'ok';
    }
    if (searchTotal > globalLimit) {
      searchTotal = globalLimit;
    }

    const pagination = Math.min(searchTotal, limit);
    const resultsPerPage = 1000;
    const pageCount = Math.ceil(pagination / resultsPerPage);

    //clear last search result
    this._searchResults = {
      fields: [],
      result: [],
    };

    //call api to get tickets for each group of tickets
    for (let i = 0; i < pageCount; i++) {
      resultsArr.push(getResults(resultsPerPage * i, resultsPerPage));
    }

    await Promise.all(resultsArr);
    this._searchResults$.next(this._searchResults); //update the last time we get the data (this includes all data)
    return 'ok';
  }

  getASearch(requestBody): Observable<any> {
    return this.makeAPIRequest(requestBody).pipe(
      first(),
      map((event) => {
        if (event && event.body && event.status === 200) {
          return event.body.searchResults.results;
        } else {
          return {
            fields: [],
            result: [],
          };
        }
      })
    );
  }

  refreshSearchResults() {
    this.progressBarService.start();
    this.ticketMapService.dontFly = true;
    if (this._lastSearch$$() && this._lastSearch$$().criteria) {
      this.fetchNewSearchResults(this, this._lastSearch$$().criteria)
        .then(() => {
          this.snackBarService.openSnackbar('Refreshed search results', SnackbarType.success);
        })
        .catch(() => {
          this.snackBarService.openSnackbar('Error refreshing search results', SnackbarType.error);
        })
        .finally(() => this.progressBarService.stop());
    } else {
      this.snackBarService.openSnackbar('No results', SnackbarType.default);
    }
  }

  fetchFormData(): void {
    if (this._formData$.value && !isEqual(this._formData$.value, {})) {
      return;
    }
    const requestBody: QueryBody = {
      query: {
        formData: {
          all: true,
        },
      },
    };
    this.makeAPIRequest(requestBody)
      .pipe(first())
      .subscribe((event) => {
        try {
          this._formData$.next(event.body.formData);
        } catch (e) {
          console.error('Error fetching form data:', e);
        }
      });
  }

  get formData$(): Observable<any> {
    return this._formData$;
  }

  fetchDefaultTemplate(): void {
    const requestBody: QueryBody = {
      query: {
        template: {
          userDefault: true,
        },
      },
    };
    this.makeAPIRequest(requestBody)
      .pipe(first())
      .subscribe((event) => {
        // unsubscribes itself immediately on 'next'
        if (event.body.template.rows) {
          if (event.body.template.rows.length > 0) {
            this.templateData = event.body.template.rows;
            this.templateDataObservable.next(this.templateData);
          } else {
            this.createDefaultTemplate();
          }
        }
      });
  }

  createDefaultTemplate(): void {
    const requestBody: QueryBody = {
      mutation: {
        create: {
          template: {
            userDefault: true,
          },
        },
      },
    };
    this.makeAPIRequest(requestBody)
      .pipe(first())
      .subscribe((newEvent) => {
        if (newEvent.statusText === 'OK') {
          this.fetchDefaultTemplate();
        }
      });
  }

  getTemplate(): Observable<any> {
    return this.templateDataObservable;
  }

  updateTemplateData(updatedData: TemplateColumn[]) {
    this.updatedTemplateData = updatedData;
  }

  saveTemplateData() {
    const requestBody: QueryBody = {
      mutation: {
        update: {
          template: {
            templateColumns: this.updatedTemplateData,
          },
        },
      },
    };
    return from(this.makeAPIRequest(requestBody));
  }

  createSavedSearch(searchName: string, criteria: SearchCriteria[]): Observable<any> {
    let result: Observable<any>;
    if (searchName === null || searchName === '') {
      result = of({ ok: false, message: 'Bad name: cannot be null.' });
    } else if (criteria.length <= 0) {
      result = of({ ok: false, message: 'Bad criteria: nothing to save.' });
    } else {
      const requestBody: QueryBody = {
        mutation: {
          create: {
            savedSearch: {
              SearchName: searchName,
              isVisibleClientWide: 0,
              canEditSavedSearch: 1,
              SavedSearchCriteria: criteria,
            },
          },
        },
      };
      result = from(this.makeAPIRequest(requestBody));
    }
    return result;
  }

  deleteSavedSearch(search: SavedSearch): Observable<any> {
    const requestBody: QueryBody = {
      mutation: {
        delete: {
          savedSearch: {
            SavedSearchID: search.SavedSearchID,
            ClientID: search.ClientID,
          },
        },
      },
    };
    return this.makeAPIRequest(requestBody);
  }

  updateSavedSearch(searchId: number, newName = '', newCriteria: SearchCriteria[] = []): Observable<any> {
    const toUpdate = this.getSavedSearchByID(searchId);
    if (toUpdate) {
      if (newName !== '') {
        toUpdate.SearchName = newName;
      }
      if (newCriteria.length > 0) {
        toUpdate.SavedSearchCriteria = newCriteria;
      }
      const requestBody: QueryBody = {
        mutation: {
          update: {
            savedSearch: toUpdate,
          },
        },
      };
      return from(this.makeAPIRequest(requestBody));
    } else {
      return of({ ok: false, message: 'Bad search ID: no search found.' });
    }
  }

  getFilterFromID(id: number): TicketFilter {
    return this._searchFilters$.value.filters[id] ?? null;
  }

  getSavedSearchByID(id: number): SavedSearch | null {
    return this._savedSearches.reduce((acc, curr: SavedSearch) => {
      if (acc !== null) {
        return acc;
      } else {
        if (curr.SavedSearchID === id) {
          return curr;
        } else {
          return null;
        }
      }
    }, null);
  }

  editLast() {
    this.prefillSearch = {
      SearchName: 'Last Search',
      SavedSearchID: -1,
      ClientID: -1,
      SavedSearchCriteria: this._lastSearch$$().criteria,
    };
    this.editingSearch = true;
    this.advancedSearchIsOpen = true;
  }

  public triggerSettingsDialog() {
    const dialog = this.dialogService.open(TicketSettingsDialogComponent, {
      width: 'fit-content',
      height: 'fit-content',
      panelClass: ['mat-dialog-overflow', 'p-6'],
      data: {
        ticketLimit: this._ticketSearchSettings$.value.ticketLimit,
      } as TicketSearchSettings,
    });

    firstValueFrom(dialog.afterClosed()).then((result) => {
      if (result) {
        this.globalTicketLimit = result.ticketLimit;
      }
    });
  }

  get advancedSearchIsOpen$(): Observable<boolean> {
    return this._advancedSearchIsOpen$.pipe();
  }

  get advancedSearchIsOpen(): boolean {
    return this._advancedSearchIsOpen;
  }

  set advancedSearchIsOpen(isOpen: boolean) {
    this._advancedSearchIsOpen = isOpen;
    this._advancedSearchIsOpen$.next(this._advancedSearchIsOpen);
  }

  get editingSearch$(): Observable<boolean> {
    return this._editingSearch$.pipe();
  }

  set editingSearch(isEditing: boolean) {
    if (this._editingSearch && !isEditing) {
      this.prefillSearch = null;
      this._prefillSearch$.next(this._prefillSearch);
    }
    this._editingSearch = isEditing;
    this._editingSearch$.next(this._editingSearch);
  }

  get prefillSearch$(): Observable<SavedSearch> {
    return this._prefillSearch$.pipe();
  }

  set prefillSearch(search: SavedSearch) {
    this._prefillSearch = search;
    this._prefillSearch$.next(this._prefillSearch);
  }

  get searchFilters$(): Observable<{ filters: Record<number, TicketFilter>; categories: Record<any, any> }> {
    return this._searchFilters$.pipe();
  }

  get defaultSearchFilterValue(): { filters: Record<number, TicketFilter>; categories: Record<any, any> } {
    return this._defaultSearchFilterValue;
  }

  get lastSearch$$(): Signal<PreviousSearch> {
    return computed(() => this._lastSearch$$());
  }

  get savedSearches$(): Observable<any> {
    return this._savedSearches$.pipe(
      combineLatestWith(this._searchFilters$),
      map(([criteriaArray, { filters }]) => {
        if (!filters || isEqual(filters, {})) {
          return criteriaArray;
        } else {
          return criteriaArray.map((search) => {
            search.SavedSearchCriteria = search.SavedSearchCriteria.map(
              (criteria: {
                FilterName: string;
                FilterID: string | number;
                DataTypeID: number;
                Value: any;
                ValueDescription: any;
              }) => {
                criteria.FilterName = filters[criteria.FilterID].visibleName;
                criteria.DataTypeID = filters[criteria.FilterID].dataTypeID;
                const selection =
                  filters[criteria.FilterID].options?.find((option) => {
                    return option.id == criteria.Value;
                  }) ?? undefined;
                // get the key that is not 'id' and use that as the description
                criteria.ValueDescription = selection
                  ? selection[Object.keys(selection).find((key) => key != 'id')]
                  : criteria.Value;
                return criteria;
              }
            );
            return search;
          });
        }
      })
    );
  }

  get lastCaller$(): Observable<any> {
    return this._lastCaller$.pipe();
  }

  get lastCaller(): unknown {
    return this._lastCaller;
  }

  set lastCaller(caller: unknown) {
    this._lastCaller = caller;
    this._lastCaller$.next(this._lastCaller);
  }

  get searchResults$(): Observable<any> {
    return this._searchResults$.pipe(map(({ result }) => result));
  }

  get ticketSearchSettings$(): Observable<TicketSearchSettings> {
    return this._ticketSearchSettings$.pipe(distinctUntilChanged());
  }

  get globalTicketLimit(): TicketSearchSettings {
    return this._ticketSearchSettings$.value;
  }

  set globalTicketLimit(limit: number) {
    const newVal = { ...this._ticketSearchSettings$.value, ticketLimit: limit };
    this._ticketSearchSettings$.next(newVal);
  }
}

// types

type QueryBody = {
  query?: {
    savedSearches?: {
      all?: boolean;
      savedSearchID?: number;
    };
    searchResults?: {
      count?: boolean;
      searchCriteria: SearchCriteria[];
      limit?: number;
      offset?: number;
    };
    template?: {
      TemplateID?: number;
      userID?: number;
      userDefault?: boolean;
    };
    formData?: {
      all: true;
    };
    ticketSearchFilters?: {
      all: true;
    };
  };
  mutation?: {
    create?: {
      savedSearch?: {
        SearchName: string;
        isVisibleClientWide: 0 | 1;
        canEditSavedSearch: 0 | 1;
        SavedSearchCriteria: SearchCriteria[];
      };
      template?: {
        userDefault?: boolean;
      };
    };
    update?: {
      savedSearch?: SavedSearch;
      savedSearchCriteria?: {
        CriteriaID: number;
        SavedSearchID: number;
        FilterID: number;
        Value: number;
        isExcluded: 0 | 1;
      };
      template?: {
        isDefault?: 0 | 1;
        templateColumns?: TemplateColumn[];
      };
    };
    delete?: {
      savedSearch?: {
        SavedSearchID: number;
        ClientID: number;
      };
    };
  };
};

export type SearchCriteria = {
  CriteriaID?: number;
  SavedSearchID?: number;
  DataTypeID?: number;
  FilterName?: string;
  ValueDescription?: string;
  FilterID: number;
  Value: string | Array<string | number>;
  isExcluded: 0 | 1;
};

export type SearchResult = {
  fields: any[];
  result: any[];
};

export type TemplateColumn = {
  TemplateColumnID: number;
  TemplateID: number;
  Title: string;
  Field: number;
  Width: number;
  Visible: 0 | 1;
  ColumnOrder: number;
};

export type SavedSearch = {
  SavedSearchID: number;
  SearchName: string;
  ClientID: number;
  SavedSearchCriteria: SearchCriteria[];
  Count?: number | null;
  TemplateID?: number | null;
};

export type TicketFilter = {
  filterID: number;
  filterName: string;
  visibleName: string;
  dataTypeID: number;
  filterCategoryID: number;
  options?: Array<any>;
};

@Component({
  template: `
    <div class="flex flex-col p-6 justify-center items-start m-auto">
      <p class="text-headline-5 mt-0 mb-9  font-semibold capitalize">Search Settings</p>
      <div class="size-fit flex flex-col gap-6 justify-start items-start">
        <div class="flex flex-col justify-center items-start mx-auto">
          <p class="text-lg mt-0 mb-3  font-semibold capitalize">Ticket Search Limit</p>
          <div class="relative flex items-start border-2 border-solid border-gray-400 rounded-lg overflow-hidden">
            <button
              class="bg-gray-100 hover:bg-gray-200 border-0 border-r-1 border-gray-300 p-3 h-11"
              (click)="decrement()">
              <svg
                class="w-3 h-3 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h16" />
              </svg>
            </button>
            <input
              [readOnly]="true"
              [formControl]="ticketLimit"
              type="text"
              id="ticket-limit"
              class="box-border bg-gray-50 border-0 h-11 text-center text-gray-900 text-md block w-full py-2.5"
              required />
            <button
              class="bg-gray-100 hover:bg-gray-200 border-0 border-l-1 border-gray-300 p-3 h-11"
              (click)="increment()">
              <svg
                class="w-3 h-3 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 1v16M1 9h16" />
              </svg>
            </button>
          </div>
        </div>
        <div class="w-full flex flex-row gap-3 justify-end items-center">
          <button
            type="button"
            class="h-9 flex justify-center items-center appearance-none rounded border-none bg-transparent py-2 px-4 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize"
            (click)="dialogRef.close(false)">
            cancel
          </button>
          <button
            type="button"
            class="h-9 flex justify-center items-center appearance-none rounded border-solid border-2 border-primary bg-primary py-2 px-4 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize"
            (click)="submit()">
            save
          </button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [ReactiveFormsModule, ButtonDropdownSelectComponent, AsyncPipe],
})
class TicketSettingsDialogComponent implements OnInit {
  protected ticketLimit = new FormControl('5000', (val) => {
    if (val.value < globalTicketLimitMin || val.value > globalTicketLimitMax) {
      return { invalid: true };
    }
    return null;
  });

  constructor(
    public dialogRef: MatDialogRef<TicketSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TicketSearchSettings
  ) {}

  ngOnInit() {
    this.ticketLimit.setValue(this.data.ticketLimit.toString());
    this.ticketLimit.valueChanges.subscribe((next) => {
      this.data.ticketLimit = parseInt(next, 10);
    });
  }

  increment() {
    if (parseInt(this.ticketLimit.value, 10) < globalTicketLimitMax) {
      this.ticketLimit.setValue((parseInt(this.ticketLimit.value, 10) + 500).toString());
    }
  }

  decrement() {
    if (parseInt(this.ticketLimit.value, 10) > globalTicketLimitMin) {
      this.ticketLimit.setValue((parseInt(this.ticketLimit.value, 10) - 500).toString());
    }
  }

  submit() {
    if (this.ticketLimit.valid) {
      this.dialogRef.close(this.data);
    }
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { TicketTag, TicketTagBuilderService } from "./ticket-tags-builder.service";
import { TicketService } from "../../ticket/ticket.service";
import { Subscription } from "rxjs";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { SettingID } from "src/app/modules/core/services/user/setting";

@Component({
  selector: "app-ticket-list-tags",
  templateUrl: "./ticket-list-tags.component.html",
  styleUrls: ["./ticket-list-tags.component.scss"],
})
export class TicketListTagsComponent implements OnInit, OnChanges, OnDestroy {
  private ticketTagsShowLimit: number = 2;
  @Input() AssignmentID;
  @Input() LocateStatusID;
  @Input() CallTypeID;

  @Input() Tags: TicketTag[];
  @Input() showAll: boolean;
  @Input() showImpactScore: boolean = false;
  @Input() showDamageThreatScore: boolean = false;
  @Input() showTagTitle: boolean = false;

  tags: TicketTag[];
  remainingTags: number = 0;
  ticketDamageThreatScore: string | null = null;
  ticketImpactScore: string | null = null;

  private tagChangeSubscription: Subscription;

  constructor(private ticketService: TicketService, private ticketTagsService: TicketTagBuilderService, private userService: UserService
  ) { }

  ngOnInit() {
    this.tagChangeSubscription = this.ticketTagsService.tagChanged.subscribe(data => {
      this.handleTagChange();
    });
  }

  async ngOnChanges() {
    await this.getTags();
  }

  async getTags() {
    if (this.AssignmentID) {
      this.tags = await this.ticketService.getTicketTags(
        this.AssignmentID.toString(),
        this.LocateStatusID
      );
    }

    if (this.Tags) {
      this.tags = this.Tags;
    }

    if (this.showAll == false && this.tags && this.tags.length > 0) {
      this.determineNumberOfVisibleTags();
      if (this.tags.length > this.ticketTagsShowLimit) {
        this.remainingTags = this.tags.length - this.ticketTagsShowLimit;
        this.tags = this.tags.slice(0, this.ticketTagsShowLimit);
      }
    }

    if (this.userService.isSettingActive(SettingID.DAMAGE_THREAT) && this.showDamageThreatScore) {
      //then we want to add the damage tag to the front 
      this.ticketDamageThreatScore = await this.ticketService.getTicketDamageThreatLabel(this.AssignmentID.toString());
      // console.log(this.ticketDamageThreatScore);
    }

    if (this.userService.isSettingActive(SettingID.IMPACT) && this.showImpactScore) {
      //then we want to add the damage tag to the front 
      this.ticketImpactScore = await this.ticketService.getImpactLabel(this.AssignmentID.toString());
      // console.log(this.ticketImpactScore);
    }
  }

  /**
   * Determines how many tags to show, if limiting the number of tags to show
   * the limit for the number of tags if not showing all is 2
   * so, if we are showing damage and/or impact, they count as tags and will affect this
   *
   * @memberof TicketListTagsComponent
   */
  determineNumberOfVisibleTags() {
    let count = 0;
    if (this.userService.isSettingActive(SettingID.DAMAGE_THREAT) && this.showDamageThreatScore) count++;
    if (this.userService.isSettingActive(SettingID.IMPACT) && this.showImpactScore) count++;

    this.ticketTagsShowLimit = 2 - count;
  }

  async handleTagChange() {
    await this.getTags()
  }

  ngOnDestroy() {
    if (this.tagChangeSubscription) {
      this.tagChangeSubscription.unsubscribe();
    }
  }

}

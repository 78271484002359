import { Component, HostListener, inject, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavBarConfig } from '../fieldside-navigation-bar/navigation-bar.component';
import { TicketSyncService } from '../../../../modules/shared/ticket/services/ticket-sync/ticket-sync.service';
import { TicketListItemObject } from '../../../../modules/shared/ticket-list/ticket-list-item/ticket-list-item';
import { NavBarService } from '../../../../modules/shared/ticket/services/router/nav-bar.service';
import { UserService } from '../../../../modules/core/services/user/user.service';
import { Ticket, TicketService } from '../../../../modules/shared/ticket/ticket.service';
import { SyncProgressText } from '../../../../modules/shared/ticket/services/notification/sync-modal.service';
import { DateTime } from 'luxon';
import { UtilocateDocumentsCacheService } from 'src/app/modules/shared/ticket/services/cache/utilocate-documents.service';
import { FieldsideService } from '../../fieldside.service';
import { TicketTagBuilderService } from 'src/app/modules/shared/ticket-tags/ticket-list-tags/ticket-tags-builder.service';
import { MatDialog } from '@angular/material/dialog';
import {
  TicketModalComponent,
  TicketModalComponentArgs,
  TicketModalComponentOpenFromEnum,
} from 'src/app/modules/shared/ticket/modals/ticket-modal/ticket-modal.component';
import { AssignmentIDCacheResult } from 'src/app/modules/core/cache/cache.interface';
import { HomeWorkspaceService } from 'src/app/routes/home/home-workspace.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { CompletionQueueService } from '../../../../modules/shared/ticket/services/completionQueue/completion-queue.service';

@Component({
  selector: 'app-fieldside-ticket-list',
  templateUrl: './fieldside-ticket-list.component.html',
  styleUrls: ['./fieldside-ticket-list.component.scss'],
})
export class FieldsideTicketListComponent implements OnInit, OnChanges, OnDestroy {
  // services
  private completionsQueue = inject(CompletionQueueService);

  ticketList: TicketListItemObject[] = [];
  onSyncClick: Subject<any>;
  onFilterClick: Subject<any>;
  firstSync: any;
  isValidDownload = true;
  ticketsToSync: string[] = [];
  fabMenu: any;
  filteredClicked = false;
  listOrder: any;
  routingIds: any;
  isDesktop: boolean;
  dialogRef: any;
  AssignmentID: any;
  screenWidth: number;
  selection: any = [0, 1];

  //observables
  protected currentlySelectedTicket$: BehaviorSubject<any> = new BehaviorSubject({});
  private destroy$: Subject<void> = new Subject<void>();
  ticketStream$: BehaviorSubject<Array<Ticket>> = new BehaviorSubject([]);
  showRoutingPins$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  protected _leftModifier: number = 0;

  protected _tabs = [
    { index: 0, title: 'list', icon: 'list' },
    { index: 1, title: 'map', icon: 'map' },
  ];
  protected resizeTrigger = new Subject<void>();

  constructor(
    private ticketSyncService: TicketSyncService,
    private router: Router,
    private route: ActivatedRoute,
    private navBarService: NavBarService,
    private userService: UserService,
    private ticketService: TicketService,
    private utilocateDocumentsCacheService: UtilocateDocumentsCacheService,
    public homeWorkspaceService: HomeWorkspaceService,
    public dialog: MatDialog,
    private fieldsideService: FieldsideService,
    private ticketTagsService: TicketTagBuilderService,
    private ngZone: NgZone
  ) {
    this.onSyncClick = new Subject<any>();
    this.onFilterClick = new Subject<any>();
    this.firstSync = this.route.snapshot.queryParams.firstSync;
    const userHasRouting: any = this.userService.isSettingActive(SettingID.ROUTING_TICKET_CLUSTER_PRIORITY);
    this.showRoutingPins$.next(userHasRouting === 1);
  }

  ngOnChanges(): void {
    this.navBarService.updateNavBar(
      new NavBarConfig(
        'Ticket Summary',
        null,
        this.ticketsToSync.length > 0,
        this.onSyncClick,
        null,
        true,
        this.showRoutingPins$.value
      )
    );
    this.setTicketList();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit(): Promise<void> {
    this.screenWidth = window.innerWidth;
    const numTicketsToSyncResponse = await this.ticketSyncService.getAssignmentIDToUpload(
      await this.ticketSyncService.getAssignedTickets()
    );
    if (numTicketsToSyncResponse) {
      this.ticketsToSync = numTicketsToSyncResponse;
    } else {
      this.ticketsToSync = [];
    }

    this.currentlySelectedTicket$.pipe(takeUntil(this.destroy$)).subscribe((ticket) => {
      console.log(ticket);
      if (ticket.AssignmentID) {
        console.log('this.currentlySelectedTicket$.pipe');
        this.onTicketItemClick(ticket);
      }
    });

    this.routingIds = await this.ticketService.getRouting();
    this.navBarService.updateNavBar(
      new NavBarConfig(
        'Ticket Summary',
        null,
        this.ticketsToSync.length > 0,
        this.onSyncClick,
        null,
        true,
        this.showRoutingPins$.value //routing
      )
    );
    const newQueryParams = this.cleanURLQueryParams();
    if (this.firstSync && this.userService.firstLogin()) {
      this.utilocateDocumentsCacheService.clearTicketChanged();
      this.router.navigate([this.route.snapshot.parent.routeConfig.path, 'ticket-summary'], {
        queryParams: newQueryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
      this.startSync(false, true);
    }

    this.onSyncClick
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.startSync(true, true);
        this.ticketsToSync = [];
        this.navBarService.updateNavBar(
          new NavBarConfig('Ticket Summary', null, this.ticketsToSync.length > 0, this.onSyncClick, null, true)
        );
      });

    this.navBarService.filterBtnClick$.pipe(takeUntil(this.destroy$)).subscribe((next) => {
      this.listOrder = next;
      this.filteredClicked = true;
      this.sortTickets(next);
    });

    this.ticketSyncService
      .resume()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isValidDownload = true;
        },

        error: () => {
          this.startSync(false, true);
        },

        complete: async () => {
          if (this.isValidDownload) {
            const readableUserData = await this.userService.getReadableUserInfo();
            this.navBarService.updateInnerNavTitles({
              Title: readableUserData.Username,
              SubTitle: readableUserData.UserCategoryDesc,
            });
          }
        },
      });

    this.fabMenu = [
      {
        label: 'Self Assign',
        icon: 'assignment_ind',
        action: this.openSelfAssign,
      },
    ];
    this.setTicketList();

    this.completionsQueue.processedTickets$.pipe(takeUntil(this.destroy$)).subscribe((tickets) => {
      console.log('tickets', tickets);
      if (tickets.length > 0) {
        this.setTicketList();
      }
    });
  }

  openSelfAssign = () => {
    this.router.navigate(['fieldside/self-assign']);
  };

  onTicketItemClick(ticketInfo: { AssignmentID: string; PrimaryID?: string }) {
    // Purpose: It's used to optimize performance by coalescing multiple asynchronous operations into a single run of Angular's change detection processes.
    // By wrapping this code in a zone, it ensures change detection triggers so the modal shows the ticket correctly
    this.ngZone.run(() => {
      // your existing code
      if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.close();
      }

      if (this.screenWidth >= 1024) {
        const data: TicketModalComponentArgs = {
          AssignmentID: ticketInfo.AssignmentID,
          PrimaryID: ticketInfo.PrimaryID ?? null,
          OpenedFrom: TicketModalComponentOpenFromEnum.Summary,
        };
        this.dialogRef = this.dialog.open(TicketModalComponent, {
          data: data,
          width: '815px',
          height: 'calc(100vh - 64px)',
          panelClass: 'ticket-container',
          position: {
            bottom: '0',
            right: '0',
          },
          disableClose: true, // Prevent closing on outside click
          backdropClass: '', // Disable background darkening
          hasBackdrop: false, // Allow interaction with elements behind the dialog
        });
        this.dialogRef.afterClosed().subscribe((result) => {
          console.log(result);
          this.setTicketList();

          //we only want to "deselect" all tickets if the user closed the dialog
          if (result && result.event === 'close') {
            this.currentlySelectedTicket$.next({ AssignmentID: null });
          }
        });
        this.dialogRef.componentInstance.ticketChanged.subscribe((value: boolean) => {
          // if the response was 'true' i.e., we need to sync, and the assignmentID doesn't exist in the list already,
          // add it to the list
          if (value && !this.ticketsToSync.includes(ticketInfo.AssignmentID.toString())) {
            this.ticketsToSync.push(ticketInfo.AssignmentID.toString());
            //update the navbar
            this.navBarService.updateNavBar(
              new NavBarConfig(
                'Ticket Summary',
                null,
                this.ticketsToSync.length > 0,
                this.onSyncClick,
                null,
                true,
                true
              )
            );
          }
        });
      } else {
        this.router.navigate(['fieldside/ticket-summary/ticket', ticketInfo.AssignmentID]);
      }
    });
  }

  async getRouteOrder(assignmentIDs) {
    const routeInfo: any = await this.ticketService.getRouting();
    let orderIndexes = [];
    if (routeInfo.length > 0) {
      const routeList = routeInfo[0].AssignmentIDList;
      const routeListArray = routeList.split(',');
      const assignmentIDsArray = assignmentIDs.map((assignment) => assignment.toString());
      orderIndexes = this.filterRoutes(routeListArray, assignmentIDsArray);
    }
    return orderIndexes;
  }

  filterRoutes(routeListArray, assignmentIDs) {
    const order = [];
    assignmentIDs.map((assignmentId) => {
      const index = routeListArray.indexOf(assignmentId);
      order.push(index);
    });
    return order;
  }

  //when a sync event happens from the ticket-list
  async onSyncEvent() {
    await this.setTicketList();
    //update the toolbar
    this.ticketsToSync = await this.ticketSyncService.getAssignmentIDToUpload(
      await this.ticketSyncService.getAssignedTickets()
    );
    this.navBarService.updateNavBar(
      new NavBarConfig('Ticket Summary', null, this.ticketsToSync.length > 0, this.onSyncClick, null, true)
    );
  }

  async setTicketList() {
    // Get all the assignments
    const assignments = await this.ticketService.listAssignments();
    const routeOrder = await this.getRouteOrder(assignments);

    //if we have show routing pins ON, but no route, then we need to turn it off
    if (this.showRoutingPins$.value && routeOrder.length === 0) {
      this.showRoutingPins$.next(false);
    } else if (this.showRoutingPins$.value === false && routeOrder.length > 0) {
      this.showRoutingPins$.next(true);
    }

    // Create a map to store assignment ID and corresponding dig date
    const digDateMap = new Map();

    for (let i = 0; i < assignments.length; i++) {
      const assignmentID = assignments[i];
      const assignmentObject: AssignmentIDCacheResult = await this.ticketService.getTicketCacheResult(
        assignmentID.toString()
      );

      // Check if the ticket is assigned to this user
      if (assignmentObject.assigned) {
        // If it doesn't exist in ticketList, add it
        if (
          !this.ticketList.some(
            (ticket) =>
              ticket.AssignmentID === assignmentObject.getTableData('tbCompletions_Assignments')[0].AssignmentID
          )
        ) {
          await this.addAssignmentObjectToTicketList(assignmentObject, routeOrder[i]);
        } else {
          //else we already have it in our list, so we want to update route order
          const ticket = this.ticketList.find(
            (ticket) =>
              ticket.AssignmentID === assignmentObject.getTableData('tbCompletions_Assignments')[0].AssignmentID
          );
          ticket.Ticket.RouteOrder = routeOrder[i];
        }
      }

      // Get the dig date and store it in the map
      const digDate = new Date(assignmentObject.getTableData('tbCompletions_Assignments')[0].ExcavationDate);
      digDateMap.set(assignmentID.toString(), digDate);
    }

    // If it exists in ticketList but not in assignments, remove it
    for (let i = 0; i < this.ticketList.length; i++) {
      const ticket = this.ticketList[i];

      // Get the dig date from the map using the assignment ID
      const digDate = digDateMap.get(ticket.AssignmentID.toString());
      if (digDate) {
        let formattedDigDate = digDate.toUTCString();
        formattedDigDate = formattedDigDate.replace('GMT', '');
        ticket.DigDate = formattedDigDate;
        ticket.LateColor = this.fieldsideService.getColorBasedOnDate(digDate, ticket.LocateStatusID);
      }
      
      //Add the impact and damage threat scores to the ticket
      ticket.Ticket.Impact = await this.ticketService.getImpactLabel(ticket.AssignmentID.toString());
      ticket.Ticket.DamageThreat = await this.ticketService.getTicketDamageThreatLabel(ticket.AssignmentID.toString())
      
      if (!assignments.some((assignment) => assignment.toString() === ticket.AssignmentID.toString())) {
        this.ticketList.splice(i, 1);
        i--;
      }

    }

    this.sortTickets(this.listOrder);

    // Create an array that contains the Ticket property from this.ticketList as the value at that index
    const Tickets = this.ticketList.map((ticket) => ticket.Ticket);

    this.ticketStream$.next(Tickets);
  }

  /**
   * Creates a new TicketListItemObject and adds it to the list
   * @param assignmentObject
   */
  async addAssignmentObjectToTicketList(assignmentObject: AssignmentIDCacheResult, routeOrder: number) {
    const assignmentsTable = assignmentObject.getTableData('tbCompletions_Assignments')[0];
    const primaryTable = assignmentObject.getTableData('tbCompletions_Primary')[0];
    assignmentsTable.SubNum = primaryTable.PrimaryID;

    const CallTypeDesc = await this.ticketTagsService.createTagsFromCallTypeID(assignmentsTable.CallTypeID);
    const digDate = new Date(assignmentsTable.ExcavationDate);

    let formattedDigDate = digDate.toUTCString();

    const color = this.fieldsideService.getColorBasedOnDate(digDate, assignmentsTable.LocateStatusID);
    formattedDigDate = formattedDigDate.replace('GMT', '');

    const tbCompletions_DocumentsCount = assignmentObject.getTableData('tbCompletions_Documents').length;
    const tbCompletions_S3DocumentsCount = assignmentObject.getTableData('tbCompletions_S3Documents').length;

    assignmentsTable.RouteOrder = routeOrder;

    const item: TicketListItemObject = {
      AssignmentID: assignmentsTable.AssignmentID,
      PrimaryID: primaryTable.PrimaryID,
      Address: `${assignmentsTable.StartHouseNumber} ${assignmentsTable.LocateAddress}, ${assignmentsTable.LocateSubRegionName}`,
      City: assignmentsTable.LocateSubRegionName,
      TypeOfWork: assignmentsTable.TypeOfWork,
      Proximity: { lat: assignmentsTable.Latitude, long: assignmentsTable.Longitude },
      CallTypeDesc: CallTypeDesc,
      RequestNumber: assignmentsTable.RequestNumber,
      DigDate: formattedDigDate,
      NumberOfDocs: (Number(tbCompletions_DocumentsCount) + Number(tbCompletions_S3DocumentsCount)).toString(),
      DistanceToSite: (routeOrder + 1).toString(),
      LateColor: color,
      Ticket: assignmentsTable,
      Impact: await this.ticketService.getImpactLabel(assignmentsTable.AssignmentID),
      Damage: await this.ticketService.getTicketDamageThreatLabel(assignmentsTable.AssignmentID),
    };
    this.ticketList.push(item);
  }

  async sortTickets(order) {
    this.routingIds = await this.ticketService.getRouting();
    if (this.routingIds.length != 0) {
      this.navBarService.updateNavBar(
        new NavBarConfig('Ticket Summary', null, this.ticketsToSync.length > 0, this.onSyncClick, null, true, true)
      );
    }

    const tickets = [...this.ticketList];

    const isDescending = order; // set to false for ascending
    tickets.sort((a, b) => {
      const nameA = a.RequestNumber;
      const nameB = b.RequestNumber;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    if (order == 'type') {
      tickets.sort((a, b) => {
        const nameA = a.TypeOfWork.toUpperCase(); // ignore upper and lowercase
        const nameB = b.TypeOfWork.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    } else if (order == 'route') {
      tickets.sort(this.customSort);
    } else {
      const toMillis = (date: Date): number => {
        return DateTime.fromJSDate(date).toMillis();
      };
      tickets.sort((a, b) => {
        return isDescending
          ? toMillis(new Date(b.DigDate)) - toMillis(new Date(a.DigDate))
          : toMillis(new Date(a.DigDate)) - toMillis(new Date(b.DigDate));
      });
    }
    this.ticketList = [...tickets];
  }

  customSort = (a: TicketListItemObject, b: TicketListItemObject) => {
    const routingArray = this.routingIds[0].AssignmentIDList.split(',');
    const numberArray = routingArray.map((str) => parseInt(str, 10));

    const indexA = numberArray.indexOf(parseInt(a.AssignmentID));
    const indexB = numberArray.indexOf(parseInt(b.AssignmentID));

    // If AssignmentID of 'a' or 'b' is not in routingArray, move it to the end
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

  /**
   * Starts a sync for the ticket list on fieldside
   * @param performUpload
   * @param performDownload
   */
  async startSync(performUpload: boolean, performDownload: boolean) {
    //get all the local tickets assigned to the user. These are the ones we want to upload
    const assignmentIDs = await this.ticketSyncService.getAssignmentIDToUpload(
      await this.ticketSyncService.getAssignedTickets()
    );

    this.ticketSyncService
      // checkAssignedTicketsForDownload = true, so we check on download for the new assignmentIDs
      // as the server assigned list may have changed
      .startSync(performUpload, performDownload, assignmentIDs, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (next) => {
          if (next.syncKey == SyncProgressText.DOWNLOAD_TICKETS) {
            this.isValidDownload = true;
          }
        },
        error: (next) => {
          if (next.syncKey == SyncProgressText.DOWNLOAD_TICKETS) {
            // this.isValidDownload = false;
          }

          if (next.syncKey == SyncProgressText.DOWNLOAD_ADMIN_TABLES) {
            // this.isValidDownload = false;
          }
        },
        complete: async () => {
          this.setTicketList();
          if (this.isValidDownload) {
            this.ticketSyncService.closeModal();
            const readableUserData = await this.userService.getReadableUserInfo();
            this.navBarService.updateInnerNavTitles({
              Title: readableUserData.Username,
              SubTitle: readableUserData.UserCategoryDesc,
            });
          }
        },
      });
  }

  public handleRouteClick(item) {
    try {
      if (item && item.action) {
        // switch (item.action) {
        //   case NavAction.logout:
        //     this.logoutAction();
        //     break;
        //   case NavAction.reset:
        //     this.resetAction();
        //     break;
        //   default:
        //     console.log("unknown nav action");
        //     break;
        // }
      }

      if (item.route) {
        // this.changeRoute(item.route);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private cleanURLQueryParams(): object {
    const queryParams: any = this.route.snapshot.queryParams;
    const queryParamsKeys: any = Object.keys(queryParams);
    const newQueryParams = {};

    try {
      const visisbleURLKeys: any = this.route.snapshot.routeConfig.data
        ? this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS
        : null;
      const len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        const queryParamsKey = queryParamsKeys[i];
        if (visisbleURLKeys && visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }
      return newQueryParams;
    } catch (error) {
      console.log(error);
    }
    return newQueryParams;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.close();
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'app-pill-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="relative z-10 flex justify-center items-center">
      <div
        class="overflow-hidden flex flex-row gap-3 justify-center items-center bg-white size-fit rounded-full shadow-2xl py-2.5 px-6">
        <ng-content />
      </div>
    </div>
  `,
})
export class PillMenuComponent {
}

@Component({
  selector: 'app-pill-menu-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon],
  template: `
    <button
      #trigger
      class="size-10 group cursor-pointer flex justify-center items-center appearance-none border-none p-0 {{
                (active) ? 'bg-primary' : 'bg-transparent hover:bg-primary'
              }} rounded-md transition-all ease-in-out duration-100"
      (click)="(clicked.emit())">
      <mat-icon
        class="size-8 text-lg {{
                  (active) ? 'text-white' : 'text-accent group-hover:text-white'
                }} transition-all ease-in-out duration-100"
        [svgIcon]="iconName" />
    </button>
  `,
})
export class PillMenuButtonComponent {
  @Input() active: boolean;
  @Input() iconName: string;
  @Output() clicked = new EventEmitter<void>();

}

@Component({
  selector: 'app-sub-menu',
  imports: [PillMenuButtonComponent, MatIcon, CdkOverlayOrigin, CdkConnectedOverlay],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  template: `
    <app-pill-menu-button
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      (clicked)="clicked.emit()"
      [active]="active"
      [iconName]="'location'"
    />
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="active"
      [cdkConnectedOverlayPanelClass]="'flex-row'"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayPositions]="positionPairs"
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayBackdropClass="quick-search-panel"
      (backdropClick)="clicked.emit()"
    >
      <div class="flex flex-col justify-end items-center gap-1.5 size-fit bg-white rounded-md shadow-2xl p-1.5">
        <button (click)="subMenuClicked.emit(0)" class="w-full bg-transparent appearance-none border-none cursor-pointer p-3 hover:bg-primary hover:text-white rounded">
          <span class="text-lg  font-semibold capitalize">user location</span>
        </button>
        <button (click)="subMenuClicked.emit(1)" class="w-full bg-transparent appearance-none border-none cursor-pointer p-3 hover:bg-primary hover:text-white rounded">
          <span class="text-lg  font-semibold capitalize">ticket location</span>
        </button>
      </div>
    </ng-template>
  `
})
export class SubMenuComponent {
  @Input() active: boolean;
  @Input() iconName: string;
  @Input() size: string = 'size-12';
  @Output() clicked = new EventEmitter<void>();
  @Output() subMenuClicked = new EventEmitter<number>();

  positionPairs: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -12
    },
  ];

}

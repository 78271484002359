import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../../../../pipes/truncate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ShapeType } from '../../../../../../modules/drawing-module/utilities/types';
import { ContextEvent } from '../../../../../../modules/drawing-module/services/map-interaction.service';
import { isEqual } from 'lodash-es';
import { Overlay } from 'ol';
import { OpenLayersService } from '../../../../../../modules/drawing-module/services/open-layers.service';

@Component({
  selector: 'app-context-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, TruncatePipe, MatButtonModule],
  template: `
    <div #container class="box-border flex flex-col p-3 pt-4">
      <div class="h-auto w-36 flex justify-start items-start bg-gray-800 rounded-md shadow-2xl py-1.5">
        <ul class="box-border size-full list-none p-0 m-0 divide-x-0 divide-y-1 divide-solid divide-gray-400">
          @for (option of options$$(); track $index) {
            <li class="w-full flex justify-start items-center">
              <button
                (click)="optionClicked(optionChoices[option].value)"
                class="size-full appearance-none border-none px-4 py-3 bg-transparent hover:bg-gray-600 text-white text-lg text-left font-medium  cursor-pointer">
                {{ optionChoices[option].name }}
              </button>
            </li>
          }
        </ul>
      </div>
    </div>
  `,
  styleUrls: ['./map-context-menu.component.scss'],
})
export class MapContextMenuComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;
  @Input() contextEvent: ContextEvent;
  @Output() optionSelected = new EventEmitter<ContextSelection>();
  @Output() ready = new EventEmitter<void>();

  // services
  private renderer = inject(Renderer2);
  private openLayersService = inject(OpenLayersService);

  // observables && signals
  options$$: WritableSignal<Array<number>> = signal([]);
  optionChoices: Record<number, Option> = {
    0: { name: 'Edit', value: 'edit' },
    1: { name: 'Delete', value: 'delete' },
    2: { name: 'Duplicate', value: 'duplicate' }
  };

  // members
  private contextMenuOverlay: Overlay;
  private unlistenFns: Array<() => void> = [];

  constructor() { }

  ngOnInit() {
    const handler = (event) => {
      if (event.composedPath().indexOf(this.container.nativeElement) === -1) {
        this.contextMenuOverlay.setPosition(undefined);
      }
    };
    this.unlistenFns.push(this.renderer.listen('document', 'mousedown', handler));
    this.unlistenFns.push(this.renderer.listen('document', 'touchstart', handler));

    this.contextMenuOverlay = new Overlay({
      element: this.container.nativeElement,
      autoPan: {
        margin: 25,
      },
      position: undefined,
      positioning: 'top-left',
    });
    this.openLayersService.attachOverlay(this.contextMenuOverlay);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.contextMenuOverlay === undefined || this.contextMenuOverlay === null) {
      return;
    }
    if (this.contextEvent === undefined || this.contextEvent === null) {
      this.contextMenuOverlay.setPosition(undefined);
      return;
    }
    if (isEqual(changes.contextEvent.currentValue, changes.contextEvent.previousValue)) {
      return;
    }

    const options = [];

    if (this.contextEvent.feature.getLength() === 1) {
      const shapeType = this.contextEvent?.feature.getArray()[0].get('shape_type_id') ?? null;
      if ([ShapeType.MeasureLine, ShapeType.Label].includes(shapeType)) {
        if (this.contextEvent.value !== undefined) {
          this.optionSelected.emit(this.contextEvent);
        }
        options.push(0);
      }
    }

    options.push(1, 2);

    this.options$$.set(options);

    if (this.contextEvent.value !== undefined) {
      this.contextMenuOverlay.setPosition(undefined);
    } else {
      this.contextMenuOverlay.setPosition(this.contextEvent.event.coordinate);
    }
  }

  ngOnDestroy() {
    if (this.unlistenFns.length > 0) {
      this.unlistenFns.forEach((fn) => fn());
    }
  }

  optionClicked(value: string) {
    this.contextMenuOverlay.setPosition(undefined);
    this.optionSelected.emit({ ...this.contextEvent, value });
  }
}

type Option = {
  name: string;
  value: string;
};

export type ContextSelection = ContextEvent;

<div class="h-full bg-white">
  <cdk-virtual-scroll-viewport itemSize="328" class="h-full w-full">
    <div class="" *ngIf="settingCategoryGroups.length > 0; else noEditSettings">
      <div class="p-4 flex flex-row" *ngFor="let settingGroup of settingCategoryGroups">
  
        <div class="flex flex-wrap">
          <div *ngFor="let settingCategory of settingGroup" class="py-4 px-8">
  
            <mat-card (click)="navigate(settingCategory.path)"
              class="hover:shadow-2xl hover:cursor-pointer rounded-md w-[328px] h-[328px] p-6 flex flex-col"
              style="background-image: url('../../../../assets/svg/setting wave-01.svg'); background-size: cover;">
              <mat-icon class="text-white p-2 w-20 h-20 text-headline-1" [svgIcon]="settingCategory.icon"></mat-icon>
              <mat-card-header class="flex-grow px-0  font-semibold capitalize">
                <mat-card-title class="text-white py-2 text-headline-6">{{ settingCategory.title }}</mat-card-title>
                <mat-card-subtitle class="text-white text-md">{{ settingCategory.description }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-actions class="px-0">
                <button mat-button
                  class="rounded capitalize font-semibold text-lg mt-auto w-32 h-9 bg-white border-none text-primary ">OPEN
                </button>
              </mat-card-actions>
            </mat-card>
  
          </div>
        </div>
  
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
  

  <ng-template #noEditSettings>
    <div class="px-9 flex flex-row justify-normal items-center">
      <mat-icon svgIcon="warning" class="pr-4"></mat-icon>
      <p class=" font-semibold capitalize text-headline-6">No settings to edit</p>
    </div>
  </ng-template>
</div>
<div
  class="w-full pb-2 min-h-full page-background"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div class="w-full pb-14 mat-elevation-z4">
    <div
      *ngIf="hasBilling"
      class="overflow-auto w-full overflow-hidden"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <app-form-input-template
        [props]="billingFormProps"
        fxFlex="100"
        class="bg-white m-2 rounded shadow-md"
      ></app-form-input-template>
    </div>

    <div
      *ngIf="hasCommon"
      class="overflow-auto w-full overflow-hidden"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <app-form-input-template
        [props]="commonFormProps"
        fxFlex="100"
        class="bg-white m-2 rounded shadow-md"
      ></app-form-input-template>
    </div>

    <div
      *ngIf="hasPrimary"
      class="bg-white m-2 rounded shadow-md"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <span class="m-2 p-2  text-headline-6 capitalize
      border-gray-300 border-solid border-t-0 border-r-0 border-l-0 border-b-[1px]"
        >Task Details</span
      >
      <div
        *ngFor="let primaryFormProp of primaryFormProps | keyvalue"
        class="overflow-auto form-main"
        fxLayout="row"
        fxLayoutAlign="center start"
      >
        <app-form-input-template
          [props]="primaryFormProp.value"
          [demoteHeader]="true"
          fxFlex="100"
          [readOnly]="
            ticketProtectionMode ||
            (isU2TicketComplete && !allowEditingCompletionDetails)
          "
          (isHideShowToggle)="completionsUpdate($event)"
        ></app-form-input-template>
      </div>
    </div>
  </div>
</div>

<!-- FAB -->
<floating-action-menu
  *ngIf="!isU2TicketComplete && !ticketProtectionMode && !this.disableFabMenu"
  [menuItems]="fabMenu"
  type="fab"
>
</floating-action-menu>

<div *ngIf="currentReport" class="h-full w-full">
  <div class="flex flex-col h-full w-full">
    <app-report-generator-query
      [report]="currentReport"
      (runReport)="runReport($event)"
      [reportIsRunning]="reportIsRunning"
      [loading]="loading"
      [dataExists]="currentReportResult.rows.length > 0"
      (downloadClicked)="downloadReportAs($event)"
      class="border border-borderWidth border-gray-300 border-solid border-t-0 border-x-0" />
    <div class="h-full max-w-full" *ngIf="currentReportResult.rows.length > 0; else noRes">
      <app-tabulator-table
        #tabulatorTable
        [tableData]="currentReportResult.rows"
        [columns]="currentReportResult.columns"
        [eagerLoading]="true"
      />
    </div>
    <ng-template #noRes>
      <div *ngIf="noResults" class="capitalize  font-semibold text-headline-5 p-4">No Results Found</div>
    </ng-template>
  </div>
</div>

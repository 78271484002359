// @ts-nocheck
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  NgModule,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ElementRef,
  Input,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { take } from 'rxjs/operators';

import { DateTime } from 'luxon'; // https://moment.github.io/luxon/#/
import { BaseComponent } from 'src/app/modules/core/base-set/base-set.component';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { MaterialModule } from '../../material.module';
import {
  CreateTicketComponentService,
  OptionsFillID,
} from 'src/app/modules/create-ticket/create-ticket-component.service';
import { SearchableDropdownModule } from '../../searchable-dropdown/searchable-dropdown.module';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';

export enum TicketDetailInputTypes {
  Checkbox = 1,
  String = 2,
  Integer = 3,
  Float = 4,
  CheckboxHS = 5,
  StringHS = 6,
  IntegerHS = 7,
  FloatHS = 8,
  Photo = 9,
  Datepicker = 10,
  Select = 11,
  MuiltSelect = 12,
  Autocomplete = 13,
  AddressPicker = 14,
  Timepicker = 15,
  IntervalTimepicker = 16,
}

export var HiddenFieldTypeIDs = [5, 6, 7, 8];

export enum FillStageIDs {
  Default = 1,
  PrefillOptional = 2,
  PrefillRequired = 3,
}

//TODO Implement Scrolling virtual (https://blog.enginesoft.in/angular-material-multi-select-all-with-large-dataset/)
@Component({
  selector: 'app-form-input-template',
  templateUrl: './form-input-template.component.html',
  styleUrls: ['./form-input-template.component.scss'],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class FormInputTemplateComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild('addresspicker') addresspicker! : any;
  @ViewChildren('formContainer') formContainer: QueryList<any>;
  @ViewChildren('addressPicker') addressPicker: QueryList<any>;
  @Output() headerClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropdownSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() isHideShowToggle: EventEmitter<any> = new EventEmitter<any>();
  @Input() readOnly: boolean = false;
  @Input() demoteHeader: boolean = false;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  className = 'FormInputTemplateComponent';
  isLoaded: boolean = false;
  TICKET_PROTECTION_MODE: boolean = true;
  view: any;
  views: any;
  isMinified: boolean = false;
  sanitizedUrl: any;
  url: any;
  urlCache: { [key: string]: string } = {};
  //key is a path and makes it clickable Add Arrow.
  AreaID: any
  navLinks = [];
  addressPickerSubcriber: Subscription;
  formContainerSubscriber: Subscription;
  currentAddressPickerField: any;
  formElement: ElementRef;

  resizeObservable: Observable<Event>;
  resizeSubscription: Subscription;

  allowedToUpdateIfPrivateTicket = [
    'LocateCrossStreet',
    'LocateSecondCrossStreet',
    'DispatcherRemarks',
    'ExtentOfWork',
    'CallerName',
    'CustomerID', // huh
    'TypeOfWork',
    'bSiteMeetRequired',
    'bRevisionDenied',
    'UnitLot',
    'MapReference',
    'Remarks',
    'email2',
    'MeetLocation'
  ];

  allowToUpdateAdditionalFields = [
    'RegionID',
    'SubRegionID',
    'LocateSubRegionName',
    'LocateSubDivision',
    'Township',
    'StartHouseNumber',
    'EndHouseNumber',
    'LocateAddress',
    'PONum',
    'ProjectNum',
    'JobNumber',
    'WorkDoneFor',
    'AlternateContact',
    'AlternativeNumber',
    'PhoneNumber',
    'email',
    'DispatcherRemarks',
    'Remarks',
    'email2',
    'MeetLocation'
  ];

  /**
   * Props
   * Needs view with added form group that was
   * generated using create ticket service.
   * One form group per view.
   * CategoryLinks is array that converts the
   * category to a button that will route user.
   */

  timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#00A0CC',
    },
    dial: {
      dialBackgroundColor: '#00A0CC',
    },
    clockFace: {
      clockFaceBackgroundColor: '#eee',
      clockHandColor: '#00A0CC',
      clockFaceTimeInactiveColor: '#222',
    },
  };

  constructor(
    logger$: LoggerService,
    public dialog: MatDialog,
    private ticket$: CreateTicketComponentService,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {
    super(logger$);
  }

  ngOnInit(): void {
    try {
      this.logger$.log('FormInputTemplateComponent: init');
      this.resizeObservable = fromEvent(window, 'resize');
      this.resizeSubscription = this.resizeObservable.subscribe((evt) => {
        if (this.formElement && this.formElement.nativeElement && this.formElement.nativeElement.offsetWidth) {
          this.isMinified = this.formElement.nativeElement.offsetWidth < 599;
        }
      });
    } catch (error) {
      this.logger$.error(error);
    }
  }
  ngAfterViewInit() {
    try {
      //initial
      if (this.addressPicker) {
        this.addressPicker.forEach((element) => {
          this.getPlaceAutocomplete(element.nativeElement);
        });
      }

      // take(1) unsubscribes after first change.
      this.addressPicker.changes.pipe(take(1)).subscribe((msg) => {
        msg.forEach((element) => {
          this.handlePrefillAdressAutoComplete(element);
          this.getPlaceAutocomplete(element.nativeElement);

          var autoCompleteObservable = new MutationObserver(function () {
            autoCompleteObservable.disconnect();
            element.nativeElement.setAttribute('autocomplete', 'new-address');
          });

          autoCompleteObservable.observe(element.nativeElement, {
            attributes: true,
            attributeFilter: ['autocomplete'],
          });
        });
      });

      // this.formContainerSubscriber =
      this.formContainer.changes.pipe(take(1)).subscribe((msg) => {
        msg.forEach((element) => {
          this.formElement = element;
          this.isMinified = this.formElement.nativeElement.offsetWidth < 599;
        });
      });
    } catch (error) {
      this.logger$.error('ngAfterViewInit: ' + error.message);
    }
  }

  /**
   * Converts the property value to a string, if there is one.
   * @param input
   * @returns {string} url
   */
  getURLFromString(input: string) {
    let url = null;
    try {
      if (input && input.key && this.props.ticket && this.props.ticket[input.key]) {
        const value = this.props.ticket[input.key];
        if (value) {
          if (!Object.prototype.hasOwnProperty.call(this.urlCache, input.key)) {
            const urlPattern = /(https?:\/\/[^\s]+)/;
            const matches = value.toString().match(urlPattern);
            if (matches && matches.length > 0) {
              url = matches[0];
              this.urlCache[input.key] = url;
            } else {
              this.urlCache[input.key] = null;
            }
          } else {
            url = this.urlCache[input.key];
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return url;
  }

  // messageService: compMsg$,
  // categoryLinks: this.navLinks,
  // view : this.view

  /**
   * If receives views @ view then it is ready to load
   */
  ngOnChanges(changes: SimpleChanges) {
    try {
      if (this.props && this.props.ticket) {        
        this.disableFields(this.props.views, this.props.ticket.CallTypeID);
        const remarks = this.props.ticket.Remarks;
        const urlPattern = /(https?:\/\/[^\s]+)/;
        const matches = remarks.match(urlPattern);
        if (matches && matches.length > 0) {
          this.url = matches[0];
          this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(matches[0]);
        }
      }
      if (changes['props'] && changes['props']['currentValue']) {
        if (changes.props.currentValue.TICKET_PROTECTION_MODE != null) {
          this.TICKET_PROTECTION_MODE = changes.props.currentValue.TICKET_PROTECTION_MODE;
        }
        if (
          changes['props']['currentValue']['view'] &&
          changes['props']['currentValue']['views'] &&
          changes['props']['currentValue']['view']['formGroup']
        ) {
          this.view = changes['props']['currentValue']['view'];
          this.views = changes['props']['currentValue']['views'];
          this.navLinks = changes['props']['currentValue']['categoryLinks'];
          this.isLoaded = true;
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  manualChange() {
    try {
      if (this.props) {
        this.view = this.props['view'];
        this.views = this.props['views'];
        this.navLinks = this.props['categoryLinks'];
        this.isLoaded = true;
        if (this.props.TICKET_PROTECTION_MODE != null) {
          this.TICKET_PROTECTION_MODE = this.props.TICKET_PROTECTION_MODE;
        }
      }
    } catch (error) {
      this.logger$.error('manualChange: ' + error.message);
    }
  }

  ngOnDestroy() {
    // this.parentMsgService.unsubscribe();
    if (this.addressPickerSubcriber) {
      this.addressPickerSubcriber.unsubscribe();
    }
    if (this.formContainerSubscriber) {
      this.formContainerSubscriber.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
    this.logger$.log(this.className + ' Destroyed');
  }

  onFormContainerClick(groupKey) {
    try {
      //TODO
      //if it is not minified or phone view. else.
      //if want to have each input then need to remove padding around each ff
      this.onHeaderClick(groupKey, false);
    } catch (error) {
      this.logger$.error('onHeaderClick: ' + error.message);
    }
  }

  onHeaderClick(groupKey, togglePanel = false) {
    try {
      let event = {
        groupKey: groupKey,
        togglePanel: togglePanel,
      };
      // this.logger$.log(event);
      this.headerClicked.emit(event);
    } catch (error) {
      this.logger$.error('onHeaderClick: ' + error.message);
    }
  }

  handlePrefillAdressAutoComplete(element) {
    // console.log(element.nativeElement.value);

    try {
      let autocompleteService = new google.maps.places.AutocompleteService();
      let request = { input: element.nativeElement.value };
      autocompleteService.getPlacePredictions(request, (predictionsArr, placesServiceStatus) => {
        console.log(
          'getting place predictions :: predictionsArr = ',
          predictionsArr,
          '\n',
          'placesServiceStatus = ',
          placesServiceStatus
        );

        let placeRequest = { placeId: predictionsArr[0].place_id };
        let placeService = new google.maps.places.PlacesService(element.nativeElement);
        placeService.getDetails(placeRequest, (placeResult, placeServiceStatus) => {
          console.log('placeService :: placeResult = ', placeResult, '\n', 'placeServiceStatus = ', placeServiceStatus);
          this.onPlaceSelected(placeResult);
          // this._handlePlaceChange(placeResult);
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Form field functions
   */

  private getPlaceAutocomplete(element) {
    try {
      const autocomplete = new google.maps.places.Autocomplete(element, {
        componentRestrictions: { country: ['CA', 'US'] },
        types: [], // 'establishment' / 'address' / 'geocode'
      });

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.onPlaceSelected(place);
      });
    } catch (error) {
      this.logger$.error(error);
    }
  }

  disableFields(views, callType) {
    let template = [];
    let editablePrivate = this.userService.isSettingActive(SettingID.EDIT_PRIVATE_TICKET);
    let editable = this.userService.isSettingActive(SettingID.EDIT_TICKET_ADDITIONAL);
    if (editablePrivate && callType == 9) {
      template = this.allowedToUpdateIfPrivateTicket;
    }
    if (editable) {
      template = this.allowToUpdateAdditionalFields;
    } 
    if(editablePrivate && callType == 9 && editable){
      template = this.allowedToUpdateIfPrivateTicket.concat(this.allowToUpdateAdditionalFields);
    }
    if(!editable && !editablePrivate){
      return '';
    }  
    if (views.ticketInfo) {
      for (const i in views.ticketInfo.groups) {
        for (const x in views.ticketInfo.groups[i].fields) {
          let field = views.ticketInfo.groups[i].fields[x].key;
          if (template.includes(field)) {
            views.ticketInfo.groups[i].fields[x].isReadOnly = false;
          }
        }
      }
    }
  }

  //need to do this because dropdown value is not primitive type
  compareOptions(option: any, selected: any): boolean {
    try {
      //return true when found
      if (option && selected) {
        if (option.value && option.value) {
          return option.value.toString().toLowerCase() === selected.value.toString().toLowerCase();
        } else {
          return option.toString().toLowerCase() === selected.toString().toLowerCase();
        }
      } else {
        return option === selected;
      }
      // return option && selected ? option.value.toString().toLowerCase() === selected.value.toString().toLowerCase() : option === selected;
    } catch (error) {
      console.log('compareOptions: ' + error.message);
      return false;
    }
  }

  onAddressPickerClick(field) {
    this.currentAddressPickerField = field;
  }

  onPlaceSelected(place) {
    try {
      let event = {
        place: place,
        field: this.currentAddressPickerField,
      };
      this.addressSelected.emit(event);
    } catch (error) {
      this.logger$.error('onPlaceSelected: ' + error.message);
    }
  }

  //11

  /**
   *
   * @param field view field
   * @param event date
   */
  onDateChange(field, event) {
    try {
      if (field && event) {
        let emitValue = {
          field: field,
          date: event,
        };
        this.dateSelected.emit(emitValue);
      }
    } catch (error) {
      this.logger$.error('onDateSelected: ' + error.message);
    }
  }

  //12 & 11
  onDropdownSelectionChange(field, event) {
    try {
      let emitValue = {
        field: field,
        event: event,
      };
      this.dropdownSelected.emit(emitValue);
    } catch (error) {
      this.logger$.error('onDropdownselectionChange: ' + error.message);
    }
  }

  //13
  displayFn(key: any): (option: any) => string {
    return (option: any): string => {
      if (typeof option === 'object') {
        return option && option.text ? option.text : '';
      } else if(key == "AreaID") {
        return this.AreaID?.text;
      } else {
        return option;
      }
    };
  }

  onAutoClick(event, field) {
    //check if field has patchValues    
    try {
      let emitValue = {
        field: field,
        event: event,
      };
      this.AreaID = event
      this.dropdownSelected.emit(emitValue);
      if (field && field.options.patchValues && field.options.patchValues.length > 0) {        
        let optionField: FormTemplateField = field;
        let optionValues: FormTemplateOptionValues = event.value;        
        this.viewsAutoSelectPatchValues(optionValues, optionField);
      }
    } catch (error) {
      this.logger$.error('onauto' + error.message);
    }
  }

  dateTimePickerClear(event, field) {
    try {
      event.stopPropagation();
      let timeChange = {};
      timeChange[field.value.key] = null;
      timeChange[field.value.key + 'Time_Ignore_'] = null;
      this.view['formGroup'].patchValue(timeChange);
    } catch (error) {
      console.log(error.message);
    }
  }

  dateTimePickerDateChanged(event, field) {
    try {
      //patch formgroup on the current view.
      let timeChange = {};
      let currentAppointmentDate = this.view['formGroup'].controls[field.value.key].value;
      let currentAppointmentTime = this.view['formGroup'].controls[field.value.key + 'Time_Ignore_'].value;
      if (!currentAppointmentDate) {
        currentAppointmentDate = new Date();
      }
      if (!currentAppointmentTime) {
        currentAppointmentTime = new Date().toLocaleTimeString('default', {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
      let dateTimeValue = this.dateTimePickerMerge(currentAppointmentDate, currentAppointmentTime);
      timeChange[field.value.key] = dateTimeValue;
      timeChange[field.value.key + 'Time_Ignore_'] = currentAppointmentTime; //add time_Ignore_ for custom field
      this.view['formGroup'].patchValue(timeChange);
    } catch (error) {
      console.log(error.message);
    }
  }

  dateTimePickerTimeChanged(event, field) {
    try {
      let timeChange = {};
      let currentAppointmentDate = this.view['formGroup'].controls[field.value.key].value;
      let currentAppointmentTime = event;

      try {
        if (currentAppointmentDate.toLowerCase().indexOf('t') > 0) {
          currentAppointmentDate = DateTime.fromISO(currentAppointmentDate).toJSDate();
        }
      } catch (error) {
        console.log('Date', currentAppointmentDate);
      }

      if (!currentAppointmentDate) {
        currentAppointmentDate = new Date();
      }
      let dateTimeValue = this.dateTimePickerMerge(currentAppointmentDate, currentAppointmentTime);
      timeChange[field.value.key] = dateTimeValue;
      this.view['formGroup'].patchValue(timeChange);
    } catch (error) {
      console.log(error.message);
    }
  }

  dateTimePickerMerge(date, time) {
    try {
      return new Date(DateTime.fromJSDate(date).toFormat('yyyy LL dd') + ' ' + time);
    } catch (error) {
      console.log(error.message);
      return date;
    }
  }

  //patch all views.
  //get patch values. view and controlKeys
  //get form of view and patch values
  private viewsAutoSelectPatchValues(event: FormTemplateOptionValues, field: FormTemplateField) {
    try {
      if (this.views) {
        let patchValues = field.options.patchValues;
        //gather the data that will be auto filled.
        this.gatherAutoCompletePatchData(field.options.optionsFillID, event)
          .then((result) => {
            for (let index in patchValues) {
              let viewkey = patchValues[index].viewKey;

              if (this.views[viewkey] && this.views[viewkey]['formGroup']) {
                let frmGroup: FormGroup = this.views[viewkey]['formGroup'];

                //build object to patch
                let obj = {};
                for (let controlKey of patchValues[index].controlKeys) {
                  if (controlKey == field.key) {
                    obj[controlKey] = event;
                  } else {
                    obj[controlKey] = result[controlKey];
                  }
                }

                frmGroup.patchValue(obj);
                this.views[viewkey]['formGroup'].patchValue(obj);
              }
            }
          })
          .catch((ex) => {
            this.logger$.error('viewsAutoSelectPatchValues.gatherAutoCompletePatchData: ' + ex.message);
          });
      }
    } catch (error) {
      this.logger$.error('viewsAutoSelectPatchValues: ' + error.message);
    }
  }

  private async gatherAutoCompletePatchData(optionFillID: number, optionValue: FormTemplateOptionValues) {
    try {
      var dataRow = {};
      switch (optionFillID) {
        case OptionsFillID.companyName:
          const excavatorID = parseInt(optionValue.value);
          let row = await this.ticket$.getCompanyInfoDB(excavatorID);
          if (row && row[0]) {
            dataRow = row[0];
          }
          break;

        default:
          break;
      }
    } catch (error) {
      this.logger$.error('gatherAutoCompletePatchData' + error.message);
    }
    return dataRow;
  }

  onEdit(group) {
    try {
      if (group && group['fields'] && Object.keys(group['fields']).length > 0) {
        this.openDialog(group);
      } else {
        this.logger$.log('no fields');
      }
    } catch (error) {
      this.logger$.error('onedit: ' + error.message);
    }
  }

  openDialog(group): void {
    try {
      var hiddenInputs = Object.values(group['fields']).filter((field) => {
        return field ? field['isHideShow'] : false;
      });

      const dialogRef = this.dialog.open(FormTemplateDialogComponent, {
        data: {
          inputs: hiddenInputs,
          title: group.header,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        let newStateOfGroup = result['inputs'].reduce(
          (total, current) => {
            if (current['isVisible']) {
              total[0].push(Number.parseInt(current['key']));
            } else {
              total[1].push(Number.parseInt(current['key']));
            }

            return total;
          },
          [[], []]
        );

        this.isHideShowToggle.emit([group['key'], newStateOfGroup]);
        this.logger$.log('The dialog was closed');
      });
    } catch (error) {
      this.logger$.error('openDialog: hiddenValues: ', error);
    }
  }
}

// color
// date
// datetime-local
// email
// month
// number
// password
// search
// tel
// text
// time
// url
// week

@Component({
  selector: 'app-form-template-dialog',
  templateUrl: './form-template-dialog.component.html',
})
export class FormTemplateDialogComponent {
  // @Input() props: any;
  accept: boolean;
  originalData: any;
  // Arr = Array;
  // num:number = 100;
  constructor(
    public dialogRef: MatDialogRef<FormTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.originalData = data;
  }

  onCheckChange(event) {
    if (this.data['inputs']) {
      if (this.data['inputs'][event.source.id]['isVisible']) {
        this.data['inputs'][event.source.id]['isVisible'] = false;
      } else {
        this.data['inputs'][event.source.id]['isVisible'] = true;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // this.data = this.originalData;
  }
}
export interface FormTemplateOptionPatchValue {
  viewKey: string;
  controlKeys: string[];
}
export interface FormTemplateOptionValues {
  text: string;
  value: string;
}
export interface FormTemplateOptions {
  hasValues: boolean;
  optionsFillID?: number;
  values?: FormTemplateOptionValues[];
  patchValues?: FormTemplateOptionPatchValue[];
}

export interface FormTemplateField {
  appearance: string;
  inputTypeID: number;
  label: string;
  key: string;
  placeholder: string;
  matches: string;
  isRequired: boolean;
  isReadOnly: boolean;
  width_xs: number | string;
  width_sm: number | string;
  width_md: number | string;
  width_lg: number | string;
  width_xl: number | string;
  fieldOrder: number;
  isVisible: boolean;
  isHideShow?: boolean;
  tableName?: string;
  options?: FormTemplateOptions;
  selectOptions?: any;
}

export interface FormTemplateGroup {
  header: string;
  key: string;
  groupOrder: number;
  hasHiddenValues?: boolean;
  fillStageID?: number;
  fillKey?: string;
  isEmpty?: boolean;
  fields: {
    [key: string]: FormTemplateField;
  };
}

export interface FormTemplateView {
  header: string;
  showHeader?: boolean;
  key: string;
  groups: {
    [key: string]: FormTemplateGroup;
  };
}

export interface FormTemplateViews {
  views: {
    [key: string]: FormTemplateView;
  };
}

@NgModule({
  declarations: [FormInputTemplateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    ScrollingModule,
    SearchableDropdownModule,
    // NgxMatTimepickerModule,
    // NgxMatDatetimePickerModule,
    // NgxMatNativeDateModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [FormInputTemplateComponent],
})
export class FormInputTemplateModule {}

import { StyleLike } from 'ol/style/Style';
import { Geometry } from 'ol/geom';
import { Sticker } from './tools/sticker-tool';
import { SvgSymbol } from '../services/map-symbols.service';

export enum ShapeType { // shapeTypeIDs
  Point = 1,
  LineString = 2,
  MultiLineString = 3,
  Polygon = 4,
  Circle,
  Label,
  Image,
  ArrowLineLegacy,
  LetterLine = 9,
  MeasureLine,
  LegacySymbol,
  SvgSymbol,
  ArrowLine,
}

export enum Point {
  image = 0,
  stamp,
  label,
  symbol,
}

export type LayerQueryFilters = {
  all: true;
  utilityID: number[];
  requestNumber: number;
};

export enum MapInteractionType {
  draw = 0,
  addText,
  addSticker,
  select,
  Symbol,
}

export enum ToolbarItemType {
  Tool = 0,
  StyleOption,
}

export enum ToolType {
  lineTool = 0,
  shapeTool,
  textTool,
  symbolTool,
  stampTool,
  imageTool,
  selectionTool,
  locateAreaTool,
}

// tool enums
export enum LineTools {
  singleLine = 0,
  multiLine,
  freeHand,
  measureLine,
  arrowLine,
  letterLine
}

export enum ShapeTools {
  circle = 0,
  square,
  polygon,
  locateArea,
}

export enum TextTools {
  label = 0,
}

export enum StickerTools {
  stamp,
}

export enum SymbolTools {
  legacySymbol,
  svgSymbol,
}

export enum SelectionTools {
  pointSelect = 0,
  boxSelect,
}

export enum ManipulationTools {
  rotate,
  scale,
}

// Style enums
export enum LineStyle {
  solid = 0,
  dashed,
  dotted,
  imported,
}

export const LineStyles = {
  [LineStyle.solid]: '',
  [LineStyle.dashed]: '20,20'
}


export enum LineThickness {
  thinnestLine = 2,
  thinLine,
  mediumLine,
  boldLine,
}

export type InteractionParameters = {
  shapeType: ShapeType;
  interactionType: MapInteractionType;
  sticker: Sticker;
  legacySymbol: Array<SymbolShape>;
  svgSymbol: SvgSymbol;
  toolType: ToolType;
  toolName: LineTools | ShapeTools | TextTools | SelectionTools;
  style?: StyleLike;
  lineType?: LineStyle;
  lineThickness?: LineThickness;
  strokeColour?: string;
  fillColour?: string;
  opacity?: number;
  auxiliaryOptions?: Record<string, unknown>;
  changes: Partial<ToolbarState>;
  toolbarState: ToolbarState
};


export type CustomLine = {
  LineID: number;
  LineType: string;
  LineColour: string;
  LineText: string;
  TextColour: string;
  LineOrder: number;
  Dashed: number[];
};

export type BaseMapShapesFeature = {
  basemap_shape_id: number;
  request_number: number;
  assignment_id: number;
  user_id: number;
  date_created: string;
  date_updated: string;
  stroke_colour: string;
  stroke_width: number;
  stroke_dasharray: string;
  fill_colour: string;
  fill_opacity: number;
  text_label: string;
  text_colour: string;
  image_source: string;
  image_width: number;
  image_height: number;
  image_rotation: number;
  geometry: Geometry;
  shape_type_id: number;
  parent_shape_id: number;
  radius: number;
  isLocateArea: boolean;
};

export type DrawingShapesFeature = BaseMapShapesFeature & {
  utility_id: number;
};

export type Symbol = {
  id: number;
  name: string;
  tooltip: string;
  symbol_image: Uint8Array;
};

export type SymbolShape = {
  id: number;
  symbol_id: number;
  geometry: Geometry;
  stroke_colour: string;
  fill_colour: string;
  width: number;
  text: string;
  rotation: number;
  index: number;
  radius: number;
  geojson: {
    type: string;
    coordinates: number[];
  };
};


export interface StickerGroup {
  CompanyID: number;
  ID: number;
  LastChanged: string;
  Name: string;
  StickerFile: string;
  StickerFileName: string;
  StickerID: number;
}

type HexString = string;

export interface ToolbarState {
  toolPage: ToolType;
  selectionTool: SelectionTools;
  manipulationTool: ManipulationTools;
  lineTool: LineTools;
  shapeTool: ShapeTools;
  lineStyle: LineStyle;
  lineThickness: LineThickness;
  utilityLine: CustomLine | null;
  sticker: StickerGroup | null;
  svgSymbol: SvgSymbol | null;
  strokeColour: HexString;
  fillColour: HexString;
  opacity: number;
  _source: ToolbarStateChangeSource
}

export type ToolbarStateChangeSource = 'toolbar' | 'interaction' | 'initialization';

export type ToolbarStateKey = keyof ToolbarState;

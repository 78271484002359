import { Component, OnInit } from "@angular/core";
import { NavBarService } from "../services/router/nav-bar.service";
import { NavBarConfig } from "src/app/routes/fieldside/components/fieldside-navigation-bar/navigation-bar.component";

@Component({
  selector: "app-punch-clock",
  templateUrl: "./punch-clock.component.html",
  styleUrls: ["./punch-clock.component.scss"],
})
export class PunchClockComponent implements OnInit {
  selected: number = 0;

  constructor(private navBarService: NavBarService) {}

  ngOnInit(): void {
    this.navBarService.updateNavBar(
      new NavBarConfig(
        "Punch In/Out",
        "ticket-summary",
        false,
        null,
        null,
        false,
        true
      )
    );
  }
}

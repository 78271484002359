import { ChangeDetectionStrategy, Component, effect, Input, OnDestroy, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ChipSelectComponent, ChipSelectComponentInput } from '../chip-select/chip-select.component';

enum RangeTypes {
  Range = 'range',
  Yesterday = 'yesterday',
  Past24Hours = 'past_24_hours',
}

@Component({
  selector: 'app-competers-date-range-picker',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersDateRangePickerComponent,
    },
  ],
  template: `
    <div
      class="box-border flex flex-col {{
        _isSelected$ ? 'pb-3' : ''
      }} gap-3 justify-start items-start bg-transparent w-[300px]">
      <div class="flex flex-row gap-3 justify-start items-center w-full">

        <!-- Title of date range -->
        @if (showLabel) {
          <div class="flex justify-start items-center w-1/2">
            <label for="me" class=" font-semibold text-md capitalize">
              {{ title }}
            </label>
          </div>
        }

        <!-- toggle button -->
        @if (!inlined) {
          <div class="flex justify-start flex-grow items-center">
            <button
              (click)="toggleSelected()"
              class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
              @if (_isSelected$|async) {
                <mat-icon class="text-success" style="width: 13px; height:13px" svgIcon="yes-filled" />
              } @else {
                <mat-icon svgIcon="toggle_off" style="width: 13px; height:13px" />
              }
            </button>
          </div>
        }
      </div>

      <!-- date range picker  -->
      <div class="flex flex-row gap-3">
        @if ((_isSelected$ | async) || inlined) {
          <div class="flex flex-col">
            <app-competers-chip-select
              [options]="rangeTypeOptions"
              [selectedValue]="currentRangeType"
              (newValueEvent)="changeRangeType($event)" />
            <div *ngIf="currentRangeType === 'range'" class="flex flex-row gap-2">
              <div class="flex flex-col justify-start items-center">
                <div
                  class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
                  <button
                    (click)="startDatePicker.open()"
                    class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                    <mat-icon style="width:20px; height:20px; " svgIcon="calendar" />
                  </button>
                  <p class="text-md text-gray-600  font-semibold">
                    {{ (startDate.value | date: 'dd-MM-YYYY') ?? 'DD-MM-YYYY' }}
                  </p>
                </div>
                <div class="overflow-hidden h-0 w-36">
                  <mat-datepicker #startDatePicker></mat-datepicker>
                  <input
                    #startDate
                    matInput
                    [matDatepicker]="startDatePicker"
                    [ngModel]="startDate$$()"
                    (ngModelChange)="startDate$$.set($event)" />
                  />
                </div>
              </div>
              <div *ngIf="currentRangeType === 'range'" class="flex flex-col justify-start items-center">
                <div
                  class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
                  <button
                    (click)="endDatePicker.open()"
                    class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                    <mat-icon style="width:20px; height:20px; " svgIcon="calendar" />
                  </button>
                  <p class="text-md text-gray-600  font-semibold">
                    {{ (endDate.value | date: 'dd-MM-YYYY') ?? 'DD-MM-YYYY' }}
                  </p>
                </div>
                <div class="overflow-hidden h-0 w-36">
                  <mat-datepicker [startAt]="defaultDate" #endDatePicker></mat-datepicker>
                  <input
                    #endDate
                    matInput
                    [matDatepicker]="endDatePicker"
                    [ngModel]="endDate$$()"
                    (ngModelChange)="endDate$$.set($event)" />
                </div>
              </div>
            </div>

          </div>

        }
      </div>
    </div>
  `,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatIconModule, MatDatepickerModule, MatInputModule, MatButtonToggleModule, ChipSelectComponent]
})
export class CompetersDateRangePickerComponent implements OnDestroy, ControlValueAccessor {
  @Input() title: string = 'Date';
  @Input() showLabel: boolean = true;
  @Input() inlined: boolean = false;
  @Input() disabled = false;

  // observables and signals
  private destroy$ = new Subject<void>();
  protected startDate$$ = signal<Date>(new Date());
  protected endDate$$ = signal<Date>(new Date());
  protected val: DateRange = {upper: new Date(), lower: new Date()};

  protected touched = false;
  protected _isSelected$ = new BehaviorSubject(false);
  protected defaultDate: Date = new Date();

  protected rangeTypeOptions: ChipSelectComponentInput[] = [
    {value: 'range', name: 'Range'},
    {value: 'yesterday', name: 'Yesterday'},
    {value: 'past_24_hours', name: 'Past 24hrs'},
  ];
  protected currentRangeType = "range";


  constructor() {
    effect(() => {
      if (this.onChange !== undefined) {
        const lower = this.startDate$$();
        const upper = this.endDate$$();
        if (this.currentRangeType !== RangeTypes.Past24Hours) {
          // if the range type is not past 24 hours, set the time to midnight
          if (lower !== null) lower.setHours(0, 0, 0, 0);
          if (upper !== null) upper.setHours(23, 59, 59, 999);
        }
        this.onChange({lower: lower, upper: upper});
        this.val = {lower: lower, upper: upper};
        this._isSelected$.next(this.val.lower !== null || this.val.upper !== null);
      }
    });
  }

  onChange: (data: DateRange) => void;

  onTouched: () => void;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(data: DateRange) {
    if (!isEqual(this.val, data)) {
      this.startDate$$.set(data?.lower ?? null);
      this.endDate$$.set(data?.upper ?? null);
    }
    if (data === null) {
      this._isSelected$.next(false);
    }
  }

  registerOnChange(onChange: () => DateRange) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  toggleSelected() {
    this._isSelected$.next(!this._isSelected$.value);
    if (!this._isSelected$.value) {
      this.startDate$$.set(null);
      this.endDate$$.set(null);
    }
  }

  /**
   * Triggers when the user selects a new range type
   * @param event
   */
  changeRangeType(event) {
    this.currentRangeType = event;

    switch (this.currentRangeType) {
      // Set start date to yesterday and end date to today
      case RangeTypes.Yesterday:
        this.startDate$$.set(new Date(new Date().setDate(new Date().getDate() - 1))); //yesterday at 00:00:00
        this.endDate$$.set(new Date(new Date().setDate(new Date().getDate() - 1))); //today at 00:00:00
        break;

      // Set start date to 24 hours ago
      case RangeTypes.Past24Hours:
        this.startDate$$.set(new Date(new Date().setHours(new Date().getHours() - 24))); //yesterday at time now
        this.endDate$$.set(new Date()); //time now
        break;
    }
  }

}

export type DateRange = {upper: Date; lower: Date};

<!--change fxLayoutGap to change distance between the upper parts and cancel/save buttons-->
<div fxLayout="column" fxLayoutAlign="space-between" class="edit_node_box" fxLayoutGap="36px">
  <!--Created an extra div to hold the all the elements of edit node in one, and the cancel/save button in another-->
  <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="36px">
    <span class=" text-overline font-semibold capitalize">{{ formatNodeTrace(nodeTrace) }}</span>
    <form #editorForm="ngForm" (ngSubmit)="saveCurrentNode()">
      <mat-form-field appearance="outline"  class="dense-6">
        <mat-label class="text-md">Criteria</mat-label>
        <mat-select
          #criteriaInput
          [disabled]="parentNodeID == 0"
          [value]="currentCriteriaID"
          (selectionChange)="criteriaChanged($event.value)">
          <mat-option *ngFor="let criteria of filteredCriterias" [value]="criteria.id">
            {{ criteria.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="dense-6">
        <mat-label class="text-md">Values</mat-label>
        <mat-select
          #valuesInput
          [disabled]="parentNodeID == 0"
          [value]="currentValues"
          (selectionChange)="valuesChanged($event.value)"
          multiple>
          <mat-option *ngFor="let value of filteredValues" [value]="value.id">
            {{ value.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"  class="dense-6">
        <mat-label class="text-md">Rate</mat-label>
        <span matPrefix>
          <mat-icon>attach_money</mat-icon>
        </span>
        <input
          #rateInput
          matInput
          [disabled]="children.length > 0"
          placeholder="0.00"
          type="number"
          [value]="currentRate"
          (change)="rateChanged($event.target.value)" />
      </mat-form-field>
      <mat-form-field appearance="outline"  class="dense-6">
        <mat-label class="text-md">Rate Name</mat-label>
        <input
          #rateInput
          matInput
          [disabled]="children.length > 0"
          placeholder="Rate Name"
          type="text"
          [value]="currentRateName"
          (change)="rateNameChanged($event.target.value)" />
      </mat-form-field>
    </form>
  </div>
  <div class="absolute right-6 bottom-6">
    <button class="btn-cancel mr-4" (click)="cancelEdit.emit()">Cancel</button>
    <button class="btn-save" (click)="saveCurrentNode()">Save</button>
  </div>
</div>

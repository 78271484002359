import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../../../../pipes/truncate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ShapeType } from '../../../../../../modules/drawing-module/utilities/types';
import { ContextSelection } from '../map-context-menu/map-context-menu.component';
import { OpenLayersService } from '../../../../../../modules/drawing-module/services/open-layers.service';
import { Overlay } from 'ol';
import { CompetersDeprecatedInputComponent } from '../../../../inputs/competers-deprecated-input/competers-deprecated-input.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'app-feature-edit-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    TruncatePipe,
    MatButtonModule,
    CompetersDeprecatedInputComponent,
    ReactiveFormsModule,
  ],
  template: `
    <div #container class="box-border">
      @if (contextSelection !== null) {
        <div
          [formGroup]="myForm"
          class="size-fit flex flex-col justify-start gap-4 items- bg-white rounded-md shadow-2xl p-3">
          @if (config$$() === 'text_label') {
            <span
              class="text-black  font-semibold text-headline-6">
              Edit Text
            </span>
            <app-competers-depricated-input
              [formControl]="getControl('text_label')"
              [type]="'text'"
              class="w-full"></app-competers-depricated-input>
          }
          <div class="w-full flex flex-row gap-3 justify-end items-center">
            <button
              type="button"
              class="h-9 w-[104px] flex justify-center items-center appearance-none border-none bg-transparent py-2 px-4 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize"
              (click)="handleCancel()">
              cancel
            </button>
            <button
              type="button"
              class="h-9 w-[104px] flex justify-center items-center appearance-none rounded border-solid border-2 border-primary bg-primary py-2 px-4 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize disabled:cursor-not-allowed disabled:bg-gray-500 disabled:border-gray-500"
              (click)="handleSubmit()">
              save
            </button>
          </div>
        </div>
      }
    </div>
  `,
  styleUrls: ['./feature-edit-menu.component.scss'],
})
export class FeatureEditMenuComponent implements OnInit, OnChanges {
  @ViewChild('container', { static: true })
  container: ElementRef<HTMLDivElement>;
  @Input() contextSelection: ContextSelection;
  @Output() featuresEdited = new EventEmitter<
    ContextSelection & FeatureEdits
  >();
  @Output() closed = new EventEmitter<void>();
  //observables
  protected config$$ = signal<'text_label'>(null);
  // services
  private renderer = inject(Renderer2);
  private formBuilder = inject(FormBuilder);
  protected myForm: FormGroup = this.formBuilder.group({});
  private openLayersService = inject(OpenLayersService);
  //members
  private editMenuOverlay: Overlay;
  private unlistenFns: Array<() => void> = [];

  constructor() { }

  ngOnInit() {
    const handler = (event) => {
      if (event.composedPath().indexOf(this.container.nativeElement) === -1) {
        this.editMenuOverlay.setPosition(undefined);
      }
    };
    this.unlistenFns.push(
      this.renderer.listen('document', 'mousedown', handler)
    );
    this.unlistenFns.push(
      this.renderer.listen('document', 'touchstart', handler)
    );

    this.editMenuOverlay = new Overlay({
      element: this.container.nativeElement,
      autoPan: {
        margin: 25,
      },
      position: undefined,
      positioning: 'top-left',
    });

    this.openLayersService.attachOverlay(this.editMenuOverlay);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.contextSelection !== undefined &&
      this.editMenuOverlay !== undefined
    ) {
      this.myForm.controls = {};
      if (this.contextSelection === null) {
        this.editMenuOverlay.setPosition(undefined);
      } else {
        const feature = this.contextSelection.feature.item(0)
        const shapeType = feature.get('shape_type_id');
        if ([ShapeType.MeasureLine, ShapeType.Label].includes(shapeType)) {
          const text = feature.get('text_label');
          this.myForm.addControl(
            'text_label',
            this.formBuilder.control({ value: text, disabled: false })
          );
          this.config$$.set('text_label');
        }
        this.editMenuOverlay.setPosition(
          this.contextSelection.event.coordinate
        );
      }
    }
  }

  getControl(name: string) {
    return this.myForm.get(name) as FormControl;
  }

  handleClose() {
    this.myForm.reset();
    this.editMenuOverlay.setPosition(undefined);
    this.closed.emit();
  }

  handleCancel() {
    this.handleClose();
  }

  handleSubmit() {
    this.featuresEdited.emit({
      ...this.contextSelection,
      edits: { ...this.myForm.value },
    });
    this.handleClose();
  }
}

export type FeatureEdits = {
  edits: Record<string, string>;
};

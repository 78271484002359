import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LonLat, OpenLayersService } from '../services/open-layers.service';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { DrawingService, DrawingTypes } from '../drawing.service';
import { LocationService } from '../../../shared/services/location/location.service';
import { CaptureFrameComponent } from '../drawing-capture/capture-frame.component';
import { CommonModule } from '@angular/common';
import { DrawingOverlayComponent } from './drawing-overlay/drawing-overlay.component';
import { DrawingActionBarComponent } from './drawing-action-bar/drawing-action-bar.component';
import { DrawingMapComponent } from '../drawing-map/drawing-map.component';
import { DrawingCanvasComponent } from '../drawing-canvas/drawing-canvas.component';
import randomCity from '../utilities/cities';
import { Ticket } from '../../shared/ticket/ticket.service';
import { MapFeatureService } from '../services/map-feature.service';
import { MapCaptureButtonComponent } from '../drawing-capture/map-capture-button.component';
import { CaptureError, CaptureErrorReason, DrawingCaptureService } from '../services/drawing-capture.service';
import { DrawingCategory, DrawingID, DrawingManagerService } from '../services/drawing-manager.service';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import { DrawingToolbarComponent } from '../drawing-toolbar/drawing-toolbar.component';
import { FloatingTabSelectComponent } from '../../../shared/components/misc/selection/floating-tab-select/floating-tab-select.component';
import { map, takeUntil } from 'rxjs/operators';
import { DrawingSwitcherComponent } from '../drawing-canvas/drawing-switcher/drawing-switcher.component';
import { Collection, Feature } from 'ol';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { LayerSwitcherComponent } from '../layer-switcher/layer-switcher.component';
import { DrawingFormComponent } from '../drawing-form/json-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputType, JsonFormControl } from '~lib/types/jsonForm';
import { DrawingCategorySelectComponent } from './drawing-category-select/drawing-category-select.component';
import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { LoggerService } from '../../core/services/logger/logger.service';
import {
  PillMenuButtonComponent,
  PillMenuComponent,
  SubMenuComponent,
} from '../../../shared/components/containers/pill-menu/pill-menu.component';
import { LayerManagerService } from '../services/layer-manager/layer-manager.service';
import { DrawingSettingsService } from '../drawing-settings.service';
import { MapInteractionService } from '../services/map-interaction.service';
import { CallTypeID } from '../../shared/ticket-details/ticket-details.module';
import { LayerQueryFilters } from '../utilities/types';
import { JsonFormGroup, JsonFormRecursive } from '~lib/types/jsonFormRecursive';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContextSelection, MapContextMenuComponent } from 'src/app/shared/components/maps/open-layers/overlays/map-context-menu/map-context-menu.component';
import { FeatureEditMenuComponent, FeatureEdits } from 'src/app/shared/components/maps/open-layers/overlays/feature-edit-menu/feature-edit-menu.component';
import { MapFeatureChange, FeatureChangeType } from '../classes/map-feature-change';

@Component({
  selector: 'app-drawing',
  standalone: true,
  imports: [
    CommonModule,
    CaptureFrameComponent,
    DrawingOverlayComponent,
    DrawingActionBarComponent,
    DrawingMapComponent,
    DrawingCanvasComponent,
    MapCaptureButtonComponent,
    DrawingToolbarComponent,
    FloatingTabSelectComponent,
    DrawingSwitcherComponent,
    LayerSwitcherComponent,
    DrawingFormComponent,
    ReactiveFormsModule,
    FormsModule,
    DrawingCategorySelectComponent,
    PillMenuButtonComponent,
    PillMenuComponent,
    SubMenuComponent,
    MatTooltipModule,
    MapContextMenuComponent,
    FeatureEditMenuComponent,
  ],
  template: `
    <div class="relative flex h-full justify-center items-center w-full bg-white select-none touch-none">
      @if (drawingService.captureModeEnabled$ | async) {
        <app-capture-frame>
          <div
            [style.width.px]="(captureService.captureDimensions$ | async)?.width"
            [style.height.px]="(captureService.captureDimensions$ | async)?.height"></div>
          @if (showDetailsForm && drawingCategory === 'auxiliary') {
            <app-drawing-form
              class="top-0 left-1/2 -translate-x-1/2 absolute z-10"
              [form]="jsonForm$ | async"
              [formValue]="_formValue$ | async"
              [height]="(captureService.captureDimensions$ | async)?.height"
              (formValueChanged)="handleFormValueChange($event)"></app-drawing-form>
          }
        </app-capture-frame>
      }
      <div class="absolute box-border size-full flex flex-col flex-grow justify-between items-end overflow-hidden">
        <!--    header-bar  -->
        @if (this.drawingService.drawingModeEnabled$ | async) {
          <app-drawing-action-bar (saveClick)="handleSaveCanvasClick()" class="w-full relative z-10" />
        }
        @if (isActive) {
          <app-drawing-overlay class="size-full">
            <div class="relative z-10" top-left>
              @if (!(drawingService.captureModeEnabled$ | async)) {
                @if (drawingService.drawingModeEnabled$ | async) {
                  <app-drawing-toolbar
                    (fileChanged)="handleNewBackground($event)"
                    (deleteClicked)="handleToolbarDeleteClick()"
                    class="drawing-tool"></app-drawing-toolbar>
                } @else {
                  <app-floating-tab-select
                    class="relative"
                    [activeTab]="selectedTab$ | async"
                    (activeTabChange)="handleTabChange($event)"
                    [multiple]="false"
                    [tabs]="tabs" />
                }
              } @else {
                <div class="absolute z-10 flex flex-row justify-start items-center gap-[59px]">
                  @if (drawingCategory === 'auxiliary') {
                    <button
                      [matTooltip]="'show auxiliary details form'"
                      (click)="showDetailsForm = !showDetailsForm"
                      class="cursor-pointer w-[111px] h-8 appearance-none border-1 border-solid border-[#707070] p-0 bg-white rounded-md">
                      <span class=" font-semibold capitalize text-md" [ngClass]="{'text-warn': showDetailsForm, 'text-primary': !showDetailsForm}">
                        {{ showDetailsForm ? 'hide' : 'show' }}
                      </span>
                    </button>
                  } @else {
                    <div class="w-[111px] h-8"></div>
                  }
                  <app-drawing-category-select
                    [ngModel]="drawingCategory"
                    (ngModelChange)="handleDrawingCategoryChange($event)"></app-drawing-category-select>
                </div>
              }
            </div>
            <div top-right class="w-fit">
              @if (drawingService.drawingType === DrawingTypes.canvas) {
                <app-drawing-switcher
                  [drawings]="drawingManager.canvasDrawings$ | async"
                  [activeDrawing]="this.activeDrawingID"
                  (deleteClick)="handleDeleteDrawingClick($event)"
                  (activeDrawingChanged)="handleDrawingChange($event)"></app-drawing-switcher>
              } @else if (drawingService.drawingType === DrawingTypes.map) {
                <app-layer-switcher
                  [drawings]="drawingManager.mapDrawings$ | async"
                  [activeDrawing]="this.activeDrawingID"
                  [selectedLayer]="layerManagerService.selectedLayer$ | async"
                  (layerSelectionChanged)="handleLayerChange($event)"
                  (activeDrawingChanged)="handleDrawingChange($event)"
                  (deleteClick)="handleDeleteDrawingClick($event)"
                  [classOverride]="(drawingService.captureModeEnabled$ | async) ? 'bg-white text-accent' : ''" />
              }
            </div>

            <div bottom-center>
              <app-pill-menu>
                <!--            drawing toggle              -->
                <app-pill-menu-button
                  [matTooltip]="'toggle drawing mode'"
                  [active]="drawingService.drawingModeEnabled$ | async"
                  (clicked)="toggleDrawingMode()"
                  [iconName]="'markup'" />

                <!-- spacer -->
                @if ((drawingService.drawingType$ | async) === DrawingTypes.map) {
                  <div class="w-0.5 h-8 bg-[#EEEEEF] mx-3"></div>
                } @else {
                  <div class="w-0.5 h-8 bg-[#EEEEEF]"></div>
                }
                @if (
                  !(drawingService.captureModeEnabled$ | async) &&
                  (drawingService.drawingType$ | async) === DrawingTypes.map
                ) {
                  <!--     info toggle -->
                  <app-pill-menu-button
                    [matTooltip]="'toggle map inspector'"
                    [active]="this.drawingService.inspectModeEnabled$ | async"
                    (clicked)="toggleInspectMode()"
                    [iconName]="'info'" />
                  <!--    'go-to' location -->
                  <app-sub-menu
                    [matTooltip]="'zoom to...'"
                    [active]="locationOpen"
                    (clicked)="locationOpen = !locationOpen"
                    (subMenuClicked)="handleFlyToCoordinates($event)"
                    [iconName]="'location'" />
                }
                <!--      capture toggle        -->
                <app-pill-menu-button
                  [matTooltip]="'toggle capture mode'"
                  [active]="this.drawingService.captureModeEnabled$ | async"
                  (clicked)="toggleCaptureMode()"
                  [iconName]="'capture area'" />
                <!--      settings button-->
                <app-pill-menu-button
                  [matTooltip]="'map settings'"
                  [active]="false"
                  (clicked)="settingsService.triggerSettingsDialog()"
                  [iconName]="'submenu_horiz'" />
              </app-pill-menu>
            </div>

            <div bottom-right>
              @if (drawingService.captureModeEnabled$ | async) {
                <app-map-capture-button
                  [matTooltip]="'capture map'"
                  (clicked)="handleCaptureClick()"></app-map-capture-button>
              }
            </div>
          </app-drawing-overlay>
        }
      </div>
      <div class="map h-full w-full">
        @if ((drawingService.drawingType$ | async) === DrawingTypes.map) {
          <app-drawing-map
            [layerFilterParameters]="layerFilterParameters"
            [ticket]="ticket"
            [coordinates]="coordinates"
            (mapTargetRendered)="attachMap($event)" />
        } @else if ((drawingService.drawingType$ | async) === DrawingTypes.canvas) {
          <app-drawing-canvas
            [image]="backgroundImage"
            [drawing]="activeDrawing"
            (mapTargetRendered)="attachMap($event)" />
        }
      </div>
    </div>
    <div #contextMenu class="size-fit">
      <app-context-menu
        (optionSelected)="contextOptionSelected($event)"
        [contextEvent]="interactionService.contextEvent$ | async" />
    </div>
    <div #featureEditMenu class="size-fit">
      <app-feature-edit-menu
        (closed)="handleEditMenuClosed()"
        (featuresEdited)="handleFeaturesEdited($event)"
        [contextSelection]="contextSelection$$()" />
    </div>
  `,
})
export class DrawingComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('map') mapTarget: ElementRef;
  @ViewChild(DrawingCanvasComponent) drawingCanvas: DrawingCanvasComponent;
  // IO
  @Input() isActive: boolean = true;
  @Input() layerFilterParameters: Partial<LayerQueryFilters>;
  @Input() ticket: Ticket;
  @Input() primaryID: number;
  @Input() assignmentID: number;
  @Input() coordinates: LonLat;
  @Input() formType: FormType = 1;
  @Output() drawingModeChange = new EventEmitter<boolean>();

  // services
  private cdr = inject(ChangeDetectorRef);
  private logger = inject(LoggerService);
  private snackbarService = inject(SnackbarService);
  private locationService = inject(LocationService);
  protected drawingService = inject(DrawingService);
  private featureService = inject(MapFeatureService);
  private authService = inject(AuthenticationService);
  protected openLayersService = inject(OpenLayersService);
  private progressBarService = inject(ProgressBarService);
  protected captureService = inject(DrawingCaptureService);
  protected drawingManager = inject(DrawingManagerService);
  protected layerManagerService = inject(LayerManagerService);
  protected interactionService = inject(MapInteractionService);
  protected settingsService = inject(DrawingSettingsService);

  // observables
  private destroy$: Subject<void> = new Subject<void>();
  protected tabSetter$ = new Subject<number>();

  protected contextSelection$$ = signal<any>(null);

  // members
  private readonly _defaultImage = '/assets/misc/backdrop.png';
  protected background: File;
  protected backgroundImage: HTMLImageElement = new Image();

  protected selectedTab$: Observable<Array<number>> = merge(this.tabSetter$, this.drawingService.drawingType$).pipe(
    map((x) => [x])
  );
  protected activeDrawingID: string;
  protected activeDrawing: Feature[];
  protected drawingCategory: DrawingCategory = 'auxiliary';

  protected showDetailsForm = false;
  protected locationOpen = false;

  protected tabs = [
    {
      index: 0,
      title: 'Canvas',
      icon: 'blank_canvas',
    },
    {
      index: 1,
      title: 'Map',
      icon: 'earth',
    },
  ];

  protected baseFormControls = {
    toFromOne: false,
    toFromTwo: false,
    locateArea: false,
    locateApprox: false,
    addressCount: false,
    pvs: false,
    gtel: false,
  };

  private readonly clientID: number;

  protected formZeroVal: JsonFormRecursive = {
    id: 0,
    controls: [],
    name: 'Capture Details',
    meta: {},
  };
  protected jsonForm$: BehaviorSubject<JsonFormRecursive> = new BehaviorSubject(this.formZeroVal);
  protected jsonFormControls$: BehaviorSubject<JsonFormControl[]> = new BehaviorSubject([]);
  protected _formValue$ = new BehaviorSubject<Record<string, string | number>>({});

  constructor() {
    this.captureService.gatherClientCaptureDimensions();
    this.clientID = this.authService.getNestedValueFromPayload('CLIENTID');
  }

  ngOnInit() {
    this.openLayersService.initMap();
    this.drawingService.drawingType$.subscribe(() => {
      this.interactionService.endInteraction();
      this.featureService.clearSession();
    });
    this.setupImage();

    this.captureService.captureDimensions$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.drawingService.drawingModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
      this.drawingModeChange.emit(x);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coordinates) {
      if (![undefined, null].includes(changes.coordinates.currentValue)) {
        this.openLayersService.flyToCoordinate(changes.coordinates.currentValue);
      } else {
        this.openLayersService.flyToCoordinate(this.locationService.userLocation);
      }
    }
    if (changes.primaryID) {
      this.refreshDrawingCache();
    }
    if (changes.formType || changes.ticket) {
      this.jsonForm$.next(this.formZeroVal);
      if (this.formType !== undefined) {
        this.checkFormLayout(this.formType);
        this.setupFormFields();
      }
    }
    if (changes.isActive && !changes.isActive.firstChange && changes.isActive.currentValue === false) {
      console.log('saving...');
      this.saveChanges();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.drawingManager.tidy();
    this.saveChanges();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.captureService.captureDimensions = this.drawingCategory;
    this.cdr.detectChanges();
  }

  saveChanges() {
    if (!this.featureService.hasUnsavedChanges) {
      return;
    }
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      this.saveCanvas().then(() => {
        this.endDrawing();
      });
    } else if (this.drawingService.drawingType === DrawingTypes.map) {
      this.interactionService.endInteraction();
      this.featureService.saveMap().then(() => {
        this.endDrawing();
      });
    } else {
      throw new Error('unknown drawing type');
    }
  }

  private warnUser(action: (...args: unknown[]) => unknown) {
    if (this.featureService.hasUnsavedChanges) {
      const ref = this.drawingService.openConfirmationDialog(
        'UNSAVED CHANGES',
        'save and continue?',
        () => {
          if (this.drawingService.drawingType === DrawingTypes.map) {
            this.interactionService.endInteraction();
            this.featureService.saveMap().then(() => {
              action();
            });
          } else if (this.drawingService.drawingType === DrawingTypes.canvas) {
            this.saveCanvas().then(() => {
              action();
            });
          }
        },
        'continue'
      );
      ref.afterClosed().subscribe((result) => {
        if (result === false || result === undefined) {
          this.tabSetter$.next(this.drawingService.drawingType);
        }
      });
    } else {
      action();
    }
  }

  private setupImage() {
    this.backgroundImage.src = this._defaultImage;
  }

  private checkFormLayout(formType: FormType) {
    if (
      this.ticket &&
      this.ticket.CallTypeID &&
      [
        CallTypeID.PSLL,
        CallTypeID.PSLL_PLANNED,
        CallTypeID.PSLL_PROJECT,
        CallTypeID.PSLL_EMERGENCY,
        CallTypeID.PSLL_POST_INSPECTION,
      ].includes(this.ticket.CallTypeID)
    ) {
      Object.entries(this.baseFormControls).forEach(([key]) => {
        this.baseFormControls[key] = false;
      });
      return;
    }
    switch (formType) {
      case FormType.LocateAreaOnly:
        this.baseFormControls.locateArea = true;
        break;
      case FormType.ToFromX1:
        this.baseFormControls.toFromOne = true;
        break;
      case FormType.ToFromX2:
        this.baseFormControls.toFromOne = true;
        break;
      case FormType.LocateApprox:
        this.baseFormControls.locateApprox = true;
        this.baseFormControls.toFromTwo = true;
        this.baseFormControls.addressCount = true;
        break;
      case FormType.PVS:
        this.baseFormControls.toFromTwo = true;
        this.baseFormControls.addressCount = true;
        break;
      case FormType.GTEL:
        this.baseFormControls.toFromTwo = true;
        this.baseFormControls.addressCount = true;
        break;
      case FormType.Empty:
      default:
        break;
    }
  }

  private setupFormFields() {
    const form: JsonFormRecursive = {
      controls: [],
      name: 'Drawing Details',
      meta: {},
      id: 0,
    };
    if (this.baseFormControls.toFromOne) {
      form.controls.push(this.addToFroms(true));
    }
    if (this.baseFormControls.toFromOne) {
      form.controls.push(this.addToFroms(true, true));
    }
    if (this.baseFormControls.locateArea) {
      form.controls.push(this.addLocateArea());
    }
    if (this.baseFormControls.locateApprox) {
      form.controls.push(this.locateApproximate());
    }
    if (this.baseFormControls.addressCount) {
      form.controls.push(this.addAddressCount());
    }
    if (this.ticket) {
      this.captureService
        .getCallTypeFormControls(this.ticket.CallTypeID)
        .then((callTypeControls) => {
          if (callTypeControls) {
            form.controls.push(...callTypeControls.groups);
          }
          this.jsonForm$.next(form);
        })
        .catch((e) => {
          this.logger.warn('setupFormFields', e);
          this.jsonForm$.next(form);
        });
    } else {
      this.jsonForm$.next(form);
    }
  }

  private addToFroms(first = false, second = false): JsonFormGroup {
    const group: JsonFormGroup = {
      name: 'To From',
      controls: [],
      meta: {},
      id: 0,
    };
    if (first) {
      group.controls.push(
        ...[
          {
            type: InputType.TEXT,
            name: 'from1',
            placeholder: 'From:',
            validations: {
              required: true,
            },
          },
          {
            type: InputType.TEXT,
            name: 'to1',
            placeholder: 'To:',
            validations: {
              required: true,
            },
          },
        ]
      );
    }
    if (second) {
      group.controls.push(
        ...[
          {
            type: InputType.TEXT,
            name: 'from2',
            placeholder: 'From:',
            validations: {
              required: true,
            },
          },
          {
            type: InputType.TEXT,
            name: 'to2',
            placeholder: 'To:',
            validations: {
              required: true,
            },
          },
        ]
      );
    }
    return group;
  }

  private addLocateArea(): JsonFormControl {
    return {
      type: InputType.TEXT,
      name: 'service addresses',
      placeholder: 'service addresses:',
      validations: {
        required: true,
      },
    };
  }

  private addAddressCount(): JsonFormControl {
    return {
      type: InputType.NUMBER,
      name: 'addressCount',
      placeholder: 'address count:',
      validations: {
        min: 0,
      },
    };
  }

  private locateApproximate(): JsonFormControl {
    return {
      label: 'locate is approximate',
      type: InputType.CHECKBOX,
      name: 'locateApproximate',
      placeholder: '',
      validations: {},
    };
  }

  protected handleLayerChange(layer: VectorLayer<VectorSource>) {
    this.warnUser(() => {
      if (this.layerManagerService.selectedLayer === layer) {
        this.layerManagerService.selectedLayer = null;
      } else {
        this.layerManagerService.selectedLayer = layer;
      }
      this.interactionService.endInteraction();
      this.featureService.clearSession();
      this.layerManagerService.refreshLayers();
    });
  }

  protected handleDrawingChange(drawingID: DrawingID) {
    this.warnUser(() => {
      if (this.drawingService.drawingType === DrawingTypes.canvas) {
        this.setupCanvasCapture(drawingID);
        this.endDrawing();
      } else if (this.drawingService.drawingType === DrawingTypes.map) {
        this.setupMapCapture(drawingID);
        this.endDrawing();
      } else {
        throw new Error('unknown drawing type');
      }
    });
  }

  protected setupCanvasCapture(drawingID: DrawingID) {
    if (drawingID) {
      this.activeDrawing = this.drawingManager.getParsedDrawing(drawingID);
      this.drawingManager
        .getDrawingBackground(this.clientID, this.assignmentID, this.primaryID, drawingID)
        .then((x) => this.handleNewBackground(x))
        .catch(() => this.logger.error('failed to fetch background'));
    } else {
      this.activeDrawing = [];
      this.featureService.nukeChanges();
      this.handleNewBackground();
    }
  }

  protected setupMapCapture(drawingID: DrawingID) {
    // TODO: Fix capture ratio
    const { mapData, drawingDetails, drawingCategory } = this.drawingManager.getDrawingByID(drawingID) ?? {};
    if (!mapData) {
      this._formValue$.next(null);
      return;
    }
    if (drawingCategory) {
      this.drawingCategory = drawingCategory;
    }
    this._formValue$.next(drawingDetails ?? null);
    this.openLayersService.setupCaptureParameters(mapData);
  }

  protected handleNewBackground(file: File = undefined) {
    if (this.backgroundImage) {
      URL.revokeObjectURL(this.backgroundImage.src);
    }
    if (file) {
      this.backgroundImage.src = URL.createObjectURL(file);
      this.background = file;
    } else {
      this.backgroundImage.src = this._defaultImage;
      this.background = undefined;
    }
  }

  protected handleTabChange(tabChangeEvent: number[]) {
    const action = () => {
      this.drawingService.drawingType = tabChangeEvent[0];
    };
    this.warnUser(action);
  }

  protected handleFlyToCoordinates(val: FlyToOptions) {
    try {
      if (val == FlyToOptions.User) {
        this.locationService.updateUserLocation().then(() => {
          this.openLayersService.flyToCoordinate(this.locationService.userLocation as LonLat);
        });
      } else if (val == FlyToOptions.Ticket) {
        if (!this.coordinates) {
          this.openLayersService.flyToCoordinate(randomCity() as LonLat);
        }
        this.openLayersService.flyToCoordinate(this.coordinates);
      }
    } catch {
      // do nothing
    }
  }

  async handleSaveCanvasClick() {
    this.progressBarService.start();
    try {
      if (this.drawingService.drawingType === DrawingTypes.canvas) {
        await this.saveCanvas();
      } else if (this.drawingService.drawingType === DrawingTypes.map) {
        this.interactionService.endInteraction();
        await this.featureService.saveMap();
      } else {
        this.progressBarService.stop();
        return;
      }
    } catch (error) {
      this.snackbarService.openSnackbar('save failed', SnackbarType.error, 'error');
      this.refreshDrawingCache();
      this.progressBarService.stop();
    }
  }

  private async saveCanvas() {
    this.activeDrawingID =
      (await this.captureService.saveCanvasDrawing(
        this.ticket['RequestNumber'].toString(),
        this.clientID,
        this.assignmentID,
        this.primaryID,
        this.captureService.canvasDrawingFromFeatures(this.drawingCanvas.features),
        this.background
      )) ?? undefined;
    if (this.activeDrawingID) {
      this.featureService.nukeChanges();
      this.drawingManager.fetchDrawings(this.primaryID);
      this.snackbarService.openSnackbar('capture uploaded', SnackbarType.success, 'success');
    } else {
      this.snackbarService.openSnackbar("couldn't save capture", SnackbarType.error, 'error');
    }
  }

  handleCaptureClick() {
    let drawingType: 'map' | 'canvas';
    let collection: GeoJSONFeatureCollection = undefined;
    let background: File;
    if (this.drawingService.drawingType === DrawingTypes.canvas) {
      drawingType = 'canvas';
      background = this.background;
      collection = this.captureService.canvasDrawingFromFeatures(this.drawingCanvas.features);
    } else {
      drawingType = 'map';
    }
    this.captureService
      .takeCapture(
        drawingType,
        this.clientID,
        this.assignmentID,
        this.primaryID,
        this.ticket['RequestNumber'],
        this.drawingCategory,
        this._formValue$.value,
        {},
        collection,
        background
      )
      .then((drawingID) => {
        if (drawingID === undefined) {
          return;
        }
        this.activeDrawingID = drawingID;
        if (this.activeDrawingID) {
          this.drawingManager.fetchDrawings(this.primaryID);
        }
        this.snackbarService.openSnackbar('capture uploaded', SnackbarType.success, 'success');
      })
      .catch((e) => {
        this.logger.error(e);
        if (e instanceof CaptureError) {
          this.snackbarService.openSnackbar(e.message, SnackbarType.error, 'Capture Error');
        } else {
          this.snackbarService.openSnackbar("couldn't save capture", SnackbarType.error, 'error');
        }
      });
  }

  handleToolbarDeleteClick() {
    this.interactionService.deleteSelectedFeatures();
  }

  handleFormValueChange(evt: Record<string, string | number>) {
    this._formValue$.next(evt);
  }

  attachMap(event: HTMLDivElement) {
    this.openLayersService.mapTarget = event;
  }

  handleDeleteDrawingClick(drawingID: DrawingID) {
    const drawing = this.drawingManager.getDrawingByID(drawingID);
    const files = [drawing.backgroundURL, drawing.captureURL].filter((x) => ![null, undefined].includes(x));
    this.captureService
      .deletePrompt()
      .then((res) => {
        if (res) {
          return this.captureService.deleteCapture(this.clientID, this.assignmentID, this.primaryID, drawingID, files);
        }
        throw new CaptureError('User Cancelled', CaptureErrorReason.UserCancelled);
      })
      .catch((e) => {
        if (e instanceof CaptureError) {
          return;
        }
      });
  }

  refreshdrawings() {
    this.drawingManager.fetchDrawings(this.primaryID);
  }

  protected toggleInspectMode() {
    this.warnUser(() => {
      this.drawingService.inspectModeEnabled = !this.drawingService.inspectModeEnabled;
      this.endDrawing();
    });
  }

  protected toggleCaptureMode() {
    this.warnUser(() => {
      this.drawingService.captureModeEnabled = !this.drawingService.captureModeEnabled;
      this.endDrawing();
    });
  }

  protected toggleDrawingMode() {
    this.warnUser(() => {
      this.drawingService.drawingModeEnabled = !this.drawingService.drawingModeEnabled;
      if (!this.drawingService.drawingModeEnabled) {
        this.endDrawing();
      }
    });
  }

  endDrawing() {
    this.interactionService.endInteraction();
    this.featureService.clearSession();
  }

  handleDrawingCategoryChange(event: 'primary' | 'auxiliary') {
    if (event === 'primary') {
      this.showDetailsForm = false;
    }
    this.drawingCategory = event;
    this.captureService.captureDimensions = event;
  }

  handleDrawingToolbarChange(event) {
    console.log('drawing stuff: ', event);
  }

  contextOptionSelected(selection: ContextSelection) {
    switch (selection.value) {
      case 'duplicate':
        this.handleFeaturesDuplicated(selection);
        break;
      case 'delete':
        this.interactionService.deleteSelectedFeatures();
        break;
      case 'edit':
        this.contextSelection$$.set(selection);
        break;
    }
  }

  handleFeaturesDuplicated(context: ContextSelection) {
    const clones: Collection<Feature> = this.interactionService.duplicateFeatures(context.feature);
    clones.forEach((f) => { f.getGeometry().translate(10, -10) })
    const duplicationSource: VectorSource = context.layer.getSource();
    const change = new MapFeatureChange(
      FeatureChangeType.added,
      clones,
      undefined,
      duplicationSource
    );
    this.featureService.addChangeToStack(change);
    this.interactionService.updateSelection(clones);
    duplicationSource.addFeatures(clones.getArray());
  }

  handleFeaturesEdited(changes: ContextSelection & FeatureEdits) {
    const context = { ...changes };
    this.contextSelection$$.set(null);
    const feature = context.feature.item(0);
    const clone = feature.clone();
    clone.setProperties(context.edits);
    clone.setId(feature.getId());
    this.interactionService.featureUpdated(
      new Collection<Feature>([feature]), // old
      new Collection<Feature>([clone]), // new
      context.layer.getSource()
    );
  }

  handleEditMenuClosed() {
    this.contextSelection$$.set(null);
  }

  private refreshDrawingCache() {
    this.drawingManager.fetchDrawings(this.primaryID);
  }

  protected readonly DrawingTypes = DrawingTypes;
}

export enum FormType {
  Empty = 1,
  LocateAreaOnly,
  ToFromX1,
  ToFromX2,
  LocateApprox,
  PVS,
  GTEL,
}

enum FlyToOptions {
  User,
  Ticket,
}

<div
  class="w-full h-full bg-[#f1f2f4] overflow-hidden"
>
  <div
    fxLayout="row"
    fxLayoutAlign="center start"
    class="overflow-hidden h-full"
  >
    <div class="my-2 pb-4 autolog-container" fxFlex="100">
      <ng-container
        *ngFor="let index of headerDates; let indexOfelement = index"
      >
        <mat-card class="rounded-t-lg w-[calc(100%-16px)] bg-white p-0 mx-2 mb-2 mat-elevation-z4">
          <mat-card-header class="sticky top-[-1px] h-7 z-10 text-primary 
          pt-2 bg-grey m-0 rounded-t-lg">
            <mat-card-title class="rounded-t-lg text-primary text-base  capitalize"
              >{{ formattedDateHeaders[indexOfelement] }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-0">
            <ng-container *ngFor="let log of formattedAutologs[index]">
              <mat-card class="m-2 shadow-none">
                <mat-card-content>
                  <div class="w-full flex">
                    <div class="flex-auto w-64">
                      <h4 class="m-0 font-medium text-[15px]">{{ log.autologDesc }}</h4>
                    </div>
                    <div class="flex-auto w-32">
                      <div class="m-0 font-medium text-md text-end">{{ log.logDate }}</div>
                    </div>
                  </div>
                  <section>
                    <span class="text-sm m-0">{{ log.name }}</span>
                    <div class="px-4 w-[calc(100%-32px)] overflow-x-hidden">
                      <span class="text-sm w-full">{{
                        log.Explaination
                      }}</span>
                    </div>
                  </section>
                </mat-card-content>
              </mat-card>
              <div
              class="w-full h-[1px] border-solid border-x-0 border-t-0"
                style="
                  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                  height: 1px;
                  width: 100%;
                "
              ></div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>

<!-- FAB -->
<floating-action-menu
  *ngIf="TICKET_PROTECTION_MODE == false && props.isU2TicketComplete == false"
  type="fab"
  (fabOnClick)="initAddNewAutolog()"
>
</floating-action-menu>

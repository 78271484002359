<div class="shadow-2xl bg-white rounded-md">
  <app-vertical-collapsible [showArrow]="false" [isOpen]="myService.isOpen$ | async" (isOpenChange)="myService.isOpen$ = $event">
    <div title class="w-full flex flex-row justify-between items-center py-1.5 pl-3 pr-1.5">
      <div class="flex flex-row gap-4 justify-start items-center">
        <mat-icon svgIcon="routing" />
        <span class=" font-semibold text-lg capitalize">Routing Search</span>
      </div>
      <span class="size-6 appearance-none bg-transparent cursor-pointer border-none p-0">
        <mat-icon svgIcon="arrow_down" class="{{ (myService.isOpen$ | async) ? '' : 'rotate-90' }}"></mat-icon>
      </span>
    </div>

    <div content>
      <div class="flex flex-col gap-4 p-3 pt-0">
        <div class="w-64 h-[30px]">
          <app-searchable-dropdown
            placeholder="Select User"
            [disabled]="false"
            [multiple]="false"
            [inlined]="false"
            [options]="userOptions"
            [(ngModel)]="selectedUser"
            (ngModelChange)="onUserChange($event)"
            [ngModelOptions]="{ standalone: true }" />
        </div>

        <app-slide-toggle
          text="Show Pin Numbers"
          textPosition="left"
          value="{{ true }}"
          (valueChange)="setPinMarkerVisibility($event)" />

        <div class="flex flex-col gap-3">
          <button
            (click)="refreshTickets()"
            [disabled]="isRefreshing"
            class="p-0 flex flex-row justify-between items-center bg-transparent border-none"
            [ngClass]="{ 'hover:cursor-pointer': !isRefreshing }">
            <span class="text-left  font-semibold capitalize text-md">refresh tickets</span>
            <span class="text-right">
              <mat-icon [ngClass]="{ 'rotate-icon': isRefreshing }" svgIcon="sync"></mat-icon>
            </span>
          </button>

          <button
            (click)="updateRoutes()"
            [disabled]="isUpdating"
            class="p-0 flex flex-row justify-between items-center bg-transparent border-none hover:cursor-pointer"
            [ngClass]="{ 'hover:cursor-pointer': !isUpdating }">
            <span class="text-left  font-semibold capitalize text-md">update route</span>
            <span class="text-right">
              <mat-icon [ngClass]="{ 'rotate-icon': isUpdating }" svgIcon="sync"></mat-icon>
            </span>
          </button>
        </div>
      </div>
    </div>
  </app-vertical-collapsible>
</div>

import { Output, SimpleChanges, EventEmitter } from "@angular/core";
import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AdminLookupService } from "src/app/modules/core/admin/admin-lookup.service";
import { ADMIN_TABLE_NAMES } from "src/app/modules/core/admin/tables";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { NotificationService } from "src/app/modules/core/services/logger/notification.service";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";

@Component({
  selector: "app-ticket-autolog-details",
  templateUrl: "./ticket-autolog-details.component.html",
  styleUrls: ["./ticket-autolog-details.component.css"],
})
export class TicketAutologDetailsComponent
  extends BasePageComponent
  implements OnChanges
{
  className = "TicketAutologDetailsComponent";

  @Input() props;
  @Output() newAutologEvent = new EventEmitter<any>();

  autologs: object[];
  AssignmentID: any;
  U2UserID: any;
  U3UserID: any;

  headerDates: any;
  formattedDateHeaders: any;
  formattedAutologs: any;

  isTicketExaminedFilterOn: boolean = false;
  isTicketProtectionFilterOn: boolean = false;
  isUpDownloadFilterOn: boolean = false;

  constructor(
    loggerService: LoggerService,
    alertService: NotificationService,
    public dialog: MatDialog,
    private adminService: AdminLookupService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private dateTimeService: DatetimeService
  ) {
    super(loggerService);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange && changes.props.currentValue) {
      if (changes.props.currentValue.TICKET_PROTECTION_MODE != null) {
        this.TICKET_PROTECTION_MODE =
          changes.props.currentValue.TICKET_PROTECTION_MODE;
      }

      if (changes.props.currentValue.U3UserID != null) {
        this.U3UserID = changes.props.currentValue.U3UserID;
      }

      if (
        changes.props.currentValue.tbCompletions_Autolog &&
        (changes.props.currentValue.AssignmentID ||
          changes.props.currentValue.RequestNumber) &&
        changes.props.currentValue.U2UserID &&
        changes.props.currentValue.isUtilityUser != null
      ) {
        this.AssignmentID = changes.props.currentValue.AssignmentID;
        this.U2UserID = changes.props.currentValue.U2UserID;
        this.autologs = changes.props.currentValue.tbCompletions_Autolog;

        if (changes.props.currentValue.isUtilityUser) {
          let isSettingActive = this.userService.isSettingActive(
            SettingID.VIEW_AUTOLOGS_UTILITY_USER
          );
          if (isSettingActive) {
            let visibleAutologDescIDs: any = this.userService.getSettingValue(
              SettingID.VIEW_AUTOLOGS_UTILITY_USER
            );
            if (!(visibleAutologDescIDs instanceof Error)) {
              let tmpArr = [];
              for (let i = 0; i < this.autologs.length; i++) {
                if (
                  visibleAutologDescIDs["Value"] == "-1" ||
                  visibleAutologDescIDs["Value"].indexOf(
                    this.autologs[i]["DescID"]
                  ) > -1
                ) {
                  tmpArr.push(this.autologs[i]);
                }
              }
              this.autologs = tmpArr;
            }
          }
        }

        this.createFormattedAutologs(this.autologs);
      }
    }
  }

  async createFormattedAutologs(autologs) {
    try {
      autologs = autologs.slice().reverse();

      let headerDates = [];
      let formattedAutologs = {};
      for (let i = 0; i < autologs.length; i++) {
        let autolog = autologs[i];

        let logDate = autolog["logDate"];
        let date = logDate.split("T")[0];

        // create key for date to sort autologs into
        if (!formattedAutologs[date]) {
          formattedAutologs[date] = [];
          headerDates.push(date);
        }

        let newLogDate = this.dateTimeService.dbDateToFormattedLocalDate(
          logDate,
          "MM/dd/yyyy HH:mm"
        );

        // get human readable name from userID
        let userIDResult = await this.adminService.getAdminTableDescFromID(
          ADMIN_TABLE_NAMES.tbLogin_Users,
          { UserID: autolog.UserID }
        );
        let name =
          userIDResult[0]["FirstName"] + " " + userIDResult[0]["LastName"];

        // get human readable autolog desc from admin table
        let autologDescResult = await this.adminService.getAdminTableDescFromID(
          ADMIN_TABLE_NAMES.tbAdmin_AutologDesc,
          { AutologDescID: autolog.DescID }
        );
        let autologDesc = autologDescResult[0]["AutologDesc"];

        // append
        formattedAutologs[date].push({
          ...autolog,
          logDate: newLogDate,
          autologDesc,
          name,
        });
      }

      this.headerDates = headerDates;
      this.formatDateHeaders(headerDates);
      this.formattedAutologs = formattedAutologs;
    } catch (error) {
      console.error(error);
    }
  }

  private formatDateHeaders(headers) {
    try {
      let newHeaders = headers.sort().reverse();
      this.headerDates = newHeaders;

      // format dates to be human readable
      let formattedDateHeaders = newHeaders.reduce((total, current) => {
        let date = new Date();
        let dateParts = current.split("-");
        date.setFullYear(dateParts[0]);
        date.setMonth(dateParts[1] - 1);
        date.setDate(dateParts[2]);

        if (!isNaN(date.getTime())) {
          total.push(
            this.dateTimeService.localDateToFormattedString(
              date,
              "cccc MMM d, yyyy"
            )
          );
        } else {
          total.push("invalid DateTime");
        }

        return total;
      }, []);
      this.formattedDateHeaders = formattedDateHeaders;
    } catch (error) {
      console.error(error);
    }
  }

  initAddNewAutolog() {
    const self = this;

    try {
      const dialogRef = this.dialog.open(AddNewAutologModalComponent, {
        width: "560px",
        height: "300px",
      });

      // subscribe to modal value
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // add new autolog to list then format
          let newAutolog = {
            AssignmentID: self.AssignmentID,
            DescID: 48, // custom autolog desc id
            UserID: self.U2UserID,
            Explaination: result,
            logDate: this.dateTimeService.localDateToDBDateStr(new Date()),
            bFieldAdded: 1,
          };

          this.autologs.push(newAutolog);
          this.createFormattedAutologs(this.autologs);

          // emit new table data
          this.newAutologEvent.emit({ data: newAutolog });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  initFilterAutologs(filterBy: number[], filterName) {
    try {
      this[filterName] = !this[filterName];
      if (this[filterName]) {
        let filteredAutologs: any = {};

        let curAutologs = this.formattedAutologs;
        let autologKeys = Object.keys(curAutologs);
        for (let i = 0; i < autologKeys.length; i++) {
          let curSet: object[] = curAutologs[autologKeys[i]];

          for (let j = 0; j < curSet.length; j++) {
            let curAutolog = curSet[j];
            if (
              curAutolog &&
              curAutolog["DescID"] != null &&
              filterBy.indexOf(curAutolog["DescID"]) == -1
            ) {
              if (!filteredAutologs[autologKeys[i]]) {
                filteredAutologs[autologKeys[i]] = [];
              }
              filteredAutologs[autologKeys[i]].push(curAutolog);
            }
          }
        }

        this.formatDateHeaders([...Object.keys(filteredAutologs)]);
        this.formattedAutologs = filteredAutologs;
      } else {
        this.createFormattedAutologs(this.autologs);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

@Component({
  selector: "app-autolog-new-dialog",
  template: `
    <div class="p-2 flex flex-col gap-4 h-full">
      <span class="flex-none text-headline-6 font-semibold  text-black capitalize">Create New Autolog</span>
      
      <textarea
        style="resize: none;"
        class="px-2 pt-1 h-3/5 flex-none border rounded bg-grey-background"
        [(ngModel)]="autolog"
      ></textarea>
      
      <div class="self-end">
        <div class="flex flex-row items-start gap-4">
          <button class="btn-cancel" (click)="onNoClick()">Cancel</button>
          <button class="btn-save" (click)="onYesClick()" cdkFocusInitial>
            Save
          </button>
        </div>
      </div>
    </div>

  `,
  styleUrls: ["./ticket-autolog-details.component.css"],
})
export class AddNewAutologModalComponent {
  autolog: string;
  constructor(
    public dialogRef: MatDialogRef<AddNewAutologModalComponent>,
    private alertService: NotificationService,
    private snackbarService: SnackbarService
  ) {
    this.autolog = "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick() {
    if (this.autolog.length <= 0) {
      this.snackbarService.openSnackbar(
        "Autolog cannot be empty",
        SnackbarType.error,
        "Warning: "
      );
    } else {
      this.dialogRef.close(this.autolog);
    }
  }
}

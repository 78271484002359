<div class="w-full h-full bg-[#f1f2f4]" fxLayout="column">
  <div class="m-2 rounded-md mat-elevation-z4 bg-gray-200">
    <div class="rounded-md bg-white">
      <mat-list fxFlex="100%" class="p-0">
        <mat-list-item *ngFor="let file of formattedDocuments" 
        class="h-24 border-solid border-b border-t-0 border-x-0 border-accent-lighter">
          <div
            style="width: 100%"
            fxLayout="row"d
            fxLayoutAlign="space-between center"
            class="h-20"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="downloadClickedFile(file)"
              style="cursor: pointer"
              fxFlex
            >
              <div
                class="h-14 w-14 bg-white mr-2"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <mat-icon
                  class="h-full w-full text-[54px]"
                  aria-hidden="false"
                  aria-label="image icon"
                  >image
                </mat-icon>
              </div>
              <div>
                <div class="text-base font-medium  capitalize">
                  {{ file.FileName }}
                </div>
                <div class="text-overline ">
                  {{ file.DocumentTypeDesc }}
                </div>
                <div class="text-overline ">
                  {{ file.Description }}
                </div>

                <div class="text-overline ">
                  {{ file.CreationDate }}
                </div>
                <div class="text-overline ">
                  {{ file.isSendable ? "Sendable: Yes" : "Sendable: No" }}
                </div>
              </div>
            </div>

            <button
              *ngIf="
                TICKET_PROTECTION_MODE == false &&
                props.isU2TicketComplete == false && 
                !file.isS3Document
              "
              mat-icon-button
              (click)="handleFileDelete(file)"
            >
              <mat-icon color="warn">close</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <div class="h-16 min-h-16 w-full"></div>
</div>

<!-- FAB -->
<floating-action-menu
  *ngIf="TICKET_PROTECTION_MODE == false"
  [menuItems]="fabMenu"
  type="fab"
>
</floating-action-menu>
<input
  #fileInputInternal
  style="display: none"
  type="file"
  (click)="fileInputInternal.value = null"
  value=""
  (change)="handleFileInput($event.target.files, 4)"
/>
<input
  #fileInputOffice
  style="display: none"
  type="file"
  (click)="fileInputOffice.value = null"
  value=""
  (change)="handleFileInput($event.target.files, 10)"
/>

<app-base-modal #deleteDocModal [id]="DELETE_DOC_MODAL_ID" maxwidth="300px">
  <ng-container class="competers-modal-content">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <span class="text-lg  text-semibold capitalize">Delete Document</span>
      <span
        >Are you sure you want to delete document? This action will upload
        changes.</span
      >
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button class="text-primary hover:text-white hover:bg-primary" mat-flat-button (click)="deleteDocModal.close()">
          Cancel
        </button>
        <button mat-flat-button class="text-white bg-warn hover:bg-warn-darker" (click)="handleConfirmDelete()">
          Delete
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<div class="flex flex-row h-full w-full" *ngIf="errorMessage === null; else error">
  <app-page-navigation [routes]="routes" [header]="header" class="flex max-w-1/5"></app-page-navigation>
  <div class="w-full overflow-auto h-full" style="max-width: calc(100% - 240px)">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #error>
  <div class="p-4">
    <div class=" text-headline-4 capitalize font-semibold">
      Unable to load user categories. Please call support (1-877-395-1122 x 2).
    </div>
    <div>
      {{errorMessage}}
    </div>
  </div>
</ng-template>
<div class="h-[96%] w-[96%] p-2">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    style="height: calc(100% - 40px)"
  >
    <mat-list>
      <div class="pl-2  capitalize font-semibold text-headline-6">Upload Progress</div>
      <mat-list-item *ngFor="let upload of uploadStatuses | keyvalue">
        <div class="flex flex-row">
          <div class=" font-medium text-lg">{{ upload.value.textToDisplay }}</div>

          <div class="ml-auto" *ngIf="upload.value.status == 1">
            <mat-icon mat-list-icon color="primary">check</mat-icon>
          </div>
          <div class="ml-auto" *ngIf="upload.value.status == 0">
            <mat-icon mat-list-icon>access_time</mat-icon>
          </div>
          <div class="ml-auto" *ngIf="upload.value.status == 2">
            <mat-icon mat-list-icon color="error-font">close</mat-icon>
          </div>
        </div>
      </mat-list-item>
    </mat-list>

    <mat-list>
      <div class="pl-2  capitalize font-semibold text-headline-6">Download Progress</div>
      <mat-list-item *ngFor="let download of downloadStatuses | keyvalue">
        <div class="flex flex-row">
          <div class=" font-medium text-lg">{{ download.value.textToDisplay }}</div>

          <div class="ml-auto" *ngIf="download.value.status == 1">
            <mat-icon mat-list-icon color="primary">check</mat-icon>
          </div>
          <div class="ml-auto" *ngIf="download.value.status == 0">
            <mat-icon mat-list-icon>access_time</mat-icon>
          </div>
          <div class="ml-auto" *ngIf="download.value.status == 2">
            <mat-icon mat-list-icon color="error-font">close</mat-icon>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
  </div>
</div>

<div style="width: 100%; height: 100%" class="flex bg-white relative">
  <!-- First section ("Generic invoice, run invoice, etc.") -->
  <div class="w-2/6 border-solid border-y-0 border-l-0 border-[1px] border-gray-300 p-6">
    <span class="text-headline-6  font-semibold capitalize">{{ report.Name }}</span>
    <p class="text-sm font-normal">{{ report.Description }}</p>
    <div *ngFor="let parameter of reportParams">
      <div class="dense-4">
        <!-- Date Picker -->
        <mat-form-field
          *ngIf="parameter.ParameterID == 1"
          class=" font-semibold font-sm w-32"
          appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            id="startDatePicker"
            class=" font-sm"
            matInput
            [matDatepicker]="picker"
            (dateChange)="startDateChanged($event)" />
          <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- Date Picker -->
        <mat-form-field
          *ngIf="parameter.ParameterID == 2"
          class=" font-semibold font-sm w-32"
          appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            id="endDatePicker"
            class=" font-sm"
            matInput
            [matDatepicker]="picker"
            (dateChange)="endDateChanged($event)" />
          <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- Number Input -->
        <mat-form-field
          *ngIf="parameter.ParameterID == 6"
          class=" font-semibold font-sm w-[420px]"
          appearance="outline">
          <mat-label>Year</mat-label>
          <input
            id="yearInput"
            class=" font-sm"
            type="number"
            matInput
            value="2024"
            (change)="yearChanged($event)" />
        </mat-form-field>

        <!-- Utilities Dropdown -->
        <mat-form-field
          *ngIf="parameter.ParameterID == 7"
          class=" font-semibold font-sm w-[420px]"
          appearance="outline">
          <mat-label>Utilities</mat-label>
          <mat-select class=" font-semibold font-sm" multiple (selectionChange)="utilitiesChanged($event)">
            <mat-option
              class=" font-semibold font-sm"
              *ngFor="let value of parameter.Values; trackBy: trackByFn"
              [value]="value.UtilityID.toString()">
              {{ value.UtilityName.toUpperCase() }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Month Dropdown -->
        <mat-form-field
          *ngIf="parameter.ParameterID == 11"
          class=" font-semibold font-sm w-[420px]"
          appearance="outline">
          <mat-label>Month</mat-label>
          <mat-select class=" font-semibold font-sm capitalize" (selectionChange)="monthChanged($event)">
            <mat-option
              class=" font-semibold font-sm capitalize"
              *ngFor="let value of months; trackBy: trackByFn"
              [value]="value.Value">
              {{ value.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button
      mat-flat-button
      color="primary"
      class="mr-3 rounded-md w-40 h-9 flex-row-reverse justify-between items-center"
      (click)="runReportClicked()">
      <mat-icon class="mr-0">chevron_right</mat-icon>
      <span class="ml-0">Run Report</span>
    </button>
  </div>

  <!-- Second Section showing the list of invoices  -->
  <div style="height: 90%" class="w-4/6 p-6 min-w-[900px]">
    <div class="flex justify-between items-center pb-2">
      <div class="flex items-center space-x-4">
        <!-- Show/hide archive -->
        <button mat-button (click)="showArchivedClicked()" class="btn-archived">
          <mat-icon svgIcon="visible" />
          {{ showArchived ? 'hide' : 'show' }} archived
        </button>

        <!-- Refresh Button -->
        <!-- <button mat-flat-button
          (click)="updateReport()"
          class="btn-archived"
          >
          <mat-icon svgIcon="sync"/>
          refresh
        </button> -->

        <div>
          <!-- Refresh -->
          <button mat-icon-button class="inline-button mx-2" aria-label="sync" (click)="updateReport()">
            <mat-icon svgIcon="sync"></mat-icon>
          </button>

          <!-- Archive -->
          <button mat-icon-button class="inline-button mx-2" aria-label="archive" [matMenuTriggerFor]="archiveMenu">
            <mat-icon svgIcon="archive"></mat-icon>
          </button>
          <mat-menu #archiveMenu="matMenu">
            <button
              mat-menu-item
              class=" font-semibold font-sm capitalize min-h-0 h-8"
              (click)="archiveSelected()">
              Archive
            </button>
            <button
              mat-menu-item
              class=" font-semibold font-sm capitalize min-h-0 h-8"
              (click)="unarchiveSelected()">
              Unarchive
            </button>
          </mat-menu>

          <!-- Download Buttons -->
          <button mat-icon-button class="inline-button mx-2" aria-label="download" [matMenuTriggerFor]="downloadMenu">
            <mat-icon svgIcon="download"></mat-icon>
          </button>
          <mat-menu #downloadMenu="matMenu">
            <button
              mat-menu-item
              class=" font-semibold font-sm capitalize min-h-0 h-8"
              (click)="downloadSelectedCSV()">
              Download CSV
            </button>
            <button
              mat-menu-item
              class=" font-semibold font-sm capitalize min-h-0 h-8"
              (click)="downloadSelectedPDF()">
              Download PDF
            </button>
          </mat-menu>
        </div>
      </div>

      <!-- Invoice Buttons -->
      <div class="flex items-center space-x-4">
        <button class="btn-cancel" (click)="uninvoiceSelected()">undo invoice</button>
        <button mat-button class="btn-green" (click)="invoiceSelected()">
          <mat-icon svgIcon="invoice" />
          invoice report
        </button>
      </div>
    </div>
    <app-tabulator-table *ngIf="displayedPastReports.length > 0"
      class="h-full w-full"
      #tabulatorTable
      [tableData]="showArchived ? displayedPastReports : activePastReports"
      [columns]="displayedColumns"
      [rowFormatter]="getRowClass"
      [showSelect]="true"
      (rowDoubleClick)="onRowClicked($event)"
      (selectedRowsChanged)="rowSelectionChanged($event)" />
  </div>
</div>

import { Component, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { isJsonFormControl, isJsonFormGroup, JsonControlLike, JsonFormGroup } from '~lib/types/jsonFormRecursive';
import { JsonFormControl } from '~lib/types/jsonForm';
import { CompetersCheckboxComponent } from '../../../../shared/components/inputs/competers-checkbox/competers-checkbox.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-json-form-group',
  standalone: true,
  imports: [CompetersCheckboxComponent, ReactiveFormsModule],
  template: `
    @if (isSubGroup) {
      <div class="w-fit flex justify-center items-center bg-black rounded-md py-[2px] px-3 mb-[26px]">
        <span class="text-left m-0 p-0 text-white  text-lg">{{ title }}</span>
      </div>
    }
    <div class="size-full flex flex-col flex-wrap gap-[36.58px] divide-x-0 divide-solid divide-y-1 divide-accent">
      @if (controls$$().length > 0) {
        <div class="flex flex-col md:grid md:grid-cols-[327px,_327px] gap-4">
          @for (control of controls$$(); track $index) {
            @if (['text', 'number', 'password', 'email', 'search', 'tel', 'url'].includes(control.type)) {
              <input
                placeholder="{{ control.placeholder }}"
                id="{{ control.name }}"
                name="{{ control.name }}"
                class="{{ classConfig[control.type] }}"
                [formControl]="getControl(control.name)" />
            } @else if (control.type === 'checkbox') {
              <div
                class="flex flex-row items-center justify-evenly gap-3 pl-[15px]
            col-span-1 box-border size-full border-solid border-1 border-accent rounded-md bg-white w-full h-[47.21px] px-3 py-2">
                <label class="w-2/3 text-lg text-accent " for="{{ control.name }}">
                  {{ control.label }}
                </label>
                <div class="flex justify-center items-center w-1/3 h-full">
                  <app-competers-checkbox [checkStyle]="'checkmark'" [formControl]="getControl(control.name)" />
                </div>
              </div>
            }
          }
        </div>
      }
      @for (group of groups$$(); track $index) {
        <app-json-form-group
          class="pt-[13px]"
          isSubGroup="true"
          [group]="group.controls"
          [controlGroup]="getControlGroup(group.name)"
          [title]="group.name"></app-json-form-group>
      }
    </div>
  `,
})
export class JsonFormGroupComponent implements OnChanges {
  @Input() title: string;
  @Input() group: JsonControlLike[];
  @Input() controlGroup: FormGroup;
  @Input() isSubGroup: boolean = false;

  groups$$ = signal<JsonFormGroup[]>([]);
  controls$$ = signal<JsonFormControl[]>([]);

  protected classConfig: Record<string, string> = {
    form: 'relative size-fit p-5',
    headline: '',
    container: 'relative flex flex-col gap-6 justify-center items-center',
    text: 'col-span-1 block box-border size-full placeholder:text-accent text-accent placeholder:font-semibold font-medium placeholder:  placeholder:capitalize placeholder:text-md border-solid border-1 border-accent rounded-md h-[47.21px] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent',
    number:
      'col-span-1 block box-border size-full placeholder:text-accent text-accent placeholder:font-semibold font-medium placeholder:  placeholder:capitalize placeholder:text-md border-solid border-1 border-accent rounded-md h-[47.21px] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent',
    textarea: 'w-full h-8',
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.group) {
      this.groups$$.set(this.group.filter(isJsonFormGroup));
      this.controls$$.set(this.group.filter(isJsonFormControl));
    }
  }

  getControl(name: string): FormControl {
    return this.controlGroup['controls'][name] as FormControl;
  }

  getControlGroup(name: string): FormGroup {
    return this.controlGroup['controls'][name] as FormGroup;
  }
}

<mat-toolbar class="app-top-navigation-bar bg-white">
  <mat-toolbar-row>
    <div class="flex flex-row justify-between items-center w-full">
      <div class="w-fit basis-0 flex flex-row justify-start items-center lg:basis-[312px] max-w-[312px]">
        <button class="text-black mx-2 p-[6px]" (click)="menuClicked()" mat-icon-button>
          <img src="assets/icons/branding/Urbint logo@2x.png" style="height: 36px; width: 36px;" alt="Urbint logo" />
        </button>
        @if(showNavigateBack) {
          <button mat-icon-button class="text-black px-1" (click)="navigateBack('/app/settings/list')">
            <mat-icon>chevron_left</mat-icon>
          </button>
        }
        <div class="hidden lg:block">
          @if (pageTitle) {
            <span class=" text-headline-6 font-semibold pl-2">{{ pageTitle }}</span>
          } @else {
            <span class=" text-headline-6 font-semibold pl-2">Utilocate</span>
          }
        </div>
      </div>
      <div class="flex flex-row items-start justify-center flex-grow">
        @if (userService.isSettingActive(SettingID.HOME_WORKSPACE)) {
          <app-quick-ticket-search class="flex-grow"></app-quick-ticket-search>
        }
      </div>

      <div class="flex flex-row items-center justify-end mx-4 px-[2.5px] lg:px-6 lg:mx-0">
        <button class="w-fit lg:w-[186px] user-section flex flex-row justify-start items-center" [matMenuTriggerFor]="userMenu">
          <div class="hidden lg:flex flex-col justify-center items-start basis-[143px]">
            <span class="user-name">{{ name$ | async }}</span>
            <span class="user-category">{{ userCategory$ | async }}</span>
          </div>
          <div id="user-section-icon" class="user-icon basis-[43px]">
            <div class="user-icon-text">{{ initials$ | async }}</div>
          </div>
        </button>

        <mat-menu #userMenu="matMenu" xPosition="before">
          <div class="user-menu flex flex-col justify-start items-center">
            <div class="flex items-center justify-center pt-8 pb-6">
              <div class="bg-[#1C7D72] rounded-full w-20 h-20 flex items-center justify-center">
                <span
                  class="text-white text-xl capitalize  text-headline-3 font-semibold mt-1 select-none">{{ initials$ | async }}</span>
              </div>
            </div>
            <span class=" capitalize font-semibold text-headline-6 select-none">{{ name$ | async }}</span>
            <span class=" text-sm py-2 select-none">{{ userCategory$ | async }}</span>
            <span class=" text-sm pt-2 pb-4 select-none">Version {{ appVersion }}</span>
            <div class="logout-menu flex flex-row justify-center items-center">
              <button class="btn-delete" (click)="logoutClicked()">Logout</button>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

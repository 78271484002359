import { Injectable } from "@angular/core";
import { Observable, from, Subject } from "rxjs";

import {
  SchedulerScheduleActions,
  GetEventCardsByScheduleIDVal,
  SchedulerGetTicketsVal,
  SchedulerTicketActions,
  GetResourceObjVal,
  SchedulerResourceActions,
  GetAllResourcesVal,
  GetResourcesByFieldVal,
  CreateResourceVal,
  DeleteResourceVal,
  UpdateResourceVal,
  GetEventObjVal,
  SchedulerEventActions,
  CreateEventVal,
  DeleteEventVal,
  UpdateEventVal,
  DBEvent,
  GetEventByField,
  SchedulerGetTicketTagsVal,
  SchedulerGetTicketByAssignmentIDVal,
  SchedulerGetTicketByPrimaryIDVal,
} from "../syncfusion-scheduler/schedulerTypes";

import { formatDate } from "@angular/common";
import { CacheService, StoreType } from "../../core/cache/cache.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { ProgressBarService } from "../../shared/progress-bar/progress-bar.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import {
  ApiService,
  UtilocateApiRequest,
} from "../../core/api/baseapi.service";
import { ActionMessage } from "../../core/component-messaging/action-message";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { SnackbarType } from "../../shared/snackbar/snackbar/snackbar";
import { ComponentMessage } from "../../core/component-messaging/component-message";
import { UploadProgress } from "../../shared/upload-documents/upload-progress";
import { DatetimeService } from "../../core/services/datetime/datetime.service";

export enum SchedulerServiceActions {
  create = "create",
  delete = "delete",
  update = "update",
}

export enum DeleteEventResponse {
  deleteLocal = 1,
  deleteServer = 2,
}

export enum UpdateEventResponse {
  createServer = 1,
  updateServer = 2,
}

@Injectable({
  providedIn: "root",
})
export class SchedulerService {
  constructor(
    private logger$: LoggerService,
    private cache$: CacheService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private schedulerApiService: ApiService,
    private datetimeService: DatetimeService
  ) { }

  //offline use ng-connection-service to get network observable.
  private IDB_ACTIONS_KEY = "actions";

  private onEventDataChange: Subject<ActionMessage>;

  /**
   * action:Actions enum (need to add scheduler actions)
   * Data:any
   */
  // this.onEventDataChange.next(message);
  getOnEventDataChange(): Observable<any> {
    return this.onEventDataChange.asObservable();
  }

  /**
   * Scheduler API Calls
   */
  // apiKeys.u2.schedulerTicket;
  // apiKeys.u2.schedulerSchedule;
  // apiKeys.u2.schedulerResource;
  // apiKeys.u2.schedulerEvent;

  initSchedule(): Observable<any> {
    return new Observable((subscriber) => {
      try {
        //try to sync what is already there.
        //Clear data.
        from(this.cache$.clear(StoreType.SCHEDULER_EVENT)).subscribe((val) => {
          this.logger$.log("Drop event table:" + val);
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: initTable: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  invokeScheduler(apiKey, apiValue): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2[apiKey];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKey,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        from(
          this.schedulerApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((response) => {
          if (response.ok) {
            subscriber.next(response.body);
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
      } catch (error) {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private handleResponse(response, message) {
    try {
      if (response != null && response["message"] != null) {
        if (response["status"] != 1 && response["status"] != "1") {
          this.displayMessage("Failed: " + message, true);
        } else {
          this.displayMessage("Success: " + message, false);
        }
      } else if (response != null) {
        if (response) {
          this.displayMessage("Success: " + message, false);
        } else {
          this.displayMessage("Failed: " + message, true);
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  private displayMessage(message, isError: boolean) {
    try {
      if (message) {
        if (isError) {
          this.snackBarService.openSnackbar(message, SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar(message, SnackbarType.success);
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  /**
   * Resource Functions
   */

  getAllResources(): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        const apiValue: GetAllResourcesVal = {
          action: SchedulerResourceActions.GetAllResources,
        };
        from(
          this.invokeScheduler(apiKeys.u2.schedulerResource, apiValue)
        ).subscribe((response) => {
          if (response) {
            subscriber.next(response);
          } else {
            subscriber.next(false);
          }
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: getAllResources: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  getResourcesByField(apiValue: GetResourcesByFieldVal): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerResource, apiValue)
        ).subscribe((response) => {
          if (response) {
            subscriber.next(response);
          } else {
            subscriber.next(false);
          }
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: getResourcesByField: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  getResourcesObj(apiValue: GetResourceObjVal): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerResource, apiValue)
        ).subscribe((response) => {
          if (response) {
            subscriber.next(response);
          } else {
            subscriber.next(false);
          }
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: getResourcesByField: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  createResource(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        const apiValue: CreateResourceVal = {
          action: SchedulerResourceActions.CreateResource,
          Resource: compMsg.message["Resource"],
        };
        from(
          this.invokeScheduler(apiKeys.u2.schedulerResource, apiValue)
        ).subscribe((val) => {
          this.handleResponse(val, "Create Resource");
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  deleteResource(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        const apiValue: DeleteResourceVal = {
          action: SchedulerResourceActions.DeleteResource,
          ResourceID: compMsg.message["Resource"]["ResourceID"],
        };
        from(
          this.invokeScheduler(apiKeys.u2.schedulerResource, apiValue)
        ).subscribe((val) => {
          this.handleResponse(val, "Delete Resource");
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  updateResource(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        const apiValue: UpdateResourceVal = {
          action: SchedulerResourceActions.UpdateResource,
          Resource: compMsg.message["Resource"],
        };
        from(
          this.invokeScheduler(apiKeys.u2.schedulerResource, apiValue)
        ).subscribe((val) => {
          this.handleResponse(val, "Update Resource");
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  /**
   * Events
   */

  getEventsByField(apiValue: GetEventByField): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
        ).subscribe((response) => {
          if (response) {
            subscriber.next(response);
          } else {
            subscriber.next(false);
          }
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: getEventsByField: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  getEventsObj(apiValue: GetEventObjVal): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
        ).subscribe((response) => {
          if (response) {
            subscriber.next(response);
          } else {
            subscriber.next(false);
          }
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: getEventsObj: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  // {\\\"AssignmentID\\\":21,
  // \\\"EventStartDateTime\\\":\\\"2020-08-19 09:23:00\\\",
  // \\\"EventEndDateTime\\\":\\\"2020-08-19 19:23:00\\\", \
  // \\\"ResourceIDArr\\\":[1],\\\
  // EventID: event.EventID,
  // AssignmentID: assignmentID,
  // EventStartDateTime: formatDate(event.StartTime, 'yyyy-MM-d HH:mm:ss', 'en-US'),
  // EventEndDateTime: formatDate(event.EndTime, 'yyyy-MM-d HH:mm:ss', 'en-US'),
  // EventTentativeEndDate: tentativeEnd,
  // ResourceIDArr: dbResources[],
  // Ticket: dbTicket
  //when create ticket sync - run this to schedule
  createTicketWithEvent(
    createEventUploadObj: UploadProgress
  ): Observable<UploadProgress> {
    return new Observable((subscriber) => {
      let { AssignmentID } = JSON.parse(createEventUploadObj.body);
      let { Schedule } = JSON.parse(createEventUploadObj.body)["row"];
      if (!Schedule) {
        subscriber.next(
          new UploadProgress(
            createEventUploadObj.body,
            true,
            createEventUploadObj.msg +
            "Schedule API Response: Nothing to schedule",
            true
          )
        );
        subscriber.complete();
      }
      if (AssignmentID) {
        if (Schedule) {
          // createEventServer result is sar
          if (
            Schedule["ResourceIDArr"] &&
            Array.isArray(Schedule["ResourceIDArr"])
          ) {
            let promiseArr = [];
            Schedule["ResourceIDArr"].forEach((resourceID) => {
              let event: DBEvent = {
                AssignmentID: AssignmentID,
                EventTypeID: Schedule["EventTypeID"],
                EventStartDateTime: this.datetimeService.localDateToDBDateStr(Schedule["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'), //currently excavaton date
                EventEndDateTime: this.datetimeService.localDateToDBDateStr(Schedule["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'), // and excavation date, but end of day
                ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(Schedule["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                ResourceIDArr: [resourceID], //arr of resouceids currently just rig
                bIncludeWeekends: Schedule["bIncludeWeekends"],
                EventNotes: Schedule["EventNotes"]
                  ? Schedule["EventNotes"]
                  : "",
              };
              promiseArr.push(
                this.createEventWithTicketServer(event).toPromise()
              );
            });
            if (promiseArr.length > 0) {
              Promise.all(promiseArr).then((promiseArrResult) => {
                if (promiseArrResult) {
                  let scheduleAPIResults = promiseArrResult;
                  let progress = {
                    ...JSON.parse(createEventUploadObj.body),
                    ScheduleResults: scheduleAPIResults,
                  };
                  subscriber.next(
                    new UploadProgress(
                      JSON.stringify(progress),
                      true,
                      createEventUploadObj.msg +
                      "Schedule API Resposne: Success"
                    )
                  );
                  subscriber.complete();
                }
              });
            } else {
              subscriber.next(
                new UploadProgress(
                  createEventUploadObj.body,
                  false,
                  createEventUploadObj.msg +
                  "Schedule API Response: No Scheduling1",
                  true
                )
              );
              subscriber.complete();
            }
          } else {
            subscriber.next(
              new UploadProgress(
                createEventUploadObj.body,
                true,
                createEventUploadObj.msg +
                "Schedule API Response: No Resources",
                true
              )
            );
            subscriber.complete();
          }
        } else {
          subscriber.next(
            new UploadProgress(
              createEventUploadObj.body,
              true,
              createEventUploadObj.msg +
              "Schedule API Response: Nothing to Schedule",
              true
            )
          );
          subscriber.complete();
        }
      } else {
        subscriber.next(
          new UploadProgress(
            createEventUploadObj.body,
            false,
            createEventUploadObj.msg +
            "Schedule API Response: No Assignment to Schedule",
            true
          )
        );
        subscriber.complete();
      }
    });
  }

  createEvent(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        // try server
        from(this.createEventServer(compMsg.message["Events"])).subscribe(
          (createEventServerVal) => {
            if (createEventServerVal["value"]) {
              //new event id returned.
              this.handleResponse(createEventServerVal, "Create Event");
              var response = {
                new: createEventServerVal["value"],
              };
              subscriber.next(response);
              this.progressBarService.stop();
              subscriber.complete();
            } else {
              this.logger$.warn("Scheduler$: createEvent: failed");
              subscriber.next(false);
              this.progressBarService.stop();
              subscriber.complete();
            }
          }
        );
      } catch (error) {
        this.logger$.warn("Scheduler$: createEvent: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  //
  private createEventServer(events): Observable<any> {
    return new Observable((subscriber) => {
      try {
        var dbEvents: DBEvent[] = [];

        if (Array.isArray(events)) {
          events.forEach((event) => {
            var dbEvent: DBEvent = {
              AssignmentID: event["AssignmentID"],
              EventTypeID: event["EventTypeID"],
              EventName: event["EventName"],
              EventLocation: event["EventLocation"],
              EventDesc: event["EventDesc"],
              EventNotes: event["EventNotes"],
              EventStartDateTime: this.datetimeService.localDateToDBDateStr(event["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
              EventEndDateTime: this.datetimeService.localDateToDBDateStr(event["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
              EventColour: event["EventColour"] ? event["EventColour"] : "#fff",
              ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(event["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
              UserResponsibleID: event["UserResponsibleID"],
              ParentEventID: event["ParentEventID"],
              Ticket: event["Ticket"],
              ResourceIDArr: event["ResourceIDArr"],
            };
            if (event["ChildrenEvents"] != null) {
              dbEvent["ChildrenEvents"] = [];
              event["ChildrenEvents"].forEach((childEvent) => {
                var dbChildEvent: DBEvent = {
                  AssignmentID: childEvent["AssignmentID"],
                  EventTypeID: childEvent["EventTypeID"],
                  EventName: childEvent["EventName"],
                  EventLocation: childEvent["EventLocation"],
                  EventDesc: childEvent["EventDesc"],
                  EventNotes: childEvent["EventNotes"],
                  EventStartDateTime: this.datetimeService.localDateToDBDateStr(childEvent["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                  EventEndDateTime: this.datetimeService.localDateToDBDateStr(childEvent["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                  EventColour: event["EventColour"]
                    ? event["EventColour"]
                    : "#fff",
                  ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(childEvent["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                  UserResponsibleID: childEvent["UserResponsibleID"],
                  ParentEventID: childEvent["ParentEventID"],
                  Ticket: childEvent["Ticket"],
                  ResourceIDArr: childEvent["ResourceIDArr"],
                };

                dbEvent["ChildrenEvents"].push(dbChildEvent);
              });
            }
            dbEvents.push(dbEvent);
          });
        } else {
          const dbEvent: DBEvent = {
            AssignmentID: events["AssignmentID"],
            EventTypeID: events["EventTypeID"],
            EventName: events["EventName"],
            EventLocation: events["EventLocation"],
            EventDesc: events["EventDesc"],
            EventNotes: events["EventNotes"],
            EventStartDateTime: this.datetimeService.localDateToDBDateStr(events["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
            EventEndDateTime: this.datetimeService.localDateToDBDateStr(events["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
            ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(events["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
            UserResponsibleID: events["UserResponsibleID"],
            ParentEventID: events["ParentEventID"],
            Ticket: events["Ticket"],
            ResourceIDArr: events["ResourceIDArr"],
          };
          dbEvents.push(dbEvent);
        }

        const apiValue: CreateEventVal = {
          action: SchedulerEventActions.CreateEvent,
          Events: dbEvents,
        };
        // subscriber.next(false);
        // subscriber.complete();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
        ).subscribe((val) => {
          subscriber.next(val);
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: createEventServer: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private createEventWithTicketServer(events): Observable<any> {
    return new Observable((subscriber) => {
      try {
        var dbEvents: DBEvent[] = [];

        if (Array.isArray(events)) {
          events.forEach((event) => {
            var dbEvent: DBEvent = {
              AssignmentID: event["AssignmentID"],
              EventTypeID: event["EventTypeID"],
              EventName: event["EventName"],
              EventLocation: event["EventLocation"],
              EventDesc: event["EventDesc"],
              EventNotes: event["EventNotes"],
              EventStartDateTime: this.datetimeService.localDateToDBDateStr(event["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
              EventEndDateTime: this.datetimeService.localDateToDBDateStr(event["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
              ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(event["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
              UserResponsibleID: event["UserResponsibleID"],
              ParentEventID: event["ParentEventID"],
              Ticket: event["Ticket"],
              ResourceIDArr: event["ResourceIDArr"],
            };
            if (event["ChildrenEvents"] != null) {
              dbEvent["ChildrenEvents"] = [];
              event["ChildrenEvents"].forEach((childEvent) => {
                var dbChildEvent: DBEvent = {
                  AssignmentID: childEvent["AssignmentID"],
                  EventTypeID: childEvent["EventTypeID"],
                  EventName: childEvent["EventName"],
                  EventLocation: childEvent["EventLocation"],
                  EventDesc: childEvent["EventDesc"],
                  EventNotes: childEvent["EventNotes"],
                  EventStartDateTime: this.datetimeService.localDateToDBDateStr(childEvent["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                  EventEndDateTime: this.datetimeService.localDateToDBDateStr(childEvent["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                  ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(childEvent["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
                  UserResponsibleID: childEvent["UserResponsibleID"],
                  ParentEventID: childEvent["ParentEventID"],
                  Ticket: childEvent["Ticket"],
                  ResourceIDArr: childEvent["ResourceIDArr"],
                };

                dbEvent["ChildrenEvents"].push(dbChildEvent);
              });
            }
            dbEvents.push(dbEvent);
          });
        } else {
          const dbEvent: DBEvent = {
            AssignmentID: events["AssignmentID"],
            EventTypeID: events["EventTypeID"],
            EventName: events["EventName"],
            EventLocation: events["EventLocation"],
            EventDesc: events["EventDesc"],
            EventNotes: events["EventNotes"],
            EventStartDateTime: this.datetimeService.localDateToDBDateStr(events["EventStartDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
            EventEndDateTime: this.datetimeService.localDateToDBDateStr(events["EventEndDateTime"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
            ProjectEventEndDate: this.datetimeService.localDateToDBDateStr(events["ProjectEventEndDate"], 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'),
            UserResponsibleID: events["UserResponsibleID"],
            ParentEventID: events["ParentEventID"],
            Ticket: events["Ticket"],
            ResourceIDArr: events["ResourceIDArr"],
          };
          if (
            new Date(events["ProjectEventEndDate"]).getTime() >
            new Date(events["EventEndDateTime"]).getTime()
          ) {
            //add children events because the project is longer than the event end date
            dbEvent["ChildrenEvents"] = this.makeChildEventsBetween(
              dbEvent,
              events["bIncludeWeekends"] == 1
            );
          }
          dbEvents.push(dbEvent);
        }

        const apiValue: CreateEventVal = {
          action: SchedulerEventActions.CreateEvent,
          Events: dbEvents,
        };
        // subscriber.next(false);
        // subscriber.complete();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
        ).subscribe((val) => {
          subscriber.next(val);
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: createEventServer: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  makeChildEventsBetween(parentEvent, includeWeekends: boolean): DBEvent[] {
    let events: DBEvent[] = [];
    try {
      let startDate = new Date(this.datetimeService.localDateToDBDateStr(parentEvent.EventStartDateTime, 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'));
      let endDate = new Date(this.datetimeService.localDateToDBDateStr(parentEvent.ProjectEventEndDate, 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'));
      var curDate = new Date(this.datetimeService.localDateToDBDateStr(startDate, 'yyyy-MM-d HH:mm:ss', 'yyyy-MM-d HH:mm:ss'));
      let i = 1;

      curDate.setDate(curDate.getDate() + 1);

      if (curDate.getFullYear() > 2001) {
        //null/default is 1969
        while (curDate <= endDate) {
          if (
            includeWeekends ||
            (!includeWeekends && curDate.getDay() != 0 && curDate.getDay() != 6)
          ) {
            var dbChildEvent: DBEvent = {
              AssignmentID: parentEvent["AssignmentID"],
              EventTypeID: parentEvent["EventTypeID"],
              EventName: parentEvent["EventName"],
              EventLocation: parentEvent["EventLocation"],
              EventDesc: parentEvent["EventDesc"],
              EventNotes: parentEvent["EventNotes"],
              EventStartDateTime: formatDate(
                curDate,
                "yyyy-MM-d 08:00:00",
                "en-US"
              ),
              EventEndDateTime: formatDate(
                curDate,
                "yyyy-MM-d 17:00:00",
                "en-US"
              ),
              ProjectEventEndDate: parentEvent["ProjectEventEndDate"],
              UserResponsibleID: parentEvent["UserResponsibleID"],
              ParentEventID: 1,
              Ticket: parentEvent["Ticket"],
              ResourceIDArr: parentEvent["ResourceIDArr"],
            };
            events.push(dbChildEvent);
          }
          i++;
          curDate.setDate(curDate.getDate() + 1);
        }
      }
    } catch (error) {
      console.error(error.message);
    }

    //max limit creation of 1 year.
    if (events.length > 365) {
      events = [];
    }
    return events;
  }

  //insert it in idb
  private createEventCache(events): Observable<any> {
    return new Observable((subscriber) => {
      try {
        var promiseArr = [];
        for (let i in events) {
          var eventID = events[i]["EventID"];
          var value = {
            event: events[i],
            action: SchedulerServiceActions.create,
          };
          promiseArr.push(
            this.cache$.insert(StoreType.SCHEDULER_EVENT, eventID, value)
          );
        }

        from(Promise.all(promiseArr)).subscribe((cacheInsertVal) => {
          //after insert into idb
          subscriber.next(cacheInsertVal);
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: createEventCache: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  updateEvent(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();

        if (
          compMsg.message["Events"] != null &&
          Array.isArray(compMsg.message["Events"]) &&
          compMsg.message["Events"].length > 0
        ) {
          var events = compMsg.message["Events"];

          let convertedTimezoneEvents = [];
          for (let event of events) {
            let newEvent = event;
            newEvent.EventStartDateTime = this.datetimeService.localDateToDBDateStr(newEvent.EventStartDateTime, "yyyy-MM-d HH:mm:ss")
            newEvent.EventEndDateTime = this.datetimeService.localDateToDBDateStr(newEvent.EventEndDateTime, "yyyy-MM-d HH:mm:ss")
            newEvent.ProjectEventEndDate = this.datetimeService.localDateToDBDateStr(newEvent.ProjectEventEndDate, "yyyy-MM-d HH:mm:ss")
            convertedTimezoneEvents.push(newEvent);
          }

          const apiValue: UpdateEventVal = {
            action: SchedulerEventActions.UpdateEvent,
            Events: convertedTimezoneEvents,
          };
          // this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
          // of(false)
          from(
            this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
          ).subscribe((val) => {
            if (val) {
              //remove update from IDB.
              this.handleResponse(val, "Update Event");
              subscriber.next(val);
              this.progressBarService.stop();
              subscriber.complete();
            } else {
              //failed to update server.
              this.logger$.warn("failed to update server.");
              subscriber.next(false);
              this.progressBarService.stop();
              subscriber.complete();
            }
          });
        } else {
          throw new Error("No Events to update");
        }
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  //update IDB Event
  //return

  private updateEventCache(event): Observable<any> {
    //todo: make work for list
    return new Observable((subscriber) => {
      try {
        //find
        from(
          this.cache$.query(StoreType.SCHEDULER_EVENT, event[0]["EventID"])
        ).subscribe((getCacheVal) => {
          var response;
          if (getCacheVal) {
            //found and remove
            var eventID = event[0]["EventID"];
            from(
              this.cache$.remove(StoreType.SCHEDULER_EVENT, eventID)
            ).subscribe((removeCacheVal) => {
              //return as false since not in idb
              //check action. if create idb then return createEvent
              if (removeCacheVal) {
                if (getCacheVal["action"] == SchedulerServiceActions.create) {
                  subscriber.next(UpdateEventResponse.createServer);
                } else {
                  subscriber.next(UpdateEventResponse.updateServer);
                }
              } else {
                subscriber.next(removeCacheVal);
              }
              subscriber.complete();
            });
          } else {
            //not found
            subscriber.next(UpdateEventResponse.updateServer);
            subscriber.complete();
          }
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: createEventCache: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  deleteEvent(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        const apiValue: DeleteEventVal = {
          action: SchedulerEventActions.DeleteEvent,
          EventIDArr: compMsg.message["EventIDs"],
        };
        from(
          this.invokeScheduler(apiKeys.u2.schedulerEvent, apiValue)
        ).subscribe((val) => {
          if (val) {
            //deleted and return
            this.handleResponse(val, "Delete Event");
            subscriber.next(val);
            this.progressBarService.stop();
            subscriber.complete();
          } else {
            subscriber.next(false);
            this.progressBarService.stop();
            subscriber.complete();
          }
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  private deleteEventCache(event): Observable<any> {
    return new Observable((subscriber) => {
      try {
        //find
        from(
          this.cache$.query(StoreType.SCHEDULER_EVENT, event["EventID"])
        ).subscribe((getCacheVal) => {
          if (getCacheVal) {
            //found
            if (getCacheVal["action"] == SchedulerServiceActions.create) {
              //means it is only local and just delete it from idb
              from(
                this.cache$.remove(StoreType.SCHEDULER_EVENT, event["EventID"])
              ).subscribe((removeCacheVal) => {
                subscriber.next(DeleteEventResponse.deleteLocal);
                subscriber.complete();
              });
            } else {
              //this means found as !create so can remove it from idb.
              //remove because changes no longer matter as it will try to remove from server.
              var eventID = event["EventID"];
              from(
                this.cache$.remove(StoreType.SCHEDULER_EVENT, eventID)
              ).subscribe((cacheInsertVal) => {
                subscriber.next(DeleteEventResponse.deleteServer);
                subscriber.complete();
              });
            }
          } else {
            //not found
            subscriber.next(DeleteEventResponse.deleteServer);
            subscriber.complete();
          }
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: createEventCache: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  /**
   * Ticket calls
   */

  getTickets(apiValue: SchedulerGetTicketsVal): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerTicket, apiValue)
        ).subscribe((val) => {
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  getTicketTags() {
    //check cache.
    //if cache get,
    //else get from server.
    const tableName = "tbAdmin_TicketTags";
    const apiValue: SchedulerGetTicketTagsVal = {
      action: SchedulerTicketActions.GetTicketTags,
    };
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.cache$.query(StoreType.SCHEDULER_TICKET, tableName)
        ).subscribe((queryVal) => {
          if (queryVal) {
            //return
            subscriber.next(queryVal);
            this.progressBarService.stop();
            subscriber.complete();
          } else {
            //get server
            from(
              this.invokeScheduler(apiKeys.u2.schedulerTicket, apiValue)
            ).subscribe((apiResult) => {
              subscriber.next(apiResult);
              this.progressBarService.stop();
              subscriber.complete();
            });
          }
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  getTicketByAssignmentID(apiValue: SchedulerGetTicketByAssignmentIDVal) {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerTicket, apiValue)
        ).subscribe((val) => {
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  getTicketByPrimaryID(apiValue: SchedulerGetTicketByPrimaryIDVal) {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        from(
          this.invokeScheduler(apiKeys.u2.schedulerTicket, apiValue)
        ).subscribe((val) => {
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  /**
   * schedule actions
   */

  getEventCardsByScheduleID(compMsg: ComponentMessage): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.progressBarService.start();
        const apiValue: GetEventCardsByScheduleIDVal = {
          action: SchedulerScheduleActions.GetEventCardsByScheduleID,
          ScheduleID: compMsg.message["ScheduleID"],
        };
        from(
          this.invokeScheduler(apiKeys.u2.schedulerSchedule, apiValue)
        ).subscribe((val) => {
          subscriber.next(val);
          this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: Action: failed: ", error);
        subscriber.next(false);
        this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }

  cancelTicket(apiValue): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2[apiKeys.u2.cancelTicketAction];
        const type = api[url].type;

        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.cancelTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(
          this.schedulerApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((response) => {
          if (response.ok) {
            subscriber.next(response.body);
          } else {
            subscriber.next(false);
          }
          // this.progressBarService.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn("Scheduler$: invokeScheduler: failed: ", error);
        subscriber.next(false);
        // this.progressBarService.stop();
        subscriber.complete();
      }
    });
  }
}

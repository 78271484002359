<div class="rule-modal">
  <!-- Name Section -->
  <div class="name-section">
    <div class="name-area">
      <div class="aligned-h3-container">
        <h3 class="font-sm capitalize">name:</h3>
        <mat-form-field class="input-text font-sm-less-weight">
          <input
            class="font-sm-less-weight"
            matInput
            autocomplete="off"
            [(ngModel)]="Rule.RuleName"
            [disabled]="viewOnly"
            placeholder="Type Name"
            required />
        </mat-form-field>
      </div>

      <div class="aligned-h3-container">
        <h3 class="font-sm capitalize">rule type:</h3>
        <mat-form-field class="dropdown" appearance="outline">
          <mat-select class="font-sm-less-weight" [(ngModel)]="Rule.RuleTypeID" [disabled]="viewOnly" required>
            <mat-option
              class="font-sm-less-weight capitalize"
              *ngFor="let ruleType of AdminRuleTypes"
              [value]="ruleType.AdminRuleTypeID">
              {{ ruleType.RuleTypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="active-toggle">
        <label class="inline-flex items-center cursor-pointer">
          <span class="font-sm pr-2 capitalize">active</span>
          <input type="checkbox" [(ngModel)]="Rule.isActive" class="sr-only peer" />
          <div
            class="relative w-11 h-6 bg-gray-200 outline-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
        </label>
      </div>
    </div>

    <mat-icon
      class="w-8 h-8 flex items-center justify-center cursor-pointer absolute right-8 top-8 rounded-full hover:fill-warn hover:bg-accent-lighter"
      (click)="onCloseClick()">
      close
    </mat-icon>
  </div>

  <!-- Description Section -->
  <div class="aligned-h3-container">
    <h3 class="font-sm capitalize">description:</h3>
    <mat-form-field class="input-text font-sm-less-weight">
      <input
        class="font-sm input-text font-normal"
        matInput
        autocomplete="off"
        [(ngModel)]="Rule.Description"
        [disabled]="viewOnly"
        placeholder="Type Description" />
    </mat-form-field>
  </div>

  <!-- Divider -->
  <hr class="long-divider" />

  <!-- Trigger Section -->
  <div>
    <h3 class="font-sm capitalize">trigger</h3>
    <mat-form-field class="trigger font-sm-less-weight" appearance="outline">
      <mat-select class="font-sm-less-weight" [(ngModel)]="Rule.TriggerID" [disabled]="viewOnly" required>
        <div *ngFor="let trigger of AdminTriggers">
          <mat-option
            class="font-sm-less-weight capitalize"
            *ngIf="ENABLED_TRIGGER_IDs.includes(trigger.TriggerID); else disabledTrigger"
            [value]="trigger.TriggerID">
            {{ trigger.TriggerName }}
          </mat-option>

          <ng-template #disabledTrigger>
            <mat-option class="font-normal font-sm-less-weight capitalize" [value]="trigger.TriggerID" disabled>
              {{ trigger.TriggerName }}
            </mat-option>
          </ng-template>
        </div>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Divider -->
  <hr class="long-divider" />

  <!-- Criterias Section -->
  <div class="criteria-section">
    <div class="header">
      <h3 class="font-sm capitalize">criterias</h3>
      <p class="p-tag">All must be true</p>
    </div>
    <br />
    <div *ngFor="let criteria of Rule.Criteria; let i = index">
      <div layout="row" layout-align="center center" class="criteria-row">
        <ng-container>
          <!-- Criteria  -->
          <label class="criteria-if font-sm capitalize">if</label>
          <mat-form-field class="criteria-input font-sm-less-weight" appearance="outline">
            <mat-select
              class="font-sm-less-weight"
              id="IF{{ i }}"
              [(ngModel)]="criteria.AdminCriteriaID"
              [disabled]="viewOnly"
              (ngModelChange)="onCriteriaChange(criteria)">
              <mat-option
                class="font-sm-less-weight capitalize"
                *ngFor="let adminCriteria of Criteria"
                [value]="adminCriteria.CriteriaID">
                {{ adminCriteria.CriteriaName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Operator -->
          <mat-form-field class="criteria-calc-input font-sm" appearance="outline">
            <mat-select
              id="equals{{ i }}"
              class="custom-mat-select font-sm"
              [(ngModel)]="criteria.OperatorID"
              [disabled]="viewOnly">
              <mat-option
                class="calc-option font-sm capitalize"
                *ngFor="let operators of getOperatorFromCriteriaID(criteria.AdminCriteriaID)"
                [value]="operators.OperatorID">
                {{ operators.OperatorName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Value -->
          <ng-container>
            <mat-form-field
              *ngIf="showCriteriaDropdownByCriteriaID(criteria.AdminCriteriaID); else datePicker"
              class="criteria-input font-sm-less-weight"
              appearance="outline">
              <mat-select
                class="font-sm-less-weight"
                id="VALUE{{ i }}"
                [(ngModel)]="criteria.Values"
                [disabled]="viewOnly"
                multiple>
                <mat-option
                  class="font-sm-less-weight capitalize"
                  *ngFor="let value of getValuesFromQuery(criteria.AdminCriteriaID); trackBy: trackByFn"
                  [value]="value.value.toString()">
                  {{ value.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template #datePicker>
              <mat-form-field
                *ngIf="showDatePickerByCriteriaID(criteria.AdminCriteriaID); else rangeTextBoxes"
                class="criteria-input font-sm-less-weight"
                appearance="outline">
                <input
                  class="font-sm-less-weight"
                  matInput
                  [disabled]="viewOnly"
                  type="date"
                  id="VALUE{{ i }}"
                  [(ngModel)]="criteria.Values" />
              </mat-form-field>
            </ng-template>

            <!-- Template is generally used for the BETWEEN or RANGE operator -->
            <ng-template #rangeTextBoxes>
              <div
                *ngIf="showBetweenTextFieldByCriteriaID(criteria.OperatorID); else textbox"
                class="flex flex-row space-between w-[312px]">
                <mat-form-field class="criteria-input font-sm-less-weight" appearance="outline">
                  <input
                    class="aligned-textbox font-sm-less-weight"
                    matInput
                    [disabled]="viewOnly"
                    type="text"
                    id="VALUE{{ i }}b"
                    [(ngModel)]="criteria.Values[0]" />
                </mat-form-field>
                <div class="mt-2 px-2 capitalize">and</div>
                <mat-form-field class="criteria-input font-sm-less-weight ml-0" appearance="outline">
                  <input
                    class="aligned-textbox font-sm-less-weight"
                    matInput
                    [disabled]="viewOnly"
                    type="text"
                    id="VALUE{{ i }}a"
                    [(ngModel)]="criteria.Values[1]" />
                </mat-form-field>
              </div>
            </ng-template>
            <!-- <input [disabled]="viewOnly" *ngIf="showCheckboxByCriteriaID(criteria.AdminCriteriaID)" type="checkbox" id="VALUE{{i}}" [(ngModel)]="criteria.Values" /> -->
          </ng-container>
          <ng-template #textbox>
            <mat-form-field
              class="criteria-input font-sm-less-weight"
              *ngIf="showTextFieldByCriteriaID(criteria.AdminCriteriaID)"
              appearance="outline">
              <input
                class="aligned-textbox font-sm-less-weight"
                matInput
                [disabled]="viewOnly"
                type="text"
                id="VALUE{{ i }}"
                [(ngModel)]="criteria.Values" />
            </mat-form-field>
          </ng-template>

          <button
            mat-icon-button
            class="trash-button"
            [class.disable-hover]="viewOnly"
            [disabled]="viewOnly"
            (click)="removeCriteria(i)"
            aria-label="delete">
            <mat-icon class="trash-button-icon" svgIcon="trash"></mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="plus-button-criteria">
      <button
        mat-icon-button
        class="add-button-hover"
        [class.disable-hover]="viewOnly"
        [disabled]="viewOnly"
        (click)="addCriteria()"
        aria-label="plus">
        <mat-icon class="plus-button" svgIcon="add"></mat-icon>
      </button>
    </div>
  </div>

  <!-- Divider -->
  <hr class="long-divider" />

  <!-- Actions Section -->
  <div class="actions-section">
    <div class="header">
      <h3 class="font-sm capitalize">actions</h3>
      <p class="p-tag">In this order</p>
    </div>
    <br />

    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let action of Rule.Actions; let i = index" cdkDrag>
        <div layout="row" layout-align="center center" class="criteria-row">
          <ng-container>
            <span class="font-sm-less-weight order-number">=&nbsp;{{ action.OrderNumber }}.</span>
            <mat-form-field class="criteria-input font-sm-less-weight" appearance="outline">
              <mat-select
                class="font-sm-less-weight"
                [(ngModel)]="action.ActionID"
                [disabled]="viewOnly"
                (ngModelChange)="onActionChange(action)">
                <mat-option
                  class="font-sm-less-weight capitalize"
                  *ngFor="let adminAction of Actions"
                  [value]="adminAction.ActionID">
                  {{ adminAction.ActionName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- IF the action can have a TO -->
            <span class="to-actions font-sm" *ngIf="actionHasQuery(action.ActionID)">TO</span>
            <div layout="column" layout-align="center center">
              <div *ngFor="let actionValue of action.ActionValues">
                <mat-form-field
                  class="criteria-input font-sm-less-weight"
                  appearance="outline"
                  *ngIf="getDataTypeFromActionValueID(actionValue.ActionValueID)">
                  <!-- select -->
                  <mat-select
                    *ngIf="getDataTypeFromActionValueID(actionValue.ActionValueID) == 3"
                    class="font-sm-less-weight"
                    [(ngModel)]="actionValue.Value"
                    [disabled]="viewOnly"
                    [placeholder]="getPlaceholderFromActionValueID(actionValue.ActionValueID)">
                    <mat-option
                      class="font-sm-less-weight capitalize"
                      *ngFor="
                        let possibleActionValues of getAdminValuesFromActionValueID(actionValue.ActionValueID);
                        trackBy: trackByFn
                      "
                      [value]="possibleActionValues.value.toString()">
                      {{ possibleActionValues.name.toString() }}
                    </mat-option>
                  </mat-select>
                  <!-- multi select -->
                  <mat-select
                    *ngIf="getDataTypeFromActionValueID(actionValue.ActionValueID) == 4"
                    class="font-sm-less-weight"
                    [(ngModel)]="actionValue.Value"
                    [disabled]="viewOnly"
                    [placeholder]="getPlaceholderFromActionValueID(actionValue.ActionValueID)"
                    multiple>
                    <mat-option
                      class="font-sm-less-weight capitalize"
                      *ngFor="
                        let possibleActionValues of getAdminValuesFromActionValueID(actionValue.ActionValueID);
                        trackBy: trackByFn
                      "
                      [value]="possibleActionValues.value.toString()">
                      {{ possibleActionValues.name.toString() }}
                    </mat-option>
                  </mat-select>
                  <!-- textbox -->
                  <input
                    *ngIf="getDataTypeFromActionValueID(actionValue.ActionValueID) == 2"
                    class="aligned-textbox font-sm-less-weight"
                    matInput
                    [disabled]="viewOnly"
                    type="text"
                    id="VALUE{{ i }}"
                    [(ngModel)]="actionValue.Value" />
                </mat-form-field>
              </div>
            </div>

            <button
              mat-icon-button
              class="trash-button"
              [class.disable-hover]="viewOnly"
              [disabled]="viewOnly"
              (click)="removeAction(i)"
              aria-label="delete">
              <mat-icon class="trash-button-icon" svgIcon="trash"></mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <br />
    <div class="plus-button-actions">
      <button
        mat-icon-button
        class="add-button-hover"
        [class.disable-hover]="viewOnly"
        [disabled]="viewOnly"
        (click)="addAction()"
        aria-label="plus">
        <mat-icon class="plus-button" svgIcon="add"></mat-icon>
      </button>
    </div>
  </div>
  <div class="buttons sticky bottom-2 right-2 flex flex-row items-end justify-end">
    <button *ngIf="!disableDelete" class="btn-delete mr-2 capitalize" (click)="onDeleteClick()">delete</button>
    <button class="btn-save capitalize" color="primary" (click)="onSaveClick()">save</button>
  </div>
</div>

<div class="punch-clock">
  <div class="clock-drop w-80">
    <span class="text-headline-5 py-4 capitalize  font-semibold" *ngIf="!showDrop">'{{ selectedOption }}' Started At</span>

    <!-- the time  -->
    <div class="text-headline-3">
      <div class="time font-mono">{{ timer }}</div>
    </div>

    <!-- the "select task" -->
    <div *ngIf="showDrop">
      <mat-form-field appearance="outline" class="dense-6 w-60 rounded-md">
        <mat-label class=" capitalize">Select task</mat-label>
        <mat-select class="capitalize  font-semibold" [(value)]="selectedOption">
          <mat-option
            class="capitalize  font-semibold"
            (click)="taskCompleted()"
            *ngFor="let task of tasks"
            [value]="task">
            {{ task }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- solid divider line -->
    <hr class="w-60 m-5 opacity-20" />

    <!-- start and switch task buttons -->
    <div class="flex flex-row justify-between items-start pt-2 w-60">
      <div class="flex flex-col items-start justify-start">
        <button
          mat-flat-button
          [disabled]="!selectedOption"
          [color]="buttonColor"
          class="w-28 h-9 text-md rounded-md"
          (click)="toggleTime()">
          {{ buttonText }}
          <mat-icon iconPositionEnd svgIcon="stopwatch" />
        </button>
        <mat-progress-bar
          *ngIf="isTimeRunning"
          class="rounded-md w-28 scale-y-50"
          [color]="buttonColor"
          mode="indeterminate" />
      </div>

      <button
        mat-stroked-button
        [disabled]="!selectedOption"
        color="primary"
        class="w-28 h-9 text-sm rounded-md"
        (click)="showDrop = !showDrop">
        SWITCH TASK
      </button>
    </div>
  </div>
</div>

import { Component, effect, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
  selector: 'app-competers-date-picker',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatIconModule, MatDatepickerModule, MatInputModule, NgxMaterialTimepickerModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersDatePickerComponent,
    },
  ],
  templateUrl: './competers-date-picker.component.html'
})


export class CompetersDatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() disabled = false;
  @Input() showTime = false;
  @Input() dateCeiling: Date;
  @Input() dateFloor: Date;

  // Observables and signals
  private destroy$ = new Subject<void>();
  protected date$$ = signal<Date | null>(null); // Signal to store the combined date and time
  protected timeValue = '';
  protected val: Date = new Date();
  protected touched = false;
  protected isSelected: boolean = false;
  protected defaultDate: Date = new Date();

  timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#00A0CC',
    },
    dial: {
      dialBackgroundColor: '#00A0CC',
    },
    clockFace: {
      clockFaceBackgroundColor: '#eee',
      clockHandColor: '#00A0CC',
      clockFaceTimeInactiveColor: '#222',
    },
  };

  ngOnInit(): void {
    const today = new Date();
    this.date$$.set(this.dateFloor ?? today); //set it to either the date floor, or today
    this.val = this.dateFloor ?? today;
    this.timeValue = this.formatTime(this.val);
  }

  constructor() {

    effect(() => {
      if (this.onChange !== undefined && this.date$$()) {
        this.onChange(this.date$$());
        this.val = this.date$$();
        this.isSelected = this.val !== null;
      }
    });
  }

  private formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  onChange: (data: Date | null) => void = () => { };

  onTouched: () => void = () => { };

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(data: Date | null) {
    if (!isEqual(this.val, data)) {
      this.date$$.set(data ?? null);
    }
    if (data === null) {
      this.isSelected = false;
    }
  }

  registerOnChange(onChange: (data: Date | null) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
    if (!this.isSelected) {
      this.date$$.set(null);
    }
  }

  setDate(event: Date) {
    const current = this.date$$() ?? new Date(); // If date is not set, default to today
    current.setFullYear(event.getFullYear(), event.getMonth(), event.getDate());
    this.date$$.set(current); // Update with the new date
  }

  setTime(event: string) {
    const current = this.date$$() ?? new Date(); // If date is not set, default to today

    // Extract hours and minutes from the time string
    const [hours, minutes] = event.split(':').map(Number);

    // Set the hours and minutes
    current.setHours(hours, minutes);
    this.date$$.set(current); // Update with the new time
    this.timeValue = event;
  }
}


export type DateRange = { upper: Date; lower: Date };

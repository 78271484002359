import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-risk-score-cell',
  template: `
    <div class="flex flex-row items-center  text-white rounded-full {{colourClass}} uppercase h-5 {{fontSize}} w-full"
    [ngClass]="{'justify-start pr-1': internalScore != null, 'justify-center px-1': internalScore == null}"
    >
      @if(internalScore === 'very high') {
        <mat-icon class='text-white scale-75' svgIcon="chevron_duo_up"></mat-icon>
      } @else if(internalScore === 'high') {
        <mat-icon class='text-white scale-75' svgIcon="chevron_up"></mat-icon>
      } @else if(internalScore === 'medium'){
        <mat-icon class='text-white scale-75' style="color:white" svgIcon="tilde"></mat-icon>
      } @else if(internalScore === 'low') {
        <mat-icon class='text-white scale-75' style="color:white" svgIcon="chevron_down"></mat-icon>
      }
      {{internalScore ?? 'Missing Data'}}
    </div>
  `,
  standalone: true,
  imports: [MatIconModule, CommonModule],
})
export class RiskScoreCellComponent implements OnInit {
  colourClass: string;
  @Input() score: string;
  @Input() increaseSize: string;
  internalScore: string;
  fontSize: string;
  fontStyle: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['score']) {
      this.internalScore = changes['score'].currentValue ? changes['score'].currentValue?.toLowerCase() : null
     this.ngOnInit()
    }
  }
  
  ngOnInit(): void {
    this.colourClass = "tabulator-table-damage-risk-absent";
    this.fontSize = this.increaseSize ? 'text-[14px]' : 'text-[9px]';
    this.internalScore = this.score ? this.score?.toLowerCase() : null;
    if (this.internalScore !== 'very high' && this.internalScore !== 'high' && this.internalScore !== 'medium' && this.internalScore !== 'low') {
      this.internalScore = null;
    } else {
      switch (this.internalScore?.toLowerCase()) {
        case "very high":
          this.colourClass = 'tabulator-table-damage-risk-very-high';
          break;
        case "high":
          this.colourClass = 'tabulator-table-damage-risk-high';
          break;
        case "medium":
          this.colourClass = 'tabulator-table-damage-risk-medium';
          break;
        case "low":
          this.colourClass = 'tabulator-table-damage-risk-low';
          break;
        default:
          this.colourClass = "tabulator-table-damage-risk-absent";
      }
    }

  }
}
<div #container class="w-full h-full shadow-none overflow-hidden flex flex-col" [ngClass]="{ 'full-screen': isFullScreen }">
  <!-- Toolbar -->
  <div class="flex-none">
    <mat-toolbar class="bg-white px-1 h-[50px] flex justify-between border-b border border-gray-300">
      <div class="flex align-baseline">
        <mat-icon class="text-black" svgIcon="ticket"></mat-icon>
        <span class=" capitalize font-semibold text-[20px] tracking-[0.0075em] text-black pl-3">
          {{ appToolbarTitle }}
        </span>
      </div>
      <div>
        <button mat-icon-button (click)="toggleFullScreen()" aria-label="Fullscreen">
          @if (isFullScreen) {
            <mat-icon class="text-black" svgIcon="minimize" aria-label="minimize"></mat-icon>
          } @else {
            <mat-icon class="text-black">fullscreen</mat-icon>
          }
        </button>
          <button *ngIf="temporaryTicket" mat-icon-button (click)="onSync()" aria-label="Close">
            <mat-icon class="text-black" svgIcon="sync"></mat-icon>
            <span *ngIf="ticketHasChanged" class="badge text-warn">!</span>
          </button>

          <button mat-icon-button (click)="onClose()" aria-label="Close">
            <mat-icon class="text-black">close</mat-icon>
          </button>
      </div>
    </mat-toolbar>
  </div>

  <!-- Ticket -->
  <div class="flex-grow h-5/6">
    <app-ticket
      *ngIf="ticketDataDownloaded"
      [AssignmentIDClicked]="data.AssignmentID"
      (ticketChangedEvent)="markTicketChanged()"
      (ticketCloseEvent)="onClose()"></app-ticket>

    <!-- Show error ticket -->
    <div *ngIf="errorTicket">
      <div class="flex items-center justify-center h-screen">
        <div class="bg-orange p-6 rounded-md text-white w-96 h-72 flex flex-col justify-center items-center">
          <div class="mb-4 text-center">
            <div class="flex justify-center mb-2">
              <mat-icon svgIcon="alerts" class="scale-150" />
            </div>
            <p class="text-center text-headline-5 capitalize  font-semibold">UNABLE TO OPEN TICKET</p>
            <p class="text-center text-headline-6 mt-2 capitalize  font-semibold">
              TICKET IS NOT DOWNLOADED
            </p>
          </div>
          <div class="text-center capitalize  font-semibold text-headline-6">TRY SYNCING</div>
        </div>
      </div>
    </div>
  </div>
</div>

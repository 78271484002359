<div class="flex flex-col items-center">
  <round-progress
    [current]="score"
    [max]="100"
    [radius]="60"
    [stroke]="8"
    [color]='color'
    [background]="'#ddd'"
    [semicircle]="true"
    [rounded]="true"
    [clockwise]="true"
    class="w-[270px] h-[145px]"
  ></round-progress>  
  <div class="flex justify-between w-full pl-2 font-semibold">
    <span>0</span>
    <span>100</span>
  </div>
  <div class="text-center mt-2 absolute top-32">
    <p class="text-gray-700 font-semibold text-md m-0 pb-[6px]">Urbint Score</p>
    <p class="text-black font-bold text-[54px] m-0">{{ score }}%</p>
  </div>

</div>
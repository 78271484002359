<div #container class="relative w-28">
  <button
    (click)="toggleDropdown()"
    [ngClass]="classOverrides"
    class="box-border z-20 relative appearance-none border-[1px] border-solid border-primary cursor-pointer flex flex-row justify-between items-center w-full h-[38px] rounded-md bg-white text-left  font-semibold text-white pr-0.5 px-4"
    [class.shadow-[0_10px_8px_rgba(0,0,0,0.1)]]="isActive">
    <span class="capitalize  font-semibold text-lg text-primary truncate">{{ selection.name }}</span>
    <mat-icon class="text-primary" style="width: 24px">arrow_drop_down</mat-icon>
  </button>
  <div
    class="absolute box-border -translate-y-2 z-10 w-full bg-white rounded-md shadow-md transition-all ease-in-out"
    [class.pt-5]="isActive"
    [class.overflow-hidden]="!isActive"
    [class.h-0]="!isActive"
    [class.w-0]="!isActive">
    <div *ngIf="isActive" class="max-h-[75vh] min-h-40 overflow-y-auto">
      <button
        *ngFor="let item of items; trackBy: trackByFn"
        (click)="changeSelection(item)"
        [ngClass]="{
          'bg-primary text-white': item.name === selection.name,
          'hover:bg-primary hover:text-white bg-white text-primary': item.name !== selection.name
        }"
        class="cursor-pointer appearance-none border-none w-full flex justify-start items-center text-left text-md font-semibold  capitalize p-[6px] transition-all h-[30px] truncate">
        {{ item.name }}
      </button>
    </div>
  </div>
</div>

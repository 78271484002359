{
  "name": "u4ia-project",
  "version": "4.2.9",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "serve:hmr": "ng serve --hmr",
    "build": "ng build && npx workbox-cli injectManifest workbox-config.js",
    "nolimitbuild": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng build --output-hashing=all && npx workbox-cli injectManifest workbox-config.js",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "devbuild": "ng build --output-hashing=all && npx workbox-cli injectManifest workbox-config.dev.js",
    "devbuild:node": "node dev_build.js dist-dev/u4ia-project src/sw.js",
    "generate-icons": "svg-to-ts",
    "clean-install": "rm -rf node_modules package-lock.json && npm install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.4",
    "@angular/cdk": "^17.0.1",
    "@angular/common": "^17.0.4",
    "@angular/compiler": "^17.0.4",
    "@angular/core": "^17.0.4",
    "@angular/elements": "^17.0.4",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^17.0.4",
    "@angular/google-maps": "^17.0.1",
    "@angular/localize": "^17.0.4",
    "@angular/material": "^17.0.1",
    "@angular/platform-browser": "^17.0.4",
    "@angular/platform-browser-dynamic": "^17.0.4",
    "@angular/pwa": "^17.0.3",
    "@angular/router": "^17.0.4",
    "@angular/service-worker": "^17.0.4",
    "@arcgis/webpack-plugin": "^4.22.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@aws-sdk/client-s3": "^3.490.0",
    "@ngneat/svg-icon": "^7.1.0",
    "@swimlane/ngx-datatable": "^20.1.0",
    "@syncfusion/ej2-angular-buttons": "23.2.4",
    "@syncfusion/ej2-angular-calendars": "23.2.4",
    "@syncfusion/ej2-angular-dropdowns": "23.2.5",
    "@syncfusion/ej2-angular-grids": "23.2.4",
    "@syncfusion/ej2-angular-inputs": "23.2.4",
    "@syncfusion/ej2-angular-navigations": "23.2.5",
    "@syncfusion/ej2-angular-popups": "23.2.4",
    "@syncfusion/ej2-angular-schedule": "23.2.4",
    "@syncfusion/ej2-base": "23.2.4",
    "@syncfusion/ej2-data": "23.2.4",
    "@syncfusion/ej2-material-theme": "23.2.5",
    "@types/googlemaps": "^3.40.0",
    "@types/lodash-es": "^4.17.12",
    "@types/tabulator-tables": "^6.2.0",
    "angular-svg-round-progressbar": "^14.0.0",
    "axios": "^1.6.2",
    "bootstrap": "^5.3.3",
    "core-js": "^3.33.3",
    "cors": "^2.8.5",
    "crypto-js": "^4.2.0",
    "document-register-element": "^1.14.6",
    "esri-loader": "^3.7.0",
    "express": "^4.18.2",
    "express-fileupload": "^1.4.3",
    "file-saver": "^2.0.5",
    "http": "0.0.1-security",
    "http-server": "^14.1.1",
    "https": "^1.0.0",
    "install": "^0.13.0",
    "jimp": "^0.22.10",
    "jquery": "^3.7.1",
    "jszip": "^3.10.1",
    "localforage": "^1.10.0",
    "lodash-es": "^4.17.21",
    "luxon": "^3.4.4",
    "moment": "^2.30.1",
    "mysql": "^2.18.1",
    "ng-gallery": "^11.0.0",
    "ngrok": "^4.3.3",
    "ngx-color-picker": "^15.0.0",
    "ngx-colors": "^3.5.3",
    "ngx-device-detector": "^7.0.0",
    "ngx-extended-pdf-viewer": "^18.1.9",
    "ngx-mat-select-search": "^7.0.5",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-webcam": "^0.4.1",
    "npm": "^10.2.4",
    "ol": "^8.2.0",
    "ol-esri-style": "git+https://git-codecommit.ca-central-1.amazonaws.com/v1/repos/ol-esri-styles",
    "ol-popup": "^5.1.0",
    "popper": "^1.0.1",
    "rxjs": "~7.8.1",
    "sharp": "^0.32.6",
    "signature_pad": "^4.1.7",
    "stream": "0.0.2",
    "tabulator-tables": "^6.2.0",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "workbox-core": "^7.1.0",
    "workbox-expiration": "^7.1.0",
    "workbox-precaching": "^7.1.0",
    "workbox-routing": "^7.1.0",
    "workbox-strategies": "^7.1.0",
    "workbox-window": "^7.1.0",
    "zone.js": "0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.3",
    "@angular-eslint/builder": "17.1.0",
    "@angular-eslint/eslint-plugin": "17.1.0",
    "@angular-eslint/eslint-plugin-template": "17.1.0",
    "@angular-eslint/schematics": "17.1.0",
    "@angular-eslint/template-parser": "17.1.0",
    "@angular/cli": "^17.0.3",
    "@angular/compiler-cli": "^17.0.4",
    "@angular/language-service": "^17.0.4",
    "@playwright/test": "^1.40.0",
    "@schematics/angular": "^17.0.3",
    "@testing-library/angular": "^14.5.0",
    "@testing-library/jest-dom": "^6.1.5",
    "@types/arcgis-js-api": "^4.27.2",
    "@types/jest": "^29.5.12",
    "@types/luxon": "^3.3.5",
    "@types/node": "^20.10.0",
    "@types/uuid": "^9.0.7",
    "@typescript-eslint/eslint-plugin": "6.12.0",
    "@typescript-eslint/parser": "6.12.0",
    "autoprefixer": "^10.4.16",
    "aws-sdk": "^2.1580.0",
    "babel-eslint": "^10.1.0",
    "codelyzer": "^0.0.28",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.0.0",
    "express-http-proxy": "^2.0.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "postcss": "^8.4.31",
    "prettier": "3.1.0",
    "prettier-eslint": "^16.1.2",
    "prettier-plugin-tailwindcss": "^0.5.7",
    "svg-to-ts": "^11.0.1",
    "tailwindcss": "^3.3.5",
    "ts-node": "~10.9.1",
    "typescript": "5.2.2",
    "workbox-webpack-plugin": "^7.1.0"
  },
  "svg-to-ts": {
    "conversionType": "object",
    "srcFiles": [
      "./src/assets/svg/*.svg"
    ],
    "outputDirectory": "./src/assets/svg",
    "fileName": "svg-icons",
    "svgoConfig": {
      "plugins": [
        {
          "removeDimensions": true,
          "cleanupAttrs": true
        }
      ]
    }
  },
  "browser": {
    "fs": false,
    "path": false,
    "os": false
  }
}

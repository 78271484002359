import { Component } from '@angular/core';
import { sections, TicketPinLegendPin, TicketPinLegendSection } from './content';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { AdminLookupService } from 'src/app/modules/core/admin/admin-lookup.service';
import { ADMIN_TABLE_NAMES } from 'src/app/modules/core/admin/tables';
import { TicketMapService } from '../ticket-map.service';

@Component({
  selector: 'app-ticket-pin-legend',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatDialogClose],
  templateUrl: './ticket-pin-legend.component.html',
  styleUrl: './ticket-pin-legend.component.scss',
})
export class TicketPinLegendComponent {
  protected ticketLegend: Array<TicketPinLegendSection> = sections;

  constructor(private adminLookupService: AdminLookupService, public ticketMapService: TicketMapService) {
    this.adminLookupService
      .getAdminTables([ADMIN_TABLE_NAMES.tbLogin_Users, ADMIN_TABLE_NAMES.tbLogin_UserCategories])
      .then(([{ Data: userData }]) => {
        const locatorSection = sections.find((element) => element.title === 'locators');
        locatorSection.pins = this.getUserPins(userData);
      })
      .catch(() => console.error('Failed to load users'));

  }

  /**
   * Returns a list of TicketPinLegendComponent that are the user pins
   *
   * @param {any[]} userData
   * @return {TicketPinLegendComponent}  {TicketPinLegendPin[]}
   * @memberof TicketPinLegendComponent
   */
  getUserPins(userData): TicketPinLegendPin[] {
    const pins: TicketPinLegendPin[] = [];
    for (const user of userData) {
      pins.push({
        label: `${user.FirstName} ${user.LastName}`,
        color: user.HexColour,
      });
    }
    return pins;
  }
}

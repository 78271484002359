import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-map-feature-inspector',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule],
  styleUrls: ['./map-feature-inspector.component.scss'],
  template: `
    <div class="box-border flex flex-col p-3 pt-4">
      @if (selfClosing) {
        <div class="flex flex-row justify-end items-center absolute right-1 top-3">
          <button
            class="flex justify-center items-center appearance-none border-none pt-[3px] pointer-events-auto hover:cursor-pointer bg-transparent"
            (click)="handleClose()">
            <mat-icon style="font-size: 16px; height: 16px; width: 16px;">close</mat-icon>
          </button>
        </div>
      }
      <div class="box-border flex flex-col justify-center items-center gap-1 h-full">
        @if (loading) {
          <mat-spinner></mat-spinner>
        } @else {
          <ng-content></ng-content>
        }
      </div>
    </div>
  `,
})
export class MapFeatureInspectorComponent {
  @Input() loading: boolean = false;
  @Input() selfClosing: boolean = false;
  @Output()
  closed = new EventEmitter<void>();

  constructor() {}

  handleClose() {
    this.closed.emit();
  }

}

<div class="w-[700px] bg-white rounded-lg inline-flex flex-col">
  <div class="px-6 pt-6 pb-2 flex items-center gap-6">
    <div class="flex-1">
      <div class="text-[18px] font-semibold text-slate-900 pb-[4px]">Damage Threat - Risk Insights</div>
      <div class="text-[13px] font-light text-slate-900">{{address}}</div>
    </div>
    <div>
      <button mat-icon-button (click)="onConfirm()">
        <mat-icon class="text-[26px]">close</mat-icon>
      </button>    </div>
  </div>
  <div class="flex flex-col items-start h-[246px] px-6 py-4 w-[652px]">
 <div class="h-[186px] flex flex-col gap-2 flex-wrap">
  <div *ngFor="let risk of keys">
  <ng-container *ngIf="riskInsights[risk].score === 'very high'">
    <div class="flex flex-col gap-2 w-80 h-18">
      <div class="flex-1 px-3 py-2 bg-[#FEF6F7] rounded shadow-[0_1px_3px_0_rgba(0,0,0,0.18)] outline outline-1 outline-[#ED506C]">
        <div class="flex items-center">
          <div class="w-16 h-16 rounded flex justify-center items-center pr-6">
            <mat-icon class="w-12 h-12 text-[48px] text-[#E7183E]" svgIcon={{riskInsights[risk].icon}}></mat-icon>
          </div>
          <div class="inline-flex flex-col justify-start items-start gap-[15px]">
            <div class="justify-start text-black text-md font-semibold font-['Inter'] leading-tight">{{riskInsights[risk].title}}</div>
            <div class="flex items-center w-[220px] justify-between">
            <div class="inline-flex flex-row justify-start items-center pr-3">
              <mat-icon class="h-5 pr-[1px]" svgIcon="chevron_duo_big_up"></mat-icon>
              <span class="text-center text-[#D44242] text-md not-italic font-semibold leading-[130%]">VERY HIGH</span>
            </div>
            <button class="rounded border border-solid border-[#BDC1C3] bg-white h-[28px] w-[94px] text-center text-md font-semibold" (click)="learnMoreClicked(riskInsights[risk],'#E7183E')">Learn More</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="riskInsights[risk].score === 'high'">
    <div class="flex flex-col gap-2 w-80 h-18">
      <div class="flex-1 px-3 py-2 bg-[#FFF8E5] rounded shadow-[0_1px_3px_0_rgba(0,0,0,0.18)] outline outline-1 outline-[#E8BA30]">
        <div class="flex items-center">
          <div class="w-16 h-16 rounded flex justify-center items-center pr-6">
            <mat-icon class="w-12 h-12 text-[48px] text-[#D69600]" svgIcon={{riskInsights[risk].icon}}></mat-icon>
          </div>
          <div class="inline-flex flex-col justify-start items-start gap-[15px]">
            <div class="justify-start text-black text-md font-semibold font-['Inter'] leading-tight">{{riskInsights[risk].title}}</div>
            <div class="flex items-center w-[220px] justify-between">
            <div class="inline-flex flex-row justify-start items-center pr-3">
              <mat-icon class="h-5 pr-[1px]" svgIcon="chevron_big_up"></mat-icon>
              <span class="text-center text-[#B75E0B] text-md not-italic font-semibold leading-[130%]">HIGH</span>
            </div>
            <button class="rounded border border-solid border-[#BDC1C3] bg-white h-[28px] w-[94px] text-center text-md font-semibold" (click)="learnMoreClicked(riskInsights[risk],'#D69600')">Learn More</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="riskInsights[risk].score === 'medium'">
    <div class="flex flex-col gap-2 w-80 h-18">
      <div class="flex-1 px-3 py-2 bg-[#F2F7FD] rounded shadow-[0_1px_3px_0_rgba(0,0,0,0.18)] outline outline-1 outline-[#67A2E4]">
        <div class="flex items-center">
          <div class="w-16 h-16 rounded flex justify-center items-center pr-6">
            <mat-icon class="w-12 h-12 text-[48px] text-[#3C87DD]" svgIcon={{riskInsights[risk].icon}}></mat-icon>
          </div>
          <div class="inline-flex flex-col justify-start items-start gap-[15px]">
            <div class="justify-start text-black text-md font-semibold font-['Inter'] leading-tight">{{riskInsights[risk].title}}</div>
            <div class="flex items-center w-[220px] justify-between">
            <div class="inline-flex flex-row justify-start items-center pr-3">
              <mat-icon class="h-5 pr-[1px]" svgIcon="tilde_big"></mat-icon>
              <span class="text-center text-[#3E70D4] text-md not-italic font-semibold leading-[130%]">MEDIUM</span>
            </div>
            <button class="rounded border border-solid border-[#BDC1C3] bg-white h-[28px] w-[94px] text-center text-md font-semibold" (click)="learnMoreClicked(riskInsights[risk], '#3C87DD')">Learn More</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="riskInsights[risk].score === 'low'">
    <div class="flex flex-col gap-2 w-80 h-18">
    <div class="flex-1 px-3 py-2 bg-[#F4FBF4] rounded shadow-[0_1px_3px_0_rgba(0,0,0,0.18)] outline outline-1 outline-[#74CA72]">
        <div class="flex items-center">
          <div class="w-16 h-16 rounded flex justify-center items-center pr-6">
            <mat-icon class="w-12 h-12 text-[48px] text-[#2BA329]" svgIcon={{riskInsights[risk].icon}}></mat-icon>
          </div>
          <div class="inline-flex flex-col justify-start items-start gap-[15px]">
            <div class="justify-start text-black text-md font-semibold font-['Inter'] leading-tight">{{riskInsights[risk].title}}</div>
            <div class="flex items-center w-[220px] justify-between">
            <div class="inline-flex flex-row justify-start items-center pr-3">
              <mat-icon class="h-5 pr-[1px]" svgIcon="chevron_big_down"></mat-icon>
              <span class="text-center text-[#238914] text-md not-italic font-semibold leading-[130%]">LOW</span>
            </div>
            <button class="rounded border border-solid border-[#BDC1C3] bg-white h-[28px] w-[94px] text-center text-md font-semibold" (click)="learnMoreClicked(riskInsights[risk],'#2BA329')">Learn More</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="riskInsights[risk].score !== 'very high' && riskInsights[risk].score !== 'high' && riskInsights[risk].score !== 'medium' && riskInsights[risk].score !== 'low'">
    <div class="flex flex-col gap-2 w-80 h-18">
      <div class="flex-1 px-3 py-2 bg-[white] rounded shadow-[0_1px_3px_0_rgba(0,0,0,0.18)] outline outline-1 outline-[black]">
          <div class="flex items-center">
            <div class="w-16 h-16 rounded flex justify-center items-center pr-6">
              <mat-icon class="w-12 h-12 text-[48px] text-[black]" svgIcon={{riskInsights[risk].icon}}></mat-icon>
            </div>
            <div class="inline-flex flex-col justify-start items-start gap-[15px]">
              <div class="justify-start text-black text-md font-semibold font-['Inter'] leading-tight">{{riskInsights[risk].title}}</div>
              <div class="flex items-center">
              <div class="inline-flex flex-row justify-start items-center pr-3">
                <span class="text-center text-[black] text-md not-italic font-semibold leading-[130%]">MISSING DATA</span>
              </div>
              <button class="rounded border border-solid border-[#BDC1C3] bg-white h-[28px] w-[94px] text-center text-md font-semibold" (click)="learnMoreClicked(riskInsights[risk], 'black')">Learn More</button>
            </div>
            </div>
          </div>
        </div>
      </div>
  </ng-container>
</div>
        
  </div>
  <div>
    <span class="text-[13px] italic font-light">*Our Risk Model considers over 300+ risk factors across these categories and this is a summarization of 
      the drivers of risk.</span>
  </div>
  </div>
</div>
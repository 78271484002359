<div class="quick-search-container flex flex-grow-0 flex-shrink-1 basis-[874px]" id="quick-search-root">
  @if (_initialized$ | async) {
    <button
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      class="quick-search-bar flex flex-row justify-between items-center"
      (click)="openSearchForm()">
      <div class="flex-left" style="color: #9d9d9d">
        <mat-icon style="margin-right: 24px">search</mat-icon>
        <p style="letter-spacing: 0; text-transform: none; font: normal normal normal 20px/24px Montserrat">Search</p>
      </div>
      <mat-icon style="color: #9d9d9d">filter_alt</mat-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPanelClass]="'quick-search-panel'"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayPositions]="positionPairs"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayWidth]="isFullScreen ? '100%' : searchBarElementWidth"
      [cdkConnectedOverlayHeight]="isFullScreen ? '100%' : '90%'"
      (backdropClick)="handleBackdropClick()">
      <form
        class="flex flex-row box-border w-full h-full min-h-full min-w-full overflow-auto"
        [formGroup]="quickSearchForm">
        <div
          class="relative flex flex-col justify-between min-[1400px]:justify-start quick-search-menu flex-grow-1 flex-shrink-1 basis-[100%] overflow-auto">
          @if (!isFullScreen) {
            <div class="quick-search-bar flex flex-row justify-between items-center" style="color: #000000">
              <div class="flex-left">
                <mat-icon style="margin-right: 24px">search</mat-icon>
                <p style="letter-spacing: 0; text-transform: none; font: normal normal normal 20px/24px Montserrat">
                  Search
                </p>
              </div>
              <mat-icon style="margin-left: 24px">filter_alt</mat-icon>
            </div>
          } @else {
            <div class="absolute box-border w-full flex flex-row justify-end items-center">
              <button mat-icon-button (click)="isOpen = !isOpen">
                <mat-icon class="size-6 text-accent-icons text-headline-5">close</mat-icon>
              </button>
            </div>
          }
          <div
            class="search-menu-grids py-3 px-5 xl:py-[30px] xl:px-[25px] flex flex-col gap-4 xl:flex-row xl:gap-10"
            style="overflow: auto; {{ !isFullScreen ? 'max-height: calc(100vh - 150px)' : '' }}">
            <div class="flex flex-col flex-grow-1 flex-shrink-1 basis-[55%]">
              <span class="mat-subtitle-1 text-primary">Quick Search</span>
              <div class="flex flex-col justify-start items-start gap-3 w-full sm:w-[433px] pb-3">
                @for (field of searchFields; track $index) {
                  @if (field.title !== 'outstanding') {
                    <div class="w-full flex flex-col sm:flex-row">
                      <label
                        [for]="field.title"
                        class="w-1/4  font-semibold text-md capitalize overflow-hidden overflow-ellipsis">
                        {{ field.title }}
                      </label>
                      <div class="w-3/4">
                        <!-- Text Input -->
                        @if (field.type === 'text' || field.type === 'number' || field.type === 'numbers') {
                          <app-input
                            class="w-full"
                            [title]="field.title"
                            [type]="field.type"
                            formControlName="{{ field.name }}" />
                        } @else if (field.type === 'select') {
                          <app-searchable-dropdown
                            class="w-full"
                            [title]="field.title"
                            [showLabel]="false"
                            [inlined]="true"
                            [multiple]="true"
                            [formControlName]="field.name"
                            [options]="field.choices"></app-searchable-dropdown>
                        }
                      </div>
                    </div>
                  }
                }
              </div>
            </div>
            <div
              class="h-auto flex flex-col justify-between items-start min-[1400px]:items-center md:flex-grow-1 md:flex-shrink-1 md:gap-[80px] md:basis-[45%] gap-0">
              <div class="flex flex-col flex-grow justify-start items-start">
                <div class="flex flex-row gap-3 mb-4" style="width: 100%">
                  <span class="w-1/2 mat-subtitle-1 text-primary m-0 p-0">outstanding</span>
                  <div class="flex justify-start flex-grow items-center">
                    <button
                      (click)="toggleOutstandingSearch()"
                      class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                      @if (searchOutstanding) {
                        <mat-icon class="text-success" style="width: 13px; height: 13px" svgIcon="yes-filled" />
                      } @else {
                        <mat-icon svgIcon="toggle_off" style="width: 13px; height: 13px" />
                      }
                    </button>
                  </div>
                </div>
                <div class="flex flex-col" style="width: 100%">
                  <span class="mat-subtitle-1 text-primary">Dates</span>
                  @for (field of dateFields; track $index) {
                    <app-competers-date-range-picker [title]="field.title" [formControlName]="field.name" />
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            class="box-border flex flex-row justify-between items-center w-full py-2 min-[1400px]:pt-0 min-[1400px]:pb-[10px] lg:pb-[30px] px-[12px] sm:px-[25px]">
            <div class="flex flex-row justify-center items-center">
              <ng-container *ngTemplateOutlet="advSearch"></ng-container>
            </div>
            <div class="flex flex-row gap-3 sm:gap-6 flex-grow justify-end items-center">
              <button
                (click)="resetForm($event)"
                class="flex justify-center items-center sm:w-[132px] w-[112px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize">
                clear
              </button>
              <button
                (click)="submitSearch($event)"
                class="flex justify-center items-center sm:w-[132px] w-[112px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize">
                search
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  }
</div>

<ng-template #advSearch>
  <button
    (click)="handleAdvancedSearchClick($event)"
    class="flex flex-row gap-[10px] items-center appearance-none box-border border-solid border-1 border-gray-400 rounded h-[35px] {{
      isFullScreen ? 'justify-center w-[42px]' : 'justify-start w-[164px]'
    }}   bg-white cursor-pointer">
    <mat-icon
      style="width: 24px; height: 24px"
      class="{{ isFullScreen ? 'mx-auto' : 'ml-3' }}"
      svgIcon="advanced_search"></mat-icon>
    @if (!isFullScreen) {
      <span class="whitespace-nowrap  font-semibold text-md capitalize text-primary">adv. search</span>
    }
  </button>
</ng-template>

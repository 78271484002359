<div fxLayout="column" fxLayoutAlign="space-between" class="modal user-editor h-full">
  <div class="wrapper px-[16px] py-[24px]">
    <div *ngIf="!userCentralizationEnabled" class="info-section flex flex-col gap-10">
      <!-- Profile -->
      <span class="mat-headline-2 text-primary">Profile</span>
      <form #myForm="ngForm" (ngSubmit)="onSaveClick()">
        <div fxLayout="row wrap" fxLayoutGap="8px">
          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">First Name *</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" [(ngModel)]="updatedUser.FirstName" name="FirstName" required />
              <mat-error *ngIf="!updatedUser.FirstName"> Required </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Employee ID</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" [(ngModel)]="updatedUser.EmployeeID" name="EmployeeID" />
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Last Name *</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" [(ngModel)]="updatedUser.LastName" name="LastName" required />
              <mat-error *ngIf="!updatedUser.LastName"> Required </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start" class="supervisor-select">
            <span class="mat-subheading-s field-header">Supervisor</span>
            <mat-form-field class="field-input">
              <mat-select [(ngModel)]="updatedUser.Supervisor" name="Supervisor">
                <mat-option *ngFor="let supervisor of supervisors" [value]="supervisor.UserID">
                  {{ supervisor.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Email *</span>
            <mat-form-field class="field-input">
              <input type="email" matInput autocomplete="new-email" [(ngModel)]="updatedUser.EmailAddress"
                name="EmailAddress" email required />
              <mat-error *ngIf="updatedUser.EmailAddress !== ''">
                Invalid email address
              </mat-error>
              <mat-error *ngIf="!updatedUser.EmailAddress">
                Required
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Truck #</span>
            <mat-form-field class="field-input font-normal">
              <input class="font-normal" matInput autocomplete="off" [(ngModel)]="updatedUser.TruckNum"
                name="TruckNum" />
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Phone #</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" type="phone" pattern="^[0-9]{10,10}$"
                [(ngModel)]="updatedUser.TwoWayNum" name="TwoWayNum" [required]="updatedUser.UserCategoryID == 1" />

              <mat-error *ngIf="!updatedUser.TwoWayNum">
                Phone # Required for Locator
              </mat-error>
              <mat-error *ngIf="!phoneRegex.test(updatedUser.TwoWayNum)">
                Invalid Phone Format
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">User Type</span>
            <mat-form-field class="field-input">
              <mat-select [(ngModel)]="updatedUser.UserCategoryID" name="UserCategoryID"
                (selectionChange)="userCategoryChanged($event)">
                <mat-option *ngFor="let userCategory of userCategories" [value]="userCategory.UserCategoryID">
                  {{ userCategory.Title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">User Color</span>
            <ngx-colors
              ngx-colors-trigger
              name="hexColour"
              [(ngModel)]="updatedUser.HexColour"
            >
            </ngx-colors>
          </div>
          

          <!-- <div *ngIf="canModifyPasswords || updatedUser.UserID == -1" fxFlex='calc(50% - 8px)' fxLayout='row' fxLayoutAlign="start start" >
            <span class="mat-subheading-s field-header">Password *</span>
            <mat-form-field class="field-input">
              <input
                [type]="hidePassword ? 'password' : 'text'"
                matInput
                autocomplete="new-password"
                [(ngModel)]="updatedUser.Password"
                name="Password"
                required
              />
              <mat-icon
                class="password-btn"
                matSuffix
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
              >
              <mat-error *ngIf="!updatedUser.Password"> Required </mat-error>
            </mat-form-field>
          </div> -->
          <!-- <div *ngIf="canModifyPasswords || updatedUser.UserID == -1" fxFlex='calc(50% - 8px)' style= "font-size: 14px;" fxLayout='row' fxLayoutAlign="start start" >
                                                                                                                                                                                                                                        <mat-checkbox name='FirstLog'
                                                                                                                                                                                                                                            [ngModel]="updatedUser.FirstLog == 1 ? true : updatedUser.FirstLog == 0 ? false : null" 
                                                                                                                                                                                                                                            (ngModelChange)="updatedUser.FirstLog = $event ? 1 : 0">
                                                                                                                                                                                                                                          Force password change on first login
                                                                                                                                                                                                                                        </mat-checkbox>
                                                                                                                                                                          </div> -->
        </div>
      </form>

      <form #myForm="ngForm">
        <div fxLayout="row wrap" fxLayoutGap="8px">
          <div *ngIf="canModifyPasswords || updatedUser.UserID == -1" fxFlex="calc(100% - 8px)" fxLayout="column"
            fxLayoutAlign="start start">
            <button mat-flat-button color="primary" (click)="onResetPasswordClick()">
              Reset Password
            </button>
          </div>
          <div *ngIf="resettingPassword || this.user.UserID == -1" fxFlex="calc(100% - 8px)" fxLayout="column"
            fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">New Password *</span>
            <mat-form-field class="field-input">
              <input [type]="hidePassword ? 'password' : 'text'" matInput autocomplete="new-password"
                [(ngModel)]="tempPassword" name="tempPassword" required />
              <mat-icon class="password-btn" matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ?
                "visibility" : "visibility_off" }}</mat-icon>
            </mat-form-field>
          </div>
          <!-- <div *ngIf="resettingPassword" fxFlex='calc(100% - 8px)' fxLayout='column' fxLayoutAlign="start start">
            <mat-checkbox name='emailPassword' [ngModel]="emailPassword" (ngModelChange)="emailPassword">
              Email password to user
            </mat-checkbox>
          </div> -->
          <div *ngIf="resettingPassword" fxFlex="calc(100% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <!-- <mat-checkbox name='FirstLog'
              [ngModel]="updatedUser.FirstLog == 1 ? true : updatedUser.FirstLog == 0 ? false : null"
              (ngModelChange)="updatedUser.FirstLog = $event ? 1 : 0">
              Force password change on first login
            </mat-checkbox> -->
            <p>Next U2 Login will prompt password change</p>
          </div>
        </div>
      </form>

      <!-- Setting -->
      <app-edit-settings-component [(editState)]="settingsEditState" [settingIDs]="userSettingsToShow" [adminSettings]="globalSettings"
        (settingsChangeEvent)="updateUserSettings($event)" [accessLevel]="1" />
    </div>

    <div *ngIf="userCentralizationEnabled && !needsCloudAssociation" class="info-section flex flex-col">
      <div class=" flex name-section">
        <div class="flex flex-row justify-center items-start gap-8 user-icon bg-primary">
          <div *ngIf="updatedUser.FirstName && updatedUser.LastName" class="user-icon-text">
            {{ updatedUser.FirstName.slice(0, 1)
            }}{{ updatedUser.LastName.slice(0, 1) }}
          </div>
        </div>
        <span class="mat-headline-1 header-main-title">{{ updatedUser.FirstName }} {{ updatedUser.LastName }}</span>
      </div>
      <!-- Profile -->
      <hr class="divider" />
      <form class="form" #myForm="ngForm" (ngSubmit)="onSaveClick()">
        <div>
          <mat-icon class="icon">person</mat-icon>
          <span style="margin-left: 21px" class="mat-headline-1 header-title">Profile Info</span>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="8px" style="padding: 24px 0px 0px 48px">
          <div fxFlex="calc(33% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">First Name *</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" [(ngModel)]="updatedUser.FirstName" name="FirstName" required />
              <mat-error *ngIf="!updatedUser.FirstName"> Required </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="calc(33% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Last Name *</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" [(ngModel)]="updatedUser.LastName" name="LastName" required />
              <mat-error *ngIf="!updatedUser.LastName"> Required </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="calc(33% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Phone #</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" type="phone" pattern="^[0-9]{10,10}$"
                [(ngModel)]="updatedUser.TwoWayNum" name="TwoWayNum" [required]="updatedUser.UserCategoryID == 1" />

              <mat-error *ngIf="!updatedUser.TwoWayNum">
                Phone # Required for Locator
              </mat-error>
              <mat-error *ngIf="!phoneRegex.test(updatedUser.TwoWayNum)">
                Invalid Phone Format
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="32.6% " fxLayout="column" class="employee-header" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Employee ID</span>
            <mat-form-field class="field-input">
              <input matInput autocomplete="off" [(ngModel)]="updatedUser.EmployeeID" name="EmployeeID" />
            </mat-form-field>
          </div>

          <div fxFlex="calc(33% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Email *</span>
            <mat-form-field class="field-input">
              <input type="email" matInput autocomplete="new-email" [(ngModel)]="updatedUser.EmailAddress"
                name="EmailAddress" email required />
              <mat-error *ngIf="!updatedUser.EmailAddress">
                Required
              </mat-error>
              <mat-error *ngIf="updatedUser.EmailAddress !== ''">
                Invalid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <hr class="divider" />

        <div>
          <mat-icon class="icon " svgIcon="cloud"></mat-icon>
          <span class="mat-headline-1  header-title"> Cloud Info</span>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="8px" style="padding: 24px 0px 0px 48px">
          <div fxFlex="calc(50% - 8px)" fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">User Type</span>
            <mat-form-field class="field-input">
              <mat-select [(ngModel)]="updatedUser.UserCategoryID" name="UserCategoryID"
                (selectionChange)="userCategoryChanged($event)">
                <mat-option *ngFor="let userCategory of cloudUserCategories" [value]="userCategory.UserCategoryID">
                  {{ userCategory.Title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div *ngIf="updatedUser.UserID == -1 && !updatedUser.CloudUserID" fxFlex='calc(50% - 8px)' fxLayout='column' fxLayoutAlign="start start">
            <span class="mat-subheading-s field-header">Password *</span>
            <mat-form-field class="field-input">
              <input
                [type]="hidePassword ? 'password' : 'text'"
                matInput
                autocomplete="new-password"
                [(ngModel)]="tempPassword"
                name="tempPassword"
                required
              />
              <mat-icon
                class="password-btn"
                matSuffix
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
              >
            </mat-form-field>
          </div> -->
        </div>
      </form>
      <form *ngIf="updatedUser.UserID != -1" #myForm="ngForm">
        <div fxLayout="row wrap" fxLayoutGap="8px" style="padding: 12px 0px 0px 45px">
          <div *ngIf="canModifyPasswords" fxLayout="column" fxLayoutAlign="start start" style="padding-right: 24px;">
            <button mat-flat-button color="primary" (click)="onResetPasswordClick()">
              Reset Password
            </button>
          </div>
          <div *ngIf="resettingPassword" fxFlex="calc(50% - 8px)" fxLayout="row" class="new-pass">
            <span class="mat-subheading-s field-header">New Password *</span>
            <mat-form-field class="field-input">
              <input [type]="hidePassword ? 'password' : 'text'" matInput autocomplete="new-password"
                [(ngModel)]="tempPassword" name="tempPassword" required />
              <mat-icon class="password-btn" matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ?
                "visibility" : "visibility_off" }}</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </form>

      <hr class="divider" />

      <div>
        <mat-icon *ngIf="updatedUser.ClientUsers && updatedUser.ClientUsers.length > 0" class="icon "
          svgIcon="settings"></mat-icon>
        <span *ngIf="updatedUser.ClientUsers && updatedUser.ClientUsers.length > 0"
          class="mat-headline-1  header-title">
          U2 Info & Settings</span>
      </div>
      <div class="pl-12 w-9/12" fxLayout="row wrap">
        <div *ngFor="let clientUser of updatedUser.ClientUsers" fxFlex="calc(100% - 8px)" class="mt-12">
          <app-client-user-editor [(editState)]="settingsEditState" [user]="clientUser" [supervisors]="supervisors" [userCategories]="userCategories"
            [accessibleSettings]="accessibleSettings" [clientUserCount]="updatedUser.ClientUsers.length" [canModifyPasswords]="canModifyPasswords"
            (updated)="onUpdateClientUser($event)"></app-client-user-editor>
        </div>
      </div>
    </div>
    <div *ngIf="userCentralizationEnabled && needsCloudAssociation" fxFlex fxLayout="column" fxLayoutGap="32px"
      class="info-section">
      <span class="mat-headline-1 header-title">{{ updatedUser.FirstName }} {{ updatedUser.LastName }}
        <mat-icon>error</mat-icon> &nbsp; &nbsp; &nbsp; NO ASSOCIATED CLOUD
        ACCOUNT</span>
      <!-- Profile -->
      <form #myForm="ngForm" (ngSubmit)="onSaveClick()">
        <div fxLayout="row wrap" fxLayoutGap="16px" style="padding: 24px 0px 0px 16px">
          <div fxFlex="calc(100% - 8px)" fxLayoutGap="8px" fxLayout="row" fxLayoutAlign="start baseline">
            <span class="mat-subheading-s field-header">Cloud Account Email Address</span>
            <mat-form-field class="field-input">
              <input type="email" matInput autocomplete="new-email" [(ngModel)]="updatedUser.EmailAddress"
                name="EmailAddress" email required />
              <mat-error *ngIf="!updatedUser.EmailAddress">
                Required
              </mat-error>
              <mat-error *ngIf="updatedUser.EmailAddress !== ''">
                Invalid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="btn-section sticky bottom-2" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button class="btn-cancel" (click)="onCancelClick()">
      Cancel
    </button>
    <button 
    
    [ngClass]="{'btn-save': !settingsEditState, 'w-32 h-9 rounded-md opacity-75 text-white bg-gray-500 border-none  font-semibold capitalize text-md hover:cursor-not-allowed': settingsEditState}"
    (click)="onSaveClick()" [disabled]="settingsEditState" [matTooltip]="settingsEditState ? 'cannot save while editing settings' : null" matTooltipPosition="above">
      <div *ngIf="saving; else save">

        <!-- Loading Spinner -->
        <div role="status">
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-transparent fill-white"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
        </div>

      </div>
    </button>
  </div>

  <ng-template #save>Save</ng-template>

</div>

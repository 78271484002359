<div class="mat-headline-1 flex flex-row items-center h-[72px] pb-3 pl-3">
  <div class="select-none text-black capitalize  font-semibold text-headline-6">
    Select Photos to Remove
  </div>
</div>

<div class="gallery-container">
  <div class="flex flex-row flex-wrap">
    <div *ngFor="let image of imagesTaken; let i = index" class="image-edit-container">
      <img
        [tabindex]="0"
        alt="Photo"
        class="{{ GalleryViewMap[galleryView] }}"
        [src]="image.data.thumb"
        [attr.imageSrc]="image.data.src"
        [attr.thumbSrc]="image.data.thumb"
        id="img-{{ i }}"
        (click)="toggleImageClick($event)" />

      <button class="edit-button" (click)="openCaptionDialog(image)">
        <mat-icon class="text-lg w-4 h-4 flex items-center justify-center">edit</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="bg-primary flex fex-row items-center justify-center fixed bottom-0 w-full h-[72px]">
  <mat-icon class="check-btn w-12 h-12" svgIcon="yes-outlined" (click)="onFinish()" [tabIndex]="1" />
</div>

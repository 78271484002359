<div class="flex sm:flex-row flex-col items-start justify-between sm:space-x-2 sm:space-y-0 space-y-2 select-none">
  @if (damageThreatSettingOn) {
    <button
      mat-button
      (click)="openDamageThreat()"
      class="rounded-lg bg-grey px-3 py-[6px] hover:cursor-pointer min-w-[15.5rem]">
      <div class="flex flex-row items-center justify-start">
        <!-- Damage Threat  -->
        <div class="pr-2 whitespace-nowrap">Damage Threat</div>
        <!-- pill icon -->
        @if (loadedDamage) {
          <div class="flex">
          <app-risk-score-cell [score]="ticketDamageThreatLabel" />
          </div>
        }
        <!-- calculator  -->
        <mat-icon svgIcon="calcaultor" class="pl-[6px]" />
      </div>
    </button>
  }

  <!-- space between  -->
  @if (impactSettingOn) {
    <button mat-button (click)="openImpact()" class="rounded-lg bg-grey px-3 py-[6px] hover:cursor-pointer min-w-[12rem]">
      <div class="flex flex-row items-center justify-start">
        <!-- Impact  -->
        <div class="pr-2 whitespace-nowrap">Impact</div>
        <!-- pill icon -->
        @if (loadedImpact) {
          <div class="flex">
          <app-risk-score-cell [score]="ticketImpactLabel" />
        </div>
        }
        <!-- calculator  -->
        <mat-icon svgIcon="calcaultor" class="pl-[6px]" />
      </div>
    </button>
  }
</div>

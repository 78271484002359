<h1 mat-dialog-title class=" font-semibold text-headline-5">EDIT UTILITIES</h1>


<div class="px-4 flex flex-row items-start justify-center min-h-52">
  <app-searchable-dropdown [options]="options" [inlined]="true" [multiple]="true"
    [(ngModel)]="selectedUtilities">
  </app-searchable-dropdown>
</div>

<div mat-dialog-actions class="flex flex-row items-center justify-end space-x-2">
  <button (click)="onClose()" class="btn-cancel">Cancel</button>
  <button (click)="onConfirm()" class="btn-save">
    Confirm
  </button>
</div>
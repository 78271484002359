<div
  #olay
  appClickStopPropagation
  (click)="handleBackdropClick()"
  class="fixed z-[60] w-screen h-screen top-0 left-0 bg-accent bg-opacity-20"></div>
<div
  class="fixed flex justify-center items-center overflow-hidden top-0 left-0 pointer-events-none
{{ (ticketSearchService.advancedSearchIsOpen$ | async) ? 'h-full w-screen z-[70]' : 'h-0 w-0 hidden' }}
">
  <div
    #container
    class="flex grow justify-center items-center w-screen h-full lg:absolute max-w-[1415px] min-[912px]:justify-start min-[912px]:items-start min-[912px]:size-full md:bottom-0 md:right-0 md:pt-[72px] md:pb-6 md:px-2 lg:px-6">
    <div
      class="size-full justify-start items-start bg-white overflow-hidden rounded-md md:w-fit lg:w-full pointer-events-auto">
      <div class="flex flex-col justify-start items-start max-h-full h-full w-full md:pt-[29px] overflow-hidden">
        <div class="w-full min-[912px]:w-auto flex flex-row justify-between items-center">
          <span class=" text-headline-6 capitalize font-semibold pl-[24px]">
            @if (ticketSearchService.editingSearch$ | async) {
              edit search
              @if (ticketSearchService.prefillSearch$ | async) {
                : "{{ (ticketSearchService.prefillSearch$ | async).SearchName }}"
              }
            } @else {
              advanced search
            }
          </span>
          <div class="min-[912px]:hidden">
            <button mat-icon-button (click)="handleBackdropClick()">
              <mat-icon class="size-6 text-accent-icons text-headline-5">close</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="flex flex-col justify-start items-center h-full max-h-full w-full overflow-y-scroll min-[912px]:overflow-y-auto overflow-x-hidden pt-3 divide-solid divide-gray-300 divide-0 md:pt-6 min-[912px]:divide-y-0 min-[912px]:divide-x-1 min-[912px]:flex-row min-[912px]:items-start">
          <!--            left -->
          <div
            class="h-fit max-h-full w-full flex flex-col gap-6 justify-center items-center md:justify-start md:items-start p-3 min-[912px]:h-full min-[912px]:w-fit min-[912px]:px-[24px] min-[912px]:pb-[83px] min-[912px]:border-r-2 min-[912px]:border-r-gray-300">
            <!--              top-->
            <div class="w-full max-w-[312px]">
              <div class="h-9 w-full min-[912px]:w-[300px]">
                <app-competers-search-bar
                  class="box-border w-full h-full"
                  placeHolderText="Search Filters"
                  (searchValueChange)="onSearchValueChange($event)" />
              </div>
            </div>
            <!--              bottom -->
            <div class="flex flex-col justify-start items-start h-full w-[312px] overflow-y-auto scroll-auto">
              @if ((_searchValue$ | async) === '') {
                @for (category of _filterCategories$ | async; track $index) {
                  <app-vertical-collapsible [showArrow]="false" [isOpen]="$index === 0" class="w-full">
                    <div title class="flex justify-end items-center px-[9.67px] w-full">
                      <span class="text-lg  font-semibold capitalize leading-[30px]">
                        {{ category['filterCategoryName'] ?? 'err' }}
                      </span>
                      <div
                        class="flex justify-end items-center flex-grow appearance-none bg-transparent cursor-pointer border-none p-0">
                        <mat-icon svgIcon="drop_down"></mat-icon>
                      </div>
                    </div>
                    <div content class="flex flex-col justify-start items-start gap-0.5 w-full">
                      @for (filter of category['filters'] ?? []; track filter['filterID']) {
                        <button
                          class="box-border flex flex-row justify-start items-center border-0 border-none rounded-md w-full h-[18px] px-[9.67px] hover:cursor-pointer"
                          [style.background-color]="
                            _selectedFilters.isSelected(filter) ? 'rgba(206, 223, 237, 0.2)' : 'rgba(255, 255, 255, 0)'
                          "
                          (click)="_selectedFilters.toggle(filter)">
                          <span
                            class="text-md  font-semibold text-[rgba(112,112,112,1)] capitalize leading-[24px]">
                            {{ filter['visibleName'] }}
                          </span>
                          <div class="flex justify-end flex-grow items-center">
                            <button
                              class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                              @if (_selectedFilters.isSelected(filter)) {
                                <mat-icon
                                  class="text-success"
                                  style="width: 16.67px; height: 16.67px"
                                  svgIcon="yes-filled" />
                              } @else {
                                <mat-icon svgIcon="toggle_off" style="width: 16.67px; height: 16.67px" />
                              }
                            </button>
                          </div>
                        </button>
                      }
                    </div>
                  </app-vertical-collapsible>
                }
              } @else {
                <div content class="flex flex-col justify-start items-start w-full">
                  @for (filter of (_filteredOptions$ | async) ?? []; track filter) {
                    <button
                      class="flex flex-row justify-start items-start border-none bg-transparent w-full hover:cursor-pointer"
                      (click)="_selectedFilters.toggle(filter)">
                      <span class="text-md  font-semibold text-[#707070] capitalize leading-[24px]">
                        {{ filter['visibleName'] }}
                      </span>
                      <div class="flex justify-end flex-grow items-center">
                        <button
                          class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                          @if (_selectedFilters.isSelected(filter)) {
                            <mat-icon class="text-success" style="width: 20px; height: 20px" svgIcon="yes-filled" />
                          } @else {
                            <mat-icon svgIcon="toggle_off" style="width: 20px; height: 20px" />
                          }
                        </button>
                      </div>
                    </button>
                  }
                </div>
              }
            </div>
          </div>
          <!--            right -->
          <div
            [formGroup]="searchFormGroup"
            class="size-full flex flex-col max-w-[100%] justify-between items-start min-[912px]:border-solid border-0 min-[912px]:border-l-1 min-[912px]:border-l-[rgba(209,211,212,1)]">
            <!--              top -->
            <div
              class="group w-full flex flex-col flex-grow gap-0 justify-start items-start overflow-y-auto scroll-smooth divide-solid divide-x-0 divide-y-1 divide-[rgba(209,211,212,1)] md:w-auto">
              @for (filter of _selectedFilters.selected; track filter['filterID']) {
                @if (getControl(filter, 'value')) {
                  <div
                    class="w-full flex flex-row justify-start items-center border-solid border-0 group-first:border-t-1 border-[rgba(209,211,212,1)] md:w-auto">
                    <div
                      class="w-full flex flex-col justify-between items-center px-1 py-1.5 md:pl-6 md:py-3 md:flex-row">
                      <div class="w-full flex flex-row justify-start items-center md:w-auto">
                        <app-competers-checkbox
                          class="mr-3"
                          [checkStyle]="'invert'"
                          [formControl]="getControl(filter, 'excluded')" />
                        <div class="flex justify-start items-center md:w-24 mr-3">
                          <span class=" font-semibold text-md capitalize">
                            {{ filter['visibleName'] }}
                          </span>
                        </div>
                      </div>
                      @if ([2, 6, 10].includes(filter['dataTypeID'])) {
                        <app-competers-depricated-input
                          class="w-[336px]"
                          [title]="filter['visibleName']"
                          [showLabel]="false"
                          [formControl]="getControl(filter, 'value')"
                          type="{{ filter['dataTypeID'] === 6 ? 'number' : 'text' }}"></app-competers-depricated-input>
                      } @else if ([3, 4].includes(filter['dataTypeID'])) {
                        <app-searchable-dropdown
                          class="w-[336px]"
                          [inlined]="true"
                          [showLabel]="false"
                          [multiple]="filter['dataTypeID'] === 4"
                          [formControl]="getControl(filter, 'value')"
                          [options]="filter['options'] | dbOptionToSelectionOption"
                          [title]="filter['visibleName']"></app-searchable-dropdown>
                      } @else if (filter['dataTypeID'] === 5) {
                        <app-competers-date-range-picker
                          class="w-[336px]"
                          [inlined]="true"
                          [showLabel]="false"
                          [title]="filter['visibleName']"
                          [formControl]="getControl(filter, 'value')"></app-competers-date-range-picker>
                      } @else if ([1, 7].includes(filter['dataTypeID'])) {
                        <div class="w-0 h-0 overflow-hidden">
                          <input type="checkbox" [formControl]="getControl(filter, 'value')" />
                        </div>
                        <div class="w-[336px]"></div>
                      }
                    </div>
                    <span class="flex justify-center items-center">
                      <button
                        (click)="_selectedFilters.deselect(filter)"
                        class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                        <mat-icon class="text-warn" style="width: 20px; height: 20px" svgIcon="close_circle" />
                      </button>
                    </span>
                  </div>
                }
              }
            </div>
            <!--              bottom -->
            <div class="shrink flex flex-row gap-6 justify-end items-center p-6 w-full">
              @if (ticketSearchService.editingSearch$ | async) {
                <button
                  (click)="cancelEdit()"
                  class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize">
                  cancel
                </button>
              } @else {
                <button
                  (click)="clearAdvancedSearch()"
                  class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize">
                  clear
                </button>
              }
              <button
                (click)="handleSearch()"
                class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize">
                search
              </button>
              <button
                (click)="initializeSaveAndSearch()"
                class="box-border flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-primary border-solid border-2 bg-transparent p-0 cursor-pointer hover:bg-grey-500 hover:bg-primary hover:text-white text-gray-500  font-semibold capitalize">
                save & search
              </button>
              <button
                (click)="initializeSave()"
                class="flex justify-center items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-500 hover:bg-opacity-60">
                <mat-icon class="text-black" style="width: 24px; height: 24px" svgIcon="save" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #save>
  <div class="flex flex-col justify-start items-start gap-4 p-4">
    <h2 class="text-headline-6  font-semibold capitalize">Save Search</h2>
    <form class="flex flex-col justify-start items-start gap-4" [formGroup]="saveFormGroup">
      <div class="flex flex-row w-full gap-3 justify-between">
        <app-competers-depricated-input
          class="w-[432px]"
          [title]="'Name'"
          formControlName="name"
          [type]="InputType.text" />
      </div>
      <div class="flex flex-row gap-4 justify-end items-center w-full">
        <button
          (click)="myDialog.close('cancel')"
          class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize">
          Cancel
        </button>
        @if (
          (ticketSearchService.editingSearch$ | async) && (ticketSearchService.prefillSearch$ | async)?.ClientID >= 0
        ) {
          <button
            (click)="handleUpdateClick()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-amber-200 p-0 cursor-pointer hover:bg-gray-500 hove:text-white hover:border-gray-500 text-accent  font-semibold capitalize">
            Update
          </button>
          <button
            (click)="handleSaveClick()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize">
            Save New
          </button>
        } @else {
          <button
            (click)="handleSaveClick()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize">
            Save
          </button>
        }
      </div>
    </form>
  </div>
</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import Layer from 'ol/layer/Layer';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import { TileArcGISRest, TileWMS } from 'ol/source';
import { DatabaseLayer, ServiceType } from '../../services/layer-manager/types/layer.types';
import { MatIcon } from '@angular/material/icon';
import { WMSControlSlotComponent } from '../wms-control-slot/wms-control-slot.component';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-layer-slot',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CdkAccordionModule, MatIcon, WMSControlSlotComponent],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
  template: `
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      class="max-w-full"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-controls]="'accordion-body-' + index">
      <div
        class="layer-slot"
        [class.sub-layer]="isSubLayer"
        [class.expanded]="accordionItem.expanded || isHovered"
        (mouseenter)="isHovered = true"
        (mouseleave)="isHovered = false">
        <div class="left">
          <button class="flex flex-row justify-center items-center bg-transparent border-none outline-none w-fit h-fit">
            <mat-icon
              [ngStyle]="{
                color:
                  isVisible && !hiddenByParent
                    ? (accordionItem.expanded || isHovered) && !isSubLayer
                      ? 'white'
                      : '#00A0CC'
                    : '#E5E5E5',
                'font-size': '20px',
                'text-align': 'center',
                width: '20px',
                height: '20px'
              }"
              (click)="handleClick()"
              svgIcon="visible" />
          </button>
        </div>

        <button
          class="right"
          [ngClass]="isSelectable || subLayers.length > 0 || isWMSLayer ? 'cursor-pointer' : ''"
          (click)="layerClick(accordionItem)">
          <span>
            @if (isWMSLayer) {
              {{ layerProps?.layerServiceName || '' }}
            } @else {
              {{ layerProps?.displayName || layerProps?.layerName || layerProps?.groupName || layerProps?.layerServiceName || '' }}
            }
          </span>
          <div
            *ngIf="isSelectable"
            class="size-4 rounded-full {{
              isSelected ? 'bg-success' : 'bg-warn'
            }} transition-colors ease-in-out duration-300"></div>
          @if (isLayerGroup || isWMSLayer) {
            @if (accordionItem.expanded) {
              <mat-icon
                [ngStyle]="{
                  color: (accordionItem.expanded || isHovered) && !isSubLayer ? 'white' : '#00A0CC'
                }"
                class="arrow-icon">
                keyboard_arrow_down
              </mat-icon>
            } @else {
              <mat-icon
                [ngStyle]="{
                  color: (accordionItem.expanded || isHovered) && !isSubLayer ? 'white' : '#00A0CC'
                }"
                class="arrow-icon">
                keyboard_arrow_right
              </mat-icon>
            }
          }
        </button>
      </div>
      @if (isLayerGroup) {
        <div
          class="accordion-item-body"
          role="region"
          [@collapse]="accordionItem.expanded"
          [style.overflow-y]="'hidden'"
          [style.overflow-x]="'hidden'"
          [attr.id]="'accordion-body-' + index"
          [attr.aria-labelledby]="'accordion-header-' + index">
          @for (subLayer of subLayers; track $index) {
            <app-layer-slot
              [layer]="subLayer"
              [selectedLayer]="selectedLayer"
              [parentVisible]="isVisible"
              (visibilityChanged)="handleChildVisibleChange($event)"
              (layerSelected)="layerSelected.emit($event)"></app-layer-slot>
          }
        </div>
      } @else if (isWMSLayer) {
        <div
          class="accordion-item-body"
          role="region"
          [@collapse]="accordionItem.expanded"
          [style.overflow-y]="'hidden'"
          [style.overflow-x]="'hidden'"
          [attr.id]="'accordion-body-' + index"
          [attr.aria-labelledby]="'accordion-header-' + index">
          @for (subLayer of layerProps['subLayers']; track $index) {
            <app-wms-control-slot
              [layerProps]="subLayer"
              [parentVisible]="isVisible"
              (visibilityChanged)="handleWMSChildVisibleChange($event)"
              (configChanged)="refreshLayer()"></app-wms-control-slot>
          }
        </div>
      }
    </cdk-accordion-item>
  `,
  styleUrls: ['./layer-slot.component.scss'],
})
export class LayerSlotComponent implements OnInit, OnChanges, OnDestroy {
  // IO
  @Input() layer: BaseLayer;
  @Input() selectedLayer: Layer;
  @Input() parentVisible: IsVisible = true;
  @Input() accordionItem: CdkAccordionItem;
  @Input() index: number;
  @Output() visibilityChanged: EventEmitter<IsVisible> = new EventEmitter<IsVisible>();
  @Output() layerSelected = new EventEmitter<Layer>();

  // services

  // observables
  private destroy$ = new Subject<void>();
  protected isVisible: IsVisible = true;
  protected myVisibility: IsVisible = true;
  protected isSelected = false;
  protected isLayerGroup = false;

  protected isSubLayer = false;
  protected isWMSLayer = false;
  protected layerProps: Record<string, any>;
  protected subLayers: BaseLayer[] = [];
  protected isSelectable = false;
  protected hiddenByParent = false;
  protected isHovered = false;
  protected readonly history = history;

  constructor() {}

  ngOnInit(): void {
    this.layerProps = this.layer.getProperties();
    const { isDefaultOn, isEditable, parentGroupID, serviceType } = this.layerProps as DatabaseLayer;

    if (parentGroupID !== -1) {
      this.isSubLayer = true;
    }

    if (serviceType === ServiceType.EsriMapServerSubLayer || serviceType === ServiceType.WMSLayerGeoServerSubLayer) {
      this.isWMSLayer = true;
    }

    if (this.layer instanceof LayerGroup) {
      this.isLayerGroup = true;
      this.subLayers = this.layer.getLayers().getArray();
    }

    const visVal = this.checkIfVisible();
    this.isVisible = visVal;
    this.layer.setVisible(visVal);

    this.isSelectable = isEditable === 1 && this.layer instanceof Layer;
    this.myVisibility = isDefaultOn === 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedLayer) {
      this.isSelected = changes.selectedLayer.currentValue === this.layer;
    }
    if (changes.parentVisible) {
      const val = changes.parentVisible.currentValue && this.myVisibility;
      this.isVisible = val;
      this.layer.setVisible(val);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkIfVisible() {
    if (this.layer instanceof LayerGroup) {
      return true;
    }
    let childVisible = false;
    if (this.subLayers.length > 0) {
      this.subLayers.forEach((layer) => {
        if (layer.getProperties().isDefaultOn === 1) {
          childVisible = true;
        }
      });
    }
    return (this.layerProps.isDefaultOn === 1 && this.parentVisible) || childVisible;
  }

  handleClick() {
    this.toggleVisibility(!this.isVisible);
  }

  handleWMSChildVisibleChange(evt: boolean) {
    this.refreshLayer();
    this.handleChildVisibleChange(evt);
  }

  handleChildVisibleChange(evt: boolean) {
    if (evt) {
      this.toggleVisibility(evt);
    }
  }

  protected refreshLayer() {
    if (this.layer instanceof Layer) {
      const source = this.layer.getSource();
      if (source instanceof TileArcGISRest || source instanceof TileWMS) {
        source.refresh();
      }
    }
  }

  toggleVisibility(val: boolean) {
    this.myVisibility = val;
    this.isVisible = val;
    this.layer.setVisible(val);
    this.visibilityChanged.emit(val);
  }

  layerClick(e: CdkAccordionItem) {
    if (this.isSelectable && this.layer instanceof Layer) {
      this.layerSelected.emit(this.layer);
    } else if (this.isLayerGroup || this.isWMSLayer) {
      e.toggle();
    }
  }
}

export type IsVisible = boolean;

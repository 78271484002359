<div class="page" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="mat-subheading-s">Automated Actions</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <button mat-stroked-button color="primary" class="mat-subheading-xs" (click)="onRetry()">Retry</button>
      <button mat-stroked-button color="primary" class="mat-subheading-xs" (click)="onAbondon()">Abandon</button>
      <button disabled *ngIf="clientID == '88'" mat-stroked-button color="primary" class="mat-subheading-xs">
        Import Netsuite Customers
      </button>
      <!-- (click)="onSyncCustomers()" -->
      <app-competers-search-bar
        class="h-9 md:w-96 lg:w-[600px]"
        (searchValueChange)="searchText = $event"></app-competers-search-bar>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- Table -->
  @if (loadedData) {
    <div class="flex flex-grow w-full h-full">
      <app-tabulator-table
        class="h-full w-full"
        #tabulatorTable
        [tableData]="jobs"
        [columns]="displayedColumns"
        (rowDoubleClick)="openTicket($event)"
        [searchString]="searchText"
        [showSelect]="true"
        (selectedRowsChanged)="rowSelectionChanged($event)" />
    </div>
  } @else if (failedToLoadData) {
    <div *ngIf="failedToLoadData" class=" capitalize font-semibold text-headline-5">Error loading data</div>
  }
</div>

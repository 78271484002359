<span mat-dialog-title class=" text-headline-5 font-medium">Pivacy Policy</span>
<mat-dialog-content class="mat-typography ">
  <span class="font-semibold text-lg">Information</span>

  <p>
    What &amp; Why We collect and use the following information to provide,
    improve and protect our services: Account. We collect, and associate with
    your account, information like your name, email address, and payment
    information. No other service providers have access to these accounts and
    information. Services. When you use our services, we store, process and
    transmit your files (including any emails that come to your utilocate
    account) and related information. This makes it easier for you to have a
    seamless experience on our site.
  </p>
  <p>
    Cookies &amp; Other technologies. We use technologies like cookies to
    provide and improve our services. For example, cookies help us with making
    sure that the work you are doing on our site is never stolen.
  </p>

  <p>
    With Whom We may share information as discussed below, but we won't sell it
    to advertisers or other third-parties.
  </p>
  <p>
    Other Users. Our services displays your name and email to other users in
    your company on the Only those in your company will see this information.
  </p>

  <p>
    Law &amp; Order. We may disclose your information to third parties if we
    determine that such disclosure is reasonably necessary to (a) comply with
    the law; (b) protect any person from death or serious bodily injury; prevent
    fraud or abuse of utilocate account or our users; or (d) protect utilocate
    account's property rights.
  </p>

  <p>
    Stewardship of your data is critical to us and a responsibility that we
    embrace. We believe that our users' data should receive the same legal
    protections regardless of whether it's stored on our services or on their
    home computer's hard drive. We'll abide by the following Government Request
    Principles when receiving, scrutinizing and responding to government
    requests (including national security requests) for our users' data:
  </p>
  <p>
    Be transparent Fight blanket requests Protect all users, and Provide trusted
    services
  </p>
  <p>
    How Security. We continue to work on features and aspects of our site to
    help keep your information safe like not storing any credit card information
    directly on our servers.
  </p>

  <span class="font-semibold text-lg">Where</span>
  <p>
    Around the World. To provide you with the services, we may store, process,
    and transmit information in other countries around the world, including the
    United States.
  </p>

  <span>Changes</span>
  <p>
    If we are involved in a reorganization, merger, acquisition or sale of our
    assets, your information may be transferred as part of that deal. We will
    notify you (for example, via a message to the email address associated with
    your account) of any such deal and outline your choices in that event.
  </p>
  <span class="font-semibold text-lg">Contact</span>
  <p>
    Have questions or concerns about utilocate, our services and privacy?
    Contact us at sales&#64;competers.com.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn-cancel">Close</button>
</mat-dialog-actions>

import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutologListItem } from './ticket-autologs/ticket-autologs-item/ticket-autologs-item.component';
import { DocumentListItem } from './ticket-documents/ticket-documents-item/ticket-documents-item.component';
import { TicketPrimary, TicketService } from './ticket.service';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, from, Subject, Subscription, switchMap } from 'rxjs';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ProgressBarService } from '../progress-bar/progress-bar.service';
import { TicketSyncService } from './services/ticket-sync/ticket-sync.service';
import { isU2AuditTicket, safeAwait } from '../../core/validators/validator';
import { DatetimeService } from '../../core/services/datetime/datetime.service';
import { AutologRow } from '../ticket-details/ticket-details/autologs/ticket-autolog';
import { AutologExplanationModalComponent } from './modals/autolog-explaination-modal/autolog-explanation-modal.component';
import { VerifyDetailsType } from './services/cache/utilocate-completions-verify';
import { COMPLETION_TABLE_NAMES } from '../../core/admin/tables';
import { ConfirmationModalComponent } from './modals/generic-confirmation-modal/confirmation-modal.component';
import { NavBarService } from './services/router/nav-bar.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { SnackbarType } from '../snackbar/snackbar/snackbar';
import { PreviewModalComponent } from './modals/preview-modal/preview-modal.component';
import * as CryptoJS from 'crypto-js';
import { AutologID, SettingID } from 'src/app/modules/core/services/user/setting';
import { DrawingMapService } from '../../drawing-module/drawing-map/drawing-map.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserService } from '../../core/services/user/user.service';
import { SchedulerCancelTicketDialogComponent } from '../../scheduler/dialogs/scheduler-cancel-ticket-dialog/scheduler-cancel-ticket-dialog.component';
import { CompletedLocateStatusIDs, LocateStatusID } from '../ticket-details/ticket-details.module';
import { EditTimeRangeModalComponent } from './modals/edit-timerange/edit-timerange.component';
import { takeUntil, tap } from 'rxjs/operators';
import { LayerOptions } from '../esri-map/esri-map-set/EsriMapModels';
import { MapService } from '../../core/services/map/map.service';
import { DownloadDocumentService } from '../../core/services/document/download-document.service';
import { TicketDocumentsService } from './ticket-documents/ticket-documents.service';
import { DrawingService } from '../../drawing-module/drawing.service';
import { FieldsideService } from 'src/app/routes/fieldside/fieldside.service';
import { NavBarConfig } from 'src/app/routes/fieldside/components/fieldside-navigation-bar/navigation-bar.component';
import { MapFeatureService } from '../../drawing-module/services/map-feature.service';
import { TicketDetailsService } from '../ticket-details/ticket-details.service';
import { TicketTagBuilderService } from '../ticket-tags/ticket-list-tags/ticket-tags-builder.service';
import { TicketActionsService } from 'src/app/shared/services/ticket-actions/ticket-actions.service';
import { TicketActionIDs } from 'src/app/shared/services/ticket-actions/TicketActionIDs';
import { CompletionQueueService } from './services/completionQueue/completion-queue.service';
import { NodeDocumentService } from './services/documents/node-document.service';
import { UtilocateDocumentsCacheService } from './services/cache/utilocate-documents.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-ticket',
  templateUrl: 'ticket.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Ticket implements OnInit, OnDestroy {
  @ViewChild('openLayerMap') openLayerMap: any;
  @ViewChild('ticketMap', { static: true }) ticketMap: MatTab;
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
  @ViewChild('matTabGroup', { static: true }) tabGroup: MatTabGroup;
  @Input() AssignmentIDClicked: any;

  //add two output events, called
  @Output() ticketChangedEvent: EventEmitter<void> = new EventEmitter();
  @Output() ticketCloseEvent: EventEmitter<void> = new EventEmitter();

  // services
  private mapService: MapService = inject(MapService);
  private completionQueueService = inject(CompletionQueueService);

  // members
  protected layerOptions: LayerOptions;
  selected: number;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  onSyncClick: Subject<any>;
  onCreateAuditClick: Subject<any>;
  private destroy$ = new Subject<void>();
  private reloadDocsSubscription: Subscription;

  PageTitle = '';

  AssignmentID: string; // from URL
  PrimaryID: string;
  RequestNumber: string;
  UserID: string;
  isU2AuditTicket: boolean;
  title: string;
  ticket: any;
  ticketPrimary: TicketPrimary;
  TicketDetails: any; // TicketDetails props
  TicketDetailSummary: any;
  DocumentList: DocumentListItem[]; // TicketDocuments props
  AutologList: AutologListItem[]; // TicketAutologs props
  CompletionsBillingDetails: any;
  CompletionsPrimaryDetails: any;
  CompletionsCommonDetails: any;
  expectedDocHash: any;
  TicketMap: any;
  mapEnabled: any;
  previousTabIndex: number = null;
  selectedIndex = 1;
  lateColour: string;
  public tabs = [{ id: 1 }, { id: 2 }, { id: 3 }];
  private tabsCount = this.tabs.length;
  mapUrl: any;
  timeFormatted: any;
  clearOptions: any[];
  stopwatchState: string | undefined;
  stopwatchColour: string | undefined;
  stopwatchProgressBarColour: string | undefined;
  stopwatchProgressBarMode: string | undefined;
  stopwatchIconColour: string | undefined;
  activeUtilities: any[];
  isFieldSide: boolean = false;
  showClearOptions: boolean;
  dialogBox: any;
  modalOpened: boolean = false; // Add a flag to track whether the modal has been opened
  //Mapping Settings
  //288
  useOpenLayerMappingService = false;

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  protected _ticketMapTabSelected$$ = signal(false);
  protected _ticketMapDrawingModeActive$$ = signal(false);
  protected _hideTabGroupHeader$$ = computed(
    () => this._ticketMapTabSelected$$() && this._ticketMapDrawingModeActive$$()
  );

  constructor(
    private ticketService: TicketService,
    private route: ActivatedRoute,
    protected userService: UserService,
    public dialog: MatDialog,
    private progressBarService: ProgressBarService,
    private ticketSyncService: TicketSyncService,
    private router: Router,
    private datetimeService: DatetimeService,
    private navBarService: NavBarService,
    private snackBarService: SnackbarService,
    private downloadService: DownloadDocumentService,
    private ticketDocumentService: TicketDocumentsService,
    private baseMapService: DrawingMapService,
    private drawingService: DrawingService,
    private fieldsideService: FieldsideService,
    private ticketDetails$: TicketDetailsService,
    private ticketTagsService: TicketTagBuilderService,
    private ticketActionService: TicketActionsService,
    private nodeDocumentService: NodeDocumentService,
    private documentCacheService: UtilocateDocumentsCacheService
  ) {
    this.onCreateAuditClick = new Subject<any>();
    this.onSyncClick = new Subject<any>();
    this.isU2AuditTicket = false;
    this.selected = 0;
  }

  ngOnInit() {
    // By deferring the assignment using setTimeout, the change will occur after Angular's change detection cycle is complete
    setTimeout(() => {
      this.progressBarService.start();
    }, 0);
    this.useOpenLayerMappingService = this.ticketService.getMappingServiceSetting();
    this.reloadDocsSubscription = this.ticketDocumentService.reloadTab$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.reloadDocs();
      });

    this.AssignmentID = this.AssignmentIDClicked ?? this.route.snapshot.paramMap.get('aid');
    const currentUrl = this.router.url;
    const isInApp = currentUrl.includes('app');
    this.isFieldSide = currentUrl.includes('fieldside');

    this.title = 'Ticket - ' + this.AssignmentID;
    sessionStorage.setItem('AssignmentID', this.AssignmentID);
    this.ticketSyncService.setAssignmentIDToUpload().then((tickets) => {
      this.getLocalTicket().then((ticketDownloaded) => {
        if (ticketDownloaded) {
          this.UserID = this.userService.getUserID();
          this.RequestNumber = this.ticket.RequestNumber;
          this.title = this.RequestNumber + ' - ' + this.PrimaryID;

          //if we're opening this ticket as it's own page (i.e., url is ticket-summary/ticket/id)
          if (!this.AssignmentIDClicked || isInApp) {
            //get the tickets assigned to the user
            this.onSyncClick
              .asObservable()
              .pipe(
                switchMap(() => this.ticketSyncService.startSync(true, true, [this.AssignmentID.toString()], true)),
                switchMap(() => from(this.getLocalTicket())),
                switchMap(() => from(this.initTabs())),
                takeUntil(this.destroy$)
              )
              .subscribe(() => {
                this.navBarService.updateNavBar(
                  new NavBarConfig(this.title, 'ticket-summary', tickets.length > 0, this.onSyncClick, null, false)
                );
                this.ticketSyncService.closeModal();
              });
            this.navBarService.updateNavBar(
              new NavBarConfig(this.title, 'ticket-summary', tickets.length > 0, this.onSyncClick, null, false)
            );
          } else {
            //if we're opening this in the side panel, rather than it being it's own page
            const currentNavBar: NavBarConfig = this.navBarService.getCurrentNavBar();
            this.navBarService.updateNavBar(
              new NavBarConfig(
                this.title,
                'ticket-summary',
                tickets.length > 0,
                currentNavBar.onSyncClick,
                currentNavBar.onCreateAuditClick,
                currentNavBar.showFilter,
                currentNavBar.showRouting
              )
            );
            currentNavBar.onSyncClick.subscribe(() => {
              this.navBarService.updateNavBar(
                new NavBarConfig(
                  this.title,
                  'ticket-summary',
                  tickets.length > 0,
                  currentNavBar.onSyncClick,
                  currentNavBar.onCreateAuditClick,
                  currentNavBar.showFilter,
                  currentNavBar.showRouting
                )
              );
            });
          }

          firstValueFrom(
            this.mapService.getEsriData(true).pipe(
              tap((result: LayerOptions) => {
                this.layerOptions = result;
              })
            )
          ).then(() => {
            this.initTabs().then(() => this.progressBarService.stop());
          });
        } else {
          // failed to load ticket
          this.progressBarService.stop();
          this.snackBarService.openSnackbar('Failed to load ticket', SnackbarType.error, 'Close');
        }
      });
    });

    this.setLateColour();
  }

  ngOnDestroy() {
    this.ticketDocumentService.clearDocs();
    this.destroy$.next();
    this.destroy$.complete();
    console.log('ticket destroyed');
  }

  async setLateColour() {
    const assignmentsTable: any = await this.ticketService.getTicketAssignment(this.AssignmentID.toString());
    const digDate = new Date(assignmentsTable.ExcavationDate);
    this.lateColour = this.fieldsideService.getColorBasedOnDate(digDate, assignmentsTable.LocateStatusID);
  }

  async setTimeIconState() {
    const status = await this.ticketService.getTicketPrimaryTimeInOutStatus(this.AssignmentID, this.PrimaryID);
    const baseClass = 'mb-1 mx-1 w-12 h-9 min-w-0 rounded-md ';

    switch (status) {
      //these colours were from the XD
      case 'Edit Time':
        this.stopwatchState = 'edit-stopwatch';
        this.stopwatchColour = baseClass + 'bg-primary text-white';
        this.stopwatchIconColour = "text-white";
        this.stopwatchProgressBarColour = 'primary';
        this.stopwatchProgressBarMode = 'determinate';
        break;

      case 'End Time':
        this.stopwatchState = 'stop-stopwatch';
        this.stopwatchColour = baseClass + 'bg-white text-warn border-warn border border-solid';
        this.stopwatchIconColour = "text-warn";
        this.stopwatchProgressBarColour = 'warn';
        this.stopwatchProgressBarMode = 'indeterminate';
        break;

      case 'Start Time':
      default:
        this.stopwatchState = 'stopwatch';
        this.stopwatchColour = baseClass + 'bg-success text-white';
        this.stopwatchIconColour = "text-white";
        this.stopwatchProgressBarColour = undefined;
        this.stopwatchProgressBarMode = undefined;
        break;
    }
  }

  private async initTabs() {
    try {
      this.TicketDetailSummary = await this.createDetailTabSummaryProps();
      //date format to display
      this.formatTime();
      const userCategoryID = this.userService.getUserCategoryID();
      this.clearOptions = await this.ticketService.getClearOptions(userCategoryID, this.PrimaryID, this.AssignmentID);
      this.canShowClearOptions();

      this.TicketDetails = await this.createDetailTabProps();
      this.TicketMap = await this.createMapTabProps();
      this.DocumentList = await this.createDocumentTabProps();
      this.AutologList = await this.createAutologTabProps();
      await this.createCompletionsTabProps();
      this.setLateColour();

      return true;
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  formatTime() {
    const convertedDate = DateTime.fromFormat(this.TicketDetailSummary.DigDate, "yyyy-MM-dd HH:mm:ss").toFormat("ccc t LLL d");
    this.timeFormatted = convertedDate;
  }

  checkMapControls(enabled) {
    this.mapEnabled = enabled;
  }

  private async getLocalTicket() {
    const [ticket, ticketError] = await safeAwait(this.ticketService.getTicketAssignment(this.AssignmentID));
    const [ticketPrimary, ticketPrimaryError] = await safeAwait(this.ticketService.getTicketPrimary(this.AssignmentID));

    if (!ticketError && !ticketPrimaryError) {
      this.ticket = ticket;
      this.ticketPrimary = ticketPrimary;
      const { PrimaryID } = ticketPrimary;
      this.PrimaryID = PrimaryID;

      return true;
    } else {
      console.log('error in get getLocalTicket');
      return false;
    }
  }

  private async createDetailTabSummaryProps() {
    const CallTypeDesc = await this.ticketService.getTicketCallTypeDesc(this.AssignmentID);
    const timeInOutStatus = await this.ticketService.getTicketPrimaryTimeInOutStatus(this.AssignmentID, this.PrimaryID);
    const DigDate = this.ticket.ExcavationDate != null
      ? this.datetimeService.dbDateToFormattedLocalDate(this.ticket.ExcavationDate, 'yyyy-MM-dd HH:mm:ss')
      : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss');
    const AppointmentDate =
      this.ticket.AppointmentDate != null
        ? this.datetimeService.dbDateToFormattedLocalDate(this.ticket.AppointmentDate, 'yyyy-MM-dd HH:mm:ss')
        : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm:ss');
    const TypeOfWork = this.ticket.TypeOfWork;
    const houseNumber = this.ticket.StartHouseNumber || '';
    const locateAddress = this.ticket.LocateAddress || '';
    const subRegionName = this.ticket.LocateSubRegionName || '';

    const Address = `${houseNumber} ${locateAddress}, ${subRegionName}`;
    const TimeIn = this.ticketPrimary.TimeIn;
    const TimeOut = this.ticketPrimary.TimeOut;

    this.setTimeIconState();

    return {
      TypeOfWork,
      Address,
      CallTypeDesc,
      AssignmentID: this.AssignmentID,
      timeInOutStatus,
      DigDate,
      AppointmentDate,
      TimeIn,
      TimeOut,
    };
  }

  private async createDetailTabProps() {
    return this.ticketService.createTicketInfo(this.AssignmentID);
  }

  private createMapTabProps(): Promise<any> {
    return this.ticketService.createMapInfo(this.AssignmentID);
  }

  private async createDocumentTabProps(): Promise<DocumentListItem[]> {
    //get tbCompletions_Documents, downloaded and in db
    const documentsResult = await this.getDocumentRows();

    //get formatted s3 and _Documents
    let formattedDocuments = this.getFormattedDocumentRow(documentsResult);

    //sort the list by date
    formattedDocuments = formattedDocuments.sort(
      (a, b) =>
        this.datetimeService.dbDateToLocalDate(b.CreationDate).getTime() -
        this.datetimeService.dbDateToLocalDate(a.CreationDate).getTime()
    );

    const S3Photos = await this.ticketDocumentService.initDocList(formattedDocuments);
    const queuedDocs = await this.ticketService.getQueuedDocuments(this.AssignmentID);
    const currentQueue = this.ticketDocumentService.getDocumentQueueValue();
    if (currentQueue.length == 0 && Object.keys(queuedDocs).length != 0) {
      for (const doc in queuedDocs) {
        this.ticketDocumentService.addDocument(queuedDocs[doc]);
      }
    }
    console.log('queuedDocs', queuedDocs);

    return S3Photos;
  }

  /**
   * Gets the formatted document row. Can handle both documents and s3 document rows
   * @param documentsResult
   * @returns
   */
  getFormattedDocumentRow(documentsResult) {
    const documentListItems: DocumentListItem[] = [];
    try {
      const documents = documentsResult[0];
      const downloadedDocIDs = documentsResult[1];
      for (let i = 0; i < documents.length; i++) {
        const documentRow = documents[i];

        const DocumentID = documentRow.DocumentID ? documentRow.DocumentID : documentRow.S3DocumentID;

        //give it the isS3Document property based on existence of field
        documentRow.isS3Document = documentRow.S3DocumentID ? true : false;

        let isDownloaded = false;
        if (downloadedDocIDs && downloadedDocIDs.length > 0) {
          isDownloaded = downloadedDocIDs.indexOf(DocumentID.toString()) > -1;
        }
        const formattedDocumentRow: DocumentListItem = {
          assignmentID: documentRow.AssignmentID,
          DocumentID,
          FileName: documentRow.FileName,
          DocumentTypeID: documentRow.DocumentTypeID,
          CreationDate: documentRow.CreationDate,
          isSendable: documentRow.isSendable,
          isDownloaded,
          S3DocumentID: documentRow.S3DocumentID,
          isS3Document: documentRow.isS3Document,
          bFieldAdded: documentRow.bFieldAdded.toString(),
          selected: false,
          Caption: documentRow.Caption ?? null,
          file: documentRow.file,
          s3UUID: documentRow.S3DocumentUUID,
        };
        documentListItems.push(formattedDocumentRow);
      }
    } catch (error) {
      console.error(error);
    }
    return documentListItems;
  }

  private async createAutologTabProps(): Promise<AutologListItem[]> {
    const autologs: any[] = await this.ticketService.getTicketAutologs(this.AssignmentID);
    let autologListItems: AutologListItem[] = [];

    for (let i = 0; i < autologs.length; i++) {
      const autologRow = autologs[i];
      autologListItems.push(this.createAutologListItem(autologRow));
    }

    autologListItems = autologListItems.sort(
      (a, b) =>
        this.datetimeService.dbDateToLocalDate(b.logDate).getTime() -
        this.datetimeService.dbDateToLocalDate(a.logDate).getTime()
    );
    return autologListItems;
  }

  private createAutologListItem(autologRow: any) {
    const autologListItem: AutologListItem = {
      DescIDDesc: autologRow.DescIDDesc,
      Explaination: autologRow.Explaination,
      logDate: autologRow.logDate,
      UserID: autologRow.UserID,
    };
    return autologListItem;
  }

  private async createCompletionsTabProps(): Promise<any> {
    if (isU2AuditTicket(this.ticket.CallTypeID)) {
      this.isU2AuditTicket = true;
    }

    //if this ticket is completed (i.e., the LocateStatusID is in CompletedLocateStatusIDs, then make all fields view only)
    const viewOnlyField =
      CompletedLocateStatusIDs.includes(this.ticket.LocateStatusID) &&
      !this.userService.isSettingActive(SettingID.EDIT_COMPLETED);
    this.CompletionsBillingDetails = await this.ticketService.createCompletionsBillingDetails(
      this.PrimaryID,
      this.AssignmentID,
      viewOnlyField
    );
    this.CompletionsPrimaryDetails = await this.ticketService.createCompletionsPrimaryDetails(
      this.PrimaryID,
      this.AssignmentID,
      viewOnlyField
    );
    this.CompletionsCommonDetails = await this.ticketService.createCompletionsCommonDetails(
      this.PrimaryID,
      this.AssignmentID,
      viewOnlyField
    );
    this.activeUtilities = await this.ticketService.getActiveUtilities(this.PrimaryID, this.AssignmentID.toString());
  }

  async onTicketDetailsUpdate(event: any) {
    if (event.formValue.ExcavationDate) {
      event.formValue = {
        ExcavationDate: event.formValue.ExcavationDate.toISOString(),
      };
    }

    await this.ticketService.updateTicketDetails(event.formValue, this.AssignmentID);
  }

  async onTimeInOutToggle(event: any) {
    await this.ticketService.updateTicketPrimary(event, this.PrimaryID, this.AssignmentID);
    const timeInOutStatus = await this.ticketService.getTicketPrimaryTimeInOutStatus(this.AssignmentID, this.PrimaryID);
    this.TicketDetailSummary.timeInOutStatus = timeInOutStatus;
    let snackbarMessage = 'Successfully edited time';

    if (event.TimeIn) {
      this.TicketDetailSummary.TimeIn = event.TimeIn;
      snackbarMessage = 'Timer Started';
    }
    if (event.TimeOut) {
      this.TicketDetailSummary.TimeOut = event.TimeOut;
      snackbarMessage = 'Timer Ended';
    }

    if (event.TimeOut && event.TimeIn) {
      snackbarMessage = 'Successfully edited time';
    }

    this.snackBarService.openSnackbar(snackbarMessage, SnackbarType.success);

    this.TicketDetailSummary = { ...this.TicketDetailSummary };
    this.setTimeIconState();
  }

  onTimeToggle() {
    const AssignmentID = sessionStorage.getItem('AssignmentID');
    this.ticketChangedEvent.emit();
    this.ticketService.insertTicketChangedToIDB(AssignmentID);
    if (this.TicketDetailSummary.timeInOutStatus == 'Edit Time') {
      const cleanTimeIn: Date = this.datetimeService.dbDateToLocalDate(this.TicketDetailSummary['TimeIn']);
      const timeIn: string = this.datetimeService.dbDateToFormattedLocalDate(
        this.TicketDetailSummary['TimeIn'],
        'HH:mm'
      );
      const timeOut: string = this.datetimeService.dbDateToFormattedLocalDate(
        this.TicketDetailSummary['TimeOut'],
        'HH:mm'
      );

      this.dialog
        .open(EditTimeRangeModalComponent, {
          width: '380px',
          data: {
            header: 'Edit Time In/Out',
            startTime: timeIn,
            endTime: timeOut,
            date: cleanTimeIn,
          },
        })
        .afterClosed()
        .subscribe((next) => {
          if (next && next['result'] && next['result']['TimeIn'] && next['result']['TimeOut']) {
            this.onTimeInOutToggle({
              TimeIn: this.datetimeService.localDateToDBDateStr(next['result']['TimeIn']),
              TimeOut: this.datetimeService.localDateToDBDateStr(next['result']['TimeOut']),
            });
          }
        });
    } else {
      const toggleTime = this.datetimeService.localDateToDBDateStr(new Date());
      if (this.TicketDetailSummary.timeInOutStatus == 'Start Time') {
        this.onTimeInOutToggle({ TimeIn: toggleTime });
      } else {
        this.onTimeInOutToggle({ TimeOut: toggleTime });
      }
    }
  }

  async onDocumentDownload(event: any) {
    this.progressBarService.start();
    const isS3Document = event.isS3Document;

    //find the document from the list of documents
    const rowToUpdate = this.DocumentList.find((row) => {
      return row.isS3Document === isS3Document && row.DocumentID === event.DocumentID;
    });
    const caption = event.Caption ?? rowToUpdate.Caption ?? '';
    let fileName;
    let linkSource;
    if (event.file) {
      linkSource = event.file;
      fileName = event.FileName;
    } else {
      const result = await this.ticketService.getTicketDocument(this.AssignmentID, event.DocumentID, isS3Document);
      linkSource = result.buffer;
      fileName = result.FileName;
    }

    this.dialog.open(PreviewModalComponent, {
      minWidth: '90vw',
      maxWidth: 'none',
      minHeight: '90vh',
      panelClass: 'preview-modal',
      data: { url: linkSource, name: fileName, caption: caption },
    });
    this.ticketDocumentService.addFile(event.DocumentID, event.isS3Document, linkSource);
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    // downloadLink.download = result["FileName"];
    // downloadLink.click();

    rowToUpdate.isDownloaded = true;
    this.progressBarService.stop();
  }

  /**
   * Gets MD5 filehash from BLOB
   * @param blob
   * @returns promise<string>
   */
  private getMD5FileHash(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
        const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);
        resolve(md5Hash);
      };

      fileReader.onerror = (event) => {
        reject(event.target?.error);
      };

      fileReader.readAsArrayBuffer(blob);
    });
  }

  /**
   * Gets the local timezone in the format "EST" or "GMT-2"
   * @returns {string} timezone
   */
  getLocalTimeZone() {
    let tz = 'local';
    try {
      tz = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    } catch (error) {
      console.error(error);
    }
    return tz;
  }

  async onMarkSendable(event) {
    const changesArray = [];
    const newSendableState = event.isSendable == 1 ? 0 : 1;
    changesArray.push({ key: 'isSendable', value: newSendableState.toString() });

    const result = this.ticketDocumentService.addMutation({
      AssignmentID: this.AssignmentID,
      DocumentID: event.DocumentID,
      isS3Document: event.isS3Document,
      changes: changesArray,
      type: 'sendable',
    });

    // this.DocumentList = await this.createDocumentTabProps();
  }

  /**
   * Updates the IDB with the change
   *
   * @param {*} event
   * @memberof Ticket
   */
  async onCaptionChange(event) {
    await this.documentCacheService.updateS3TicketDocuments(
      { Caption: event.Caption },
      event.DocumentID,
      event.AssignmentID
    );
  }

  async reloadDocs() {
    this.DocumentList = await this.createDocumentTabProps();
  }

  async onDocumentUpload(events) {
    //DocumentTypeID 6 is S3 document
    //DocumentTypeID 4 is S3 document
    //DocumentTypeID 106 is aux photo - this can stay the same (upload to s3 and tbCompletions)

    this.progressBarService.start();
    const len = events.length;
    let isSendable = '0';

    //event looks like: {file: FileList, documentTypeID: 4} for example
    for (let i = 0; i < len; i++) {
      const event = events[i];
      let file = null;
      if (Array.isArray(event.file)) {
        file = event.file[0];
      } else {
        file = event.file;
      }

      const filehash = await this.getMD5FileHash(file);
      const uuid = crypto.randomUUID();
      //if it is an s3 document we want to upload using the uploadDocumentS3 lambda
      const isS3Document = true;
      const newDocID = uuid;

      if (event.isSendable) {
        isSendable = '1';
      }

      const metadata = {
        AssignmentID: this.AssignmentID.toString(),
        PrimaryID: this.PrimaryID.toString(),
        CreationDate: this.datetimeService.localDateToDBDateStr(new Date()),
        Description: 'Added from U4 Locator Documents',
        FileName: file.name,
        DocumentTypeID: event.documentTypeID.toString(),
        RequestNumber: this.RequestNumber.toString(),
        isSendable: isSendable,
        CreationDateTz: this.getLocalTimeZone().toString(),
        DocumentHash: filehash, //should be md5 filehash
        RotateAngle: '0',
        bFieldAdded: '1',
        Caption: event.caption,
        s3UUID: uuid,
      };

      const tmpDocRow = this.getTmpDocRow(isS3Document, newDocID, event, file, isSendable);


      if (this.userService.isSettingActive(SettingID.CHECK_DOCUMENT_MANIFEST)) {
        await this.ticketService.addDocumentToManifest(this.AssignmentID, newDocID.toString(), {
          FileName: file.name,
          type: 'img',
          metadata,
          bFieldAdded: 1,
          isS3Document: isS3Document,
          isSendable: isSendable,
          s3UUID: uuid,
        });
      }

      const fileReader = new FileReader();
      let dataUrl;
      const fileReadPromise = new Promise<void>((resolve, reject) => {
        fileReader.onload = (event) => {
          dataUrl = event.target.result;
          resolve(); // Resolve the Promise once the data URL is obtained
        };

        fileReader.onerror = (error) => {
          reject(error); // Reject the Promise if there's an error reading the file
        };
      });
      fileReader.readAsDataURL(file);
      await fileReadPromise;

      await this.ticketService.queueDocumentToUpload(this.AssignmentID, newDocID.toString(), {
        Blob: file,
        FileName: file.name,
        type: 'img',
        metadata,
        bFieldAdded: 1,
        isS3Document: isS3Document,
        isSendable: isSendable,
        fileUrl: dataUrl,
      });

      const ms = new Date().getTime();
      const zipName = `${metadata.CreationDate.replace(/[-: ]/g, '-')}-${ms}_${this.UserID}_${metadata.AssignmentID
        }.zip`;
      const data = {
        CreationDate: this.datetimeService.localDateToDBDateStr(new Date()),
        DocumentID: newDocID,
        DocumentTypeID: event.documentTypeID.toString(),
        FileName: file.name,
        bFieldAdded: 1,
        file: dataUrl,
        isDownloaded: true,
        S3DocumentID: isS3Document ? newDocID : null,
        isS3Document: isS3Document,
        isSendable: isSendable,
        selected: false,
        Caption: event.caption,
        Blob: file,
        metadata: metadata,
        AssignmentID: this.AssignmentID,
        zipName: zipName,
        s3UUID: uuid,
        tmpDocRow: tmpDocRow,
        UserID: this.UserID,
      };
      this.ticketDocumentService.addDocument(data);

      await this.ticketService.addDocumentToQueue(this.AssignmentID, newDocID.toString(), data);
      this.ticketChangedEvent.emit();
      this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
    }
    this.AutologList = await this.createAutologTabProps();
    this.ticketDocumentService.disableSync(true);

    this.progressBarService.stop();
  }

  /**
   * Gets the tmpDocRow object for the document that is being uploaded.
   * @param {boolean} isS3Document
   * @param newDocID
   * @param event
   * @returns {object}
   */
  getTmpDocRow(isS3Document, newDocID, event, file, isSendable) {
    const commonFields = {
      AssignmentID: this.AssignmentID,
      PrimaryID: this.PrimaryID,
      CreationDate: this.datetimeService.localDateToDBDateStr(new Date()),
      Description: 'Added from U4 Documents',
      FileName: file.name,
      DocumentTypeID: event.documentTypeID.toString(),
      RequestNumber: this.RequestNumber.toString(),
      isSendable: isSendable,
      bFieldAdded: '1',
      Caption: event.caption,
    };

    if (isS3Document) {
      return {
        S3DocumentID: newDocID,
        ...commonFields,
      };
    } else {
      return {
        DocumentID: newDocID,
        ...commonFields,
      };
    }
  }

  /**
   * Receives an event which is an object that has 3 properties: (FileName, isS3document, DocumentID)
   * @param events
   * @param showMessage {boolean} Whether to show a snackbar message or not. Default is true.
   * @returns {Promise<void>}
   * @description This function deletes a document from the database and from the local file system.
   * It also deletes the document from the tbCompletions_Documents table if the document is an S3 document.
   */
  async onDocumentDelete(events) {
    this.ticketChangedEvent.emit();
    this.ticketService.onDocumentDelete(events, this.AssignmentID);
    this.AutologList = await this.createAutologTabProps();
  }

  async getDocumentRows() {
    //get _S3 or _Document ID from events object
    const documentsResult: any = await this.ticketService.getTicketDocumentRows(this.AssignmentID);
    //get tbCompletions_S3Documents, downloaded and in db
    const s3DocumentsResult: any = await this.ticketService.getTicketS3DocumentRows(this.AssignmentID);

    for (let i = 0; i < 2; i++) {
      documentsResult[i] = documentsResult[i].concat(s3DocumentsResult[i]);
    }

    return documentsResult;
  }

  async onCreateAutolog() {
    this.ticketChangedEvent.emit();
    this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
    this.progressBarService.start();
    this.dialog
      .open(AutologExplanationModalComponent, {
        width: '380px',
        data: { header: 'New Log' },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (closeValue) => {
        if (closeValue && closeValue.result) {
          const newAutolog: AutologRow = {
            AssignmentID: this.AssignmentID,
            DescID: '48', // custom autolog desc id
            UserID: this.UserID,
            Explaination: closeValue.result,
            logDate: this.datetimeService.localDateToDBDateStr(new Date()),
            bFieldAdded: '1',
          };

          await this.ticketService.addAutologToAssignment(newAutolog, this.AssignmentID);
          this.AutologList = await this.createAutologTabProps();
          this.AutologList = [...this.AutologList];
        }
        this.progressBarService.stop();
      });
  }

  async onExcavationDateChange(event: any) {
    this.progressBarService.start();
    try {
      const actionResult = await this.ticketActionService.dispatchBulkAction(
        TicketActionIDs.CHANGE_EXCAVATION_DATE_ACTION_ID,
        [parseInt(this.PrimaryID)],
        [parseInt(this.AssignmentID)]
      );

      //if the result is null, then we didn't do the action
      if (!actionResult) {
        this.progressBarService.stop();
        return;
      }

      this.ticketSyncService.startSync(false, true, [this.AssignmentID.toString()]).subscribe({
        complete: async () => {
          this.AutologList = await this.createAutologTabProps();
          this.TicketDetails = await this.createDetailTabProps();
          this.TicketDetailSummary = await this.createDetailTabSummaryProps();
          this.ticketSyncService.closeModal();
          this.progressBarService.stop();
        },
        error: () => {
          this.progressBarService.stop();
        },
      });
    } catch (error) {
      console.error(error);
      this.progressBarService.stop();
    }
  }

  async onAppointmentDateChange(event) {
    this.progressBarService.start();
    const newAppointmentDate = event;
    const newAutolog: AutologRow = {
      AssignmentID: this.AssignmentID,
      DescID: '17',
      UserID: this.UserID,
      Explaination: 'Appointment date set to ' + event,
      logDate: this.datetimeService.localDateToDBDateStr(new Date()),
      bFieldAdded: '1',
    };

    await this.ticketService.updateTicketDetails({ AppointmentDate: newAppointmentDate }, this.AssignmentID);
    await this.ticketService.addAutologToAssignment(newAutolog, this.AssignmentID);
    this.AutologList = await this.createAutologTabProps();
    this.TicketDetails = await this.createDetailTabProps();
    this.TicketDetailSummary = await this.createDetailTabSummaryProps();
    this.progressBarService.stop();
    this.snackBarService.openSnackbar('Successfully changed appointment date', SnackbarType.success);
  }

  async onEmailChange(event) {
    this.ticketChangedEvent.emit();
    await this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
    await this.ticketService.updateTicketDetails({ email2: event }, this.AssignmentID);
  }

  async sendToLSP(result: { LSPID: number }) {
    try {
      if (result.LSPID != null) {
        this.progressBarService.start();

        const toLSPResult = await this.ticketDetails$.sendToLSP(this.PrimaryID, result.LSPID);
        if (toLSPResult && toLSPResult[0] && toLSPResult[0]['Error']) {
          const errorMsg = toLSPResult[0]['Error'].split(':')[1];
          this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar('Success', SnackbarType.success);
          //on success, upload and close
          this.ticketSyncService.startSync(true, false, [this.AssignmentID.toString()]).subscribe({
            complete: () => {
              this.ticketSyncService.closeModal();
              this.ticketCloseEvent.emit();
            },
          });
        }

        this.progressBarService.stop();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onInputChange(event) {
    this.ticketChangedEvent.emit();
    this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
    await this.ticketService.updateTicketDetails(event, this.AssignmentID);
  }

  onTicketTagsChange() {
    this.ticketChangedEvent.emit();
    this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
    this.ticketTagsService.tagChanged.emit();
  }

  /**
   * Handles event when someone adds/removes utilities
   * @param event
   */
  async onUtilitiesChanged(event) {
    console.log(event);
    this.progressBarService.start();
    const differences = event.difference;
    const explanation = event.explanation.result;

    try {
      if (!differences || !explanation) throw new Error('Cannot update utilities');

      for (const diffInstance of differences) {
        const newAutolog: AutologRow = {
          AssignmentID: this.AssignmentID,
          DescID: '2',
          UserID: this.UserID,
          Explaination: explanation,
          logDate: this.datetimeService.localDateToDBDateStr(new Date()),
          bFieldAdded: '1',
        };
        switch (diffInstance.action) {
          case 'add':
            newAutolog.DescID = '2';
            await this.ticketService.addTicketUtility(diffInstance.id, this.PrimaryID, this.AssignmentID.toString());
            break;
          case 'remove':
            newAutolog.DescID = '52';
            await this.ticketService.removeTicketUtility(diffInstance.id, this.PrimaryID, this.AssignmentID.toString());
            break;
          default:
            throw new Error('Unknown action: onUtilitiesChanged()');
        }
        await this.ticketService.addAutologToAssignment(newAutolog, this.AssignmentID.toString());
      }
      this.snackBarService.openSnackbar('Successfully Modified Utilities', SnackbarType.success);
    } catch (error) {
      this.snackBarService.openSnackbar('Unable to update utilities', SnackbarType.error);
    }
    this.progressBarService.stop();
    this.ticketSyncService.startSync(true, true, [this.AssignmentID.toString()]).subscribe({
      complete: async () => {
        this.ticketSyncService.closeModal();
        await this.initTabs();
      },
    });
  }

  async onCompletionsChanges(event) {
    this.ticketChangedEvent.emit();
    let formTemplateViewGroups: any;
    if (event.CompletionType == 'Billing') {
      formTemplateViewGroups = Object.values(this.CompletionsBillingDetails[event.viewKey].groups);
    } else if (event.CompletionType == 'Primary') {
      formTemplateViewGroups = Object.values(this.CompletionsPrimaryDetails[event.viewKey].groups);
    } else if (event.CompletionType == 'Common') {
      formTemplateViewGroups = Object.values(this.CompletionsCommonDetails[event.viewKey].groups);
    }

    const formTemplateViewFields = [];
    for (let i = 0; i < formTemplateViewGroups.length; i++) {
      const groupFields = Object.values(formTemplateViewGroups[i].fields);
      formTemplateViewFields.push(...groupFields);
    }

    const isHighRisk = await this.highProfileChecked(formTemplateViewFields, event.formValue);

    if (!isHighRisk) {
      if (event.CompletionType == 'Billing') {
        await this.ticketService.updateTicketCompletionDetails(
          event.formValue,
          formTemplateViewFields,
          COMPLETION_TABLE_NAMES.tbCompletions_Billing,
          'BillingID'
        );
      } else if (event.CompletionType == 'Primary') {
        await this.ticketService.updateTicketCompletionDetails(
          event.formValue,
          formTemplateViewFields,
          COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
          'PrimaryDetailsFieldID',
          this.PrimaryID
        );
      } else if (event.CompletionType == 'Common') {
        await this.ticketService.updateTicketCompletionDetails(
          event.formValue,
          formTemplateViewFields,
          COMPLETION_TABLE_NAMES.tbCompletions_CommonDetails,
          'CommonDetailsFieldID',
          this.PrimaryID
        );
      }
    }
  }

  async highProfileChecked(formTemplateViewFields, changedValue) {
    const setting = this.userService.getSettingValue(SettingID.HIGH_PROFILE);
    const asyncOperations = formTemplateViewFields.map(async (field) => {
      if (Object.keys(changedValue)[0] == field.key && Object.values(changedValue)[0] == 1) {
        if (field.label.replace(/\s/g, '') == setting && !this.modalOpened) {
          this.modalOpened = true;

          this.dialogBox = await this.dialog
            .open(AutologExplanationModalComponent, {
              width: '380px',
              data: { header: 'Explain High Profile' },
            })
            .afterClosed()
            .toPromise();

          if (this.dialogBox && this.dialogBox.result) {
            const newAutolog: AutologRow = {
              AssignmentID: this.AssignmentID,
              DescID: '77', // custom autolog desc id
              UserID: this.UserID,
              Explaination: this.dialogBox.result,
              logDate: this.datetimeService.localDateToDBDateStr(new Date()),
              bFieldAdded: '1',
            };

            await this.ticketService.addAutologToAssignment(newAutolog, this.AssignmentID);
            this.AutologList = await this.createAutologTabProps();
            this.AutologList = [...this.AutologList];
            return false;
          }
          return true;
        }
      }
    });

    const results = await Promise.all(asyncOperations);

    results.forEach((result) => {
      if (result === true) {
        this.modalOpened = false;
      }
    });

    return results.some((result) => result === true);
  }

  async verify(event, ignoreSuccess: boolean = false) {
    let successfulVerify = false;

    this.progressBarService.start();
    const results: any = await this.ticketService.verifyTicketBeforeComplete(this.PrimaryID, this.AssignmentID);

    const ticketType = results[0];
    const verifyDetails = results[1];

    const modalData: any = {
      header: 'Verify: Successful',
      message: ticketType,
    };
    if (verifyDetails.length > 0) {
      const billingErrorMap = verifyDetails.reduce((total, value) => {
        if (value.Type == VerifyDetailsType.Billing) {
          total = { ...total, ...value.ErrorMap };
        }
        return total;
      }, {});
      this.highlightBillingErrorAuxIDs(billingErrorMap);

      const primaryDetailsErrorMap = verifyDetails.reduce((total, value) => {
        if (value.Type == VerifyDetailsType.PrimaryDetails) {
          total = { ...total, ...value.ErrorMap };
        }
        return total;
      }, {});
      this.updatePrimaryErrorIDs(primaryDetailsErrorMap);

      const primaryErrorMap = verifyDetails.filter((row) => row.Type == VerifyDetailsType.Primary);
      if (primaryErrorMap.length > 0) {
        modalData.detailMessage = primaryErrorMap[0].ErrorMap[this.PrimaryID];
      }

      modalData.header = 'Verify: Failed';
    } else {
      this.highlightBillingErrorAuxIDs({});
      this.updatePrimaryErrorIDs({});
      successfulVerify = true;
    }

    this.progressBarService.stop();
    if (successfulVerify && ignoreSuccess) {
      return successfulVerify;
    }

    await this.dialog
      .open(ConfirmationModalComponent, {
        width: '380px',
        data: modalData,
      })
      .afterClosed()
      .toPromise();

    return successfulVerify;
  }

  private highlightBillingErrorAuxIDs(errorMap) {
    const errorKeys = Object.keys(errorMap);
    const keys = Object.keys(this.CompletionsBillingDetails);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const groups = this.CompletionsBillingDetails[key].groups;

      const groupKeys = Object.keys(groups);
      for (let j = 0; j < groupKeys.length; j++) {
        const groupKey = groupKeys[j];
        const group = groups[groupKey];

        if (errorKeys.length > 0 && errorKeys.includes(groupKey)) {
          group.isErrorEnabled = 1;
          group.ErrorMessage = errorMap[groupKey];
        } else {
          group.isErrorEnabled = 0;
          group.ErrorMessage = '';
        }
      }
    }
  }

  private updatePrimaryErrorIDs(errorMap) {
    const errorKeys = Object.keys(errorMap);
    const keys = Object.keys(this.CompletionsPrimaryDetails);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const groups = this.CompletionsPrimaryDetails[key].groups;

      const groupKeys = Object.keys(groups);
      for (let j = 0; j < groupKeys.length; j++) {
        const groupKey = groupKeys[j];
        const group = groups[groupKey];

        const fields = group.fields;
        const fieldKeys = Object.keys(fields);

        group.isErrorEnabled = 0;
        group.isErrorMessage = '';

        for (let k = 0; k < fieldKeys.length; k++) {
          const fieldKey = fieldKeys[k];
          if (errorKeys.length > 0 && errorKeys.includes(fieldKey)) {
            group.isErrorEnabled = 1;
            group.ErrorMessage = errorMap[fieldKey];
          }
        }
      }
    }
  }

  async onComplete(event) {
    if (!navigator.onLine) {
      this.snackBarService.openSnackbar('Cannot complete ticket while offline', SnackbarType.error);
      return;
    }

    const verifyResult = await this.verify(null, true);
    const timeInOutStatus = await this.ticketService.getTicketPrimaryTimeInOutStatus(this.AssignmentID, this.PrimaryID);
    const time = this.datetimeService.localDateToDBDateStr(new Date());

    if (verifyResult) {
      if (timeInOutStatus === 'End Time') {
        await this.onTimeInOutToggle({ TimeOut: time });
      }

      await this.ticketService.markTicketAsComplete(this.AssignmentID, event.locateStatusID, event.autolog);

      this.completionQueueService.enqueue(this.AssignmentID).then(() => {
        this.snackBarService.openSnackbar('Completion queued successfully', SnackbarType.success);
      });
      await this.ticketService.removeTicketDocumentsLocally(this.AssignmentID, true);

      this.ticketCloseEvent.emit();
    }
  }

  async openGoogleMapsDirections() {
    let googlemapsURL = 'https://www.google.com/maps/dir/?api=1';
    googlemapsURL = googlemapsURL
      .concat('&destination=')
      .concat(encodeURI(this.ticket.LocateAddress.concat(',').concat(this.ticket.LocateSubRegionName)));

    const a = document.createElement('a');
    document.body.appendChild(a);
    // a.style = "display: none";
    a.href = googlemapsURL;
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onTabChange(e: MatTabChangeEvent, tabGroup: MatTabGroup) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let featureManager: MapFeatureService;
    if (e.index === this.previousTabIndex) {
      return;
    }
    this._ticketMapTabSelected$$.set(e.tab === this.ticketMap);
    this.drawingService.endAllModes();
    // const action = (bool: boolean) => {
    //   if (!bool) {
    //     tabGroup.focusTab(this.previousTabIndex);
    //     tabGroup.selectedIndex = this.previousTabIndex;
    //   } else {
    //     this.drawingService.endAllModes();
    //     this.previousTabIndex = e.index;
    //   }
    // };
    // if (this !== undefined) {
    //   featureManager = this.baseMapService.featureManager;
    //   if (featureManager.hasUnsavedChanges) {
    //     this.openDialog('UNSAVED CHANGES', 'you will lose your unsaved changes if you continue', action, 'continue?');
    //   } else {
    //     this.drawingService.endAllModes();
    //     this.previousTabIndex = e.index;
    //   }
    // } else {
    //   this.previousTabIndex = e.index;
    // }
  }

  openDialog(title: string, message: string, action: (param: boolean) => void, confirmText: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: title,
        message: message,
        confirmText: confirmText,
      },
    });

    dialogRef.afterClosed().subscribe(action);
  }

  async onAssignTicketToUser(result) {
    try {
      if (result['UserID'] != null) {
        this.progressBarService.start();

        const assignResult = await this.ticketActionService.reassignTicketToUser([this.PrimaryID], result['UserID']);
        if (assignResult && assignResult[0] && assignResult[0]['Error']) {
          const errorMsg = assignResult[0]['Error'].split(':')[1];
          this.snackBarService.openSnackbar(errorMsg.toString().trim(), SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar('Success', SnackbarType.success);
          this.ticketCloseEvent.emit();
          if (this.isFieldSide) this.router.navigate(['fieldside/ticket-summary']);
        }

        this.progressBarService.stop();
      }
    } catch (error) {
      console.error(error);
    }
  }


  /**
   * Triggers when the ticket is uncompleted
   */
  async onUncompleteTicketEvent() {
    //TODO: refresh tabs
    console.log("onUncompleteTicketEvent");
    this.ticketSyncService.startSync(false, true, [this.AssignmentID.toString()]).subscribe({
      complete: async () => {
        this.ticketSyncService.closeModal();
        await this.initTabs();
      },
    });
  }

  async onCancelTicketEvent() {
    this.progressBarService.start();
    const dialogRef = this.dialog.open(SchedulerCancelTicketDialogComponent, {
      width: '400px',
      data: {
        message: 'Would you like to cancel the ticket?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.indexOf('noCancel') == -1) {
        const apiValue = {};
        apiValue[this.PrimaryID] = {
          Explanation: result,
        };

        await this.ticketService.completeTicketAs(LocateStatusID.OFFICE_CANCELLED, this.AssignmentID, this.UserID);
        this.ticketService.cancelTicket(apiValue).subscribe(async (response) => {
          this.progressBarService.stop();
          console.log(response);
          if (response && (response['Error'] || response[0]['Error'])) {
            //there was an error

            this.snackBarService.openSnackbar(
              response[0]['Error'].toString() ?? response['Error'].toString(),
              SnackbarType.error
            );
          } else {
            this.snackBarService.openSnackbar('Success', SnackbarType.success);
            this.ticketCloseEvent.emit();
            if (this.isFieldSide) this.router.navigate(['fieldside/ticket-summary']);
          }
        });
      }
    });
  }

  /**
   * Calls the clear ticket endpoint and closes the ticket
   * @param option
   */
  clearTicket(option: any) {
    if (this.ticketDocumentService.getDocumentQueueValue().length && this.userService.isSettingActive(SettingID.CHECK_DOCUMENT_MANIFEST)) {
      this.snackBarService.openSnackbar('Documents uploading or failed to upload. Please wait or sync the ticket and try again.', SnackbarType.warning);
      return;
    }
    this.progressBarService.start();
    this.ticketSyncService.startSync(true, false, [this.AssignmentID.toString()]).subscribe({
      complete: () => {
        this.ticketSyncService.closeModal();
        this.ticketService
          .callClearTicket(option ? option.ClearTypeID : this.clearOptions[0]['ClearTypeID'], this.PrimaryID.toString())
          .subscribe(
            (response) => {
              if (response && response['Error']) {
                this.snackBarService.openSnackbar(response['Error'].toString(), SnackbarType.error);
              } else {
                this.snackBarService.openSnackbar('Success', SnackbarType.success);
                //remove from the idb
                this.ticketSyncService.removeLocalTicket([this.AssignmentID.toString()]).then(() => {
                  this.ticketCloseEvent.emit(); //tell ticket-modal-component to close the modal
                  if (this.isFieldSide) this.router.navigate(['fieldside/ticket-summary']);
                });
              }
            },
            (error) => {
              // Handle error case
              console.error('An error occurred:', error);
              this.snackBarService.openSnackbar('An error occurred. Please try again.', SnackbarType.error);
            },
            () => {
              this.progressBarService.stop();
            }
          );
      },
    });
  }


  /**
   * Returns whether or not the user can clear the ticket
   * @returns
   */
  canShowClearOptions() {
    const userSettingToClear = this.userService.isSettingActive(SettingID.CLEAR_LSP_DROPDOWN_LIST);
    //if they have clear option                                       //and setting active  // and ticket is not already completed
    this.showClearOptions =
      this.clearOptions &&
      this.clearOptions.length > 0 &&
      userSettingToClear &&
      !CompletedLocateStatusIDs.includes(this.ticket.LocateStatusID);
  }

  protected getSettingValue(val: SettingID): string {
    return this.userService.getSettingValue(val);
  }

  onSwipe() {
    // // https://hammerjs.github.io/api/
    // const DIRECTIONS = {
    //   LEFT: 2,
    //   RIGHT: 4
    // };
    // console.log('test,', event);
    // if (event.type != 'swipe') {
    //   const tmpIndex = this.matTabGroup.selectedIndex;
    //   switch (event.direction) {
    //     case DIRECTIONS.LEFT:
    //       if (tmpIndex + 1 <= this.matTabGroup._allTabs.length) {
    //         this.matTabGroup.selectedIndex += 1;
    //       }
    //       break;
    //     case DIRECTIONS.RIGHT:
    //       if (tmpIndex - 1 >= 0) {
    //         this.matTabGroup.selectedIndex -= 1;
    //       }
    //       break;
    //     default:
    //       break;
    //   }
    // }
  }

  protected readonly SettingID = SettingID;
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TicketService } from "../../ticket.service";
import { MatIconModule } from '@angular/material/icon';
import { RiskCalculatorExplainerModalComponent } from "../impact-calculator-explainer-modal/risk-calculator-explainer-modal";

@Component({
  selector: "app-risk-calculator-modal",
  templateUrl: "risk-calculator-modal.html",
})
export class RiskCalculatorModalComponent {
  data: any;
  isFail: boolean;
  address: string;
  keys: string[] = [];
    
  public riskInsights = {
   'Excavator Rank': { title: 'Excavator Rank', score: '', percentage: '0', rate: '0', icon: 'excavator' },
   'Ticket Type': { title: 'Ticket Type', score: '', rate: '0', percentage: '0', icon: 'ticket_type' },
   'Location Complexity': { title: 'Location Complexity', score: '', rate: '0', percentage: '0', icon: 'location_complex' },
   'Type of Work': { title: 'Type of Work', score: '', rate: '0', percentage: '0', icon: 'work_type' }}

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any, 
    public dialog: MatDialog,
    public ticketService: TicketService,
    public dialogRef: MatDialogRef<RiskCalculatorModalComponent>
  ) {
    this.data = Data;
    this.address = this.ticketService.getAddress();
  }

  ngOnInit() {
    if(this.data && this.data.RiskInsights){
      this.riskInsights['Excavator Rank'].score = this.data.RiskInsights['excavator_name_group_risk_rank'];
      this.riskInsights['Excavator Rank'].percentage = this.data.RiskInsights['excavator_name_group_percentile'];
      this.riskInsights['Excavator Rank'].rate = this.data.RiskInsights['excavator_name_group_damage_rate'];
      this.riskInsights['Ticket Type'].score = this.data.RiskInsights['ticket_type_risk_rank'];
      this.riskInsights['Ticket Type'].percentage = this.data.RiskInsights['ticket_type_percentile'];
      this.riskInsights['Ticket Type'].rate = this.data.RiskInsights['ticket_type_damage_rate'];
      this.riskInsights['Location Complexity'].score = this.data.RiskInsights['hid8_risk_rank'];
      this.riskInsights['Location Complexity'].percentage = this.data.RiskInsights['hid8_percentile'];
      this.riskInsights['Location Complexity'].rate = this.data.RiskInsights['hid8_damage_rate'];
      this.riskInsights['Type of Work'].score = this.data.RiskInsights['work_type_cat_risk_rank'];
      this.riskInsights['Type of Work'].percentage = this.data.RiskInsights['work_type_cat_percentile'];
      this.riskInsights['Type of Work'].rate = this.data.RiskInsights['work_type_cat_damage_rate'];
    }
    this.keys = Object.keys(this.riskInsights);    
  }

 

  learnMoreClicked(risk, color) {
    this.dialog
    .open(RiskCalculatorExplainerModalComponent, {
      data: [this.riskInsights,color,risk,this.keys]
    })   
  }
  
  onConfirm() {
    this.dialogRef.close({ event: "close", result: true });
  }
}

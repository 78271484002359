<div
  class="filter-main-div"
  fxLayout="column"
  fxLayoutAlign="start"
  fxLayoutGap="16px grid"
>
  <form id="filter-form" [formGroup]="form">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap="16px grid"
    >
      <div fxFlex="100">
        <ejs-dropdownlist
          #rigFilter
          formControlName="rigFilter"
          id="rigFilter1"
          [dataSource]="rigResourceDateSource"
          [fields]="rigResourceFields"
          floatLabelType="Auto"
          placeholder="Select Rig"
          (change)="dropdownChange('rigFilter', $event.value)"
        >
        </ejs-dropdownlist>
      </div>

      <div fxFlex="100">
        <ejs-multiselect
          #peopleFilter
          formControlName="peopleFilter"
          id="peopleFilter1"
          [dataSource]="peopleResourceDataSource"
          [fields]="peopleResourceFields"
          floatLabelType="Auto"
          placeholder="Select People"
          (change)="dropdownChange('peopleFilter', $event.value)"
        >
        </ejs-multiselect>
      </div>

      <!-- <mat-form-field fxFlex='100' class="example-full-width">
        <input matInput class='mat-input-text' formControlName='cityFilter' id='cityFilter' type="text">
        <mat-placeholder>City</mat-placeholder>
      </mat-form-field> -->

      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="jobNumFilter"
          id="jobNumFilter"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">Job #</label>
      </div>

      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="customerFilter"
          id="customerFilter"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">Customer</label>
      </div>

      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="cityFilter"
          id="cityFilter"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">City</label>
      </div>

      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="addressFilter"
          id="addressFilter"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">Address</label>
      </div>

      <!-- <mat-form-field fxFlex='100' class="example-full-width">
        <input matInput class='mat-input-text' formControlName='addressFilter' id='addressFilter' type="text">
        <mat-placeholder>Address</mat-placeholder>
      </mat-form-field> -->
    </div>
  </form>

  <div fxLayout="row">
    <button
      mat-button
      color="primary"
      fxFlex="50"
      id="clearBtn"
      class=" capitalize font-semibold"
      (click)="clearFilter()"
    >
      Clear
    </button>
    <button
      mat-button
      color="primary"
      fxFlex="50"
      id="applyBtn"
      class=" capitalize font-semibold"
      (click)="applyFilter()"
    >
      Apply
    </button>
  </div>
</div>

import { Feature, Map } from 'ol';
import Style, { StyleFunction, StyleLike } from 'ol/style/Style';
import Styles from '../utilities/styles';
import units from "../utilities/styles/units"
import { LineStyle, LineThickness, ShapeType } from '../utilities/types';
import { Fill, Stroke, Text } from 'ol/style';
import { Circle, GeometryCollection, Point, Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { OpenLayersService } from './open-layers.service';
import { filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { firstValueFrom, from, fromEvent, Subject } from 'rxjs';
import { RGBA, StyleToolbox } from '../classes/style-toolbox';
import { MapSymbolsService } from './map-symbols.service';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root',
})
export class FeatureStyleService implements OnDestroy {
  private mapService = inject(OpenLayersService);
  private symbolService = inject(MapSymbolsService);
  private readonly styleToolbox = new StyleToolbox();
  private mapRef: Map;

  private destroy$ = new Subject<void>();

  constructor() {
    this.mapService.map$
      .pipe(
        filter((x) => x !== null),
        takeUntil(this.destroy$)
      )
      .subscribe((map) => (this.mapRef = map));

    units.getCurrentUnits$()
      .pipe(
        switchMap(() => from(this.mapRef?.getAllLayers() || [])),
        filter((x) => x instanceof VectorLayer),
        mergeMap((x) => from((x as VectorLayer<VectorSource>).getSource().getFeatures())),
        tap((feature: Feature) => feature.changed()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => { });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public renderFeature(feature: Feature, resolution: number) {
    if (feature.get('bIsShownInApp') === -1) {
      return Styles.invisible();
    } else {
      const shapeType = feature.get('shape_type_id');
      switch (shapeType) {
        case ShapeType.LineString:
        case ShapeType.MultiLineString:
        case ShapeType.LetterLine:
          feature.setStyle(
            Styles.defaultStyle);
          break;
        case ShapeType.Point:
          feature.setStyle(Styles.baseStyle());
          break;
        case ShapeType.Polygon:
          this.buildStyleBase(feature);
          break;
        case ShapeType.Label:
          this.addTextStyle(feature);
          break;
        case ShapeType.ArrowLineLegacy:
        case ShapeType.ArrowLine:
          this.buildLineStyle(feature, Styles.arrowLine);
          break;
        case ShapeType.Circle:
          this.buildStyleBase(feature);
          this.buildCircleStyle(feature);
          break;
        case ShapeType.Image:
          this.buildImageStyle(feature);
          break;
        case ShapeType.MeasureLine:
          this.buildLineStyle(feature, Styles.measureLine);
          break;
        case ShapeType.SvgSymbol:
        case ShapeType.LegacySymbol:
          this.buildSymbolStyle(feature);
          break;
        default:
          this.buildStyleBase(feature);
          break;
      }
    }
  }

  private buildStyleBase(feature: Feature) {
    const props = feature.getProperties();
    const style = Styles.baseStyle() as Style;
    const opacity = (props.fill_opacity || 1) * 100;
    const stroke = new Stroke({
      color: this.styleToolbox.hexToRGBA(props.stroke_colour, opacity),
      width: props.stroke_width,
      lineDash: props.stroke_dasharray?.split(',').map((x: string) => parseInt(x, 10)),
    });
    if (props.fill_colour && props.fill_colour !== '') {
      const fill = new Fill({
        color: this.styleToolbox.hexToRGBA(props.fill_colour, opacity),
      });
      style.setFill(fill);
    } else {
      style.setFill(undefined);
    }
    style.setStroke(stroke);
    feature.setStyle(style);
  }

  private buildLineStyle(feature: Feature, styleBuilder: (width: number, color: RGBA) => StyleLike) {
    try {
      const props = feature.getProperties();
      const color = this.styleToolbox.hexToRGBA(props.stroke_colour, props.fill_opacity * 100 || 100);
      const width = props.stroke_width;
      feature.setStyle(styleBuilder(width, color));
    } catch (error) {
      feature.setStyle(Styles.baseStyle());
    }
  }

  private buildCircleStyle(feature: Feature) {
    const props = feature.getProperties();
    // get radius of the geometry
    const geom = feature.getGeometry() as Point;
    const center = geom.getCoordinates() as Coordinate;
    const radius = props.radius;
    const circle = new Circle(center, radius);
    feature.setGeometry(circle);
  }

  private buildImageStyle(feature: Feature) {
    const img = new Image();
    img.src = feature.get('image_source');
    const extent = (feature.getGeometry() as Polygon).getExtent();
    const center = getCenter(extent);
    feature.setGeometry(new GeometryCollection([new Point(center), feature.getGeometry()]));
    const style = Styles.sticker(img, this.mapRef);
    feature.setStyle(style);
  }

  private addTextStyle(feature: Feature) {
    const style = Styles.text(this.mapRef, this.styleToolbox) as StyleFunction;
    feature.setStyle(style);
  }

  private addLineText(feature: Feature) {
    const text = feature.get('text_label');
    const textStyle = new Text({
      font: '12px Calibri,sans-serif',
      overflow: true,
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
      text: text,
      placement: 'line',
      repeat: 150,
    });
    (feature.getStyle() as Style).setText(textStyle);
  }

  private buildSymbolStyle(feature: Feature) {
    const symbolId = feature.get('symbol_id');
    try {
      if (symbolId) {
        if (feature.get('shape_type_id') === ShapeType.SvgSymbol) {
          const img = new Image();
          const symbol = this.symbolService.getSvgSymbolByID(symbolId);
          firstValueFrom(fromEvent(img, 'load')).then(() => feature.changed());
          img.src = symbol.src;
          const geom = feature.getGeometry();
          const extent = (geom as Polygon).getExtent();
          const center = getCenter(extent);
          const style = Styles.svgSymbol(img, this.mapRef);
          feature.setGeometry(new GeometryCollection([new Point(center), geom]));
          feature.setStyle(style);
        } else {
          const symbol = this.symbolService.getLegacySymbolByID(symbolId);
          const style = Styles.symbol(symbol, this.mapRef);
          feature.setStyle(style);
        }
      } else {
        feature.setStyle(Styles.baseStyle());
      }
    } catch (e) {
      feature.setStyle(Styles.baseStyle());
      console.warn('Error building symbol style: symbol_id = ', symbolId);
    }
  }

  buildStyleLike(
    fillColour: string,
    strokeColour: string,
    lineStyle: number,
    lineThickness: number,
    opacity: number
  ): Style {
    const toolbox = new StyleToolbox();
    return new Style({
      fill:
        fillColour == undefined || fillColour == ''
          ? undefined
          : new Fill({
            color: toolbox.hexToRGBA(fillColour, opacity),
          }),
      stroke: new Stroke({
        color: toolbox.hexToRGBA(strokeColour, opacity),
        width: lineThickness * 2,
        lineDash: [lineStyle, lineStyle].map((x) => (x % 2 === 1 ? x * 20 : x * 10)),
      }),
    });
  }

  buildUtilityLineStyle(colour: string, text: string, dashPattern: number[]): Style {
    const tempStyle = this.buildStyleLike(colour, colour, LineStyle.dashed, LineThickness.mediumLine, 100);
    tempStyle.setText(
      new Text({
        font: '12px Calibri,sans-serif',
        overflow: true,
        fill: new Fill({
          color: '#000',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3,
        }),
        text: text,
        placement: 'line',
        repeat: 150,
      })
    );
    tempStyle.getStroke().setLineDash(dashPattern);

    return tempStyle;
  }
}

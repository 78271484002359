import units from "./units"
import { Fill, Stroke, Style, Text } from 'ol/style';
import { GeometryCollection, LineString, Point, Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { ShapeType } from '../types';
import { ColourFormatOption, RGBA, StyleToolbox } from '../../classes/style-toolbox';
import { isEqual } from 'lodash-es';
import { Coordinate } from 'ol/coordinate';

const styleToolbox = new StyleToolbox();

const measureLine = (width: number, color: RGBA) => {
  const coords = {
    start: null,
    end: null,
  }

  const point = new Point([]);
  const stroke = new Stroke({ width, color });
  const fill = new Fill({
    color,
  });

  const arrowLine = new Style({ stroke: new Stroke({ width: 1, color: [0, 0, 0, 0] }) });

  const arrowStart = new Polygon([]);
  const arrowStartStyle = new Style({
    geometry: arrowStart,
    stroke,
    fill,
  });

  const arrowEnd = new Polygon([]);
  const arrowEndStyle = new Style({
    geometry: arrowEnd,
    stroke,
    fill,
  });

  const text = new Text({
    text: 'error',
    font: '2px Inter',
    justify: 'center',
    fill: new Fill({
      color: [0, 0, 0, 1],
    }),
    backgroundFill: new Fill({
      color: [255, 255, 255, 1],
    }),
    padding: [0, 0, 0, 0],
    overflow: true,
    placement: 'point',
  })

  const textStyle = new Style({
    geometry: point,
    text
  })

  const styleArr =
    [
      arrowLine,
      arrowStartStyle,
      arrowEndStyle,
      textStyle
    ];

  return (feature: Feature, resolution: number) => {
    const geometry = feature.getGeometry();
    let lineString: LineString;
    if (geometry instanceof LineString) {
      lineString = geometry;
    } else if (geometry instanceof GeometryCollection) {
      lineString = geometry.getGeometries().filter((geom) => geom instanceof LineString)[0] as LineString;
    }

    const [start, end] = [lineString.getFirstCoordinate(), lineString.getLastCoordinate()];
    if (!isEqual(coords.start, start) || !isEqual(coords.end, end)) {
      coords.start = start;
      coords.end = end;
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx) + 90 * (Math.PI / 180);
      const arrowLength = 2;
      const xGain = 7;
      const yGain = 2;

      let pointX, pointY;
      if (start[0] > end[0]) {
        pointX = start[0] + xGain;
        pointY = start[1] - yGain;
      } else {
        pointX = end[0] + xGain;
        pointY = end[1] - yGain;
      }

      point.setCoordinates([pointX, pointY]);

      updateArrowStyle(arrowEnd, end, arrowLength, rotation);
      updateArrowStyle(arrowStart, start, arrowLength, rotation + Math.PI);
    }

    feature.setProperties(
      {
        shape_type_id: ShapeType.MeasureLine,
        stroke_colour: styleToolbox.convertColour(color, ColourFormatOption.hex),
        fill_colour: styleToolbox.convertColour(color, ColourFormatOption.hex),
        fill_opacity: Array.isArray(color) ? color[3] : 1,
      },
      true
    );

    if ([null, undefined].includes(feature.get('text_label'))) {
      const t = String(Math.floor(lineString.getLength() * units.getUnitDict()[units.getCurrentUnits$().value].factor)) + units.getCurrentUnits$().value;
      text.setText(t);
    } else {
      text.setText(feature.get('text_label'));
    }

    text.setScale(1 / resolution);

    return styleArr;
  }
}

function updateArrowStyle(arrow: Polygon, coordinate: Coordinate, length: number, rotation: number) {
  arrow.setCoordinates(
    [
      [
        coordinate,
        [coordinate[0] - length / 2, coordinate[1] - length],
        [coordinate[0] - length / 6, coordinate[1] - length],
        [coordinate[0] - length / 6, coordinate[1] - length * 2],
        [coordinate[0] + length / 6, coordinate[1] - length * 2],
        [coordinate[0] + length / 6, coordinate[1] - length],
        [coordinate[0] + length / 2, coordinate[1] - length],
        coordinate,
      ],
    ]
  );
  arrow.rotate(rotation, coordinate);
}

export default measureLine;

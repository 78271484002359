<div *ngIf="dataReady" class="bottom-divider">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="text-[20px]  capitalize p-2">Customer Info</span>
  </div>
</div>

<!-- Company information -->
<div *ngIf="dataReady" class="bottom-divider">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="margin-left: 8px; margin-right: 8px"
  >
    <span class=" text-lg capitalize text-primary text-semibold p-2" any>Company</span>
    <button
      *ngIf="props.AssignmentID < 0"
      [disabled]="!viewReady"
      mat-icon-button
      aria-label="edit company contact"
      (click)="editCompanyClick($event, slideOverPanel)"
    >
      <mat-icon mat-list-icon>edit</mat-icon>
    </button>

    <button
      *ngIf="props.AssignmentID > -1"
      [disabled]="
        !viewReady || TICKET_PROTECTION_MODE || props.isU2TicketComplete
      "
      mat-icon-button
      aria-label="edit company contact"
      [matMenuTriggerFor]="companyActions"
    >
      <mat-icon mat-list-icon>more_vert</mat-icon>
      <mat-menu #companyActions="matMenu">
        <button
          class=""
          mat-menu-item
          (click)="editCompanyClick($event, slideOverPanel)"
        >
          Edit Company
        </button>
        <button
          class=""
          mat-menu-item
          (click)="switchCompanyClick($event, slideOverPanel)"
        >
          Switch Company
        </button>
      </mat-menu>
    </button>
  </div>
  <ng-container *ngFor="let e of excavator">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start start"
      class="heading-padding text-padding-container"
    >
      <div
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"d
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Company Name: <span class="text-base  font-normal">{{ e.ExcavatorName }}</span>
      </div>
      <div
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Company City: <span class="text-base  font-normal">{{ e.ExcavatorCity }}</span>
      </div>
      <div
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Company Address:
        <span class="text-base  font-normal">{{ e.ExcavatorAddress }}</span>
      </div>
      <div
        *ngIf="!multipleExcavatorContacts"
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Contact Name: <span class="text-base  font-normal">{{ e.ContactName }}</span>
      </div>
      <div
        *ngIf="!multipleExcavatorContacts"
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Contact Email: <span class="text-base  font-normal">{{ e.Email }}</span>
      </div>
      <div
        *ngIf="!multipleExcavatorContacts"
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Contact Phone Number:
        <span class="text-base  font-normal">{{ e.PhoneNumber }}</span>
      </div>
      <div
        *ngIf="!multipleExcavatorContacts"
        fxFlex.xl="50"
        fxFlex.lg="50"
        fxFlex.md="50"
        fxFlex.lt-md="100"
        class="text-padding"
      >
        Fax Number: <span class="text-base  font-normal">{{ e.FaxNumber }}</span>
      </div>
    </div>
  </ng-container>
</div>

<!-- contacts -->
<div
  *ngIf="!dataReady && loadingData && multipleExcavatorContacts"
  class="mx-2 pb-4 w-[calc(100%-16px)]"
>
  Loading...
</div>

<div *ngIf="dataReady && multipleExcavatorContacts" class="mx-2 w-[calc(100%-16px)]">
  <div class="flex space-x-4 place-content-between">
    <span class="text-lg  capitalize text-primary p-2">Contacts</span>
    <button
      *ngIf="isCreateTicket"
      color="primary"
      mat-raised-button
      (click)="createContactClick($event, slideOverPanel)"
      class=""
    >
      CREATE CONTACT
    </button>
    <button
      *ngIf="props.AssignmentID > -1"
      [disabled]="
        !viewReady || TICKET_PROTECTION_MODE || props.isU2TicketComplete
      "
      mat-icon-button
      aria-label="edit contact"
      [matMenuTriggerFor]="contactActions"
    >
      <mat-icon mat-list-icon>more_vert</mat-icon>
      <mat-menu #contactActions="matMenu">
        <button
          mat-menu-item
          [disabled]="
            !viewReady || TICKET_PROTECTION_MODE || props.isU2TicketComplete
          "
          (click)="createContactClick($event, slideOverPanel)"
          class=""
        >
        CREATE CONTACT
         </button>

        <button
          mat-menu-item
          [disabled]="
            !viewReady || TICKET_PROTECTION_MODE || props.isU2TicketComplete
          "
          (click)="toggleShowContacts()"
        >
          {{ toggleContactsBtnText }}
        </button>
      </mat-menu>
    </button>
  </div>
  <!-- <span class="left-padding-default">
    Selected Contact(s): {{contactList.selectedOptions.selected.length}}
  </span> -->
  <mat-selection-list
    (selectionChange)="onContactListChange($event)"
    #contactList
  >
    <ng-container *ngFor="let contact of contacts">
      <div
        [ngClass]="{ hidden: !(this.showAllContacts || option.selected) }"
        fxLayout="row wrap"
        fxLayoutAlign="space-between center"
        class="border-t-0 border-l-0 border-r-0 border-solid border-[1px] border-gray-300 hover:bg-black-100"
      >
        <div fxFlex fxFlex.lt-sm="70">
          <mat-list-option
            #option
            [disabled]="TICKET_PROTECTION_MODE || props.isU2TicketComplete"
            checkboxPosition="before"
            class="hover:bg-black-100"
            [value]="contact"
          >
            <div
            class="w-full flex flex-row place-content-start items-center">
              <span
                fxFlex="33"
                fxFlex.md="50"
                fxFlex.sm="50"
                fxFlex.xs="100"
                class=" font-medium text-lg"
                >{{ contact.ContactName }}</span
              >
              <span class=" font-medium text-lg" 
              fxFlex="33" fxFlex.md="50" [fxHide.lt-md]="true">{{
                contact.PhoneNumber
              }}</span>
              <span class=" font-medium text-lg" 
              fxFlex="33" [fxHide.lt-lg]="true"
                >{{ contact.Email }}
              </span>
            </div>
            <!-- <ng-container *ngFor="let kvp of contact | keyvalue">
              {{kvp.value}}
            </ng-container> -->
          </mat-list-option>
        </div>
        <div dir="rtl" fxFlex.lt-sm="30">
          <button
            [disabled]="TICKET_PROTECTION_MODE || props.isU2TicketComplete"
            mat-icon-button
            aria-label="remove contact"
            (click)="removeContactClick(contact)"
          >
            <mat-icon mat-list-icon color="warn">close</mat-icon>
          </button>
          <button
            [disabled]="TICKET_PROTECTION_MODE || props.isU2TicketComplete"
            mat-icon-button
            aria-label="edit contact"
            (click)="editContactClick(contact, slideOverPanel)"
          >
            <mat-icon mat-list-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </mat-selection-list>
</div>

<app-slideover-side-panel
  #slideOverPanel
  [props]="slideoverProps"
></app-slideover-side-panel>

<div class="flex flex-row justify-start space-x-2 items-center">
  @if (showTagTitle && tags && tags.length > 0) {
    <div class="text-sm font-normal text-[#839296] capitalize h-4">Tags</div>
  }
  @if (ticketDamageThreatScore != null) {
    <div class="mr-1">
      <app-risk-score-cell [score]="ticketDamageThreatScore" />
    </div>
  }
  @if (ticketImpactScore != null) {
    <div class="mr-1">
      <app-risk-score-cell [score]="ticketImpactScore" />
    </div>
  }
  <div
    class="flex justify-center items-center px-4 rounded-full h-5"
    *ngFor="let item of tags"
    [ngStyle]="{ 'background-color': item.color, color: item.textColor }">
    <span class="text-[9px] text-center capitalize text-nowrap">
      {{ item.value }}
    </span>
  </div>
  <p *ngIf="remainingTags > 0" class="mat-body-xs">+{{ remainingTags }}</p>
</div>

import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, NgModule, OnInit } from '@angular/core';
import {RoundProgressModule} from 'angular-svg-round-progressbar';

@Component({
  selector: 'app-percentage-slider',
  templateUrl: './percentage-slider.component.html',
  styleUrls: ['./percentage-slider.component.scss'],
})
export class PercentageSliderComponent implements OnInit {
  @Input() score: number = 0; // Default score
  @Input() color: string = '#238914';


  ngOnInit() {
  }


}


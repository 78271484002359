<div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%" class="example-div">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
    fxFlex="88"
    (click)="
      selected.emit({
        NodeID: nodeID,
        ParentNodeID: parentNodeID,
        CriteriaTypeID: criteriaTypeID,
        ChildCriteriaTypeID: childCriteriaTypeID,
        Values: values,
        Rate: rate,
        RateName: RateName,
        Children: children,
        nodeTrace: nodeTrace,
        readableValues: readableValues
      })
    ">
    <!--changed 16px to 12px-->
    <mat-icon [svgIcon]="icon" />
    <span class=" font-normal text-md">
      <span *ngIf="parentNodeID > 0">{{ criteriaName }}: </span>
      <span>{{ readableValues.join(', ') }}</span>
      <span *ngIf="rate"> Rate: ${{ rate }}</span>
    </span>
  </div>
  <!--reorganized the add and three dots button. Adding class name to allign them to the right-->
  <div fxLayout="row" fxLayoutAlign="end center" class="add_dots_button -mr-28">
    <!--add button-->
    <button
      *ngIf="!rate && !RateName"
      mat-mini-fab
      class="add_button"
      (click)="
        add.emit({
          NodeID: nodeID,
          ParentNodeID: parentNodeID,
          CriteriaTypeID: criteriaTypeID,
          ChildCriteriaTypeID: childCriteriaTypeID,
          Values: values,
          Rate: rate,
          RateName: RateName,
          Children: children,
          nodeTrace: nodeTrace,
          readableValues: readableValues
        })
      ">
      <mat-icon svgIcon="add" />
    </button>
    <!--vertical dots button. Reorganized pulling the button and mat-menu tags at the top here.-->
    <button class="more_vertical_button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="submenu_vertical" />
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="
          copyEmitter.emit({
            NodeID: nodeID,
            ParentNodeID: parentNodeID,
            CriteriaTypeID: criteriaTypeID,
            ChildCriteriaTypeID: childCriteriaTypeID,
            Values: values,
            Rate: rate,
            RateName: RateName,
            Children: children,
            nodeTrace: nodeTrace,
            readableValues: readableValues
          })
        "
        *ngIf="parentNodeID == 0">
        <mat-icon svgIcon="copy"/>
        <span class="mat-subtitle-1">Copy</span>
      </button>
      <button
        mat-menu-item
        (click)="
          pasteEmitter.emit({
            NodeID: nodeID,
            ParentNodeID: parentNodeID,
            CriteriaTypeID: criteriaTypeID,
            ChildCriteriaTypeID: childCriteriaTypeID,
            Values: values,
            Rate: rate,
            RateName: RateName,
            Children: children,
            nodeTrace: nodeTrace,
            readableValues: readableValues
          })
        "
        *ngIf="parentNodeID == 0">
        <mat-icon svgIcon="paste" />
        <span class="mat-subtitle-1">Paste</span>
      </button>
      <button
        mat-menu-item
        (click)="
          delete.emit({
            NodeID: nodeID,
            ParentNodeID: parentNodeID,
            CriteriaTypeID: criteriaTypeID,
            ChildCriteriaTypeID: childCriteriaTypeID,
            Values: values,
            Rate: rate,
            RateName: RateName,
            Children: children,
            nodeTrace: nodeTrace,
            readableValues: readableValues
          })
        "
        *ngIf="parentNodeID > 0">
        <mat-icon svgIcon="trash" />
        <span class="mat-subtitle-1">Delete</span>
      </button>
      <button
        mat-menu-item
        (click)="
          selected.emit({
            NodeID: nodeID,
            ParentNodeID: parentNodeID,
            CriteriaTypeID: criteriaTypeID,
            ChildCriteriaTypeID: childCriteriaTypeID,
            Values: values,
            Rate: rate,
            RateName: RateName,
            Children: children,
            nodeTrace: nodeTrace,
            readableValues: readableValues
          })
        "
        *ngIf="parentNodeID > 0">
        <mat-icon svgIcon="edit" />
        <span class="mat-subtitle-1">Edit</span>
      </button>
    </mat-menu>
  </div>
</div>

<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  style="height: 100%; overflow-x: hidden"
  (swiperight)="onSwipe($event)"
  (swipeleft)="onSwipe($event)">
  <div class="flex mt-3 mb-6">
    <label for="default-search" class="mb-2 text-sm  font-medium text-[#9d9d9d] sr-only">Search</label>
    <div class="relative w-[95%] ml-6 max-h-10">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none pb-[6px]">
        <mat-icon class="w-5 h-5 text-gray-500 dark:text-gray-400" svgIcon="search"></mat-icon>
      </div>
      <input
        type="search"
        id="default-search"
        class="block w-full p-[9px] ps-10 text-lg rounded-lg placeholder-[#9d9d9d] text-gray-900 border-0 bg-[#f1f3f4] focus:ring-primary focus:border-primary"
        placeholder="Search"
        [(ngModel)]="searchText"
        #searchbar
        required />
    </div>
    <!-- //TODO: add filtering for autologs -->
    <button class="filter mt-[-8px]" mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu for filtering">
      <mat-icon class="text-black">filter_list</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="flex flex-col justify-start items-start w-fit max-w-56">
        <button class="appearance-none border-none bg-transparent hover:bg-gray-200/50 w-full gap-3 min-h-[32px]"
                (click)="filters[0] = !filters[0]" appClickStopPropagation>
          <div class="box-border w-full flex flex-row justify-between items-center px-1.5">
          <span class="text-md  capitalize font-semibold">
          TICKET EXAMINED
        </span>
            <app-competers-checkbox [(ngModel)]="filters[0]" appClickStopPropagation />
          </div>
        </button>
        <button class="appearance-none border-none bg-transparent hover:bg-gray-200/50 w-full gap-3 min-h-[32px]"
                (click)="filters[1] = !filters[1]" appClickStopPropagation>
          <div class="box-border w-full flex flex-row justify-between items-center px-1.5">
          <span class="text-md  capitalize font-semibold">
          TICKET PROTECTION
        </span>
            <app-competers-checkbox [(ngModel)]="filters[1]" appClickStopPropagation />
          </div>
        </button>
        <button class="appearance-none border-none bg-transparent hover:bg-gray-200/50 w-full min-h-[32px]"
                (click)="filters[2] = !filters[2]" appClickStopPropagation>
          <div class="box-border w-full flex flex-row justify-between items-center gap-3 px-1.5">
          <span class="text-md  capitalize font-semibold">
          DOWNLOAD/UPLOAD
        </span>
            <app-competers-checkbox [(ngModel)]="filters[2]" appClickStopPropagation />
          </div>
        </button>
      </div>
    </mat-menu>
  </div>

  <cdk-virtual-scroll-viewport
    *ngIf="AutologList && AutologList.length > 0"
    itemSize="80"
    class="h-full">
    <div *cdkVirtualFor="let group of orderedAutologList | filterAutolog: searchText; let i = index">
      <span class="ml-4 text-[12px]">{{ group.date | date: 'EEEE dd MMM yyyy' }}</span>
      <hr class="border-primary" style="width: 100%; margin: 0px" />
      <div *ngFor="let item of group.list">
        <app-ticket-autologs-item [autolog]="item"></app-ticket-autologs-item>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<floating-action-menu
  type="fab"
  (fabOnClick)="openCreateNewAutolog()"></floating-action-menu>

import { Component, Inject, inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonDropdownSelectComponent,
} from '../../shared/components/inputs/button-dropdown-select/button-dropdown-select-select.component';
import units, { MapUnits } from './utilities/styles/units';
import { AsyncPipe } from '@angular/common';

export type MapSettings = {
  pinUserLocation: boolean;
  pinTicketLocation: boolean;
  units: MapUnits;
};

@Injectable({
  providedIn: 'root',
})
export class DrawingSettingsService implements OnDestroy {
  // services
  private dialogService = inject(MatDialog);

  // observables
  private _mapSettings$: BehaviorSubject<MapSettings> = new BehaviorSubject<MapSettings>({
    pinUserLocation: false,
    pinTicketLocation: false,
    units: 'm',
  });
  private destroy$: Subject<void> = new Subject<void>();

  // members

  constructor() {
    // check local storage for map settings and update units if found
    localStorage.getItem('mapSettings') && this._mapSettings$.next(JSON.parse(localStorage.getItem('mapSettings')));

    this._mapSettings$.pipe(takeUntil(this.destroy$)).subscribe((next) => {
      // save to local storage
      localStorage.setItem('mapSettings', JSON.stringify(next));
      units.updateUnits(next.units);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  triggerSettingsDialog() {
    const dialog = this.dialogService.open(MapSettingsDialogComponent, {
      width: 'fit-content',
      height: 'fit-content',
      panelClass: ['mat-dialog-overflow', 'p-6'],
      data: this._mapSettings$.value,
    });

    firstValueFrom(dialog.afterClosed()).then((result) => {
      if (result) {
        this._mapSettings$.next(result);
      }
    });
  }

  get mapSettings$() {
    return this._mapSettings$.pipe()
  }

  get mapSettings() {
    return this._mapSettings$.value
  }

}

@Component({
  selector: 'app-map-settings-dialog',
  template: `
    <div class="flex flex-col p-3 justify-center items-center m-auto">
      <h2 class="text-headline-6  font-semibold capitalize">Map Settings</h2>
      <div class="size-fit flex flex-col gap-6 justify-start items-start">
        <label class="flex flex-row justify-between w-64 items-center cursor-pointer">
          <span class="ms-3 text-sm font-medium text-gray-900">Pin User Location</span>
          <input [formControl]="pinUserLocation" type="checkbox" value="" class="sr-only peer" />
          <div
            class="relative w-11 h-6 bg-gray-200 outline-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
        </label>
        <label class="flex flex-row justify-between w-64 items-center cursor-pointer">
          <span class="ms-3 text-sm font-medium text-gray-900">Pin Ticket Location</span>
          <input [formControl]="pinTicketLocation" type="checkbox" value="" class="sr-only peer" />
          <div
            class="relative w-11 h-6 bg-gray-200 outline-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
        </label>
        <div class="relative w-full">
          <button
            id="dropdownRadioButton"
            data-dropdown-toggle="dropdownDefaultRadio"
            class="cursor-pointer outline-0 appearance-none border-none w-64 flex flex-row justify-between items-center text-white bg-primary hover:bg-primary font-medium rounded-lg text-sm px-5 py-2.5"
            type="button"
            (click)="showUnitsDropdown = !showUnitsDropdown">
            <span class="text-white  font-semibold capitalize text-lg">Units: '{{ data.units }}'</span>
            <svg
              class="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <!-- Dropdown menu -->
          <div
            tabindex="{{ -1 }}"
            class="fixed z-10 top-0 left-0 w-screen h-screen {{ showUnitsDropdown ? 'block' : 'hidden' }}"
            (keydown.escape)="showUnitsDropdown = false"
            (click)="showUnitsDropdown = false"></div>
          <div
            id="dropdownDefaultRadio"
            class="absolute w-64 {{
              showUnitsDropdown ? 'h-auto' : 'h-0'
            }} z-20 top-0 left-0 bg-white divide-y divide-gray-100 rounded-lg shadow transition-all ease-in-out overflow-hidden">
            <ul class="list-none p-3 space-y-3 text-sm text-gray-700" aria-labelledby="dropdownRadioButton">
              @for (unit of getUnits(); track $index) {
                <li>
                  <div class="flex items-center">
                    <input
                      [formControl]="units"
                      id="{{ unit + $index }}"
                      type="radio"
                      value="{{ unit }}"
                      name="unit-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" />
                    <label for="{{ unit + $index }}" class="ms-2 text-sm font-medium text-gray-900">{{ unit }}</label>
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>
        <div class="w-full flex flex-row gap-6 justify-end items-center">
          <button
            type="button"
            class="flex justify-center items-center appearance-none border-none bg-transparent py-2 px-4 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn  font-semibold capitalize"
            (click)="dialogRef.close(false)">
            cancel
          </button>
          <button
            type="button"
            class="flex justify-center items-center appearance-none rounded border-solid border-2 border-primary bg-primary py-2 px-4 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white  font-semibold capitalize"
            (click)="dialogRef.close(data)">
            save
          </button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [ReactiveFormsModule, ButtonDropdownSelectComponent, AsyncPipe],
})
export class MapSettingsDialogComponent implements OnInit {
  protected pinUserLocation = new FormControl(false);
  protected pinTicketLocation = new FormControl(false);
  protected units = new FormControl('m');
  protected showUnitsDropdown = false;

  constructor(
    public dialogRef: MatDialogRef<MapSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MapSettings,
  ) {
  }

  ngOnInit() {
    this.pinUserLocation.setValue(this.data.pinUserLocation);
    this.pinTicketLocation.setValue(this.data.pinTicketLocation);
    this.units.setValue(this.data.units);

    this.pinUserLocation.valueChanges.subscribe((next) => {
      this.data['pinUserLocation'] = next;
    });
    this.pinTicketLocation.valueChanges.subscribe((next) => {
      this.data['pinTicketLocation'] = next;
    });
    this.units.valueChanges.subscribe((next: MapUnits) => {
      this.data['units'] = next;
    });
  }

  protected readonly getUnits = units.getUnits;
}

<div #mapParent class="h-full w-full relative">
  <app-map-body (mapReady)="mapReady($event)"></app-map-body>
  <!--  button overlay  -->
  <div
    class="box-border flex flex-col justify-between items-center w-full h-full absolute z-20 top-0 left-0 p-6 pointer-events-none">
    <!--    top    -->
    <div class="flex flex-row justify-between items-start w-full">
      <!--      left    -->
      <div class="mt-16 flex flex-col justify-start items-center z-30 space-y-1">
        @if (
          dispatchAreasEnabled &&
          userService.isSettingActive(SettingID.MODIFY_DISPATCH_AREAS) &&
          (myService.menuSelection$ | async).includes(0)
        ) {
          <app-dispatch-area-editor class="pointer-events-auto"></app-dispatch-area-editor>
        }
        @if (
          dispatchAreasEnabled &&
          userService.isSettingActive(SettingID.DISPATCH_AREA_ROUTING_MANAGEMENT) &&
          (myService.menuSelection$ | async).includes(1)
        ) {
          <app-route-user-editor class="pointer-events-auto"></app-route-user-editor>
        }
      </div>
      <!--      right    -->
      <div class="flex flex-col gap-3 justify-start items-end flex-grow">
        <app-layer-switcher [layers]="myService.clientLayers$"></app-layer-switcher>
        <div class="zoomies flex flex-col items-center justify-center rounded overflow-hidden">
          <button (click)="myService.zoomIn()">
            <mat-icon svgIcon="add" />
          </button>
          <button (click)="myService.zoomOut()">
            <mat-icon svgIcon="minus" />
          </button>
        </div>
        <button (click)="myService.flyToUserLocation()">
          <mat-icon svgIcon="location" />
        </button>
      </div>
    </div>
    <!--    bottom    -->
    <div class="flex flex-row justify-between items-end w-full">
      <!--      left    -->
      <div class="flex justify-start items-center flex-grow">
        <app-floating-map-menu [options]="menuButtons">
          <!--          pin colour sub-menu         -->
          <app-pin-color-popup
            [myService]="myService"
            [sections]="sections"
            (openTicketPinLegend)="openTicketPinLegend()" />
        </app-floating-map-menu>
      </div>
      <!--      right    -->
    </div>
  </div>
</div>

<div #ticketInspector class="w-fit h-fit">
  <app-map-feature-inspector selfClosing="true" (closed)="myService.closeTicketPreview()">
    @if (myService.selectedTicket$ | async) {
      <div class="flex flex-col w-full gap-3 h-full">
        <div class="flex flex-col">
          <div class="h-7 flex flex-row justify-between items-start">
            <div class="flex flex-col">
            <span class="h-full text-lg align-top leading-[1.25rem] capitalize font-semibold">
              {{
                myService.selectedTicket['Call Type']
                  ? myService.selectedTicket['Call Type']
                  : myService.selectedTicket['TypeOfWork']
              }}
            </span>
            <span class="h-full text-md align-top leading-[1.25rem] text-[#839296] capitalize font-medium">
              #{{
                myService.selectedTicket['RequestNumber']
                  ? myService.selectedTicket['RequestNumber']
                  : myService.selectedTicket['RequestNum']
              }}
            </span>
          </div>
            <span class="h-full text-sm capitalize font-medium mr-6">
              {{
                myService.selectedTicket['Work to Begin Date'] ?? myService.selectedTicket['ExcavationDate'] | popupDate
              }}
            </span>
          </div>
          <div class="flex flex-col gap-[6px] pb-2 pt-4">
          <div class="flex flex-row justify-between items-center w-full" *ngIf="myService.checkSetting('DAMAGE THREAT')">
            <span class="capitalize text-md">Damage Threat</span>
              <app-risk-score-cell class="pr-[7px]" [score]="myService.selectedTicket['DamageThreat'] ? myService.selectedTicket['DamageThreat'] : myService.selectedTicket['Damage Threat']"></app-risk-score-cell>
          </div>
          <div class="flex flex-row justify-between items-center w-full" *ngIf="myService.checkSetting('IMPACT')">
            <span class="capitalize text-md">Impact</span>
              <app-risk-score-cell class="pr-[7px]" [score]="myService.selectedTicket['Impact']"></app-risk-score-cell>
          </div>
        </div>
          <hr class="w-full h-[2px] mx-auto bg-[#E9E9E9] mt-[1px] border-0 rounded-sm">
          <div class="info-rows">
            <div>
              <mat-icon class="flex-none" svgIcon="home"></mat-icon>
              {{
                myService.selectedTicket['LocateAddress']
                  ? (myService.selectedTicket['StartHouseNumber']
                      ? myService.selectedTicket['StartHouseNumber'] + ' '
                      : '') +
                    myService.selectedTicket['LocateAddress'] +
                    (myService.selectedTicket['LocateSubRegionName']
                      ? ', ' + myService.selectedTicket['LocateSubRegionName']
                      : '')
                  : (myService.selectedTicket['House #'] ? myService.selectedTicket['House #'] + ' ' : '') +
                    (myService.selectedTicket['Street Name'] ? myService.selectedTicket['Street Name'] : '') +
                    (myService.selectedTicket['City'] ? ', ' + myService.selectedTicket['City'] : '')
              }}
            </div>
            <div>
              @if (myService.selectedTicket['Excavator']) {
                <mat-icon class="flex-none" svgIcon="excavate"></mat-icon>
                {{ myService.selectedTicket['Excavator'] }}
              } @else {
                <mat-icon class="flex-none" svgIcon="phone"></mat-icon>
                {{ myService.selectedTicket['CallerName'] }}
              }
            </div>
            <div *ngIf="!myService.selectedTicket['RequestNumber']">
              <mat-icon class="flex-none" svgIcon="worker"></mat-icon>
              {{ myService.selectedTicket['Assigned Name'] }}
            </div>
            <div>
              <mat-icon class="flex-none" svgIcon="info"></mat-icon>
              {{
                myService.selectedTicket['Trans. Type']
                  ? myService.selectedTicket['Trans. Type']
                  : myService.selectedTicket['TypeOfWork']
              }}
            </div>
          </div>
        </div>
        @if (canReassignTickets) {
          <div class="w-full">
            <app-searchable-dropdown
              class="w-full"
              [title]="'reassign'"
              [multiple]="false"
              [options]="myService.locatorOptions$ | async"
              [(ngModel)]="selectedLocatorID"></app-searchable-dropdown>
          </div>
        }
        <div class="flex flex-row justify-center w-full gap-4">
          @if (canReassignTickets) {
            <button
              class="min-w-16 flex justify-center items-center py-1 px-3 appearance-none border-none pointer-events-auto hover:cursor-pointer bg-primary  capitalize text-lg font-semibold rounded text-white"
              (click)="reassignTicketClick([myService.selectedTicket.SubNum, selectedLocatorID[0].value])">
              Assign
            </button>
          }
          <button
            class="min-w-16 flex justify-center items-center py-1 px-3 appearance-none border-none pointer-events-auto hover:cursor-pointer bg-primary  capitalize text-lg font-semibold rounded text-white"
            (click)="
              openTicket.emit({
                AssignmentID: myService.selectedTicket.AssignmentID,
                PrimaryID: myService.selectedTicket.SubNum
              })
            ">
            Open
          </button>
        </div>
      </div>
    }
  </app-map-feature-inspector>
</div>

<div #ticketPreview class="w-fit h-fit">
  <app-map-feature-inspector>
    @if (myService.previewTicket$ | async) {
      <div class="w-full h-7 flex flex-row justify-between items-start">
        <div class="flex flex-col">
        <span class="h-full text-lg align-top leading-[1.25rem] capitalize font-semibold ">
          {{
            myService.previewTicket['Call Type']
              ? myService.previewTicket['Call Type']
              : myService.previewTicket['TypeOfWork']
          }}
        </span>
        <span class="h-full text-md align-top leading-[1.25rem] text-[#839296] capitalize font-medium">
          #{{
            myService.previewTicket['RequestNumber']
              ? myService.previewTicket['RequestNumber']
              : myService.previewTicket['RequestNum']
          }}
        </span>
      </div>
        <span class="h-full text-sm capitalize font-medium mr-3">
          {{ myService.previewTicket['Work to Begin Date'] ?? myService.previewTicket['ExcavationDate'] | popupDate }}
        </span>
     
      </div>
      <div class="flex flex-col gap-[6px] pb-2 pt-4 w-full">
        <div class="flex flex-row justify-between items-center w-full" *ngIf="myService.checkSetting('DAMAGE THREAT')">
          <span class="capitalize text-md">Damage Threat </span>
            <app-risk-score-cell class="pr-[7px]" [score]="myService.previewTicket['DamageThreat'] ? myService.previewTicket['DamageThreat'] : myService.previewTicket['Damage Threat']"></app-risk-score-cell>
        </div>
        <div class="flex flex-row justify-between items-center w-full" *ngIf="myService.checkSetting('IMPACT')">
          <span class="capitalize text-md">Impact </span>
            <app-risk-score-cell class="pr-[7px]" [score]="myService.previewTicket['Impact']"></app-risk-score-cell>
        </div>
      </div>
      <hr class="w-full h-[2px] mx-auto bg-[#E9E9E9] mt-[1px] border-0 rounded-sm">
      <div class="flex flex-row justify-start items-center w-full">
        <mat-icon class="flex-none" svgIcon="home"></mat-icon>
        <span class="text-sm ml-[6px]">
          {{
            myService.previewTicket['LocateAddress']
              ? (myService.previewTicket['StartHouseNumber'] ? myService.previewTicket['StartHouseNumber'] + ' ' : '') +
                myService.previewTicket['LocateAddress'] +
                (myService.previewTicket['LocateSubRegionName']
                  ? ', ' + myService.previewTicket['LocateSubRegionName']
                  : '')
              : (myService.previewTicket['House #'] ? myService.previewTicket['House #'] + ' ' : '') +
                (myService.previewTicket['Street Name'] ? myService.previewTicket['Street Name'] : '') +
                (myService.previewTicket['City'] ? ', ' + myService.previewTicket['City'] : '')
          }}
        </span>
      </div>
    }
  </app-map-feature-inspector>
</div>

<div #locatorCheckin class="w-fit h-fit">
  <app-map-feature-inspector>
    @if (myService.locatorCheckin$ | async) {
      <span class=" font-semibold text-headline-6 capitalize mb-1">Locator Check-in</span>
      <div class="flex flex-row justify-between items-center w-full">
        <mat-icon svgIcon="calendar"></mat-icon>
        <span class="text-sm capitalize font-semibold">
          {{ myService.locatorCheckin[1] | date: 'LLL, d h:mm a' }}
        </span>
      </div>
      <div class="flex flex-row justify-between items-center w-full">
        <mat-icon svgIcon="worker"></mat-icon>
        <span class="text-md ">
          {{ myService.locatorCheckin[0] }}
        </span>
      </div>
    } @else {
      <div>locator check-in</div>
    }
  </app-map-feature-inspector>
</div>

<div #inspector class="size-fit">
  <app-map-feature-inspector>
    <app-inspector-content [data]="inspectorData"></app-inspector-content>
  </app-map-feature-inspector>
</div>

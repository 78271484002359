import { Injectable } from "@angular/core";
import { isEqual } from "lodash-es";
import { ADMIN_TABLE_NAMES } from "../admin/tables";
import {
  BaseAdminCacheService,
  CacheWhereClause,
  CacheWhereClauseType,
} from "./cache.interface";
import { GraphicInfo } from "./GraphicsInterface";

@Injectable({
  providedIn: "root",
})
export class UtilocateAdminCacheService {
  constructor(private baseAdminCacheService: BaseAdminCacheService) {}

  async queryTableNames(tablenames: string[]) {
    const existingTableKeys = await this.baseAdminCacheService.Store.keys();
    return isEqual(tablenames, existingTableKeys);
  }

  async tableCached(tablename: string) {
    const existingTableKeys = await this.baseAdminCacheService.Store.keys();
    return existingTableKeys.includes(tablename);
  }

  async queryTable(
    tablename: string,
    where?: CacheWhereClause[]
  ): Promise<Record<string, any>[] | Error> {
    const result: Record<string, any>[] | Error =
      await this.baseAdminCacheService.queryTableData(tablename, where);
    if (!(result instanceof Error)) {
      return result;
    } else {
      throw result;
    }
  }

  async queryTableDataByColumns(
    tablename: string,
    columns: string[],
    where?: CacheWhereClause[]
  ): Promise<string[] | Error> {
    const result = [];
    try {
      const tableData: Record<string, any>[] | Error = await this.queryTable(
        tablename,
        where
      );
      if (!(tableData instanceof Error)) {
        for (let i = 0; i < tableData.length; i++) {
          const tableRow: Record<string, any> = tableData[i];
          const rowColumnValues = [];

          for (let j = 0; j < columns.length; j++) {
            if (tableRow[columns[j]]) {
              // column value found
              // add to row-value-array
              rowColumnValues.push(tableRow[columns[j]]);
            }
          }

          if (rowColumnValues.length > 0) {
            // add row-value-array to rows-value-array
            result.push(rowColumnValues);
          }
        }

        return result;
      } else {
        throw tableData;
      }
    } catch (error) {
      return error;
    }
  }

  async createAdminIDToDescMap(
    tablename: string,
    IDColumn: string,
    DescColumn: string
  ) {
    const mapping = {};
    const tableData: Record<string, any>[] | Error = await this.queryTable(
      tablename
    );
    if (!(tableData instanceof Error)) {
      for (let i = 0; i < tableData.length; i++) {
        const tableRow = tableData[i];
        const tableRowIDColumnValue = tableRow[IDColumn];
        const tableRowDescColumnValue = tableRow[DescColumn];
        mapping[tableRowIDColumnValue] = tableRowDescColumnValue;
      }

      return mapping;
    } else {
      throw tableData;
    }
  }

  async getUtilityBillingCategoryIDs(
    billingCatIDs: any
  ): Promise<Record<string, any> | Error> {
    const where: CacheWhereClause = {
      Column: "UtilityBillingCatID",
      Value: billingCatIDs,
      ValueType: CacheWhereClauseType.ARRAY,
    };
    const adminBillingCatRows: Record<string, any>[] | Error =
      await this.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_UtilityBillingCategories,
        [where]
      );
    if (!(adminBillingCatRows instanceof Error)) {
      const obj = {};
      for (let i = 0; i < adminBillingCatRows.length; i++) {
        const row = adminBillingCatRows[i];
        obj[row.UtilityBillingCatID] = row;
      }
      return obj;
    } else {
      throw adminBillingCatRows;
    }
  }

  async getUtilityBillingDetails(
    billingCatIDs: any
  ): Promise<Record<string, any> | Error> {
    const where: CacheWhereClause = {
      Column: "UtilityBillingCatID",
      Value: billingCatIDs,
      ValueType: CacheWhereClauseType.ARRAY,
    };
    const adminBillingCatRows: Record<string, any>[] | Error =
      await this.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_UtilityBillingDetails,
        [where]
      );
    if (!(adminBillingCatRows instanceof Error)) {
      const obj = {};
      for (let i = 0; i < adminBillingCatRows.length; i++) {
        const row = adminBillingCatRows[i];
        if (obj[row.UtilityBillingCatID]) {
          obj[row.UtilityBillingCatID][row.UtilityID] = row;
        } else {
          obj[row.UtilityBillingCatID] = {};
          obj[row.UtilityBillingCatID][row.UtilityID] = row;
        }
      }
      return obj;
    } else {
      throw adminBillingCatRows;
    }
  }

  async getPrimaryDetailsCategoryIDs(utilityTypes: any[]) {
    const primaryDetailsCatRowsWC: CacheWhereClause = {
      Column: "UtilityType",
      Value: utilityTypes,
      ValueType: CacheWhereClauseType.ARRAY,
    };
    const primaryDetailsCatRows: Record<string, any>[] | Error =
      await this.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailsCategories,
        [primaryDetailsCatRowsWC]
      );
    if (!(primaryDetailsCatRows instanceof Error)) {
      let formattedPrimaryDetailCatIDs = [];
      for (const row of primaryDetailsCatRows) {
        formattedPrimaryDetailCatIDs.push(row.PrimaryDetailCategoryID);
      }
      formattedPrimaryDetailCatIDs = [
        ...new Set([...formattedPrimaryDetailCatIDs]),
      ];
      return formattedPrimaryDetailCatIDs;
    } else {
      throw primaryDetailsCatRows;
    }
  }

  async getTicketDigsites(requestNumber: number, callCenterID: number) {
    const digsites: GraphicInfo[] = [];
    try {
      const shapes: Record<string, any>[] | Error = await this.queryTable(
        ADMIN_TABLE_NAMES.tbDigsite_Shapes,
        [
          {
            Column: "RequestNumber",
            Value: requestNumber,
            ValueType: CacheWhereClauseType.NUMBER,
          },
          {
            Column: "CallCenterID",
            Value: callCenterID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
        ]
      );

      if (Array.isArray(shapes) && shapes.length > 0) {
        const digsiteShapeIDs = [];
        for (const i in shapes) {
          digsiteShapeIDs.push(shapes[i].DigsiteShapeID);
        }

        const shapeCoordinates = await this.queryTable(
          ADMIN_TABLE_NAMES.tbDigsite_GPS,
          [
            {
              Column: "DigsiteShapeID",
              Value: digsiteShapeIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            },
          ]
        );

        const coordinatesList = {};

        for (const i in shapeCoordinates) {
          const coord = shapeCoordinates[i];
          if (!coordinatesList[coord.DigsiteShapeID]) {
            coordinatesList[coord.DigsiteShapeID] = [];
          }

          coordinatesList[coord.DigsiteShapeID].push({
            lat: Number(coord.Latitude),
            lng: Number(coord.Longitude),
          });
        }

        for (const i in shapes) {
          const shape = shapes[i];
          digsites.push({
            graphicID: shape.DigsiteShapeID,
            type: shape.DigsiteTypeID,
            coordinates: coordinatesList[shape.DigsiteShapeID],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    return digsites;
  }

  async insertTable(key, value, assignmentID?: string) {
    return this.baseAdminCacheService.insertTable(key, value, assignmentID);
  }

  async clear() {
    this.baseAdminCacheService.clear();
  }
}

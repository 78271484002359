import { InteractionParameters, MapInteractionType, SymbolShape, ToolbarStateKey, ShapeType, ToolType, LineTools, LineStyles } from './types';
import { DragBox, Draw, Interaction, Select } from 'ol/interaction';
import Styles from './styles';
import { StyleLike } from 'ol/style/Style';
import { Collection, Feature, Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FeatureOrCollection } from '../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../classes/map-feature-change';
import { OpenLayersUtilities } from '../classes/open-layers-utilities';
import { TextTool } from './tools/text-tool';
import { LegacySymbolTool } from './tools/legacy-symbol-tool';
import { Sticker, StickerTool } from './tools/sticker-tool';
import { SymbolTool } from './tools/symbol-tool';
import { SvgSymbol } from '../services/map-symbols.service';

export interface InteractionOptions {
  // @ts-ignore
  opts: Options;
  style: StyleLike;
  sticker: Sticker;
  symbol: Array<SymbolShape>;
  svgSymbol: SvgSymbol;
  mapRef: Map;
  layerRef: VectorLayer<VectorSource>;
  featureCollectionSubject: BehaviorSubject<Collection<Feature>>;
  newSelection: Subject<void>;
  featureChanges: Subject<MapFeatureChange<FeatureOrCollection>>;
  endInteraction: Subject<void>;
  olUtilities: OpenLayersUtilities;
  parameters: InteractionParameters;
}

const lineToShapeType = {
  [LineTools.singleLine]: ShapeType.LineString,
  [LineTools.multiLine]: ShapeType.MultiLineString,
  [LineTools.freeHand]: ShapeType.MultiLineString,
  [LineTools.arrowLine]: ShapeType.ArrowLineLegacy,
  [LineTools.letterLine]: ShapeType.LetterLine
}

function assignShapeType(toolbarState, feature: Feature) {
  let id = -1;
  if (toolbarState.toolPage === 0) {
    if (toolbarState.utilityLine !== null && Number(toolbarState.utilityLine["LineID"]) !== -1) {
      id = ShapeType.LetterLine;
    } else {
      id = lineToShapeType[toolbarState.lineTool]
    }

  }
  feature.set('shape_type_id', id);
}

function setupBaseProperties(toolbarState, feature: Feature) {
  const {
    lineStyle,
    lineThickness,
    utilityLine,
    sticker,
    svgSymbol,
    strokeColour,
    fillColour,
    opacity
  } = toolbarState;
  feature.setProperties({
    stroke_colour: strokeColour,
    stroke_width: lineThickness * 2,
    fill_colour: fillColour,
    fill_opacity: opacity,
    text_colour: strokeColour,
    stroke_dasharray: LineStyles[lineStyle]
  })
}

function addCustomLineProperties(utilityLine, feature: Feature) {
  const {
    LineColour,
    LineText,
    TextColour,
    Dashed
  } = utilityLine
  feature.setProperties({
    stroke_colour: LineColour,
    text_colour: TextColour,
    stroke_dasharray: Dashed.map((x) => (x % 2 === 1 ? x * 20 : x * 10)).join(','),
    text_label: LineText
  })
}

/**
 * @param toolbarState
  *@param feature
  */
function updateFeatureProps(toolbarState: Record<ToolbarStateKey, any>, feature: Feature) {
  assignShapeType(toolbarState, feature);
  setupBaseProperties(toolbarState, feature);

  if ([ShapeType.LineString, ShapeType.MultiLineString, ShapeType.LetterLine].includes(feature.get("shape_type_id")) && toolbarState.utilityLine !== null && Number(toolbarState.utilityLine["LineID"]) !== -1) {
    addCustomLineProperties(toolbarState.utilityLine, feature);
  }
}

/**
 *
 */
function handleDrawnLine(parameters: InteractionParameters, feature: Feature) {
  updateFeatureProps(parameters.toolbarState, feature)
  feature.setStyle(Styles.defaultStyle);

  console.log(feature.getProperties())
}

const MapInteractions: Record<MapInteractionType, (arg: InteractionOptions) => Interaction[]> = {
  [MapInteractionType.Symbol]: ({ opts, symbol, svgSymbol, featureChanges, mapRef }): Interaction[] => {
    if (opts.legacy) {
      return [
        new LegacySymbolTool({
          mapRef,
          sourceRef: opts.source,
          symbol,
          featureChanges,
        })
      ];
    } else {
      return [
        new SymbolTool({
          mapRef,
          sourceRef: opts.source,
          symbol: svgSymbol,
          featureChanges,
        }),
      ];
    }
  },
  [MapInteractionType.addSticker]: ({ opts, sticker, featureChanges, olUtilities, mapRef }): Interaction[] => {
    // const drawingToolbarService = new OpenLayersService(null);

    return [
      new StickerTool({
        mapRef,
        sourceRef: opts.source,
        sticker,
        featureChanges,
        olUtilities,
      }),
    ];
  },
  [MapInteractionType.addText]: ({
    opts,
    featureChanges,
    mapRef,
    olUtilities,
    endInteraction,
    parameters
  }): Interaction[] => {
    return [
      new TextTool({
        mapRef,
        sourceRef: opts.source,
        featureChanges,
        olUtilities,
        endInteraction,
        parameters,
      }),
    ];
  },
  [MapInteractionType.draw]: ({ opts, style, mapRef, featureChanges, parameters }): Interaction[] => {
    const draw = new Draw(opts);
    const element = mapRef.getTargetElement();
    element.style.cursor = 'crosshair';
    draw.on('drawend', (event) => {
      const feature = event.feature;
      if (parameters.shapeType !== undefined) {
        feature.set('shape_type_id', parameters.shapeType);
      }
      if (parameters.toolType === ToolType.lineTool &&
        [LineTools.singleLine, LineTools.multiLine, LineTools.freeHand,
        LineTools.letterLine].includes(parameters.toolName as LineTools)) {
        handleDrawnLine(parameters, feature);
      } else {
        feature.setStyle(style || Styles.baseStyle());
      }
      featureChanges.next(
        new MapFeatureChange(FeatureChangeType.added, new Collection([event.feature]), undefined, opts.source)
      );
    });
    return [draw];
  },
  [MapInteractionType.select]: ({ opts, mapRef, layerRef, featureCollectionSubject, newSelection }): Interaction[] => {
    if (opts.dragBox) {
      const dragBox = new DragBox();

      dragBox.on('boxend', () => {
        const extent = dragBox.getGeometry().getExtent();
        const boxFeatures = layerRef
          .getSource()
          .getFeaturesInExtent(extent)
          .filter((feature) => feature.getGeometry().intersectsExtent(extent));
        const rotation = mapRef.getView().getRotation();
        const oblique = rotation % (Math.PI / 2) !== 0;

        if (oblique) {
          const anchor = [0, 0];
          const geometry = dragBox.getGeometry().clone();
          geometry.rotate(-rotation, anchor);
          const dragExtent = geometry.getExtent();
          const selected: Collection<Feature> = boxFeatures.reduce((col: Collection<Feature>, feature: Feature) => {
            const geom = feature.getGeometry().clone();
            geom.rotate(-rotation, anchor);
            if (geom.intersectsExtent(dragExtent)) {
              col.push(feature);
            }
            return col;
          }, new Collection());
          featureCollectionSubject.next(selected);
        } else {
          const selected = new Collection<Feature>(boxFeatures);
          featureCollectionSubject.next(selected);
        }
        newSelection.next();
      });

      dragBox.on('boxstart', () => {
        featureCollectionSubject.next(new Collection<Feature>());
      });
      return [dragBox];
    } else {
      const select = new Select({
        layers: [layerRef],
        style: null,
        hitTolerance: 5,
      });
      featureCollectionSubject
        .pipe(
          filter((x) => x.getLength() === 0),
          takeUntil(fromEvent(select, 'change:active'))
        )
        .subscribe(() => select.getFeatures().clear());

      select.on('select', (event) => {
        featureCollectionSubject.next(new Collection(event.selected));
        newSelection.next();
      });

      return [select];
    }
  },
};

export default MapInteractions;

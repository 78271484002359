export const ADMIN_TABLE_NAMES = {
  tbAdmin_AutologDesc: 'tbAdmin_AutologDesc',
  tbAdmin_CallType: 'tbAdmin_CallType',
  tbAdmin_ClearOptions: 'tbAdmin_ClearOptions',
  tbAdmin_LocateStatus: 'tbAdmin_LocateStatus',
  tbAdmin_LocateType: 'tbAdmin_LocateType',
  tbAdmin_Excavators: 'tbAdmin_Excavators',
  tbLogin_Users: 'tbLogin_Users',
  tbAdmin_PrimaryDetailFieldRules: 'tbAdmin_PrimaryDetailFieldRules',
  tbAdmin_PrimaryDetailFields: 'tbAdmin_PrimaryDetailFields',
  tbAdmin_PrimaryDetailsCategories: 'tbAdmin_PrimaryDetailsCategories',
  tbAdmin_Utilities: 'tbAdmin_Utilities',
  tbAdmin_UtilityToCallType: 'tbAdmin_UtilityToCallType',
  tbAdmin_RegionList: 'tbAdmin_RegionList',
  tbLogin_UserCategories: 'tbLogin_UserCategories',
  tbAdmin_TicketTags: 'tbAdmin_TicketTags',
  tbAdmin_CallTypeToPreCompletionCategory: 'tbAdmin_CallTypeToPreCompletionCategory',
  tbAdmin_PreCompletionCategories: 'tbAdmin_PreCompletionCategories',
  tbAdmin_PreCompletionFields: 'tbAdmin_PreCompletionFields',
  tbAdmin_PreCompletionFieldOptions: 'tbAdmin_PreCompletionFieldOptions',
  tbAdmin_Settings: 'tbAdmin_Settings',
  tbUser_SettingOverrides: 'tbUser_SettingOverride',
  tbAdmin_UtilityBillingCategories: 'tbAdmin_UtilityBillingCategories',
  tbAdmin_UtilityBillingDetails: 'tbAdmin_UtilityBillingDetails',
  tbAdmin_BillingRules: 'tbAdmin_BillingRules',
  tbDigsite_Types: 'tbDigsite_Types',
  tbDigsite_Shapes: 'tbDigsite_Shapes',
  tbDigsite_GPS: 'tbDigsite_GPS',
  tbRouting_Algorithms: 'tbRouting_Algorithms',
  tbUser_CategoryToSetting: 'tbUser_CategoryToSetting',
  tbUser_CategoryAccessToSetting: 'tbUser_CategoryAccessToSetting',
  tbMap_Routes: 'tbMap_Routes',
};

export const COMPLETION_TABLE_NAMES = {
  tbCompletions_Assignments: 'tbCompletions_Assignments',
  tbCompletions_Primary: 'tbCompletions_Primary',
  tbCompletions_PrimaryDetails: 'tbCompletions_PrimaryDetails',
  tbCompletions_AuxiliaryDetails: 'tbCompletions_AuxiliaryDetails',
  tbCompletions_Autolog: 'tbCompletions_Autolog',
  tbCompletions_AssignmentPolygons: 'tbCompletions_AssignmentPolygons',
  tbCompletions_Billing: 'tbCompletions_Billing',
  tbCompletions_Documents: 'tbCompletions_Documents',
  tbCompletions_PreCompletionCategories: 'tbCompletions_PreCompletionCategories',
  tbCompletions_PreCompletionDetails: 'tbCompletions_PreCompletionDetails',
  tbCompletions_CommonDetails: 'tbCompletions_CommonDetails',
  tbCompletions_AssignmentToTags: 'tbCompletions_AssignmentToTags',
  tbCompletions_S3Documents: 'tbCompletions_S3Documents',
  tbCompletions_ExcavationDateLog: 'tbCompletions_ExcavationDateLog',
  tbQueue_AWSS3Uploads: 'tbQueue_AWSS3Uploads',
  tbSync_ExpectedDocumentHash: 'tbSync_ExpectedDocumentHash',
  tbCompletions_AssignmentExtraFields: 'tbCompletions_AssignmentExtraFields',
  tbCompletions_AssignmentRiskScores: 'tbCompletions_AssignmentRiskScores',
  tbCompletions_AssignmentImpactScores: 'tbCompletions_AssignmentImpactScores'
};

<div fxLayout="column" fxLayoutAlign="space-between center" style="height: 100%">
  <h2 fxFlex="24px" mat-dialog-title>Edit Series</h2>
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px" style="overflow: auto; padding: 20px">
    <span mat-h4>Events To Edit Date Range</span>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="16px grid"
      style="width: 100%; height: 100%">
      <ejs-datepicker
        #Start
        fxFlex="50"
        [value]="event.StartTime"
        [min]="event.EventStart"
        [max]="event.EventEnd"
        placeholder="Start Date"
        floatLabelType="Auto"
        (change)="updateResources()"></ejs-datepicker>
      <ejs-datepicker
        #End
        fxFlex="50"
        [value]="event.EventEnd"
        [min]="event.EventStart"
        [max]="event.EventEnd"
        placeholder="End Date"
        floatLabelType="Auto"
        (change)="updateResources()"></ejs-datepicker>
    </div>

    <span mat-h4 style="margin-top: 0px">Fields</span>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="16px grid"
      style="width: 100%; height: 100%; padding-bottom: 8px">
      <div fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start end">
          <div class="e-float-input">
            <input
              class="e-field e-input"
              id="EventColour"
              type="text"
              name="EventColour"
              [value]="currentEventColour" />
            <span class="e-float-line e-label-top"></span>
            <label class="e-float-text e-label-top">Event Colour</label>
          </div>

          <mat-icon
            style="
              padding-bottom: 4px;
              font-size: 18px;
              height: 18px;
              width: 18px;
              cursor: pointer;
              color: rgba(0, 0, 0, 0.54);
            "
            (click)="currentEventColour = ''">
            close
          </mat-icon>
          <ngx-colors
            ngx-colors-trigger
            [hideColorPicker]="false"
            [hideTextInput]="true"
            (change)="colorPickerChange($event)"></ngx-colors>
        </div>
      </div>

      <ejs-dropdownlist
        #ResourceID
        fxFlex="100"
        id="ddlelement"
        [dataSource]="resources"
        [fields]="resourceFields"
        placeholder="Selected Rig"
        floatLabelType="Auto"
        [showClearButton]="true"></ejs-dropdownlist>

      <ejs-checkbox
        #filterResources
        fxFlex="100"
        [hidden]="false"
        id="filterResources"
        label="Only Show Available People"
        class="e-field e-input"
        data-name="filterResources"
        [checked]="true"
        (change)="onCheckChange($event)"></ejs-checkbox>

      <ejs-dropdownlist
        #Driller
        fxFlex="100"
        id="drillerElement"
        [dataSource]="drillerResources"
        [fields]="drillerFields"
        placeholder="Selected Driller"
        floatLabelType="Auto"
        [showClearButton]="true"></ejs-dropdownlist>

      <ejs-multiselect
        #Helpers
        fxFlex="100"
        id="helperElement"
        [dataSource]="helperResources"
        [fields]="resourceFields"
        placeholder="Selected Helpers"
        floatLabelType="Auto"></ejs-multiselect>

      <ejs-multiselect
        #Equipment
        fxFlex="100"
        id="equipmentElement"
        [dataSource]="equipmentResources"
        [fields]="resourceFields"
        placeholder="Selected Equipment"
        floatLabelType="Auto"></ejs-multiselect>
    </div>

    <div fxFlex="100">
      <div class="e-float-input">
        <textarea
          id="Description"
          class="e-field e-input"
          name="message"
          rows="3"
          cols="50"
          [(ngModel)]="eventNotes"
          style="width: 100%; height: 50px !important; resize: vertical"></textarea>
        <label class="e-float-text">Notes</label>
      </div>
    </div>
  </div>
  <div fxFlex="52px" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
    <button class="btn-save" color="primary" (click)="save()">SAVE</button>
    <button class="btn-cancel" (click)="close()">CANCEL</button>
  </div>
</div>

import VectorSource from 'ol/source/Vector';
import { LineTools, SelectionTools, ShapeTools, StickerTools, SymbolTools, TextTools, ToolType } from './types';
import { createBox } from 'ol/interaction/Draw';

const lineToolOptions: Record<LineTools, (arg: VectorSource) => Record<any, any>> = {
  [LineTools.singleLine]: (layerSource: VectorSource) => {
    return {
      type: 'LineString',
      maxPoints: 2,
      source: layerSource,
    };
  },
  [LineTools.multiLine]: (layerSource: VectorSource) => {
    return {
      type: 'MultiLineString',
      source: layerSource,
    };
  },
  [LineTools.freeHand]: (layerSource: VectorSource) => {
    return {
      type: 'MultiLineString',
      source: layerSource,
      freehand: true,
    };
  },
  [LineTools.arrowLine]: (layerSource: VectorSource) => {
    return {
      type: 'MultiLineString',
      maxPoints: 2,
      source: layerSource,
    };
  },
  [LineTools.measureLine]: (layerSource: VectorSource) => {
    return {
      type: 'LineString',
      maxPoints: 2,
      source: layerSource,
    };
  },
  [LineTools.letterLine]: (layerSource: VectorSource) => {
    return {
      type: 'MultiLineString',
      source: layerSource,
    };
  },
};

const shapeToolOptions: Record<ShapeTools, (arg: VectorSource) => Record<any, any>> = {
  [ShapeTools.polygon]: (layerSource: VectorSource) => {
    return {
      type: 'Polygon',
      source: layerSource,
    };
  },
  [ShapeTools.square]: (layerSource: VectorSource) => {
    return {
      type: 'Circle',
      geometryFunction: createBox(),
      source: layerSource,
    };
  },
  [ShapeTools.circle]: (layerSource: VectorSource) => {
    return {
      type: 'Circle',
      source: layerSource,
    };
  },
  [ShapeTools.locateArea]: (layerSource: VectorSource) => {
    return {
      type: 'Circle',
      geometryFunction: createBox(),
      source: layerSource,
    };
  },
};

const textToolOptions: Record<TextTools, (arg: VectorSource) => Record<any, any>> = {
  [TextTools.label]: (layerSource: VectorSource) => {
    return {
      type: 'Point',
      source: layerSource,
    };
  },
};

const stickerToolOptions: Record<StickerTools, (arg: VectorSource) => Record<any, any>> = {
  [StickerTools.stamp]: (layerSource: VectorSource) => {
    return {
      type: 'Point',
      source: layerSource,
    };
  },
};

const symbolToolOptions: Record<SymbolTools, (arg: VectorSource) => Record<any, any>> = {
  [SymbolTools.legacySymbol]: (layerSource: VectorSource) => {
    return {
      legacy: true,
      type: 'Point',
      source: layerSource,
    };
  },
  [SymbolTools.svgSymbol]: (layerSource: VectorSource) => {
    return {
      type: 'Point',
      source: layerSource,
    };
  },
};

const selectionToolOptions: Record<SelectionTools, (arg: unknown) => Record<any, any>> = {
  [SelectionTools.pointSelect]: () => {
    return { dragBox: false };
  },
  [SelectionTools.boxSelect]: () => {
    return { dragBox: true };
  },
};

const ToolOptions: Record<symbol, () => Record<symbol, () => Record<any, any>>> = {
  //@ts-ignore
  [ToolType.lineTool]: () => lineToolOptions,
  [ToolType.shapeTool]: () => shapeToolOptions,
  [ToolType.textTool]: () => textToolOptions,
  [ToolType.stampTool]: () => stickerToolOptions,
  [ToolType.selectionTool]: () => selectionToolOptions,
  [ToolType.symbolTool]: () => symbolToolOptions,
};

export default ToolOptions;

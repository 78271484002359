<div id="user-manager-container" fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="mat-headline-2">User Manager</span>
    <button mat-flat-button color="primary" class="capitalize  font-semibold" (click)="addUser()">
      Add User
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="12px">
      <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
        <button
          mat-stroked-button
          color="primary"
          class="mat-subheading-xs archive-btn"
          style="max-height: 32px"
          (click)="batchArchive()">
          Archive User(s)
        </button>
        <button
          mat-stroked-button
          class="mat-subheading-xs unarchive-btn"
          style="max-height: 32px"
          (click)="batchUnarchive()">
          Unarchive User(s)
        </button>
      </div>
      <div class="h-9 md:w-96 lg:w-[600px]">
        <app-competers-search-bar
          (searchValueChange)="searchTerm = $event"></app-competers-search-bar>
      </div>
    </div>

    <mat-slide-toggle color="primary" [(ngModel)]="showArchived">Show Archived Users</mat-slide-toggle>
  </div>

  <!-- Table -->
  <div class="flex flex-grow w-full h-full">
    <app-tabulator-table
      class="h-full w-full"
      #tabulatorTable
      [tableData]="showArchived ? displayedUsers : activeUsers"
      [columns]="displayedColumns"
      [searchString]="searchTerm"
      [rowFormatter]="getRowClass"
      [showSelect]="true"
      (rowDoubleClick)="onRowClicked($event)"
      (selectedRowsChanged)="rowSelectionChanged($event)" />
  </div>
</div>

<!-- User Modal -->
<app-base-modal #userDialog [id]="USER_MODAL_ID" maxwidth="1168px" height="80%">
  <ng-container>
    <app-user-manager-editor   
      [user]="openUser"
      [supervisors]="supervisors"
      [userCategories]="userCategories"
      [cloudUserCategories]="cloudUserCategories"
      (cancel)="closeUserModal()"
      (save)="userSaved()"></app-user-manager-editor>
  </ng-container>
</app-base-modal>

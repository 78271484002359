import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import { InvoicingManagerService } from '../invoicing-manager.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { TableService } from '../../shared/table/table.service';
import { TemplateColumn } from '../../shared/table/table.module';
import { SelectionType } from '@swimlane/ngx-datatable';
import { RowComponent } from 'tabulator-tables';
import { DatetimeService } from '../../core/services/datetime/datetime.service';

const InvoiceReportType = {
  1: 'Ticket Data Set',
  2: 'Details',
  3: 'Summary',
  4: 'Compliance',
  5: 'Late',
  6: 'Non-invoice',
};

const GeneratedReportStatusID = {
  1: 'In Progress',
  2: 'Failed',
  5: 'Completed',
  8: 'No Data',
  12: 'Started',
};

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrl: './invoice-report.component.scss',
  providers: [TableService],
})
export class InvoiceReportComponent implements OnInit {
  reportID: number;
  report: any = {
    ReportID: 0,
    ReportName: 'Generic',
    ReportDesc: 'generic report',
  };
  reportParams: any[] = [];
  pastReports: any[] = [];

  displayedColumnNames: string[] = [
    'Utility',
    'Report Type',
    'To Date',
    'Status',
    'Invoiceable',
    'Invoiced',
    'PDF Generated',
    'Date Created',
    'File Name',
    'User',
  ];

  months: any[] = [
    { Value: 1, Name: 'January' },
    { Value: 2, Name: 'February' },
    { Value: 3, Name: 'March' },
    { Value: 4, Name: 'April' },
    { Value: 5, Name: 'May' },
    { Value: 6, Name: 'June' },
    { Value: 7, Name: 'July' },
    { Value: 8, Name: 'August' },
    { Value: 9, Name: 'September' },
    { Value: 10, Name: 'October' },
    { Value: 11, Name: 'November' },
    { Value: 12, Name: 'December' },
  ];

  displayedColumns: TemplateColumn[];
  selectionType: SelectionType = SelectionType.multi;
  rowClickedCallback;
  getRowClassCallback;
  showArchived = false;
  displayedPastReports: any[] = [];
  activePastReports: any[] = [];
  selected: any[] = [];

  parametersToRun: any = {};
  endDate: string = null;
  utilities: string[] = [];
  startDate: string = null;
  month: string = null;
  year: number = null;

  constructor(
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    public tableService: TableService<any>,
    private invoicingService: InvoicingManagerService,
    private datetimeService: DatetimeService
  ) {
    this.rowClickedCallback = this.onRowClicked.bind(this);
    this.getRowClassCallback = this.getRowClass.bind(this);
  }
  /**
   * setup subscriptions for route and treeNodes
   */
  async ngOnInit() {
    this.route.params.subscribe((params) => {
      let paramsRouteID: number = parseInt(params['reportID'], 10); //parsing string into integer
      paramsRouteID = this.shouldRerouteToInvoicePage(paramsRouteID) ? null : paramsRouteID; // changes paramsRouteID to null if wrong route is mentioned
      this.reportID = paramsRouteID;
      this.updateReport();
    });
  }

  /**
   * checks param(reportID) and decides if page should be rerouted to /app/invoicing-manager
   * @param reportID
   * @returns boolean value if invoice page should be rerouted
   */
  shouldRerouteToInvoicePage(reportID: number): boolean {
    let shouldReroute: boolean;
    const availableReportRoutes: number[] = this.invoicingService.getReportIDs(); //available Routes for Report feature,i.e. [1, 2]
    try {
      if (availableReportRoutes && !availableReportRoutes.includes(reportID)) {
        this.rerouteToInvoicePage();
        shouldReroute = true;
      } else {
        shouldReroute = false;
      }
    } catch (error) {
      console.error(`Error occured rerouting to invoice page ${error}`);
    }
    return shouldReroute;
  }

  /**
   * reroutes URL to /app/invoicing-manager page
   * and pops a snackbar error message
   */
  rerouteToInvoicePage(): void {
    this.snackbarService.openSnackbar(`Invalid Page Request: Route Does Not Exist`, SnackbarType.error);
    this.router.navigate(['/app/invoicing-manager']);
  }

  async updateReport() {
    this.progressBarService.start();
    const report = await this.invoicingService.getInvoiceReportByID(this.reportID);
    this.report = report.report;
    this.reportParams = report.parameters;
    this.pastReports = report.pastReports;

    this.displayedColumns = this.displayedColumnNames.map((col, index) => {
      return {
        TemplateColumnID: 0,
        ColumnOrder: index,
        TemplateID: 0,
        Field: 0,
        Visible: 1,
        Width: 150,
        Title: col,
      } as TemplateColumn;
    });

    this.formatPastReports();

    this.selected = [];

    this.utilities = [];
    this.startDate = null;
    this.endDate = null;
    this.month = null;
    this.year = 2024; //default to this year
    this.parametersToRun = {};
    this.parametersToRun['6'] = this.year;

    this.progressBarService.stop();
  }

  formatDate(date) {
    if (date && date != '') {
      let newDate = this.datetimeService.dbDateToFormattedLocalDate(date);
      return newDate;
    } else {
      return '';
    }
  }

  formatPastReports() {
    const active = [];
    const displayed = [];
    for (const pastReport of this.pastReports) {
      const rowData = {
        GeneratedReportID: pastReport.GeneratedReportID,
        ParentGeneratedReportID: pastReport.ParentGeneratedReportID,
        Utilities: pastReport.Utilities,
        InvoiceReportTypeID: pastReport.InvoiceReportTypeID,
        UserID: pastReport.UserID,
        isArchived: pastReport.isArchived,
        isInvoiced: pastReport.isInvoiced,
        PDFS3DocumentID: pastReport.PDFS3DocumentID,
        PDFFileName: pastReport.PDFFileName,
        //visible columns
        'File Name': pastReport.FileName,
        'Date Created': this.formatDate(pastReport.FinishDate),
        User: `${pastReport.FirstName} ${pastReport.LastName}`,
        'Report Type': InvoiceReportType[pastReport.InvoiceReportTypeID],
        'To Date': this.formatDate(pastReport.ToDate),
        Invoiceable: pastReport.InvoiceReportTypeID == 1 && pastReport.S3DocumentID ? 'Yes' : 'No',
        Invoiced: pastReport.isInvoiced ? 'Yes' : 'No',
        Utility: pastReport.UtilityName,
        'PDF Generated': pastReport.PDFS3DocumentID ? (pastReport.PDFS3DocumentID == -1 ? 'Failed' : 'Yes') : 'No',
        Status: GeneratedReportStatusID[pastReport.Status],
      };
      displayed.push(rowData);
      if (pastReport.isArchived == 0) {
        active.push(rowData);
      }
    }
    this.activePastReports = active;
    this.displayedPastReports = displayed;
    console.log(this.activePastReports)
    console.log(this.displayedColumns);


  }

  /**
   *
   * @param index
   * @param item
   * @returns
   */
  trackByFn(index, item) {
    return item.id; // Use a unique identifier property from your object
  }

  rowSelectionChanged(e: any[]) {
    this.selected = e;
  }

  onRowClicked(e) { }

  getRowClass(row: RowComponent) {
    const data = { ...row.getData() };
    if (data.isArchived && data.isArchived === 1) {
      row.getElement().classList.add('tabulator-table-user-archived');
    } else if (data.isInvoiced && data.isInvoiced === 1) {
      row.getElement().classList.add('tabulator-table-invoiced');
    }
  }

  startDateChanged(event: any) {
    const startDate = event.value;
    this.startDate = this.datetimeService.localDateToDBDateStr(startDate, 'yyyy-MM-dd HH:mm:ss', 'yyyy-MM-dd');
    this.parametersToRun['1'] = this.startDate;
  }

  endDateChanged(event: any) {
    const endDate = event.value;
    this.endDate = this.datetimeService.localDateToDBDateStr(endDate, 'yyyy-MM-dd HH:mm:ss', 'yyyy-MM-dd');
    this.parametersToRun['2'] = this.endDate;
  }

  utilitiesChanged(event: any) {
    this.utilities = event.value;
    this.parametersToRun['7'] = this.utilities;
  }

  monthChanged(event: any) {
    this.month = event.value;
    this.parametersToRun['11'] = this.month;
  }

  yearChanged(event: any) {
    this.year = event.target.value;
    this.parametersToRun['6'] = this.year;
  }

  async runReportClicked() {
    this.progressBarService.start();
    let hasRequiredParams = true;

    for (const param of this.reportParams) {
      const paramID = param.ParameterID;
      if (!this.parametersToRun[paramID]) {
        hasRequiredParams = false;
      }
    }

    if (hasRequiredParams) {
      const reportParameters = this.parametersToRun;

      if (this.utilities && this.utilities.length > 0) {
        for (const utility of this.utilities) {
          reportParameters['7'] = utility;
          await this.invoicingService.runInvoiceReport(this.reportID, reportParameters);
        }
      } else {
        await this.invoicingService.runInvoiceReport(this.reportID, reportParameters);
      }

      this.snackbarService.openSnackbar('Report(s) running in the background', SnackbarType.success);
      await this.updateReport();
    } else {
      this.snackbarService.openSnackbar('Missing required parameter(s)', SnackbarType.warning);
    }

    // if (this.endDate && this.utilities && this.utilities.length > 0) {
    //   for (let utility of this.utilities) {
    //     let reportParameters = {
    //       "2": this.endDate,
    //       "7": utility
    //     }
    //     await this.invoicingService.runInvoiceReport(this.reportID, reportParameters);
    //   }
    //   this.snackbarService.openSnackbar('Report(s) running in the background', SnackbarType.success);
    //   await this.updateReport();
    // } else if (this.startDate && this.endDate) {
    //   let reportParameters = {
    //     "1": this.startDate,
    //     "2": this.endDate
    //   }
    //   await this.invoicingService.runInvoiceReport(this.reportID, reportParameters);
    //   this.snackbarService.openSnackbar('Report(s) running in the background', SnackbarType.success);
    //   await this.updateReport();
    // } else {
    //   this.snackbarService.openSnackbar('Missing Parameter(s)', SnackbarType.warning);
    // }
    this.progressBarService.stop();
  }

  showArchivedClicked() {
    this.showArchived = !this.showArchived;
  }

  async archiveSelected() {
    // console.log("selected", this.selected);
    if (this.selected.length > 0) {
      const toArchive = [];
      for (const generatedReport of this.selected) {
        toArchive.push(generatedReport.GeneratedReportID);
      }
      await this.invoicingService.archiveGeneratedReports(toArchive);
      await this.updateReport();
    } else {
      this.snackbarService.openSnackbar('No Reports Selected', SnackbarType.warning);
    }
  }

  async unarchiveSelected() {
    // console.log("selected", this.selected);
    if (this.selected.length > 0) {
      const toUnarchive = [];
      for (const generatedReport of this.selected) {
        toUnarchive.push(generatedReport.GeneratedReportID);
      }
      await this.invoicingService.unarchiveGeneratedReports(toUnarchive);
      await this.updateReport();
    } else {
      this.snackbarService.openSnackbar('No Reports Selected', SnackbarType.warning);
    }
  }

  async downloadSelectedCSV() {
    // console.log("selected", this.selected);
    if (this.selected.length > 0) {
      for (const file of this.selected) {
        if (file['File Name']) {
          await this.invoicingService.downloadFile(file['File Name'], file.UserID, file.GeneratedReportID);
        } else {
          this.snackbarService.openSnackbar('No Files to Download', SnackbarType.warning);
        }
      }
    } else {
      this.snackbarService.openSnackbar('No Reports Selected', SnackbarType.warning);
    }
  }

  async downloadSelectedPDF() {
    // console.log("selected", this.selected);
    if (this.selected.length == 0) {
      this.snackbarService.openSnackbar('No Reports Selected', SnackbarType.warning);
    } else {
      // Download PDF -> Toast that says Downloading x of y pdfs.
      // So if i select 6 rows and 1 row doesn't have a PDFS3DocumentID then it say downloading 5 of 6 kind of thing
      const rowsWithPDFs = this.getSelectedRowsWithPDFDocuments();

      if (rowsWithPDFs.length > 0) {
        const snackbarMessage = `Downloading ${rowsWithPDFs.length}/${this.selected.length} PDFs`;
        this.snackbarService.openSnackbar(snackbarMessage, SnackbarType.default);
      } else {
        this.snackbarService.openSnackbar('No Files To Download', SnackbarType.warning);
      }

      // Downloads PDFs
      for (const file of rowsWithPDFs) {
        await this.invoicingService.downloadFile(file.PDFFileName, file.UserID, file.GeneratedReportID);
      }
    }
  }

  getSelectedRowsWithPDFDocuments() {
    return this.selected.filter((file) => file.PDFS3DocumentID); // returns number of files with PDFS3DocumentID
  }

  async invoiceSelected() {
    // console.log("selected", this.selected);
    const toInvoice = [];
    const invoiceIDs = [];
    for (const generatedReport of this.selected) {
      if (generatedReport.InvoiceReportTypeID == 1 && !invoiceIDs.includes(generatedReport.GeneratedReportID)) {
        invoiceIDs.push(generatedReport.GeneratedReportID);
        toInvoice.push(generatedReport);
      }
    }
    if (toInvoice.length > 0) {
      console.log('toInvoice:', toInvoice);
      const result = await this.invoicingService.invoiceGeneratedReports(toInvoice);
      if (result == true) {
        this.snackbarService.openSnackbar('Reports Successfully Invoiced', SnackbarType.success);
      } else {
        this.snackbarService.openSnackbar('Error Invoicing Reports', SnackbarType.error);
      }
      this.updateReport();
    } else {
      this.snackbarService.openSnackbar('No Invoiceable Reports Selected', SnackbarType.warning);
    }
  }

  async uninvoiceSelected() {
    // console.log("selected", this.selected);
    const toUninvoice = [];
    const invoiceIDs = [];
    for (const generatedReport of this.selected) {
      if (generatedReport.InvoiceReportTypeID == 1 && !invoiceIDs.includes(generatedReport.GeneratedReportID)) {
        invoiceIDs.push(generatedReport.GeneratedReportID);
        toUninvoice.push(generatedReport);
      }
    }
    if (toUninvoice.length > 0) {
      console.log('toUninvoice:', toUninvoice);
      const result = await this.invoicingService.uninvoiceGeneratedReports(toUninvoice);
      console.log('result:', result);
      if (result == true) {
        this.snackbarService.openSnackbar('Reports Successfully Uninvoiced', SnackbarType.success);
      } else {
        this.snackbarService.openSnackbar('Error Uninvoicing Reports', SnackbarType.error);
      }
      this.updateReport();
    } else {
      this.snackbarService.openSnackbar('No Invoiceable Reports Selected', SnackbarType.warning);
    }
  }
}

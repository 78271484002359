<h2
  *ngIf="isFail === false; else failBlock"
  mat-dialog-title
  class=" capitalize font-semibold text-headline-5 pl-4">
  {{ data.header }}
</h2>
<ng-template #failBlock>
  <h2 mat-dialog-title class=" capitalize font-semibold text-headline-5" style="color: #e62525">
    {{ data.header }}
  </h2>
</ng-template>

<mat-dialog-content style="overflow-y: hidden" class=" text-lg">
  {{ data.message }}
  <br />
  {{ data.detailMessage }}
</mat-dialog-content>
<div mat-dialog-actions class="flex flex-row items-center justify-end space-x-2">
  <button class="btn-cancel" mat-dialog-close>Cancel</button>
  <button class="btn-save" (click)="onConfirm($event)">Confirm</button>
</div>

<h3 mat-dialog-title class=" font-semibold text-headline-5 capitalize">{{ data.title }}</h3>
<div mat-dialog-content>
  <p class=" text-lg">{{ data.message }}</p>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial class=" capitalize font-semibold">{{cancelText}}</button>
  <button mat-raised-button [color]="confirmColor" [mat-dialog-close]="true" class=" capitalize font-semibold">
    {{ confirmText }}
  </button>
</div>

<div class="control-section">
  <ejs-schedule
    #scheduleObj
    width="100%"
    [height]="screenHeight ? screenHeight : 550"
    [selectedDate]="selectedDate"
    [eventSettings]="eventSettings"
    [allowDragAndDrop]="allowDragAndDrop"
    [allowResizing]="allowResize"
    [group]="groupModel"
    [views]="viewArr"
    [timeScale]="timeScale"
    [readonly]="readonly"
    [rowAutoHeight]="true"
    [firstDayOfWeek]="weekFirstDay"
    (eventRendered)="onEventRendered($event)"
    (popupOpen)="popupOpen($event)"
    (actionBegin)="actions.onActionBegin($event)"
    (popupClose)="actions.popupClose($event)"
    (cellClick)="actions.cellClick($event)"
    (actionComplete)="actions.actionComplete($event)"
    (dragStart)="actions.dragStop($event)"
    (dragStop)="actions.dragStop($event)"
    (eventClick)="onEventClick($event)"
    (renderCell)="onRenderCell($event)">
    <e-resources>
      <e-resource
        *ngIf="groupsDataSource != null"
        field="GroupId"
        title="Group"
        name="Groups"
        [dataSource]="groupsDataSource"
        textField="text"
        idField="id"></e-resource>
      <e-resource
        field="ResourceID"
        title="Resource"
        name="Resources"
        [dataSource]="resourcesDataSource"
        [allowMultiple]="allowMultipleCategory"
        textField="ResourceName"
        idField="ResourceID"></e-resource>
    </e-resources>

    <e-header-rows>
      <e-header-row option="Date"></e-header-row>
    </e-header-rows>

    <!-- Header Template -->
    <!-- instance date getting causes lag -->

    <ng-template #dateHeaderTemplate let-data>
      <div class="date-text">{{ getDateHeaderText(data.date) }}</div>
      <div>Out: {{ actions.getNumberOfSchuduledRigs(data.date) }}</div>
    </ng-template>

    <!-- data.resourceData.HexColour -->
    <!-- data.resourceData.ResourceName -->
    <ng-template #resourceHeaderTemplate let-data>
      <div
        style="height: 100%; width: 100%; font-size: 14px; font-weight: 500"
        [ngStyle]="{
          'background-color': data.resourceData.HexColour,
          color: getTextColour(data.resourceData.HexColour)
        }">
        <div style="padding: 8px">
          {{ data.resourceData.ResourceName }}
        </div>
      </div>
    </ng-template>

    <!-- Use these to customize the cards on each calendar view -->
    <!-- Can take this out and pass it in as a variable if want it to be different -->
    <!-- ng cause lag fix later -->
    <!-- Do not use flex layout or anything trigger change detection in template sync -->

    <e-views>
      <!-- Geo specific Views -->
      <e-view displayName="Work Week" option="TimelineWorkWeek" [workDays]="workWeekDays">
        <ng-template #eventTemplate let-data>
          <!-- Note -->
          <div
            *ngIf="data.EventTypeID == 3"
            class="event-card timeline-work-week-event-geo"
            [ngStyle]="{
              'background-color': data.EventColour,
              color: getTextColour(data.EventColour)
            }">
            <div class="event-body-notes">
              <textarea rows="6" readonly="true" class="text-area-element">{{ data.EventNotes }}</textarea>
            </div>
            <div class="event-body-notes">
              <mat-icon class="event-icon">group</mat-icon>
              <p class="event-text">
                {{ actions.getPeople(data.EventID) }}
              </p>
            </div>
          </div>
          <!-- parent/child event -->
          <!-- Header -->
          <!-- Body -->

          <div
            *ngIf="data.EventTypeID == 2"
            style="height: 64px; width: 100%"
            class="row"
            [ngStyle]="{ opacity: getEventOpacity(data) }">
            <div style="height: 16px">
              <div class="column e-arrow-icon-events">
                <mat-icon
                  class="e-arrow-icon-events"
                  *ngIf="data.StartTime > data.EventStart"
                  (click)="actions.navigatePrevEvent(data)">
                  arrow_forward
                </mat-icon>
              </div>
              <div class="column text-overflow-e" style="width: 276px">
                <div class="text-overflow-e column event-header-text" style="width: 124px">
                  {{ data.EventName }}
                </div>
                <div class="text-overflow-e column event-header-text" style="width: 76px">| {{ data.ContactName }}</div>
                <div class="text-overflow-e column event-header-text" style="width: 76px">| {{ data.JobNumber }}</div>
              </div>
              <div class="column e-arrow-icon-events">
                <mat-icon
                  class="e-arrow-icon-events"
                  *ngIf="data.EndTime < data.EventEnd"
                  (click)="actions.navigateNextEvent(data)">
                  arrow_forward
                </mat-icon>
              </div>
            </div>

            <div
              style="height: 48px; width: 101%"
              [ngStyle]="{
                'background-color': data.EventColour,
                color: getTextColour(data.EventColour)
              }">
              <div class="row" style="height: 16px; width: 300px">
                <div class="column text-overflow-e" style="height: 16px; width: 200px">
                  <mat-icon class="event-icon-custom">work</mat-icon>
                  <span class="event-text-geo">{{ data.ExtentOfWork }}</span>
                </div>
                <div class="column text-overflow-e" style="height: 16px; width: 100px">
                  <mat-icon class="event-icon-custom">location_on</mat-icon>
                  <span class="event-text-geo">{{ data.City }}</span>
                </div>
              </div>
              <div class="row" style="height: 16px; width: 300px">
                <div class="column text-overflow-e" style="height: 16px; width: 200px">
                  <mat-icon class="event-icon-custom">description</mat-icon>
                  <span class="event-text-geo">{{ data.EventNotes }}</span>
                </div>
                <div class="column text-overflow-e" style="height: 16px; width: 200px">
                  <mat-icon class="event-icon-custom">person</mat-icon>
                  <span class="event-text-geo">{{ data.UserResponsible }}</span>
                </div>
              </div>
              <div class="row" style="height: 16px; width: 298px">
                <div class="column text-overflow-e" style="height: 16px; width: 200px">
                  <mat-icon class="event-icon-custom">people</mat-icon>
                  <span class="event-text-geo">{{ actions.getPeople(data.EventID) }}</span>
                </div>
                <div
                  class="column text-overflow-e"
                  style="height: 16px; width: 98px; overflow: hidden; text-align: end">
                  <ejs-tooltip *ngFor="let tag of data.Tags" #tooltip [content]="actions.getTagName(tag)">
                    <svg height="12px" width="12px" style="padding-left: 4px">
                      <circle
                        cx="6"
                        cy="6"
                        r="5px"
                        [ngStyle]="{ stroke: getTextColour(data.EventColour) }"
                        stroke-width="1"
                        [attr.fill]="actions.getTagColour(tag)" />
                    </svg>
                  </ejs-tooltip>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </e-view>

      <!-- 2 weeks -->
      <e-view displayName="Custom" option="TimelineWeek" [interval]="weekInterval">
        <ng-template #eventTemplate let-data>
          <!-- Note -->
          <div
            *ngIf="data.EventTypeID == 3"
            [ngStyle]="{
              'background-color': data.EventColour,
              color: getTextColour(data.EventColour)
            }">
            <div class="event-body-notes">
              <textarea readonly="true" class="text-area-element">{{ data.EventNotes }}</textarea>
            </div>
          </div>

          <div *ngIf="data.EventTypeID == 2" style="height: 19px; width: 124px" class="row">
            <div style="height: 19px">
              <div class="column e-arrow-icon-events">
                <mat-icon
                  class="e-arrow-icon-events"
                  *ngIf="data.StartTime > data.EventStart"
                  (click)="actions.navigatePrevEvent(data)">
                  arrow_forward
                </mat-icon>
              </div>
              <div class="column text-overflow-e" style="width: 100px">
                {{ data.EventName }}
              </div>
              <div class="column e-arrow-icon-events">
                <mat-icon
                  class="e-arrow-icon-events"
                  *ngIf="data.EndTime < data.EventEnd"
                  (click)="actions.navigateNextEvent(data)">
                  arrow_forward
                </mat-icon>
              </div>
            </div>
          </div>
        </ng-template>
      </e-view>
    </e-views>

    <!-- Quick Info Template -->

    <ng-template #quickInfoTemplatesHeader let-data>
      <div *ngIf="data.elementType === 'event'">
        <div
          class="e-popup-header"
          [ngStyle]="{
            'background-color': data.EventTypeID == 3 ? data.EventColour : actions.getResourceColour(data.ResourceID)
          }">
          <div class="e-header-icon-wrapper button-gap-padding-row" style="height: 40px">
            <button
              mat-icon-button
              *ngIf="data.EventTypeID == 2 && !readonly"
              color="primary"
              (click)="actions.onCopyClick(data.AssignmentID)"
              class="quick-info-icon-button"
              [ngStyle]="{
                color: data.EventTypeID == 3 ? getTextColour(data.EventColour) : '#fff'
              }">
              <mat-icon class="quick-info-icon">content_copy</mat-icon>
            </button>

            <button
              mat-icon-button
              *ngIf="data.EventTypeID == 2 && !readonly"
              color="primary"
              (click)="actions.onMoveToClick(data)"
              class="quick-info-icon-button"
              [ngStyle]="{
                color: data.EventTypeID == 3 ? getTextColour(data.EventColour) : '#fff'
              }">
              <mat-icon style="line-height: 28px" class="quick-info-icon">
                <img style="max-height: 20px; max-width: 20px; height: 20px; width: 20px" id="multiEdit" />
              </mat-icon>
            </button>

            <button
              mat-icon-button
              *ngIf="!readonly"
              color="primary"
              (click)="openEditor(data, 'Save')"
              class="quick-info-icon-button"
              [ngStyle]="{
                color: data.EventTypeID == 3 ? getTextColour(data.EventColour) : '#fff'
              }">
              <mat-icon class="quick-info-icon">create</mat-icon>
            </button>

            <button
              mat-icon-button
              *ngIf="!readonly"
              color="primary"
              (click)="deleteEvent(data.EventID)"
              class="quick-info-icon-button"
              [ngStyle]="{
                color: data.EventTypeID == 3 ? getTextColour(data.EventColour) : '#fff'
              }">
              <mat-icon class="quick-info-icon">delete</mat-icon>
            </button>

            <button
              mat-icon-button
              color="primary"
              (click)="closeQuickPopup()"
              class="quick-info-icon-button"
              [ngStyle]="{
                color: data.EventTypeID == 3 ? getTextColour(data.EventColour) : '#fff'
              }">
              <mat-icon class="quick-info-icon">close</mat-icon>
            </button>
          </div>

          <div class="e-subject-wrap">
            <div
              class="e-subject e-text-ellipsis"
              [ngStyle]="{
                color: data.EventTypeID == 3 ? getTextColour(data.EventColour) : '#fff'
              }"
              title="Subject">
              {{ data.EventName }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #quickInfoTemplatesContent let-data>
      <div fxLayout="column" fxLayoutGap="8px">
        <div>
          <div fxLayout="row" fxLayoutAlign="start center" class="quick-info-body">
            <mat-icon class="event-icon">location_on</mat-icon>
            <p class="quick-info-event-text" fxFlex>
              {{ data.Location }}
            </p>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" class="quick-info-body">
            <mat-icon class="event-icon">person</mat-icon>
            <p class="quick-info-event-text" fxFlex>
              {{ actions.getPeople(data.EventID) }}
            </p>
          </div>

          <div *ngIf="data.EventTypeID == 2" style="padding-right: 8px">
            <p class="mat-body">Ticket</p>
            <button mat-raised-button class="quick-info-button bg-white" (click)="actions.viewTicket(data, false)">
              <div class="text-black">{{ actions.getDisplayTicketString(data.AssignmentID) }}</div>
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Editor Template -->
    <ng-template #editorTemplate let-data>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between stretch"
        class="editor-container"
        style="height: 100%"
        fxLayoutGap="16px grid">
        <div fxFlex="100" fxLayoutAlign="start" [hidden]="data.EventTypeID == 3">
          <p class="mat-h3" style="margin: 0px">Project</p>
        </div>
        <div fxFlex="50" [hidden]="data.EventTypeID == 3">
          <div class="e-float-input">
            <input class="e-field e-input" [readonly]="true" id="eventName" type="text" value="" name="EventName" />
            <span class="e-float-line e-label-top"></span>
            <label class="e-float-text e-label-top">Event Name</label>
          </div>
        </div>

        <div fxFlex="50" [hidden]="data.EventTypeID == 3">
          <div class="e-float-input">
            <input
              class="e-field e-input"
              [readonly]="true"
              id="location"
              type="text"
              name="Location"
              maxlength="100" />
            <span class="e-float-line e-label-top"></span>
            <label class="e-float-text e-label-top">Location</label>
          </div>
        </div>

        <div fxFlex="50" [hidden]="true">
          <input class="e-field" id="EventTypeID" name="EventTypeID" placeholder="Start Time" />
        </div>

        <!-- start and end of single event -->
        <div fxFlex="50" [hidden]="data.EventTypeID != 3">
          <input class="e-field" id="startTime" name="StartTime" placeholder="Start Time" />
        </div>

        <div fxFlex="50" [hidden]="data.EventTypeID != 3">
          <input class="e-field" id="endTime" name="EndTime" placeholder="End Time" />
        </div>

        <!-- start and end of event series -->
        <div fxFlex="50" [hidden]="data.EventTypeID == 3">
          <input class="e-field" id="eventStart" name="EventStart" placeholder="Start Time" />
        </div>

        <div fxFlex="50" [hidden]="data.EventTypeID == 3">
          <input class="e-field" id="eventEnd" name="EventEnd" placeholder="End Time" />
        </div>

        <div fxFlex="100" [hidden]="data.EventTypeID == 3">
          <ejs-checkbox
            label="Inculde Weekends"
            id="bIncludeWeekends"
            class="e-field e-input"
            data-name="bIncludeWeekends"
            [checked]="data.bIncludeWeekends"></ejs-checkbox>
        </div>

        <div fxFlex=" 100" fxLayout="column" fxLayoutGap="8px" [hidden]="data.EventTypeID == 3">
          <p class="ticket-label">Ticket</p>
          <button
            *ngIf="data.AssignmentID != null"
            mat-raised-button
            class="event-button"
            (click)="actions.viewTicket(data, true)">
            {{ actions.getDisplayTicketString(data.AssignmentID) }}
          </button>

          <div [hidden]="data.AssignmentID != null">
            <input class="e-field" id="ticketInput" data-name="AssignmentID" />
          </div>
        </div>

        <div fxFlex="100" fxLayoutAlign="start" [hidden]="data.EventTypeID == 3">
          <p class="mat-h3" style="margin: 0px">Resouce</p>
        </div>

        <div fxFlex="100" [hidden]="data.EventTypeID == 3">
          <input id="resourceInput" class="e-field" data-name="ResourceID" />
        </div>

        <div fxFlex="100" fxLayoutAlign="start" [hidden]="data.EventTypeID == 3">
          <p class="mat-h3" style="margin: 0px">Event</p>
        </div>

        <div fxFlex="100">
          <div fxLayout="row" fxLayoutAlign="start end">
            <div class="e-float-input">
              <input
                class="e-field e-input"
                id="EventColour"
                type="text"
                name="EventColour"
                [value]="currentEventColour" />
              <span class="e-float-line e-label-top"></span>
              <label class="e-float-text e-label-top">Event Colour</label>
            </div>

            <mat-icon
              style="
                padding-bottom: 4px;
                font-size: 18px;
                height: 18px;
                width: 18px;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.54);
              "
              (click)="currentEventColour = clearEventColour(data.EventTypeID)">
              close
            </mat-icon>
            <div fxFlex="16" style="width: 100%" fxLayout="row" fxLayoutAlign="end center">
              <ngx-colors
                ngx-colors-trigger
                [hideColorPicker]="false"
                [hideTextInput]="true"
                (change)="colorPickerChange($event)"></ngx-colors>
            </div>
          </div>
        </div>

        <div fxFlex="100" [hidden]="false" id="filterResources">
          <ejs-checkbox
            #filterResources
            label="Only Show Available People"
            class="e-field e-input"
            data-name="filterResources"
            [checked]="true"
            (change)="onCheckChange($event, data)"></ejs-checkbox>
        </div>

        <div fxFlex="100" [hidden]="false">
          <input class="e-field" id="drillerInput" data-name="UserResponsibleID" />
        </div>

        <div fxFlex="100" [hidden]="false">
          <input class="e-field" id="helperInput" data-name="Helpers" />
        </div>

        <div fxFlex="100" [hidden]="false">
          <input class="e-field" id="resourceEquipmentInput" data-name="ResourcesEquipment" />
        </div>

        <div fxFlex="100">
          <div class="e-float-input">
            <textarea
              id="Description"
              class="e-field e-input"
              id="eventNotes"
              name="EventNotes"
              rows="3"
              cols="50"
              value="{{ data.EventNotes }}"
              style="width: 100%; height: 50px !important; resize: vertical"></textarea>
            <label class="e-float-text">Notes</label>
          </div>
        </div>
      </div>
    </ng-template>
  </ejs-schedule>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="center center"
  style="width: 100%; height: 100%"
  class="background"
>
<span class="text-[20px]  font-semibold text-login">SMARTER, BETTER, FASTER, SAFER LOCATES</span>

  <div
    class="app-register-content-container"
    fxFlex.xs="100"
    fxFlex="390px"
    fxLayout="column"
  >
    <img class="h-[72px] w-[72px]" src="assets/icons/branding/urbint/72x72.png" alt="Utilocate logo" />
    <img class="h-[140px] w-[140px]" src="assets/svg/utilocate word-01.svg" alt="Utilocate word" />

    <!-- <h2 class=" text-[32px] mt-[12.5px] mb-[16px]">utilocate</h2> -->

    <div class="flex flex-col justify-center items-center w-full">
      <span class="font-semibold  text-[20px] capitalize mb-3">{{errorMessage}}</span>
    </div>

    <div class="flex flex-col justify-left items-center w-full">

      <div class="flex flex-col justify-left items-center w-full" *ngFor="let client of clientList">
        <button
          (click)="handleLogin(client)"
          mat-stroked-button
          class="mat-button app-full-width capitalize text-sm font-semibold "
          style="width: 100%; margin-bottom: 20px"
        >
          {{client.ClientName}}
        </button>
      </div>
      
      <div class="flex flex-col justify-left items-center w-full">
        <button
          (click)="returnToLogin()"
          mat-stroked-button
          class="mat-button app-full-width capitalize text-sm font-semibold "
          style="width: 100%; margin-bottom: 20px"
        >
          Return to Login
        </button>
      </div>
      <!-- <p class="mat-body-xs">
        By logging in you agree to our
        <a style="cursor: pointer; color: primary" (click)="openPrivacyPolicy()"
          >Privacy Policy</a
        >
      </p> -->
    </div>
  </div>
</div>

<!-- <button mat-icon-button class="update-btn text-[10px] h-12 w-24 " (click)="resetApp()">
  <mat-icon class="h-3 w-[14px] text-md"> refresh</mat-icon>
  REFRESH
</button> -->

<div class="app-login-container"></div>

import { Component, Input, OnInit } from '@angular/core';
import { RiskScoreCellComponent } from '../risk-score/risk-score.component';
import { MatIconModule } from '@angular/material/icon';
import { TicketService } from '../ticket/ticket.service';
import { UserService } from '../../core/services/user/user.service';
import { SettingID } from "src/app/modules/core/services/user/setting";
import { DamageThreatRow } from './damageThreat.type';
import { ImpactRow } from './impact.type';
import { MatButtonModule } from '@angular/material/button';
import { ImpactCalculatorModalComponent } from '../ticket/modals/impact-calculator-modal/impact-calculator-modal';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../ticket/modals/generic-confirmation-modal/confirmation-modal.component';
import { RiskCalculatorModalComponent } from '../ticket/modals/risk-calculator-modal/risk-calculator-modal';

@Component({
  selector: 'app-model-explainer',
  standalone: true,
  imports: [RiskScoreCellComponent, MatIconModule, MatButtonModule],
  templateUrl: './model-explainer.component.html'
})
export class ModelExplainerComponent implements OnInit {
  @Input() assignmentID: string = '';

  ticketDamageThreat: object | null = null;
  ticketDamageThreatLabel: string | null = null;
  ticketImpact: object | null = null;
  ticketImpactLabel: string | null = null;
  loadedDamage: boolean = false;
  loadedImpact: boolean = false;

  damageThreatSettingOn: boolean = false;
  impactSettingOn: boolean = false;
  address: string = '';

  constructor(private ticketService: TicketService, private userService: UserService, public dialog: MatDialog,
  ) {

  }

  async ngOnInit(): Promise<void> {
    //if the setting for damage threat is active 
    this.damageThreatSettingOn = this.userService.isSettingActive(SettingID.DAMAGE_THREAT);
    this.impactSettingOn = this.userService.isSettingActive(SettingID.IMPACT);

    if (this.damageThreatSettingOn) {
      //then get the damage threat row and set the label
      await this.ticketService.getTicketDamageThreat(this.assignmentID).then((result: DamageThreatRow) => {
        this.ticketDamageThreat = result;
        if (result && "RiskLabel" in result) {
          this.ticketDamageThreatLabel = result.RiskLabel as string;
        }
        this.loadedDamage = true;
      })
    }

    //If the setting for impact is active
    if (this.impactSettingOn) {
      //then get the impact row and set the label
      this.ticketService.getImpact(this.assignmentID).then((result: ImpactRow) => {
        this.ticketImpact = result;
        if (result && "ImpactLabel" in result) {
          this.ticketImpactLabel = result.ImpactLabel as string;
        }
        this.loadedImpact = true;
      })
    }

    this.address = this.ticketService.getAddress();
    
  }

  openDamageThreat() { 
    this.dialog
    .open(RiskCalculatorModalComponent, {
      data: this.ticketDamageThreat,
    })   
  }

  openImpact() {
    this.dialog
    .open(ImpactCalculatorModalComponent, {
      data: this.ticketImpact,
    })
   }   
  }

<div *ngIf="isValidDownload; else invalidDownload">
  <app-ticket-list
    [TicketListItems]="ticketList"
    (ticketListItemClick)="onTicketItemClick($event)"
    [CurrentlySelectedTicket]="currentlySelectedTicket$"
  ></app-ticket-list>
</div>

<ng-template #invalidDownload>
  <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
    <mat-icon style="font-size: 150px; height: 150px; width: 150px"
      ><img id="closeIcon"
    /></mat-icon>
    <p class="mat-headline-2">400</p>
    <p class="mat-headline-2">Mauvaise requête</p>
    <p class="mat-headline-2">TEST BAD REQUEST</p>
  </div>
</ng-template>

<app-base-modal #userDialog [id]="CONFIRM_SELF_ASSIGN_MODAL" maxwidth="360px">
  <ng-container class="competers-modal-content">
    <div
      fxLayout="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="16px"
    >
      <div fxLayout="column" fxLayoutGap="16px" style="width: 100%">
        <span class="capitalize  font-semibold text-headline-5">Self Assign Ticket</span>

        <span class=" text-lg"
          >Do you want to assign ticket
          {{ this.selectedTicket ? this.selectedTicket.RequestNumber : "n/a" }}
          to yourself?</span
        >
      </div>

      <!-- Bottom Buttons -->
      <div
        class="btn-section"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button
          class="btn-cancel"
          (click)="onSelfAssignCancelClick()"
        >
          Cancel
        </button>
        <button
          disable-on-submit
          class="btn-save"
          (click)="onSelfAssignConfirmClick()"
        >
          Confirm
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>

{
  "versionHistory": [
    {
      "VersionNumber": "4.2.9",
      "VersionDate": "2025-04-02",
      "Title": "4.2.9",
      "Description": [
        "UI Urbint Updates",
        "Initial release of UI for Risk and Impact"
      ]
    }, 
    {
      "VersionNumber": "4.2.8",
      "VersionDate": "2025-03-05",
      "Title": "4.2.8",
      "Description": [
        "Ability to add a background to a color",
        "Ability to duplicate a feature in drawing tool",
        "CTRL/CMD click Pins in multi-select",
        "Ability to change utility line thickness",
        "Default utility line thickness reduced",
        "Added tooltips in drawing tool",
        "Arrow tool change to head first then tail",
        "Mobile zoom on photo previews",
        "Color fix for stopwatch"
      ]
    }, 
    {
      "VersionNumber": "4.2.7",
      "VersionDate": "2025-02-03",
      "Title": "4.2.7",
      "Description": [
        "Locator Disabled Feature (Like U2)",
        "Bulk Excavation Date Validation",
        "Minor bug fixes"
      ]
    }, 
    {
      "VersionNumber": "4.2.6",
      "VersionDate": "2025-01-14",
      "Title": "4.2.6 On Hold & Timezone",
      "Description": [
        "Added option to mark ticket as 'on hold' or 'not clear'",
        "Added timezone standardization",
        "View and toggle map layers from homepage",
        "Minor bug fixes"
      ]
    }, 
    {
      "VersionNumber": "4.2.4",
      "VersionDate": "2024-12-06",
      "Title": "4.2.2 Hotfix",
      "Description": [
        "Ordering bug fix"
      ]
    }, 
    {
      "VersionNumber": "4.2.3",
      "VersionDate": "2024-12-04",
      "Title": "4.2.3 Map Layer Updates",
      "Description": [
        "Toggle Labels",
        "Specify Mapserver Layers"
      ]
    }, 
    {
      "VersionNumber": "4.2.2",
      "VersionDate": "2024-12-02",
      "Title": "4.2.2 Map Layer Updates",
      "Description": [
        "Toggle Labels",
        "Specify Mapserver Layers"
      ]
    }, 
    {
      "VersionNumber": "4.2.1",
      "VersionDate": "2024-11-20",
      "Title": "4.2.1 Hotfix",
      "Description": [
        "PDF Preview Fix",
        "Assignment Triggers"
      ]
    }, 
    {
      "VersionNumber": "4.2.0",
      "VersionDate": "2024-11-18",
      "Title": "2024-11-18 Photo Integrity",
      "Description": [
        "Updated photo taking and integrity",
        "New setting to validate document uploading",
        "Clear Ticket data upload bug fix",
        "Excavator texting update"
      ]
    }, 
    {
      "VersionNumber": "4.1.2",
      "VersionDate": "2024-10-08",
      "Title": "2024-10-08 Update",
      "Description": [
        "1st Iteration Excavator Renegotiation Texting",
        "User Category Management Updates",
        "Ticket Detail Template updates"
      ]
    }, 
    {
      "VersionNumber": "4.1.1",
      "VersionDate": "2024-09-25",
      "Title": "2024-09-25 Update",
      "Description": [
        "Ticket loading bug fix",
        "Edit details bug fix"
      ]
    }, 
    {
      "VersionNumber": "4.1.0",
      "VersionDate": "2024-09-23",
      "Title": "2024-09-23 Update",
      "Description": [
        "Homepage template saving",
        "Form Template Updates",
        "First stage of offline"
      ]
    }, 
    {
      "VersionNumber": "4.0.13",
      "VersionDate": "2024-09-04",
      "Title": "2024-09-04 Update",
      "Description": [
        "Fullscreen drawing mode",
        "Increase Drawing visibility",
        "Esri Inspector Tool Fix",
        "Photo Captions and Editing"
      ]
    }, 
    {
      "VersionNumber": "4.0.12",
      "VersionDate": "2024-08-20",
      "Title": "2024-08-20 Update",
      "Description": [
        "Table Updates",
        "Style Updates",
        "Map Capture Blank Base"
      ]
    }, 
    {
      "VersionNumber": "4.0.11",
      "VersionDate": "2024-08-06",
      "Title": "AD and Drawing Updates",
      "Description": [
        "Ability to edit text",
        "SSO multiple emails",
        "SVG Symbols",
        "Billing Rule Updates"
      ]
    }, 
    {
      "VersionNumber": "4.0.10",
      "VersionDate": "2024-07-09",
      "Title": "Version 4 Captions",
      "Description": [
        "Ability to add Captions to Photos",
        "Rule Builder Updates"
      ]
    }, 
    {
      "VersionNumber": "4.0.9",
      "VersionDate": "2024-06-27",
      "Title": "Version 4 Hotfix",
      "Description": [
        "Self Assign Bugfix",
        "Dig area over Satellite"
      ]
    }, 
    {
      "VersionNumber": "4.0.8",
      "VersionDate": "2024-06-19",
      "Title": "Version 4 Homepage Updates",
      "Description": [
        "Pin Coloring Updates",
        "Dispatch Area Features",
        "Routing Users",
        "Login Changes"
      ]
    }, 
    {
      "VersionNumber": "4.0.7",
      "VersionDate": "2024-06-06",
      "Title": "Version 4 Updates",
      "Description": [
        "Made Drawing Naming a Setting",
        "Drawing Bug Fixes",
        "Ticket Summary Sorting",
        "Compliance Color Updates"
      ]
    }, 
    {
      "VersionNumber": "4.0.6",
      "VersionDate": "2024-06-04",
      "Title": "Version 4 Auxiliary",
      "Description": [
        "Added Auxiliary Details",
        "Added Primary Drawing Capture",
        "Create Ticket Updates",
        "Homepage User Color Updates"
      ]
    }, 
    {
      "VersionNumber": "4.0.4",
      "VersionDate": "2024-05-21",
      "Title": "Version 4 Reports Tab",
      "Description": [
        "Added First version of Reports tab",
        "Added Reports tab setting",
        "Added Homepage Date Quick Selection",
        "Fixed Quick Search Scaling"
      ]
    }, 
    {
      "VersionNumber": "4.0.3",
      "VersionDate": "2024-05-14",
      "Title": "Version 4 Update",
      "Description": [
        "Scheduler Updates",
        "User Manager Changes",
        "Ticket Info Display Updates",
        "Copy Ticket Bug Fix"
      ]
    }, 
    {
      "VersionNumber": "4.0.2",
      "VersionDate": "2024-05-07",
      "Title": "Homepage and Bug Fixes",
      "Description": [
        "Homepage Searching Update",
        "User Manager Save Bug Fix",
        "Displaying Ticket Information on Map Pin Bug Fix"
      ]
    }, 
    {
      "VersionNumber": "4.0.0",
      "VersionDate": "2024-04-29",
      "Title": "Utilocate Version 4",
      "Description": [
        "UI Updates",
        "U4 Homepage",
        "Ticket View Update",
        "New User Manager & Setting Features",
        "User Category Management",
        "Setting & Site management Page",
        "Drawing Tool Canvas Drawing (Saving Coming Soon)",
        "Document Tab Gallery Mode"
      ]
    }, 
    {
      "VersionNumber": "2.0.6",
      "VersionDate": "2024-03-08",
      "Title": "Unlocatable Verified",
      "Description": [
        "Prompt for notes when marking Unlocatable Verified"
      ]
    }, 
    {
      "VersionNumber": "2.0.5",
      "VersionDate": "2024-03-06",
      "Title": "360 System Maintenance",
      "Description": [
        "Added 360 System Maintenance",
        "Improved 360 Logs",
        "Utility Specific Maps Fieldside"
      ]
    }, 
    {
      "VersionNumber": "2.0.4",
      "VersionDate": "2024-02-22",
      "Title": "Invoice Report & Templates",
      "Description": [
        "Invoice Reports Page changes",
        "Form Template Updates"
      ]
    }, 
    {
      "VersionNumber": "2.0.2-2.0.3",
      "VersionDate": "2024-02-13",
      "Title": "Invoice Reports",
      "Description": [
        "Invoice Reports Page",
        "UI bug fixes"
      ]
    }, 
    {
      "VersionNumber": "2.0.1",
      "VersionDate": "2024-01-30",
      "Title": "Backend Upgrades and Bug Fixes",
      "Description": [
        "Upgrading Backend Resources",
        "Check For Updates on Login",
        "Minor Bug Fixes"
      ]
    }, 
    {
      "VersionNumber": "2.0.0",
      "VersionDate": "2024-01-16",
      "Title": "Upgrade Resourses and Frameworks",
      "Description": [
        "Upgrade Services",
        "Upgrade Modules",
        "Upgrade Frameworks"
      ]
    }, 
    {
      "VersionNumber": "1.7.0-1.7.1",
      "VersionDate": "2023-12-05",
      "Title": "U4 Document Storage",
      "Description": [
        "Local Storage Update",
        "ESRI Map Upgrade",
        "ESRI WMS Services"
      ]
    }, 
    {
      "VersionNumber": "1.6.33",
      "VersionDate": "2023-11-30",
      "Title": "U4 Storage Update",
      "Description": [
        "Update to local file storing"
      ]
    }, 
    {
      "VersionNumber": "1.6.32",
      "VersionDate": "2023-11-28",
      "Title": "U4 Upgrades",
      "Description": [
        "Add Sendable Documents Fieldside",
        "Ability to Add Second Email",
        "Auto Engine Improvements",
        "User Manager Bug Fixes"
      ]
    }, 
    {
      "VersionNumber": "1.6.31",
      "VersionDate": "2023-11-13",
      "Title": "ESRI Viewer Updates",
      "Description": [
        "Measurement Tool",
        "Layer Selection Bug Fix",
        "Rule Builder Updates",
        "Ticket and Verification Bug Fixes",
        "Version Information Page"
      ]
    }, 
    {
      "VersionNumber": "1.6.30",
      "VersionDate": "2023-11-01",
      "Title": "Symbols and Mapping Updates",
      "Description": [
        "Reduced Symbol Sizes",
        "Rule Builder and Version Page Updates",
        "Set Specific ESRI versioning"
      ]
    }, 
    {
      "VersionNumber": "1.6.26-1.6.29",
      "VersionDate": "2023-10-24",
      "Title": "User Centralization, Rule Builder UI",
      "Description": [
        "User Management Updates",
        "Rule Builder Updates",
        "Version Information Page"
      ]
    }, 
    {
      "VersionNumber": "1.6.24-1.6.25",
      "VersionDate": "2023-10-10",
      "Title": "Added scroll lock on map + Bug fixes",
      "Description": [
        "Mapping Updates and Bug Fixes",
        "Create Audit Fieldside"
      ]
    }, 
    {
      "VersionNumber": "1.6.22-1.6.23",
      "VersionDate": "2023-09-19",
      "Title": "Updates + Bug Fixes",
      "Description": [
        "Mapping and GPS Tracking Updates",
        "Fix Issue loading dig area shapes",
        "Timesheet Editing",
        "New Screenshot Tool"
      ]
    },
    {
      "VersionNumber": "1.6.21",
      "VersionDate": "2023-09-19",
      "Title": "Updates + Bug Fixes",
      "Description": [
        "Timesheet",
        "Route Order Fieldside display Route Number on Ticket List",
        "Punchclock"
      ]
    },
    {
      "VersionNumber": "1.6.17",
      "VersionDate": "2023-08-30",
      "Title": "Drawing Tool Feature Management + Added Punchclock",
      "Description": [
        "Punchclock",
        "Form Editing Updates"
      ]
    },
    {
      "VersionNumber": "1.6.16",
      "VersionDate": "2023-08-22",
      "Title": "Feature Drop",
      "Description": [
        "Mapping Updates"
      ]
    },
    {
      "VersionNumber": "1.6.13-1.6.14",
      "VersionDate": "2023-08-10",
      "Title": "Feature Update",
      "Description": [
        "Fix Digsite and Marker not showing in Esri-Viewer",
        "Delete Local Documents Fieldside",
        "Unlocatable and Unlocatable Verified Process",
        "Photo Required Feature",
        "Sort by route order in ticket list"
      ]
    },
    {
      "VersionNumber": "1.6.10-1.6.11",
      "VersionDate": "2023-07-24",
      "Title": "Feature Update + Bug Fixes",
      "Description": [
        "Self Assign",
        "Show CallType in ticket list and self assign list",
        "User Management Updates",
        "SendToLSP Button in the Web U4",
        "Add Internal Photo"
      ]
    }
  ]
}



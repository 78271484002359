<div
  #formContainer
  *ngIf="isVisible"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="form-main p-2"
>
  <!-- Header -->
  <ng-container
    *ngIf="readOnly || !props.hasHiddenValues; else hiddenValueHeader"
  >
    <ng-container *ngIf="!canRemove; else removableCategory">
      <div class="flex flex-row items-center content-start" class="w-100 text-left text-lg  font-semibold capitalize py-2">
        <span class="text-primary px-2 pt-2  capitalize">{{
          props.header
        }}</span>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #removableCategory>
    <div
      class="flex flex-row items-center content-start text-primary w-full text-left text-lg  font-semibold capitalize py-2"
    >
      <span class="text-lg text-primary px-2 pt-2  capitalize">{{
        props.header
      }}</span>
      <button mat-icon-button (click)="onRemove()">
        <mat-icon class="text-headline-6">remove</mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #hiddenValueHeader>
    <ng-container *ngIf="!canRemove; else hiddenValueRemoveHeader">
      <button
      mat-button
      color="primary"
      type="button"
      class="w-full pl-2 justify-between"
      (click)="onEdit(props)"
      > 
        <span class="order-1 text-lg  capitalize font-semibold">{{
          props.header
        }}</span>
        <mat-icon class="order-2 text-headline-6">edit</mat-icon>
      </button>
    </ng-container>
  </ng-template>

  <ng-template #hiddenValueRemoveHeader>
      <button
        mat-button
        color="primary"
        type="button"
        class="w-full pl-2 justify-between"
        (click)="onEdit(props)"
      >
      <span class="order-1 text-lg  capitalize font-semibold">{{
        props.header
      }}</span>
      <mat-icon class="order-2">edit</mat-icon>
      </button>

      <button mat-icon-button (click)="onRemove()">
        <mat-icon class="text-headline-6">remove</mat-icon>
      </button>
  </ng-template>

  <div class="border-solid border-divider-lighter border-x-0 border-b-0 border-[1px]">
    <!-- Fields -->
    <ng-container *ngIf="hasFields">
      <app-recursive-form-input-template
        [props]="fieldProps"
        [readOnly]="readOnly"
      ></app-recursive-form-input-template>
    </ng-container>

    <!-- Categories -->
    <ng-container *ngFor="let group of subGroups | keyvalue">
      <div
        fxFlexOrder="{{ group.value.groupOrder }}"
      >
        <app-recursive-form-group-template
          [props]="group.value"
          [readOnly]="readOnly"
          (addCategories)="addCategories.emit($event)"
          (removeCategory)="removeCategory.emit($event)"
          (isHideShowToggle)="isHideShowToggle.emit($event)"
        ></app-recursive-form-group-template>
      </div>
    </ng-container>
  </div>
</div>

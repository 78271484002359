import { BehaviorSubject } from "rxjs";

const currentUnits$ = new BehaviorSubject<MapUnits>('m');

const unitDict = {
  m: {
    name: 'metres',
    factor: 1,
  },
  km: {
    name: 'kilometres',
    factor: 0.001,
  },
  ft: {
    name: 'feet',
    factor: 3.28084,
  },
  yd: {
    name: 'yards',
    factor: 1.09361,
  },
  mi: {
    name: 'miles',
    factor: 0.000621371,
  },
};

const updateUnits = (unit: MapUnits) => {
  currentUnits$.next(unit);
  return currentUnits$.value;
};

const getUnitDict = () => unitDict;

const getUnits = () => Object.keys(unitDict);
const getCurrentUnits$ = () => currentUnits$;

export default {
  updateUnits,
  getUnits,
  getUnitDict,
  getCurrentUnits$
}

export type MapUnits = keyof typeof unitDict;

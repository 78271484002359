<div class="w-[550px] h-[528px] bg-white rounded-lg inline-flex flex-col">
  <div class="pl-6 pr-4 pt-4 flex items-center gap-6">
    <div class="flex flex-1 flex-row">
      <mat-icon class="w-[35px] h-[35px]" [style.color]="colorArray[score]" [svgIcon]="insights[activeTab].icon"></mat-icon>
      <div class="text-[18px] font-semibold text-slate-900 pl-4 flex items-center">{{insights[activeTab].title}}</div>
    </div>

    <div>
      <button mat-icon-button (click)="onConfirm()">
        <mat-icon class="text-[26px]">close</mat-icon>
      </button>    
    </div>
  </div>

  <div class="flex flex-col items-start gap-4 h-[364px] px-6 py-4">
    <div class="flex flex-row gap-6">
 <div class="h-40 flex flex-col gap-2 flex-wrap px-6">
<app-percentage-slider [score]="insights[activeTab].percentage != 'null' ? insights[activeTab].percentage : 0 " [color]="colorArray[score] ?? '#238914'"></app-percentage-slider>
  </div>
  <div class="flex flex-col gap-6">
  <div class="flex flex-col gap-1">
    <span class="text-md">Relative rank</span>
    <div class="flex">
    <app-risk-score-cell [score]="score" [increaseSize]="true" />
  </div>
  </div>
  <div class="flex flex-col">
    <span class="text-md pb-[4px] text-[#3C4F55]">Damage rate</span>
    <span class="text-[28px] font-semibold">{{insights[activeTab].rate != 'null' ? insights[activeTab].rate : 0 }}</span>
    <span class="text-[11px] text-[#041E25]">Damages/1000 tickets</span>
  </div>
</div>
</div>
<div class="leading-[110%]">
  <span class="text-[11px] leading-[110%] font-light">The insights shared provide information related to some of the risk factors that drive Urbint's Machine Learning Models. However, the actual Machine Learning model's decision is based on combined effect of a multitude of engineered features including the ones shared above.</span>
</div>
<div class="leading-[110%] flex flex-col gap-2">
  <span class="text-[14px] leading-[150%] font-normal">If you think the risk rating needs to be revised, we'd appreciate your feedback to enhance our model.</span>
  <mat-form-field appearance="outline" class="dense-6 w-[376px] h-9 rounded-md">
    <mat-select class="capitalize font-normal" placeholder="Please select an option" [(value)]="selectedOption">
      <mat-option
        class="capitalize font-normal text-lg"
        (click)="feedbackClicked()"
        *ngFor="let option of options"
        [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
@if(showSubmit){
  <button class="btn-save w-[71px] bg-[#007899]" (click)="submitFeedback()">Submit</button>
}
</div>
</div>
 <div class="px-6 py-4 border-0 border-[#BDC1C3] border-t-1 border-solid text-end flex {{showPrevious ? 'justify-between' : 'justify-end'}}">
  <ng-container *ngIf="showPrevious">
    <button class="btn-cancel w-[116px] border-[#BDC1C3] border-1 border-solid text-lg flex items-center font-medium" (click)="prevClicked()"> 
      <mat-icon class="text-[16px] flex items-center justify-end">arrow_back_ios</mat-icon>
      Previous
    </button>
  </ng-container>
  <ng-container *ngIf="showNext">
  <button class="btn-save w-[143px] bg-[#007899] text-lg" (click)="nextClicked()">Next risk factor</button>
  </ng-container>
  <ng-container *ngIf="showDone">
    <button class="btn-save w-[71px] bg-[#007899] text-lg" (click)="doneClicked()">Done</button>
    </ng-container>
 
 </div>
  
</div>
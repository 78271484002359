<div class="p-5">
  <div class="bg-white w-full">
    <!-- current date and day (ex: November 25, 2024 \n Tuesday) -->
    <h3 class="pl-4 text-sm font-semibold capitalize">{{ currentDate }}</h3>
    <h3 class="pl-4 text-lg  font-normal">{{ currentDay }}</h3>

    <!-- calendar section -->
    <div class="flex justify-between items-center" name="fontStyle" aria-label="Font Style">
      <!-- left arrow -->
      <button mat-icon-button (click)="arrowClicked('back')">
        <mat-icon class="text-lg font-bold">arrow_back_ios</mat-icon>
      </button>

      <!-- center section -->
      <div class="bg-primary rounded-md flex flex-row w-full justify-between p-4">
        <div class="w-10 flex justify-center" *ngFor="let day of days">
          <button mat-button (click)="dayClicked(day.fullDate)" class="h-16 rounded-md">
            <div class="flex flex-col text-white text-sm  font-light">
              <span class="pb-3">{{ day.day }}</span>
              <span
                [ngClass]="{
                  'bg-primary text-white rounded-full outline outline-2 outline-white w-6 h-6 flex items-center justify-center':
                    day.dayNum === selectedDay,
                  'w-4 h-4': day.dayNum !== selectedDay
                }"
                class="transition-all duration-200 ease-in-out">
                {{ day.dayNum }}
              </span>
            </div>
          </button>
        </div>
      </div>

      <!-- right arrow -->
      <button mat-icon-button (click)="arrowClicked('forward')">
        <mat-icon class="text-lg font-bold">arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="tbTimesheetEntries && tbTimesheetEntries.length > 0">
    <div class="p-3" *ngFor="let entries of tbTimesheetEntries">
      <app-punch-clock-timesheet-item
        [StartTime]="entries.StartTime"
        [StopTime]="entries.StopTime"
        [Task]="entries.TimesheetTaskID"
        [EntryID]="entries.TimesheetEntryID"
        [TaskArray]="taskArray"></app-punch-clock-timesheet-item>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportGeneratorService } from '../report-generator.service';
import { Report } from '../report-generator.types';
import { TabulatorTableComponent } from '../../shared/table/tabulator-table/tabulator-table.component';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { DatetimeService } from '../../core/services/datetime/datetime.service';


export enum ReportState {
  RouteStart,
  RouteEnd,
  Running,
  Completed,
  Failed
}

@Component({
  selector: 'app-report-generator-item',
  templateUrl: './report-generator-item.component.html',
  styleUrl: './report-generator-item.component.scss'
})
export class ReportGeneratorItemComponent implements OnInit {
  currentReport: Report;
  currentReportResult: { rows: object[], columns: object[] } = { rows: [], columns: [] };
  reportIsRunning: boolean = false;
  loading: boolean = false;
  noResults: boolean = false;
  currentState: ReportState;

  startTime: Date = new Date();
  endTime: Date = new Date();

  @ViewChild('tabulatorTable') tabulatorTable: TabulatorTableComponent<any, Pick<any, ''>>;

  constructor(private route: ActivatedRoute, private reportGeneratorService: ReportGeneratorService, private snackBarService: SnackbarService, private datetimeService: DatetimeService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const paramsRouteID: number = parseInt(params['reportID']); //parsing string into integer
      //When we change our route, we want to refresh the currently "selected" report
      this.startTime = new Date();
      this.setState(ReportState.RouteStart);
      this.reportGeneratorService.refreshCurrentReport(paramsRouteID);
    });

    // observable which holds the currently selected report. Triggered when the currently 
    // selected report changes 
    this.reportGeneratorService.getCurrentReport().subscribe((report) => {
      if (report) {
        this.endTime = new Date();
        this.setState(ReportState.RouteEnd);
        this.currentReport = report;
      }
    });
  }

  /**
   * Set the state of the report (loading, no results, etc...)
   *
   * @param {number} stateID
   * @param {*} [result]
   * @memberof ReportGeneratorItemComponent
   */
  setState(stateID: number, result?: any) {
    switch (stateID) {
      case ReportState.RouteStart:
        this.currentState = ReportState.RouteStart;
        this.loading = true;
        this.noResults = false;
        this.reportIsRunning = false;
        this.currentReportResult = { rows: [], columns: [] }; //reset the current report 
        break;
      case ReportState.RouteEnd:
        this.currentState = ReportState.RouteEnd;
        this.loading = false;
        this.noResults = false;
        this.reportIsRunning = false;
        this.currentReportResult = { rows: [], columns: [] }; //reset the current report 
        break;
      case ReportState.Running:
        this.currentState = ReportState.Running;
        this.loading = true;
        this.noResults = false;
        this.reportIsRunning = true;
        this.currentReportResult = { rows: [], columns: [] }; //reset the current report 
        break;
      case ReportState.Completed:
        this.currentState = ReportState.Completed;
        this.loading = false;
        this.noResults = false;
        this.reportIsRunning = false;
        this.currentReportResult = result;
        break;
      case ReportState.Failed:
        this.currentState = ReportState.Failed;
        this.loading = false;
        this.noResults = true;
        this.reportIsRunning = false;
        break;
      default:
        this.loading = false;
        this.noResults = true;
        this.reportIsRunning = false;
    }
  }

  /**
   * Get the values from the report's form fields
   *
   * @return {*} 
   * @memberof ReportGeneratorItemComponent
   */
  getValuesFromReportParameters(): object {
    const values = {};
    for (const field in this.currentReport.FormGroup.controls) { // 'field' is a string
      values[field] = this.currentReport.FormGroup.controls[field].value;
      if (values[field] instanceof Date) {
        values[field] = this.datetimeService.localDateToDBDateStr(values[field], "yyyy-MM-dd HH:mm:ss", "yyyy-MM-dd")
      }
    }
    return values;
  }



  /**
   * Run a report when clicked 
   */
  runReport($event: boolean) {
    if (!$event) {
      this.snackBarService.openSnackbar('The report could not be run', SnackbarType.warning);
      return;
    }
    //get all the values from the current report's form 
    const values = this.getValuesFromReportParameters();

    this.setState(ReportState.Running);

    this.reportGeneratorService.runReport(this.currentReport.ReportID, values).subscribe(
      {
        next: (result) => {
          if (result && result.rows && result.rows.length > 0) {
            this.setState(ReportState.Completed, result);
          } else {
            this.setState(ReportState.Failed);
          }
        },
        error: (error) => {
          console.error(error);
          this.setState(ReportState.Failed);
        }
      }
    );

    // wait a max of 10 seconds 
    setTimeout(() => {
      if (this.currentState === ReportState.Running) {
        this.setState(ReportState.Failed);
      }
    }, 10000);
  }

  /**
   * Downloads the report as a certain type 
   * @param type 
   * @returns 
   */
  downloadReportAs(type: string) {
    if (!type) return;
    let reportName = this.currentReport.Name.replace(/ /g, '_');
    if (this.currentReport.ReportParameters.length > 0) {

      //generate the name for the report based on the form values
      const values = this.getValuesFromReportParameters();
      reportName += "(";
      const parameterNamingAddition = [];

      //loop over the reports parameters 
      for (const key of Object.keys(values)) {

        //get the report parameter's name 
        const paramName = this.currentReport.ReportParameters.find(param => param.ParameterID === parseInt(key));
        //append the name and value to the report name
        parameterNamingAddition.push(`${paramName.Prompt}: ${values[key]}`);
      }
      //join the report name with the parameter name and value
      reportName += parameterNamingAddition.join(" | ");
      reportName += ")";
    }

    switch (type.toUpperCase()) {
      case 'XLSX':
        this.snackBarService.openSnackbar('Downloading as a xlsx is not currently supported', SnackbarType.warning);
        //TODO: Add libraries to tabulator table to enable this functionality 
        // this.tabulatorTable.downloadTable(`${reportName}.xlsx`, "xlsx");
        break;
      case 'PDF':
        this.snackBarService.openSnackbar('Downloading as a PDF is not currently supported', SnackbarType.warning);
        // this.tabulatorTable.downloadTable(`${reportName}.pdf`, "pdf");
        break;
      default:
        this.tabulatorTable.downloadTable(`${reportName}.csv`, "csv");
        break;
    }
  }
}

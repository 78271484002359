import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-competers-checkbox',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersCheckboxComponent,
    },
  ],
  template: `
    <div class="box-border flex flex-row justify-between items-center bg-transparent w-full">
      @if (title && title.length > 0) {
        <label
          for="me"
          class=" font-semibold text-md capitalize max-w-[100px] overflow-hidden overflow-ellipsis">
          {{ title }}
        </label>
      }
      <button
        [disabled]="disabled"
        (click)="setValue(!val)"
        class="flex justify-between items-center appearance-none border-none rounded-sm
                    bg-transparent {{ disabled ? '' : 'hover:bg-primary cursor-pointer' }} p-0.5">
        <span
          class="flex justify-center items-center h-4 w-4 bg-gray-100 border-b-gray-700 border-solid border-2 rounded-sm text-gray-700">
          @if (val && checkStyle === 'checkmark') {
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          } @else if (val && checkStyle === 'x') {
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="3.75 3.75 10.5 10.5">
              <path
                d="M14.25,4.8075 L13.1925,3.75 L9,7.9425 L4.8075,3.75 L3.75,4.8075 L7.9425,9 L3.75,13.1925 L4.8075,14.25 L9,10.0575 L13.1925,14.25 L14.25,13.1925 L10.0575,9 L14.25,4.8075 L14.25,4.8075 Z"
                fill="currentColor" />
            </svg>
          } @else if (checkStyle === 'invert') {
            @if (val) {
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="3.75 3.75 10.5 10.5">
                <path
                  d="M14.25,4.8075 L13.1925,3.75 L9,7.9425 L4.8075,3.75 L3.75,4.8075 L7.9425,9 L3.75,13.1925 L4.8075,14.25 L9,10.0575 L13.1925,14.25 L14.25,13.1925 L10.0575,9 L14.25,4.8075 L14.25,4.8075 Z"
                  fill="currentColor" />
              </svg>
            } @else {
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                <path fill="currentColor" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
              </svg>
            }
          }
        </span>
      </button>
    </div>
  `,
})
export class CompetersCheckboxComponent implements OnDestroy, ControlValueAccessor {
  @Input() title: string;
  @Input() inlined: boolean = false;
  @Input() checkStyle: 'checkmark' | 'x' | 'invert' = 'checkmark';
  @Input() disabled: boolean = false;

  private destroy$ = new Subject<void>();
  private touched = false;
  protected val: boolean = false;

  constructor() {}

  onChange = (val) => {};
  onTouched = (val) => {};

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(data: any) {
    this.val = data;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setValue(val) {
    if (val !== undefined && !isEqual(this.val, val)) {
      this.val = val;
      this.onChange(val);
      this.onTouched(val);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}

<div class="flex items-center  text-md font-normal">
  <ng-container *ngIf="!editClicked">
    <div class="w-full flex flex-row items-center justify-between h-12">
      <div class="w-full flex flex-row items-center h-12 border border-solid border-divider border-x-0 border-t-0">
        <span>{{ convertedTask }}</span>
        <div class="flex flex-row justify-end w-full">
          <span class="pr-4">Start: {{ chosenStartTime }}</span>
          <span>End: {{ chosenStopTime }}</span>
        </div>
      </div>
      <button mat-icon-button (click)="editTask()" class="edit-btn">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="editClicked">
    <div class="w-full flex items-center h-12 border border-solid border-divider border-x-0 border-t-0">
      <div class="w-full flex justify-around items-center">
        <mat-form-field class="dense-6 h-12" appearance="outline">
          <mat-select [(value)]="selectedOption">
            <mat-option *ngFor="let task of TaskArray[0]" [value]="task">{{ task }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="times-edit">
          <span>Start:&nbsp;</span>
          <span
            [ngxTimepicker]="startTime"
            [(ngModel)]="chosenStartTime"
            [format]="24"
            [max]="chosenStopTime"
            class="cursor-pointer underline pr-4">
            {{ chosenStartTime }}
          </span>
          <ngx-material-timepicker [theme]="timePickerTheme" #startTime></ngx-material-timepicker>
          <span>End:&nbsp;</span>
          <span
            [ngxTimepicker]="stopTime"
            [(ngModel)]="chosenStopTime"
            [format]="24"
            [min]="chosenStartTime"
            class="cursor-pointer underline">
            {{ chosenStopTime }}
          </span>
          <ngx-material-timepicker [theme]="timePickerTheme" #stopTime></ngx-material-timepicker>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="saveTask()" class="edit-btn">
      <mat-icon>save</mat-icon>
    </button>
  </ng-container>
</div>

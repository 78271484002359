import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { Drawing, DrawingID } from '../../../../services/drawing-manager.service';

@Component({
  selector: 'app-drawing-slot',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CdkAccordionModule, MatIcon],
  template: `
    <div
      class="group box-border flex flex-row justify-between items-center gap-3 w-full max-w-full h-12 appearance-none border-none p-0 px-5 bg-white hover:shadow-none hover:bg-primary layer-slot">
      <button
        class="flex justify-center items-center cursor-pointer w-8 appearance-none border-none p-0 bg-white group-hover:shadow-none group-hover:bg-primary"
        (click)="deleteClick.emit(drawing.drawingID)">
        <mat-icon class="text-warn" [svgIcon]="'trash'"></mat-icon>
      </button>
      <button
        class="cursor-pointer box-border flex flex-row justify-between items-center gap-3 w-full max-w-full h-12 appearance-none border-none p-0 bg-white group-hover:shadow-none group-hover:bg-primary layer-slot"
        (click)="drawingClick.emit(drawing.drawingID)">
        <div class="flex flex-row justify-center items-center pl-3 "></div>
        <div class="w-full flex flex-row justify-between items-center flex-grow">
          <div class="flex-grow flex flex-row justify-between items-center pr-4">
            <span
              class="capitalize text-center max-w-32  font-semibold text-headline-6 leading-7 text-black group-hover:text-white truncate">
              {{ drawing.title ?? 'untitled' }}
            </span>
            @if (drawing.drawingCategory) {
              <span
                class="capitalize text-center  font-semibold text-headline-6 leading-7 text-black group-hover:text-white">
                ({{ drawing.drawingCategory }})
              </span>
            }
          </div>
          <div
            class="size-4 rounded-full {{
              selected ? 'bg-success' : 'bg-warn'
            }} transition-colors ease-in-out duration-300"></div>
        </div>
      </button>
    </div>
  `,
})
export class DrawingSlotComponent {
  // IO
  @Input() drawing: Drawing;
  @Input() selected: boolean = false;
  @Output() drawingClick = new EventEmitter<DrawingID>();
  @Output() deleteClick = new EventEmitter<DrawingID>();

  constructor() {}
}

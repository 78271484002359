<form [formGroup]="formGroup" autocomplete="off">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="grid"
    class="header-values"
  >
    <ng-container *ngFor="let field of fields | keyvalue">
      <span
        *ngIf="field.value.isVisible && field.value.inputTypeID == 0"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="  font-semibold text-headline-6"
      >
        {{ field.value.label }}
      </span>

      <!-- Checkbox -->
      <mat-checkbox
        *ngIf="field.value.isVisible && field.value.inputTypeID == 1"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        id="{{ field.value.key }}"
        formControlName="{{ field.value.key }}"
        class="form-check p-2  font-normal text-md"
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
      >
        {{ field.value.label }}
      </mat-checkbox>

      <!-- String, Time Picker -->
      <mat-form-field
        [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
        *ngIf="
          field.value.isVisible &&
          (field.value.inputTypeID == 2 ||
            field.value.inputTypeID == 15 ||
            field.value.inputTypeID == 16)
        "
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="p-2  font-normal text-md"
      >
        <mat-label>{{ field.value.label }}</mat-label>
        <input
          id="{{ field.value.key }}"
          matInput
          tabindex="{{ field.value.tabindex }}"
          placeholder="{{ field.value.placeholder }}"
          required="{{ field.value.isRequired }}"
          [readonly]="field.value.isReadOnly"
          formControlName="{{ field.value.key }}"
          type="search"
        />
      </mat-form-field>

      <!-- Text Area -->
      <mat-form-field
        [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
        *ngIf="field.value.isVisible && field.value.inputTypeID == 18"
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="p-2  font-normal text-md"
      >
        <!-- floatLabel="always" -->
        <mat-label>{{ field.value.label }}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          id="{{ field.value.key }}"
          tabindex="{{ field.value.tabindex }}"
          placeholder="{{ field.value.placeholder }}"
          required="{{ field.value.isRequired }}"
          [readonly]="field.value.isReadOnly"
          formControlName="{{ field.value.key }}"
        ></textarea>
      </mat-form-field>

      <!-- Integer, Float -->
      <mat-form-field
        [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
        *ngIf="
          field.value.isVisible &&
          (field.value.inputTypeID == 3 || field.value.inputTypeID == 4)
        "
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="p-2  font-normal text-md"
      >
        <mat-label>{{ field.value.label }}</mat-label>
        <input
          id="{{ field.value.key }}"
          matInput
          tabindex="{{ field.value.tabindex }}"
          placeholder="0"
          required="{{ field.value.isRequired }}"
          [readonly]="field.value.isReadOnly"
          formControlName="{{ field.value.key }}"
          type="number"
          step="any"
        />
      </mat-form-field>

      <!-- Date Picker -->
      <mat-form-field
        *ngIf="field.value.isVisible && field.value.inputTypeID == 10"
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="p-2"
        [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
        (click)="picker.open()"
      >
        <mat-label>{{ field.value.label }}</mat-label>
        <input
          matInput
          tabindex="{{ field.value.tabindex }}"
          [readonly]="field.value.isReadOnly"
          [matDatepicker]="picker"
          id="{{ field.value.key }}"
          placeholder="{{ field.value.placeholder }}"
          required="{{ field.value.isRequired }}"
          formControlName="{{ field.value.key }}"
          type="search"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          [disabled]="field.value.isReadOnly || readOnly"
        ></mat-datepicker>
      </mat-form-field>

      <!-- Dropdown -->
      <mat-form-field
        [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
        *ngIf="field.value.isVisible && field.value.inputTypeID == 11"
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="p-2  font-normal text-md"
      >
        <mat-label>{{ field.value.label }}</mat-label>
        <mat-select
          tabindex="{{ field.value.tabindex }}"
          [compareWith]="compareOptions"
          id="{{ field.value.key }}"
          placeholder="{{ field.value.placeholder }}"
          required="{{ field.value.isRequired }}"
          formControlName="{{ field.value.key }}"
          (selectionChange)="onDropdownSelectionChange(field, $event.value)"
        >
          <mat-option>--</mat-option>
          <mat-option
            *ngFor="let option of field.value.selectOptions"
            [value]="option"
            >{{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Multiselect -->
      <mat-form-field
        *ngIf="field.value.isVisible && field.value.inputTypeID == 12"
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        class="p-2"
        [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
      >
        <mat-label>{{ field.value.label }}</mat-label>
        <mat-select
          tabindex="{{ field.value.tabindex }}"
          [compareWith]="compareOptions"
          id="{{ field.value.key }}"
          placeholder="{{ field.value.placeholder }}"
          required="{{ field.value.isRequired }}"
          formControlName="{{ field.value.key }}"
          (selectionChange)="onDropdownSelectionChange(field, $event.value)"
          multiple
        >
          <mat-option
            *ngFor="let option of field.value.selectOptions"
            [value]="option"
            >{{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Photo -->
      <div
        *ngIf="field.value.isVisible && field.value.inputTypeID == 9"
        fxFlex.xs="{{ field.value.width_xs }}"
        fxFlex.sm="{{ field.value.width_sm }}"
        fxFlex.md="{{ field.value.width_md }}"
        fxFlex.lg="{{ field.value.width_lg }}"
        fxFlex.xl="{{ field.value.width_xl }}"
        fxFlexOrder="{{ field.value.fieldOrder }}"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <p class=" font-normal text-lg pl-2" style="padding-left: 8px">
          {{ field.value.label }}
        </p>
        <div matTooltip="Currently only availble in Android App">
          <button mat-flat-button color="primary" [disabled]="true">
            Take Photo
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</form>

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LonLat, OpenLayersService } from '../services/open-layers.service';
import { LayerManagerService } from '../services/layer-manager/layer-manager.service';
import { LayerQueryFilters, ShapeType } from '../utilities/types';
import { MapFeatureService } from '../services/map-feature.service';
import { combineLatestWith, firstValueFrom, fromEvent, Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrawingService } from '../drawing.service';
import { LocationService } from '../../../shared/services/location/location.service';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { MapFeatureInspectorComponent } from '../../../shared/components/maps/open-layers/map-feature-inspector/map-feature-inspector.component';
import Map from 'ol/Map';
import { Collection, Feature, MapBrowserEvent, Overlay } from 'ol';
import { Point } from 'ol/geom';
import { isEqual } from 'lodash-es';
import { InspectorContentComponent } from '../../../shared/components/maps/open-layers/map-feature-inspector/inspector-content/inspector-content.component';
import { fromLonLat } from 'ol/proj';
import { DrawingSettingsService, MapSettings } from '../drawing-settings.service';
import { DrawingCategory } from '../services/drawing-manager.service';
import { DigsiteAreaService } from '../../../shared/services/digsite-area/digsite-area.service';
import { MapInteractionService } from '../services/map-interaction.service';
import {
  ContextSelection,
  MapContextMenuComponent,
} from '../../../shared/components/maps/open-layers/overlays/map-context-menu/map-context-menu.component';
import {
  FeatureEditMenuComponent,
  FeatureEdits,
} from '../../../shared/components/maps/open-layers/overlays/feature-edit-menu/feature-edit-menu.component';
import { FeatureInspectorService } from '../services/feature-inspector.service';
import VectorSource from 'ol/source/Vector';
import { FeatureChangeType, MapFeatureChange } from '../classes/map-feature-change';

@Component({
  selector: 'app-drawing-map',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MapFeatureInspectorComponent,
    InspectorContentComponent,
    MapContextMenuComponent,
    FeatureEditMenuComponent,
  ],
  template: `
    <div class="relative flex h-full w-full">
      <div #map class="h-full w-full"></div>
    </div>
    <div #inspector class="size-fit">
      <app-map-feature-inspector>
        <app-inspector-content [data]="data"></app-inspector-content>
      </app-map-feature-inspector>
    </div>
    <div #contextMenu class="size-fit">
      <app-context-menu
        (optionSelected)="contextOptionSelected($event)"
        [contextEvent]="interactionService.contextEvent$ | async" />
    </div>
    <div #featureEditMenu class="size-fit">
      <app-feature-edit-menu
        (closed)="handleEditMenuClosed()"
        (featuresEdited)="handleFeaturesEdited($event)"
        [contextSelection]="contextSelection$$()" />
    </div>
  `,
})
export class DrawingMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('map') mapTarget: ElementRef;
  @ViewChild('inspector') inspector: ElementRef;
  @ViewChild('contextMenu') contextMenu: ElementRef;
  // IO
  @Input() layerFilterParameters: Partial<LayerQueryFilters>;
  @Input() ticket: Record<string, unknown>;
  @Input() coordinates: LonLat;
  @Input() drawingCategory: DrawingCategory;
  @Input() shapeType: ShapeType;
  @Output() mapTargetRendered = new EventEmitter<HTMLDivElement>();
  // services
  private locationService = inject(LocationService);
  private cdr = inject(ChangeDetectorRef);
  protected drawingService = inject(DrawingService);
  protected drawingSettingsService = inject(DrawingSettingsService);
  protected digSiteAreaService = inject(DigsiteAreaService);
  protected interactionService = inject(MapInteractionService);
  private featureInspectorService = inject(FeatureInspectorService);
  private mapFeatureService = inject(MapFeatureService)

  // observables
  destroy$: Subject<void> = new Subject<void>();
  protected contextSelection$$ = signal<any>(null);

  // members
  protected inspectorOverlay: Overlay;
  protected contextMenuOverlay: Overlay;
  protected locatorPin: Feature<Point>;
  protected ticketPin: Feature<Point>;
  protected data = [];

  constructor(
    private layerService: LayerManagerService,
    protected openLayersService: OpenLayersService
  ) {
    this.locatorPin = new Feature({
      geometry: new Point(fromLonLat([0, 0])),
      colour: '#2cd8f6',
    });
    this.ticketPin = new Feature({
      geometry: new Point(fromLonLat([0, 0])),
      colour: '#da2020',
    });
  }

  ngOnInit(): void {
    // watch for changes in the drawing mode and hide the inspector when not in inspection mode
    this.drawingService.inspectModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((enabled) => {
      if (!enabled) {
        this.inspectorOverlay?.setPosition(undefined);
        this.cdr.markForCheck();
      }
    });

    // watch for changes in the map and the layers and update the map
    this.openLayersService.map$
      .pipe(combineLatestWith(this.layerService.layers$), takeUntil(this.destroy$))
      .subscribe(([olMap, layers]) => {
        if (olMap && layers) {
          olMap.setLayers(layers);
          olMap.addLayer(this.layerService.miscLayer);
        }
      });

    // watch for changes in the map settings and update the pins
    this.drawingSettingsService.mapSettings$
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.layerService.miscLayer.getSource().removeFeature(this.locatorPin);
          this.layerService.miscLayer.getSource().removeFeature(this.ticketPin);
        })
      )
      .subscribe((settings: MapSettings) => {
        if (settings.pinUserLocation) {
          try {
            this.layerService.miscLayer.getSource().addFeature(this.locatorPin);
          } catch {
            console.log('Error: add UserLocation failed.');
          }
        } else {
          try {
            this.layerService.miscLayer.getSource().removeFeature(this.locatorPin);
          } catch {
            console.log('Error: remove UserLocation failed.');
          }
        }
        if (settings.pinTicketLocation) {
          try {
            this.layerService.miscLayer.getSource().addFeature(this.ticketPin);
          } catch {
            console.log('Error: add TicketLocation failed.');
          }
        } else {
          try {
            this.layerService.miscLayer.getSource().removeFeature(this.ticketPin);
          } catch {
            console.log('Error: remove TicketLocation failed.');
          }
        }
      });
    this.openLayersService.initMap();
    this.openLayersService.useMapView();
  }

  ngAfterViewInit() {
    this.mapTargetRendered.emit(this.mapTarget.nativeElement);
    this.inspectorOverlay = new Overlay({
      element: this.inspector.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
      position: undefined,
    });
    this.openLayersService.attachOverlay(this.inspectorOverlay);
    this.addClickListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coordinates) {
      if (
        changes.coordinates.currentValue &&
        Array.isArray(changes.coordinates.currentValue) &&
        changes.coordinates.currentValue.length === 2
      ) {
        this.ticketPin.getGeometry().setCoordinates(fromLonLat(changes.coordinates.currentValue));
      } else {
        this.ticketPin.getGeometry().setCoordinates(fromLonLat([0, 0]));
      }
    }

    if (
      changes.layerFilterParameters &&
      !isEqual(changes.layerFilterParameters.currentValue, changes.layerFilterParameters.previousValue)
    ) {
      this.layerService.filters = this.layerFilterParameters;
    }

    if (changes.ticket && !isEqual(changes.ticket.currentValue, changes.ticket.previousValue)) {
      const { AssignmentID } = this.ticket;
      this.mapFeatureService.getTicketSubject().next(this.ticket);
      firstValueFrom(this.digSiteAreaService.fetchDigSiteAreas([AssignmentID as number])).then((digSiteShapes) => {
        if (digSiteShapes instanceof Error) {
          this.layerService.digSites = [];
          return;
        }
        this.layerService.digSites = digSiteShapes;
      });
    }

    this.locationService.updateUserLocation().then((location: Array<number>) => {
      this.locatorPin.getGeometry().setCoordinates(fromLonLat(location));
    });
  }

  ngOnDestroy() {
    this.openLayersService.removeOverlay(this.inspectorOverlay);
    this.openLayersService.removeOverlay(this.contextMenuOverlay);
    this.drawingService.drawingModeEnabled = false;
    this.destroy$.next();
  }

  contextOptionSelected(selection: ContextSelection) {
    switch (selection.value) {
      case 'duplicate':
        this.handleFeaturesDuplicated(selection);
        break;
      case 'delete':
        this.interactionService.deleteSelectedFeatures();
        break;
      case 'edit':
        this.contextSelection$$.set(selection);
        break;
    }
  }

  handleFeaturesDuplicated(context: ContextSelection) {
    const clones: Collection<Feature> = this.interactionService.duplicateFeatures(context.feature);
    clones.forEach((f) => { f.getGeometry().translate(10, -10) })
    const duplicationSource: VectorSource = context.layer.getSource();
    const change = new MapFeatureChange(
      FeatureChangeType.added,
      clones,
      undefined,
      duplicationSource
    );
    this.mapFeatureService.addChangeToStack(change);
    this.interactionService.updateSelection(clones);
    duplicationSource.addFeatures(clones.getArray());
  }

  handleFeaturesEdited(changes: ContextSelection & FeatureEdits) {
    const context = { ...changes };
    this.contextSelection$$.set(null);
    const feature = context.feature.item(0);
    const clone = feature.clone();
    clone.setProperties(context.edits);
    clone.setId(feature.getId());
    this.interactionService.featureUpdated(
      new Collection<Feature>([feature]), // old
      new Collection<Feature>([clone]), // new
      context.layer.getSource()
    );
  }

  handleEditMenuClosed() {
    this.contextSelection$$.set(null);
  }

  addClickListeners() {
    this.openLayersService.map$
      .pipe(
        filter((olMap) => olMap instanceof Map),
        switchMap((olMap: Map) => fromEvent(olMap, 'singleclick')),
        filter(() => this.drawingService.inspectModeEnabled),
        takeUntil(this.destroy$)
      )
      .subscribe((evt: MapBrowserEvent<MouseEvent>) => {
        this.handleInspection(evt).then(() => {
          this.cdr.detectChanges();
        });
      });
  }

  private async handleInspection(evt: MapBrowserEvent<MouseEvent>) {
    this.updateInspectorPosition(evt);
    const res = await this.featureInspectorService.inspectMap(evt);
    this.data = res ?? [];
  }

  private updateInspectorPosition(evt: MapBrowserEvent<MouseEvent>) {
    this.inspectorOverlay.setPosition(evt.coordinate);
  }
}

<div class="h-full overflow-y-auto bg-white">
  <ng-container *ngIf="isOfficeSide">
    <mat-toolbar class="bg-white px-1 h-[50px]">
      <div class="w-full flex flex-row justify-end items-center">
      <button *ngIf="disableSync" mat-icon-button class="px-0" (click)="onSync()">
        <mat-icon *ngIf="showSyncBadge; else defaultSyncIcon" matBadge="!" matBadgeSize="small" matBadgeColor="warn">
          sync
        </mat-icon>
        <ng-template #defaultSyncIcon>
          <mat-icon>sync</mat-icon>
        </ng-template>
      </button>
      <div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="allowCreateAudit" (click)="createTicket()" class="" mat-menu-item>
            <span class="mat-subtitle-1">Create Audit</span>
          </button>
          <button (click)="openSelfAssign()" mat-menu-item >
            <span class="mat-subtitle-1">Self Assign</span>
          </button>
        </mat-menu>
      </div>
      </div>
    </mat-toolbar>
  </ng-container>

  <div class="flex mt-3 mb-3 mr-2">
    <label for="default-search" class="mb-2 text-sm  font-medium text-[#9d9d9d] sr-only">Search</label>
    <div class="relative w-[90%] ml-3">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none pb-[6px]">
        <mat-icon class="w-5 h-5 text-[#9d9d9d]" svgIcon="search"></mat-icon>
      </div>
      <input
        type="search"
        id="default-search"
        class="block w-full p-[9px] ps-10 text-lg rounded-lg placeholder-[#9d9d9d] text-gray-900 border-0 bg-[#f1f3f4] focus:ring-primary focus:border-primary"
        placeholder="Search"
        [(ngModel)]="searchText"
        #searchbar
        required />
    </div>
    <button class="filter mt-[-6px]" mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu for filtering">
      <mat-icon class="text-black">filter_list</mat-icon>
    </button>
    <ng-content class="pr-2"></ng-content>
    <mat-menu #menu="matMenu">
      <button (click)="onFilter(false)" mat-menu-item [class.selected]="selectedFilter === false">
        <span class="capitalize">Excavation Date (asc)</span>
      </button>
      <button (click)="onFilter(true)" mat-menu-item [class.selected]="selectedFilter === true">
        <span class="capitalize">Excavation Date (desc)</span>
      </button>
      <button (click)="onFilter(listFilter)" mat-menu-item [class.selected]="selectedFilter === listFilter">
        <span class="capitalize">Type Of Work</span>
      </button>
      <ng-container *ngIf="showRouting">
        <button (click)="onFilter(routeFilter)" mat-menu-item [class.selected]="selectedFilter === routeFilter">
          <span class="capitalize">Route</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <div *ngFor="let item of TicketListItems | filter: searchText; let i = index">
    <app-ticket-list-item
      [AssignmentID]="item.AssignmentID"
      [Address]="item.Address"
      [City]="item.City"
      [TypeOfWork]="item.TypeOfWork"
      [RequestNumber]="item.RequestNumber"
      [Index]="i"
      [Proximity]="item.Proximity"
      [NumberOfDocs]="item.NumberOfDocs"
      [DistanceToSite]="item.DistanceToSite"
      [DigDate]="item.DigDate"
      [CallTypeDesc]="item.CallTypeDesc"
      [PrimaryID]="item.PrimaryID ? item.PrimaryID : null"
      [LocateStatusID]="item.LocateStatusID ? item.LocateStatusID : null"
      [CallTypeID]="item.CallTypeID ? item.CallTypeID : null"
      [LateColor]="item.LateColor"
      [IsSelected]="item.IsSelected"
      (ticketListItemClick)="openTicket($event)"/>
  </div>
</div>

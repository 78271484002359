import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-risk-calculator-explainer-modal",
  templateUrl: "risk-calculator-explainer-modal.html",
})
export class RiskCalculatorExplainerModalComponent {
  data: any;
  showSubmit = false;
  options = ['Incorrect excavator name','Inaccurate excavator damage rate','Inaccurate excavator rank', 'other']
  selectedOption: string = '';
  insights: any;
  risk: any;
  color: any
  activeTab: any;
  keys: any;
  score: any
  showPrevious = false;
  showDone = false;
  showNext = true;
  address: string;

  colorArray: any = {
    'low': '#2BA329', 
    'medium': '#3C87DD',
    'high': '#B75E0B',
    'very-high': '#E7183E'
  }
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<RiskCalculatorExplainerModalComponent>
  ) {
    this.data = Data;
    this.insights = this.data[0];
    this.color = this.data[1];
    this.risk = this.data[2];
    this.keys = this.data[3];
  }

  ngOnInit() {
    this.score = this.insights[this.risk.title].score;
    this.color = this.colorArray[this.score];
    this.activeTab = this.risk.title;
    
    if (this.activeTab === "Type of Work") {
      this.showNext = false;
      this.showDone = true;
    }
    if (this.activeTab !== "Excavator Rank") {
      this.showPrevious = true;
    }
  }

  feedbackClicked() {
    this.showSubmit = true
  }

  submitFeedback(){
    console.log(this.selectedOption);
  }

  nextClicked() {
    let current = this.keys.indexOf(this.activeTab)
    if (this.activeTab === "Type of Work") {
      this.showNext = false;
      this.showDone = true;
      return;
    }
    this.showSubmit = false;    
    this.selectedOption = '';
    this.activeTab = this.keys[current + 1];
    this.score = this.insights[this.activeTab].score 
    this.showPrevious = true;
    if (this.activeTab === "Type of Work") {
      this.showNext = false;
      this.showDone = true;
      return;
    }
  }

  doneClicked() {
    this.dialogRef.close({ event: "close", result: true });
  }

  prevClicked() {
    if (this.activeTab === "Excavator Rank") {  
      this.showPrevious = false;
      return;
    }
    this.showNext = true;
    this.showDone = false;
    this.showSubmit = false;
    this.selectedOption = '';
    let current = this.keys.indexOf(this.activeTab)

    this.activeTab = this.keys[current - 1];
    this.score = this.insights[this.activeTab].score 
    if (this.activeTab === "Excavator Rank") {
      this.showPrevious = false;
      return;
    }
  }

  onConfirm() {
    this.dialogRef.close({ event: "close", result: true });
  }
}

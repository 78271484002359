import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TicketService } from "../../ticket.service";

@Component({
  selector: "app-impact-calculator-modal",
  templateUrl: "impact-calculator-modal.html",
})
export class ImpactCalculatorModalComponent {
  data: any;
  isFail: boolean;
  address: string;

    impactInsights = [
      { title: 'Asset Type', description: 'No Data', icon: 'gear' },
      { title: 'High Profile Building Data', description: 'No Data', icon: 'building' },
      { title: 'Outage Criteria', description: 'No Data', icon: 'bolt' },
      { title: 'Asset Classification', description: 'No Data', icon: 'tag' },
      { title: 'Boring', description: 'No Data', icon: 'cylinder' },
      { title: 'Repair Criteria', description: 'No Data', icon: 'wrench' }
    ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any, public ticketService: TicketService,
    public dialogRef: MatDialogRef<ImpactCalculatorModalComponent>
  ) {
    this.data = Data;
    this.address = this.ticketService.getAddress();
    
  }

  ngOnInit() {
    if(this.data.ImpactDetails){
    
 for(let i of this.data.ImpactDetails){
  switch (i.insight.insight_name) {
    case "ASSET_TYPE":
      this.impactInsights[0].description = i.insight_data;
      break;
    case "HIGH_PROFILE_BUILDING":
      this.impactInsights[1].description = i.insight_data;
      break;
    case "OUTAGE_ASSET_CRITERIA": 
    const parts = i.insight_data.split(':');
    if (parts.length > 1) {
      this.impactInsights[2].description = parts[1].trim();
    } else {
      this.impactInsights[2].description = i.insight_data; // Fallback if no semicolon
    }
      break;
    case "ASSET_CLASSIFICATION":
      this.impactInsights[3].description = i.insight_data;
      break;
    case "BORING":
      this.impactInsights[4].description = i.insight_data;
      break;
    case "REPAIR_ASSET_CRITERIA":
      const split = i.insight_data.split(':');
      if (split.length > 1) {
        this.impactInsights[5].description = split[1].trim();
      } else {
        this.impactInsights[5].description = i.insight_data; // Fallback if no semicolon
      }     
      break;
  }
 }
}
  }
  onConfirm() {
    this.dialogRef.close({ event: "close", result: true });
  }
}

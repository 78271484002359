<div
  class="box-border flex flex-col {{
    isSelected ? 'pb-3' : ''
  }} gap-3 justify-start items-start bg-transparent w-[300px]">
  <div class="flex flex-row gap-3">
    <!-- Date picker section -->
    <div class="flex flex-col justify-start items-center">
      <div
        class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
        <button
          (click)="startDatePicker.open()"
          class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
          <mat-icon style="width: 20px; height: 20px" svgIcon="calendar"></mat-icon>
        </button>
        <p class="text-md text-gray-600  font-semibold">
          {{ (startDate.value | date: 'dd-MM-YYYY') ?? 'DD-MM-YYYY' }}
        </p>
      </div>
      <div class="overflow-hidden h-0 w-36">
        <mat-datepicker #startDatePicker></mat-datepicker>
        <input
          #startDate
          matInput
          [matDatepicker]="startDatePicker"
          [ngModel]="date$$()"
          [min]="dateFloor"
          [max]="dateCeiling"
          (ngModelChange)="setDate($event)" />
      </div>
    </div>

    <!-- Time picker section -->
    <div class="flex flex-col justify-start items-center">
      <div
        class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
        <button
          (click)="picker.open()"
          class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
          <mat-icon style="width: 20px; height: 20px" svgIcon="stopwatch"></mat-icon>
        </button>
        <p class="text-md text-gray-600  font-semibold">
          {{ timeValue ? timeValue : 'HH:MM' }}
        </p>
      </div>
      <ngx-material-timepicker
        #picker
        [theme]="timePickerTheme"
        (timeSet)="setTime($event)"
        [format]="24"
        [minutesGap]="5"></ngx-material-timepicker>
    </div>
  </div>
</div>

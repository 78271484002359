import { ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { CommonModule, NgForOf } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';
import { MapInteractionService } from '../../services/map-interaction.service';
import { MapFeatureService } from '../../services/map-feature.service';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DrawingService, DrawingTypes } from '../../drawing.service';

interface Action {
  action: () => any;
}

type DrawingOverlayButton = Action & {
  iconName: string;
  disabled: boolean;
  secondary: Observable<boolean>;
};

@Component({
  selector: 'app-drawing-action-bar',
  standalone: true,
  imports: [CommonModule, MatDivider, NgForOf, MatIcon, MatIconButton],
  template: `
    <div class="box-border z-10 w-full h-12 flex flex-row justify-between items-center px-4 bg-[#414042E5]">
      <!--        right       -->
      <div class="flex-row justify-center items-center"></div>
      <!--        left        -->
      <div class="flex flex-row justify-center items-center gap-4">
        <ng-container *ngFor="let actionButton of drawingOverlayButtons.left">
          <ng-container
            [ngTemplateOutlet]="actionButtonTemplate"
            [ngTemplateOutletContext]="{ actionButton: actionButton }"></ng-container>
        </ng-container>
        <mat-divider [vertical]="true"></mat-divider>
        <div style="background-color: white; width: 2px; height: 30px"></div>
        <ng-container *ngFor="let actionButton of drawingOverlayButtons.center">
          <ng-container
            [ngTemplateOutlet]="actionButtonTemplate"
            [ngTemplateOutletContext]="{ actionButton: actionButton }"></ng-container>
        </ng-container>
        <div style="background-color: white; width: 2px; height: 30px"></div>
        <ng-container *ngFor="let actionButton of drawingOverlayButtons.right">
          <ng-container
            [ngTemplateOutlet]="actionButtonTemplate"
            [ngTemplateOutletContext]="{ actionButton: actionButton }"></ng-container>
        </ng-container>
      </div>
    </div>
    <ng-template #actionButtonTemplate let-actionButton="actionButton">
      <button
        class="group header-menu-button"
        mat-icon-button
        (click)="actionButton.action()"
        disabled="{{ actionButton.disabled }}"
        [ngClass]="{ secondary: (actionButton.secondary | async) }">
        <!--    [style.cursor]="button.disabled ? 'not-allowed' : 'pointer'"-->
        <mat-icon
          class="mat-icon {{
            (actionButton.secondary | async) ? 'text-accent-icons' : 'text-white'
          }} group-hover:text-accent-icons group-disabled:text-opacity-50 group-disabled:cursor-not-allowed"
          svgIcon="{{ actionButton.iconName }}"
          style="height: 24px !important; width: 24px !important"></mat-icon>
      </button>
    </ng-template>
  `,
  styleUrl: 'drawing-action-bar.component.scss',
})
export class DrawingActionBarComponent implements OnDestroy {
  // IO
  @Output() saveClick = new EventEmitter<void>();
  // services
  private dialog: MatDialog = inject(MatDialog);
  private cdr = inject(ChangeDetectorRef);
  private drawingService = inject(DrawingService);
  private featureService: MapFeatureService = inject(MapFeatureService);
  private interactionService: MapInteractionService = inject(MapInteractionService);

  // observables
  private destroy$ = new Subject<void>();
  private undo$ = new Subject<void>();
  private redo$ = new Subject<void>();

  // members
  private undoDisabled: boolean = true;
  private redoDisabled: boolean = true;
  protected fireClearChanges: () => void;
  protected drawingOverlayButtons: Record<string, DrawingOverlayButton[]>;
  protected drawingMode: 'enabled' | 'disabled' = 'disabled';

  constructor() {
    const boundEnableDrawing = (this.fireClearChanges = () => {
      const action = () => {
        this.featureService.clearSession.bind(this.featureService)();
        this.interactionService.endInteraction.bind(this.interactionService)();
      };
      if (this.featureService.hasUnsavedChanges) {
        this.openDialog('UNSAVED CHANGES', 'Clear all unsaved changes?', action, 'clear');
      } else {
        action();
      }
    });
    this.featureService.addUndoTrigger(this.undo$.pipe(tap(() => this.interactionService.endInteraction())), this.destroy$);
    this.featureService.addRedoTrigger(this.redo$.pipe(tap(() => this.interactionService.endInteraction())), this.destroy$);

    this.drawingOverlayButtons = {
      left: [
        {
          iconName: 'unlock',
          action: () => this.interactionService.toggleViewLock(),
          disabled: false,
          secondary: this.interactionService.viewLocked$,
        },
      ],
      center: [
        {
          iconName: 'undo',
          action: () => this.undo$.next(),
          disabled: this.undoDisabled,
          secondary: of(false),
        },
        {
          iconName: 'redo',
          action: () => this.redo$.next(),
          disabled: this.redoDisabled,
          secondary: of(false),
        },
      ],
      right: [
        {
          iconName: 'trash',
          action: () => this.fireClearChanges(),
          disabled: this.undoDisabled && this.redoDisabled,
          secondary: of(false),
        },
        {
          iconName: 'save',
          action: () => this.saveClick.emit(),
          disabled: this.undoDisabled && this.drawingService.drawingType === DrawingTypes.map,
          secondary: of(false),
        },
      ],
    };

    this.featureService
      .getUndoRedoStackLengths()
      .pipe(takeUntil(this.destroy$))
      .subscribe(([undoLength, redoLength]) => {
        this.undoDisabled = undoLength === 0;
        this.redoDisabled = redoLength === 0;
        this.drawingOverlayButtons.center[0].disabled = this.undoDisabled;
        this.drawingOverlayButtons.center[1].disabled = this.redoDisabled;
        this.drawingOverlayButtons.right[0].disabled = this.redoDisabled && this.undoDisabled;
        this.drawingOverlayButtons.right[1].disabled =
          this.undoDisabled && this.drawingService.drawingType === DrawingTypes.map;
        this.drawingOverlayButtons = { ...this.drawingOverlayButtons };
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDialog(title: string, message: string, action: () => void, confirmText: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: title,
        message: message,
        confirmText: confirmText,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action();
      }
    });
  }

  onExitClick() {
    const action = () => {
      this.drawingService.drawingModeEnabled = false;
    };
    if (this.featureService.hasUnsavedChanges) {
      this.openDialog('UNSAVED CHANGES', 'you will lose your unsaved changes if you continue.', action, 'continue?');
    } else {
      action();
    }
  }
}

<div style="height: 100%; overflow: hidden">
  <div class="ticket-details-page-header">
    <mat-toolbar
      fxShow.lt-sm="true"
      color="primary"
      class="mat-elevation-z6"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-toolbar-row fxFlex="50" style="height: 48px">
        <h2 class="toolbar-ticket-header  text-headline-6 capitalize font-semibold">
          {{ RequestNumber }} - {{ PrimaryID }}
        </h2>
      </mat-toolbar-row>

      <mat-toolbar-row
        fxLayout="row"
        fxFlex="50"
        fxLayoutAlign="end center"
        style="height: 48px"
      >
        <button
          *ngIf="showMakeLoadsheet"
          mat-icon-button
          (click)="makeLoadsheet()"
        >
          <mat-icon>description</mat-icon>
        </button>
        <button *ngIf="showMakeInvoice" mat-icon-button (click)="makeInvoice()">
          <mat-icon>request_quote</mat-icon>
        </button>

        <button
          mat-icon-button
          [disabled]="isUtilityUser"
          (click)="startSync(true, true)"
        >
          <div
            *ngIf="
              formGroupValuesChanged;
              then syncWithChanges;
              else syncWithoutChanges
            "
          ></div>
          <ng-template #syncWithoutChanges>
            <mat-icon>sync</mat-icon>
          </ng-template>

          <ng-template #syncWithChanges>
            <mat-icon matBadge="!" matBadgeSize="medium" matBadgeColor="warn"
              >sync</mat-icon
            >
          </ng-template>
        </button>
        <button
          *ngIf="props.isModal || IFrameModal"
          mat-icon-button
          [disabled]="isUtilityUser"
          (click)="onModalClose()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div
    *ngIf="isValidState; else invalidState"
    class="panel-container"
    fxLayout="column"
  >
    <app-ticket-summary
      fxFlex="156px"
      [props]="childProps"
      (clear)="onHandleClearTicket($event)"
      (toggleStartEndTime)="handleTimeInOutChange($event)"
    >
    </app-ticket-summary>

    <div fxFill>
      <mat-tab-group
        mat-stretch-tabs
        [backgroundColor]="navBackground"
        class="h-[calc(100%-196px)] border-solid border-b border-t-0 border-x-0 border-accent-lighter"
      >
        <mat-tab *ngIf="tabs.includes('ticketInfo')" label="Ticket Details" class="">
          <app-ticket-work-details
            #ticketWorkDetailsComponent
            [props]="childProps"
            (formGroupChanged)="handleFinishEditMode($event)"
            (finishAssignUserToTicket)="assignTicketToUser($event)"
            (finishSendToLSP)="sendToLSP($event)"
            (cancelTicketEvent)="onCancelTicketEvent($event)"
            (uncompleteEvent)="onUncompleteEvent()"
            (markAsOfficeOngoingEvent)="markAsOfficeOngoingEvent()"
            (syncAfterAction)="startSync(true, true)"
          ></app-ticket-work-details>
        </mat-tab>
        <mat-tab *ngIf="tabs.includes('digsiteDetails')" label="Digsite">
          <app-ticket-digsite-details
            [props]="childProps"
          ></app-ticket-digsite-details>
        </mat-tab>
        <mat-tab *ngIf="tabs.includes('ticketMap')" label="Map">
          <app-map
            [props]="childProps"
            (newAutologEvent)="newAutologEvent($event)"
            (newDocumentEvent)="newDocumentEvent($event)"
          >
          </app-map>
        </mat-tab>
        <mat-tab *ngIf="tabs.includes('documentDetails')" label="Documents">
          <app-ticket-documents-details
            [props]="childProps"
            (syncAfterUploadDocs)="startSync(true, true)"
            (newAutologEvent)="newAutologEvent($event)"
          >
          </app-ticket-documents-details>
        </mat-tab>
        <mat-tab *ngIf="tabs.includes('autologDetails')" label="Autologs">
          <app-ticket-autolog-details
            [props]="childProps"
            (newAutologEvent)="newAutologEvent($event)"
          ></app-ticket-autolog-details>
        </mat-tab>
        <mat-tab *ngIf="showPreCompletions" label="PreCompletions">
          <app-ticket-pre-completions-details
            [props]="childProps"
            (formGroupChanged)="handleFinishEditMode($event)"
            [ticketProtectionMode]="this.TICKET_PROTECTION_MODE"
          ></app-ticket-pre-completions-details>
        </mat-tab>
        <mat-tab *ngIf="tabs.includes('completionDetails')" label="Completion">
          <app-ticket-completions-details
            [props]="childProps"
            [ticketProtectionMode]="this.TICKET_PROTECTION_MODE"
            (syncAfterAction)="startSync(true, true)"
            [allowEditingCompletionDetails]="this.allowEditingCompletionDetails"
            (formGroupChanged)="handleFinishEditMode($event)"
            (markAsCompleteEvent)="markAsCompleteEvent($event)"
          ></app-ticket-completions-details>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #invalidState>
  <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
    <mat-icon style="font-size: 150px; height: 150px; width: 150px"
      ><img id="privacyIcon"
    /></mat-icon>
    <p class="mat-headline-2">403</p>
    <p class="mat-headline-2">accès refusé</p>
    <p class="mat-headline-2">ACCESS DENIED</p>
  </div>
</ng-template>

<app-base-modal #dialog [id]="CLEAR_TICKET_FAILED_MODAL_ID" maxwidth="360px">
  <ng-container class="competers-modal-content">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <span class="mat-subtitle-1">Failed to Clear Ticket</span>
      <span>{{ clearTicketFailMessage }}</span>
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button mat-flat-button color="primary" (click)="dialog.close()">
          Cancel
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { sections } from '../../../../shared/components/maps/open-layers/ticket-map/ticket-pin-legend/content';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { TicketMapService } from '../../../../shared/components/maps/open-layers/ticket-map/ticket-map.service';

@Component({
  selector: 'app-pin-color-popup',
  standalone: true,
  imports: [AsyncPipe, FormsModule, MatIcon],
  template: `
    <div
      class="pointer-events-auto box-border flex flex-col gap-2 justify-start items-center {{
        (myService.menuSelection$ | async).includes(3) ? 'w-fit h-auto p-4' : 'size-0 overflow-hidden'
      }} absolute bg-white left-full top-0 rounded-r-md lg:rounded-none lg:rounded-t-md lg:left-0 lg:top-auto lg:bottom-full z-50 transition-all">
      <div class="box-border w-full flex flex-row justify-between items-center">
        <span class="text-primary text-lg  font-semibold capitalize text-left">Ticket Color Scheme</span>
        <button
          (click)="openTicketPinLegend.emit()"
          class="appearance-none border-none bg-transparent cursor-pointer rounded-full
               {{ (myService.menuSelection$ | async).includes(3) ? 'p-2' : '' }}
               ">
          <mat-icon class="text-primary">help</mat-icon>
        </button>
      </div>
      <select
        [ngModel]="myService.ticketPinColourScheme$ | async"
        (ngModelChange)="myService.ticketPinColourScheme = $event"
        class="pointer-events-auto cursor-pointer bg-white border border-primary text-primary text-lg  font-semibold capitalize rounded-md focus:ring-primary-lighter block h-fit w-40 p-1.5 overflow-hidden">
        @for (section of sections; track $index) {
          @if (myService.checkSetting(section.title)) {
          <option [value]="$index" class="h-8 font-normal">
            {{ section.title }}
          </option>
          }
        }
      </select>
    </div>
  `,
})
export class PinColourPopupComponent {
  @Input() myService: TicketMapService;
  @Input() sections = sections;
  @Output() openTicketPinLegend = new EventEmitter<void>();
}
